/**
 * Copyright 2020 AXA Group Operations S.A.
 *
 * Licensed under the Apache License, Version 2.0 (the "License")
 * you may not use this file except in compliance with the License
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

const getInitialState = () => ({
  notifications: []
});

export default {
  namespaced: true,
  state: getInitialState(),
  actions: {
    reset: ({ commit }) => commit('RESET'),
    add: ({ commit }, notification) => commit('ADD', notification),
    addMap: ({ commit }, notifications) =>
      notifications.map((n) => commit('ADD', n)),
    remove: ({ commit }, identifier) => commit('REMOVE', identifier)
  },
  getters: {
    hasUnreadNotifications: (state) =>
      state.notifications.filter((n) => n.unread).length > 0,
    unreadNotifications: (state) => state.notifications.filter((n) => n.unread)
  },
  mutations: {
    RESET: (state) => Object.assign(state, getInitialState()),
    ADD: (state, notification) => {
      state.notifications = [...state.notifications, notification];
    },
    REMOVE: (state, identifier) => {
      state.notifications = state.notifications.filter(
        (n) => n.identifier !== identifier
      );
    }
  }
};
