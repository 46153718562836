/**
 * Copyright 2020 AXA Group Operations S.A.
 *
 * Licensed under the Apache License, Version 2.0 (the "License")
 * you may not use this file except in compliance with the License
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

/* eslint-disable no-param-reassign */
import actions from './product.actions';
import getters from './product.getters';
import mutations from './product.mutations';

const getInitialState = () => ({
  sidebarIsVisible: true,
  requestWarnings: false,
  inspectorIsVisible:
    // eslint-disable-next-line eqeqeq
    window.localStorage.getItem('RuleEditorShowInspector') === 'true',
  ruleDisplayType:
    window.localStorage.getItem('RuleEditorDisplayType') || 'graph',
  product: null,
  isLoading: false,
  error: null,
  testCoverageMode: false,
  graphWithId: {},
  warnings: {},
  complexity: {},
  complexityAllowed: false,
  ruleId: undefined,
  sharedProperties: [],
  technicalNameValidationError: null,
  activeConfigTab: null,
  copilotStatus: undefined,
  extractedDimensions: undefined,
  extractingDimensions: undefined
});

export default {
  namespaced: true,
  state: getInitialState(),
  getters,
  actions,
  mutations
};
