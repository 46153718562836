/**
 * Copyright 2020 AXA Group Operations S.A.
 *
 * Licensed under the Apache License, Version 2.0 (the "License")
 * you may not use this file except in compliance with the License
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

const moment = require('moment');

const isIsoDate = (str) => {
  if (!/\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(.\d{3})?Z/.test(str)) return false;
  const d = new Date(str);
  return d.toISOString() === str;
};

const dateFormatter = (date, format = 'lll') => {
  const defaultOffset = new Date().getTimezoneOffset();
  const serverOffset = 60;
  const offset = (defaultOffset + serverOffset) * -1;
  const then = moment(date).add(isIsoDate(date) ? 0 : offset, 'm');
  const now = moment();
  return now.diff(then) > 24 * 3600 * 1000
    ? then.format(format)
    : then.fromNow();
};

module.exports = {
  dateFormatter
};
