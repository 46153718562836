/**
 * Copyright 2020 AXA Group Operations S.A.
 *
 * Licensed under the Apache License, Version 2.0 (the "License")
 * you may not use this file except in compliance with the License
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

/* eslint-disable consistent-return */
import types from './tokens.types';
import feathersClient from '../../feathers';

export default {
  fetchTokens: async ({ commit }, tenant) => {
    commit(types.FETCH_TOKENS_REQUEST);
    try {
      const findOpts = tenant ? { query: { tenant } } : {};
      const tokens = await feathersClient.service('tokens').find(findOpts);
      commit(types.FETCH_TOKENS_SUCCESS, {
        tokens
      });
    } catch (e) {
      commit(types.FETCH_TOKENS_ERROR);
    }
  },
  createToken: async ({ commit }, tokenData) => {
    commit(types.CREATE_TOKENS_REQUEST);
    try {
      const token = await feathersClient.service('tokens').create(tokenData);
      commit(types.CREATE_TOKENS_SUCCESS);
      return token;
    } catch (e) {
      commit(types.CREATE_TOKENS_ERROR);
    }
  },
  deleteToken: async ({ commit }, tokenId) => {
    commit(types.DELETE_TOKENS_REQUEST);
    try {
      const token = await feathersClient.service('tokens').remove(tokenId);
      commit(types.DELETE_TOKENS_SUCCESS);
      return token;
    } catch (e) {
      commit(types.DELETE_TOKENS_ERROR);
    }
  }
};
