<template>
  <el-dialog
    visible
    :before-close="() => $emit('close')"
    width="50%"
    :title="`Import term(s) for ${property}`"
  >
    <el-tabs v-model="activeTab">
      <el-tab-pane label="From definition List" name="from-definition-list">
        <p class="pb-3">{{ $t('product.terms.import_from_existing') }}</p>
        <el-form label-width="200px">
          <el-form-item label="Definition List">
            <span v-if="!useDefinitionListSelect">{{
              definitionListName
            }}</span>
            <el-select
              v-else
              v-model="forms.fromDefinitionList.definitionListId"
              filterable
            >
              <el-option
                v-for="orderedDefinitionList in orderedDefinitionLists"
                :key="orderedDefinitionList._id"
                :value="orderedDefinitionList._id"
                :label="orderedDefinitionList.name"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="Column">
            <el-select
              v-if="existColumnsInSelectedDeflist"
              v-model="forms.fromDefinitionList.columnName"
              :disabled="!forms.fromDefinitionList.definitionListId"
            >
              <el-option
                v-for="column in columnsInSelectedDefinitionList"
                :key="column"
                :label="column"
                :value="column"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="Terms">
            <el-checkbox
              v-if="
                Object.keys(termsForSelectedDefinitionListColumn).length > 0
              "
              v-model="checkAllTerms"
              :indeterminate="isIndeterminate"
              @change="handleCheckAllTermsChange"
            >
              {{ $t('product.terms.check_all') }}
            </el-checkbox>
            <div v-else style="color: #ccc; word-break: break-word">
              {{ $t('product.terms.available_terms') }}
            </div>
            <el-checkbox-group
              v-model="termsToImport"
              size="small"
              @change="handleCheckedTermsChange"
            >
              <el-checkbox
                v-for="(defs, term) in termsForSelectedDefinitionListColumn"
                :key="term"
                :value="term"
                :label="term"
                :disabled="isCheckboxDisabled(defs, term)"
              >
                <el-tooltip
                  v-if="isCheckboxDisabled(defs, term)"
                  class="item"
                  effect="dark"
                  :content="$t('product.terms.term_already_assigned')"
                  placement="top-start"
                  ><div>{{ term }} ({{ defs.length }})</div>
                </el-tooltip>
                <div v-else>{{ term }} ({{ defs.length }}</div>
              </el-checkbox>
            </el-checkbox-group>
          </el-form-item>
          <el-form-item>
            <el-button
              type="primary"
              size="mini"
              :disabled="!termsToImport.length"
              @click="createMultipleTermsFromDefinitionListColumn"
            >
              Import {{ termsToImport.length }} term(s)
            </el-button>
          </el-form-item>
        </el-form>
        <div v-if="!validColumns" class="invalid-columns">
          {{ $t('product.terms.invalid_columns_for_import') }}
        </div>
      </el-tab-pane>

      <el-tab-pane label="From other property" name="from-dimension">
        <p class="pb-3">{{ $t('product.terms.import_from_property') }}</p>
        <el-form label-width="200px">
          <el-form-item label="Property">
            <el-select v-model="forms.fromDimension.dimension">
              <el-option-group
                v-for="(item, key) in importableProperties"
                :key="key"
                :value="key"
                :label="item.displayName || item.name"
              >
              </el-option-group>
            </el-select>
          </el-form-item>
          <el-form-item label="Term">
            <el-select v-model="forms.fromDimension.selectedTermId">
              <el-option-group
                v-for="term in importableTermsSelectedProperty"
                :key="term._id"
                :value="term._id"
                :label="term.name"
                :disabled="termAlreadyAdded(term.name)"
              >
              </el-option-group>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button
              type="primary"
              size="mini"
              :disabled="!forms.fromDimension.selectedTermId"
              @click="createTermImportedFromDimension"
            >
              Import</el-button
            >
          </el-form-item>
        </el-form>
      </el-tab-pane>
    </el-tabs>
  </el-dialog>
</template>

<script>
import { Message } from 'element-ui';
import { groupBy } from 'lodash';
import { mapActions, mapState } from 'vuex';
import * as api from '../api';
import TermMixin from './TermMixin';

export default {
  mixins: [TermMixin],
  props: {
    property: {
      type: String,
      required: true
    },
    product: {
      type: Object,
      required: true
    },
    definitionList: {
      type: Object,
      default: null
    },
    definitions: {
      type: Array,
      default() {
        return [];
      }
    }
  },
  data() {
    return {
      checkAllTerms: false,
      isIndeterminate: false,
      activeTab: 'from-definition-list',
      forms: {
        fromDefinitionList: {
          definitionListId: null,
          columnName: null
        },
        fromDimension: {
          dimension: null,
          selectedTerm: null,
          selectedTermId: null
        }
      },
      selectedDefinitionList: null,
      selectedProductTerms: null,
      termsToImport: [],
      importableProperties: {},
      importablePropertiesTerms: {},
      importableTermsSelectedProperty: []
    };
  },
  computed: {
    ...mapState('auth', ['tenant']),
    existColumnsInSelectedDeflist() {
      return !!this.selectedDefinitionList;
    },
    definitionListName() {
      return (this.definitionList && this.definitionList.name) || '';
    },
    useDefinitionListSelect() {
      // eslint-disable-next-line no-underscore-dangle
      return !(this.definitionList && this.definitionList._id);
    },
    itemsInSelectedDefinitionList() {
      if (
        !this.selectedDefinitionList ||
        !this.selectedDefinitionList.items ||
        !this.selectedDefinitionList.items[0]
      ) {
        return [];
      }
      return this.selectedDefinitionList.items;
    },
    columnsInSelectedDefinitionList() {
      if (!this.selectedDefinitionList) {
        return [];
      }
      const [firstRow] = this.itemsInSelectedDefinitionList;
      const { primaryKeyColumn } = this.selectedDefinitionList;

      return firstRow && firstRow.columns
        ? Object.keys(firstRow.columns).filter((c) => c !== primaryKeyColumn)
        : [];
    },

    termsForSelectedDefinitionListColumn() {
      const { columnName } = this.forms.fromDefinitionList;
      if (!columnName) {
        return {};
      }

      this.itemsInSelectedDefinitionList.map((df) => {
        const termInUse = this.termAlreadyAdded(
          df.columns[columnName],
          columnName,
          Object.values(
            groupBy(this.itemsInSelectedDefinitionList, `columns.${columnName}`)
          ).length === 1
        );
        // eslint-disable-next-line no-param-reassign
        df.termInUse = termInUse;
        return df;
      });

      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.termsToImport = [];

      return groupBy(
        this.itemsInSelectedDefinitionList,
        `columns.${columnName}`
      );
    },
    validColumns() {
      return this.columnsInSelectedDefinitionList.length > 0;
    },
    newTerms() {
      return Object.keys(this.termsForSelectedDefinitionListColumn);
    },
    orderedDefinitionLists() {
      return this.definitions
        .filter((item) => item.name)
        .sort((a, b) => a.name.localeCompare(b.name));
    }
  },
  watch: {
    'forms.fromDefinitionList.definitionListId': {
      handler() {
        this.fetchSelectedDefinitionList();
      }
    },
    'forms.fromDimension.dimension': {
      handler() {
        this.forms.fromDimension.selectedTermId = null;
        this.forms.fromDimension.selectedTerm = null;

        const selectedDim = this.forms.fromDimension.dimension;

        const terms = this.importablePropertiesTerms[selectedDim]
          ? this.importablePropertiesTerms[selectedDim].sort((a, b) => {
              return a.name.localeCompare(b.name);
            })
          : [];

        this.importableTermsSelectedProperty = terms;
      }
    },
    'forms.fromDimension.selectedTermId': {
      handler(val) {
        [this.forms.fromDimension.selectedTerm] =
          this.importableTermsSelectedProperty.filter(
            // eslint-disable-next-line no-underscore-dangle
            (term) => val === term._id
          );
      }
    }
  },
  async mounted() {
    this.forms.fromDefinitionList.definitionListId =
      // eslint-disable-next-line no-underscore-dangle
      (this.definitionList && this.definitionList._id) || '';
    const { data } = await api.getDimensionsAggregations(
      this.product.id,
      this.tenant
    );

    const allProperties = data.properties;

    const filteredProperties = {};
    Object.keys(allProperties).forEach((key) => {
      if (
        // eslint-disable-next-line no-underscore-dangle
        allProperties[key].definitionListId === this.definitionList._id &&
        allProperties[key].name !== this.property
      ) {
        filteredProperties[key] = allProperties[key];
      }
    });

    this.importableProperties = filteredProperties;
    this.importablePropertiesTerms = data.terms;
  },
  methods: {
    ...mapActions('product', ['refreshProduct']),
    isCheckboxDisabled(defs) {
      for (let i = 0; i < defs.length; i += 1) {
        if (defs[0].termInUse === true) {
          return true;
        }
      }
      return false;
    },
    close() {
      this.$emit('close');
    },
    async fetchSelectedDefinitionList() {
      this.selectedDefinitionList = null;
      this.forms.fromDefinitionList.columnName = null;
      const { definitionListId } = this.forms.fromDefinitionList;
      const { data } = await api.getDefinitionList(definitionListId, {
        tenant: this.tenant
      });
      this.selectedDefinitionList = data;
    },
    async createMultipleTermsFromDefinitionListColumn() {
      const { property } = this;
      const requestData = this.termsToImport.map((name) => {
        if (this.termAlreadyAdded(name)) {
          return;
        }
        const definitions = this.termsForSelectedDefinitionListColumn[name];
        // eslint-disable-next-line consistent-return
        return { name, dimension: property, definitions };
      });

      await api.createTerm(this.product.id, requestData, this.tenant);

      await this.refreshProduct({
        productId: this.product.id
      });

      Message.success(
        `Successfully created ${this.termsToImport.length} term(s)`
      );

      this.$emit('update', { fetchProductRequired: true });
      this.$emit('close');
    },
    async createTermImportedFromDimension() {
      const { property } = this;
      const term = this.forms.fromDimension.selectedTerm;

      if (this.termAlreadyAdded(term.name)) {
        return;
      }

      const data = {
        name: term.name,
        dimension: property,
        definitions: term.definitions
      };

      await api.createTerm(this.product.id, data, this.tenant);

      Message.success(
        `Successfully created ${this.termsToImport.length} term(s)`
      );
      this.$emit('update');
      this.$emit('close');
    },
    handleCheckAllTermsChange(val) {
      const termsForSelectedDefinitionListColumnFiltered = Object.keys(
        this.termsForSelectedDefinitionListColumn
      ).filter((t) => {
        for (
          let i = 0;
          i < this.termsForSelectedDefinitionListColumn[t].length;
          i += 1
        ) {
          const currentTermToEvaluate =
            this.termsForSelectedDefinitionListColumn[t][i];
          if (
            currentTermToEvaluate.termInUse &&
            currentTermToEvaluate.termInUse === true
          ) {
            return false;
          }
          return true;
        }
        return true;
      });

      this.termsToImport = val
        ? termsForSelectedDefinitionListColumnFiltered
        : [];

      this.isIndeterminate = false;
    },
    handleCheckedTermsChange(value) {
      const checkedCount = value.length;
      this.checkAllTerms =
        checkedCount ===
        Object.keys(this.termsForSelectedDefinitionListColumn).length;
      this.isIndeterminate =
        checkedCount > 0 &&
        checkedCount <
          Object.keys(this.termsForSelectedDefinitionListColumn).length;
    }
  }
};
</script>
<style scoped>
.invalid-columns {
  word-break: break-word;
}
</style>
