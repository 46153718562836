/**
 * Copyright 2020 AXA Group Operations S.A.
 *
 * Licensed under the Apache License, Version 2.0 (the "License")
 * you may not use this file except in compliance with the License
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { IfNodeFactory, IfNode } from './index';
import linkStyleForNode from './AnnotationStyle';

export default class AnnotatedIfNode extends IfNode {
  linkStyle() {
    return linkStyleForNode(this);
  }
}

export class AnnotatedIfNodeFactory extends IfNodeFactory {
  nodeFromModel(graph, parent, model) {
    return new AnnotatedIfNode(
      graph,
      parent,
      {
        ...model,
        type: 'if',
        source: model.source || 'CGI'
      },
      this.configuration
    );
  }

  // eslint-disable-next-line
  newNodeWithTemplate(graph, node) {
    return new AnnotatedIfNode(
      graph,
      null,
      {
        type: 'if',
        source: 'CGI'
      },
      this.configuration
    );
  }
}
