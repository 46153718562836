<template>
  <div class="container py-3">
    <router-link :to="{ name: 'product-terms-index' }">
      &larr; Back
    </router-link>
  </div>
</template>

<script>
export default {};
</script>
