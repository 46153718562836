<template>
  <div class="home d-flex flex-column flex-grow-1 flex-shrink-1 overflow-hidden">
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'Home'
};
</script>

<style scoped lang="scss">
@import '@axatechlab/assets/scss/_variables';

.nav-bar {
  width: 100%;
  min-height: 64px;
  display: flex;
  flex-flow: row nowrap;
  align-items: stretch;
  justify-content: flex-end;
}
</style>
