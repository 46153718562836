const validTabName = ['RELEASES', 'REVISIONS'];

const getInitialState = () => ({
  currentTab: 'RELEASES'
});

export default {
  namespaced: true,
  state: getInitialState(),
  actions: {
    reset: ({ commit }) => commit('RESET'),
    changeTab: async ({ commit }, tabName) => {
      commit('CHANGE_TAB', tabName);
    }
  },
  getters: {},
  mutations: {
    RESET: (state) => Object.assign(state, getInitialState()),
    CHANGE_TAB(state, tabName) {
      if (!validTabName.includes(tabName)) throw new Error('Invalid Tab Name');
      state.currentTab = tabName;
    }
  }
};
