<template>
  <el-form :inline="true" class="UseAsTemplate">
    <UiToggle
      v-model="templateMode"
      :label="$t('product.use_as_template')"
      :disabled="isReadOnly"
      class="ui_switch_use_as_template"
      @change="update"
    />
  </el-form>
</template>

<script>
export default {
  name: 'UseAsTemplate',
  props: {
    isTemplate: { type: Boolean, default: false },
    isReadOnly: { type: Boolean, default: false },
    templates: { type: Array, required: true }
  },
  data() {
    return {
      templateMode: this.isTemplate
    };
  },
  async mounted() {
    this.templateMode = this.isTemplate;
  },
  methods: {
    update() {
      this.$emit('update', {
        isTemplate: this.templateMode
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.UseAsTemplate {
  display: flex;

  .el-form-item {
    &:last-child {
      margin-right: 0;
    }
  }
}
</style>
