<template>
  <div v-selector.view class="container">
    <h2 class="py-3">Not Found</h2>
    <p>Go back to <router-link to="/"> home page </router-link>.</p>
  </div>
</template>

<script>
export default {
  name: 'NotFound'
};
</script>
