import { retry } from 'retry-ignore-abort';
import { MessageBox } from 'element-ui';
import Cookies from 'js-cookie';
import { acceptLanguage } from '../i18n';

import { impersonateIfValueInLocalStorage } from '../axios';

let unauthorizedCallback;

export const setUnauthorizedCallback = (cb) => {
  unauthorizedCallback = cb;
};

const showMessageBox = (settings) => {
  MessageBox({
    duration: 5000,
    type: 'error',
    title: 'Network Error',
    dangerouslyUseHTMLString: true,
    message: `Please check that the API server is running and accessible on
                  <a target="_blank" rel="noopener noreferrer" href="${settings.baseURL}">${settings.baseURL}</a>`
  });
};

const applyRequestHeaders = (request) => async () => {
  const AUTH_COOKIE = window.__env__.VUE_APP_AUTH_COOKIE;

  const authorization = Cookies.get(AUTH_COOKIE);
  if (!authorization) return unauthorizedCallback();
  return {
    ...request,
    headers: {
      ...request.headers,
      'Accept-Language': acceptLanguage(),
      Authorization: authorization
    }
  };
};

export const configureClient = (client, settings) => {
  client.interceptors.response.use(
    (response) => response,
    (error) => {
      const { response = { status: undefined, data: '' }, message } = error;
      if (response.status === 401 && unauthorizedCallback) {
        unauthorizedCallback();
      }

      if (
        error.message === 'Network Error' ||
        response.data.toString().match(/ECONNREFUSED/)
      ) {
        showMessageBox(settings);
      }

      if (
        ['Test request cancelled', 'Check rules request cancelled'].includes(
          message
        )
      ) {
        // eslint-disable-next-line no-console
        console.log(message);
        return;
      }
      // eslint-disable-next-line consistent-return
      return Promise.reject(response ? response.data : error);
    }
  );

  client.interceptors.request.use((request) => {
    impersonateIfValueInLocalStorage(client);
    return retry(applyRequestHeaders(request)).then((r) => r);
  });
};
