import getByPath from './getByPath';

export default (fields) => (a, b) =>
  fields
    .map((o) => {
      let dir = 1;
      if (o[0] === '-') {
        dir = -1;
        // eslint-disable-next-line no-param-reassign
        o = o.substring(1);
      }
      if (getByPath(a, o) > getByPath(b, o)) return dir;
      if (getByPath(a, o) < getByPath(b, o)) return -dir;
      return 0;
    })
    .reduce((p, n) => p || n, 0);
