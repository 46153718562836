/**
 * Copyright 2020 AXA Group Operations S.A.
 *
 * Licensed under the Apache License, Version 2.0 (the "License")
 * you may not use this file except in compliance with the License
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

const sortByFieldname = (data, fieldName, asc) => {
  if (Array.isArray(data.length) && data.lengt.length === 0) return data;
  return data.sort((a, b) => {
    const valB = b[fieldName] ? `${b[fieldName]}` : '';
    const valA = a[fieldName] ? `${a[fieldName]}` : '';
    if (asc && (asc === 'desc' || asc === 'descending')) {
      return valB.toLowerCase().localeCompare(valA.toLowerCase());
    }
    return valA.toLowerCase().localeCompare(valB.toLowerCase());
  });
};
module.exports = { sortByFieldname };
