const definitionOrderPriorities = (maxPriorityIndex = 10, definitions = []) => {
  const currentPrioritiesLength = (definitions || []).filter(
    (d) => d.priority !== null
  ).length;

  const nbLeft =
    definitions.length - currentPrioritiesLength < maxPriorityIndex
      ? definitions.length - currentPrioritiesLength
      : maxPriorityIndex;

  return { nbLeft, currentPrioritiesLength };
};

module.exports = { definitionOrderPriorities };
