/**
 * Copyright 2020 AXA Group Operations S.A.
 *
 * Licensed under the Apache License, Version 2.0 (the "License")
 * you may not use this file except in compliance with the License
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { mapState } from 'vuex';

// @todo move hardcoded labels to translations files
const typeMapping = {
  input: 'Input',
  output: 'Output',
  unit: 'Unit',
  constant: 'Constant',
  dimension: 'Dimension',
  constraint: 'Endorsment'
};

// @todo move hardcoded labels to translations files
const subTypeMapping = {
  ask: 'Ask',
  enum: 'Dimension',
  insuranceOutput: 'Output',
  textOutput: 'Output',
  rule: 'Rule',
  exclusion: 'Exclusion'
};

export default {
  computed: {
    ...mapState('definitions', ['definitionItems']),
    ...mapState('productProperty', ['dimensions'])
  },
  methods: {
    isProperty(type) {
      return ['unit', 'input', 'output', 'dimension', 'constant'].includes(
        type
      );
    },
    highlight(value) {
      if (!value || value.trim() === '') return value;
      return value
        .replaceAll('{{ ', "<strong class='s'>")
        .replaceAll(' }}', '</strong>');
    },
    typeMapping(type) {
      const t = type.trim();
      return typeMapping[t] || t;
    },
    subTypeMapping(subtype) {
      const s = subtype.trim();
      return subTypeMapping[s] || s;
    }
  }
};
