<template>
  <div>
    <el-form ref="form" :model="formData" :rules="rules" :disabled="readonly">
      <template v-for="(question, index) in modelQuestions">
        <question
          :key="`${question.id}-${index}`"
          :question="question"
          @focusQuestion="focusQuestion"
          @answerQuestion="answerQuestion"
          @clearQuestion="clearQuestion"
          @questionAnswered="questionAnswered"
          @inputShow="inputShow"
        ></question>
      </template>
    </el-form>
  </div>
</template>

<script>
import { set } from 'lodash';
import questionRulesMixin from './mixins/questionRulesMixin';
import BooleanQuestion from './models/booleanQuestion.model';
import EnumQuestion from './models/enumQuestion.model';
import MonetaryQuestion from './models/monetaryQuestion.model';
import NumberQuestion from './models/numberQuestion.model';
import DateQuestion from './models/dateQuestion.model';
import DateTimeQuestion from './models/dateTimeQuestion.model';
import QuestionModel from './models/question.model';
import TextQuestion from './models/textQuestion.model';
import Question from './Question.vue';
import { getHumanDateFromTimestamp } from '../../util';

export default {
  name: 'QuestionsForm',
  components: { Question },
  mixins: [questionRulesMixin],
  provide: {
    kbShortcutsEnabled: { val: false },
    onPossible: { val: false }
  },
  props: {
    questions: { type: Array, default: () => [] },
    readonly: { type: Boolean }
  },
  data() {
    return {
      formData: {
        global: {},
        lines: {}
      },
      modelQuestions: []
    };
  },
  computed: {
    rules() {
      return this.getRulesForQuestions(this.modelQuestions);
    },
    form() {
      return this.$refs.form;
    }
  },
  watch: {
    modelQuestions: {
      handler() {
        this.formData = this.modelQuestions.reduce(
          (form, question) => {
            if (question.allowMultiplePerClaim) {
              set(
                form,
                question.formProp(),
                question.response
                  ? question.response.map((r) => r.id)
                  : question.defaultValue
              );
            } else {
              set(
                form.global,
                question.id,
                question.response ? question.response.id : question.defaultValue
              );
            }
            return form;
          },
          { lines: {}, global: {} }
        );
      },
      immediate: true
    }
  },
  mounted() {
    this.questions.forEach((q) => {
      return this.modelQuestions.push(this.makeQuestion(q));
    });
  },
  methods: {
    focusQuestion() {
      this.$emit('focusQuestion');
    },
    answerQuestion(data) {
      this.$emit('answerQuestion', data);
    },
    clearQuestion(question) {
      this.$emit('clearQuestion', question);
    },
    questionAnswered(data) {
      this.$emit('questionAnswered', data);
    },
    inputShow() {
      this.$emit('inputShow');
    },
    makeQuestion: (questionData, stateLocale = 'en', lastPriority = 0) => {
      let question;
      switch (questionData.type) {
        case 'monetary':
          question = new MonetaryQuestion(
            questionData,
            stateLocale,
            lastPriority
          );
          break;
        case 'date':
          question = new DateQuestion(questionData, stateLocale, lastPriority);
          break;
        case 'datetime':
          question = new DateTimeQuestion(
            questionData,
            stateLocale,
            lastPriority
          );
          break;
        case 'text':
          question = new TextQuestion(questionData, stateLocale, lastPriority);
          break;
        case 'number':
          if (
            questionData.metadata &&
            questionData.metadata.some((meta) => meta.key === 'inputMask')
          ) {
            question = new TextQuestion(
              questionData,
              stateLocale,
              lastPriority
            );
            break;
          }
          question = new NumberQuestion(
            questionData,
            stateLocale,
            lastPriority
          );
          break;
        case 'enum':
          question = new EnumQuestion(questionData, stateLocale, lastPriority);
          break;
        case 'boolean':
          question = new BooleanQuestion(
            questionData,
            stateLocale,
            lastPriority
          );
          break;
        default:
          question = new QuestionModel(questionData, stateLocale, lastPriority);
          break;
      }

      return question;
    },
    questionDisplayName(question) {
      const currentQuestion =
        (question.metadata &&
          question.metadata.find((m) => m.key === 'displayName')) ||
        null;
      return currentQuestion ? currentQuestion.value : question.id;
    },
    parseReadableQuestionValue(question) {
      // eslint-disable-next-line no-nested-ternary
      return question.defaultValue === 'true'
        ? this.$t('tests.boolean.true')
        : // eslint-disable-next-line no-nested-ternary
        question.defaultValue === 'false'
        ? this.$t('tests.boolean.false')
        : // eslint-disable-next-line no-nested-ternary
        Array.isArray(question.defaultValue)
        ? question.defaultValue.join(',')
        : question.type === 'date' || question.type === 'datetime'
        ? getHumanDateFromTimestamp(question.defaultValue, question.type)
        : question.defaultValue;
    }
  }
};
</script>
<style scoped>
.labelTitle {
  padding-right: 10px;
}
.labelValue {
  font-style: italic;
}
</style>
