export const FULLSCREEN_LOADING = 'fullscreen';
export const TOP_LOADING = 'top';
export const VIEW_LOADING = 'view';

const validTypes = [FULLSCREEN_LOADING, TOP_LOADING, VIEW_LOADING];

const getInitialState = () => ({
  loading: false,
  icon: null,
  message: null,
  type: FULLSCREEN_LOADING
});

const changeMessage = (state, message, icon) => {
  state.icon = icon && icon.trim();
  state.message = message && message.trim();
};

export default {
  namespaced: true,
  state: getInitialState(),
  actions: {
    reset: ({ commit }) => commit('RESET'),
    show({ commit }, payload = {}) {
      const {
        icon = null,
        message = null,
        type = FULLSCREEN_LOADING
      } = payload;
      if (!validTypes.includes(type)) throw Error('Invalid loading type');
      commit('SHOW_LOADING', { icon, message, type });
    },
    changeMessage({ state, commit }, { icon, message }) {
      if (!state.loading) return;
      commit('CHANGE_MESSAGE', { icon, message });
    },
    hide({ state, commit }) {
      if (!state.loading) return;
      commit('RESET');
    }
  },
  getters: {},
  mutations: {
    RESET: (state) => Object.assign(state, getInitialState()),
    SHOW_LOADING(state, { icon, message, type }) {
      state.loading = true;
      state.type = type;
      changeMessage(state, message, icon);
    },
    CHANGE_MESSAGE(state, { icon, message }) {
      changeMessage(state, message, icon);
    }
  }
};
