/**
 * Copyright 2020 AXA Group Operations S.A.
 *
 * Licensed under the Apache License, Version 2.0 (the "License")
 * you may not use this file except in compliance with the License
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import productStore from '../../store/product';
import authStore from '../../store/auth';
import { indexingClient } from '../../api/searchClient';
import { findAll } from '../../store/products/products.actions';
import { productTypes } from '../../const/product';

const indexProduct = async (tenant, product) => {
  const startedAt = performance.now();
  try {
    await indexingClient(tenant, product.id, true);
    return `"${product.name}" (${product.id}) indexing done, duration=${(
      (performance.now() - startedAt) /
      1000
    ).toFixed(3)}s`;
  } catch (error) {
    return `"${product.name}" (${product.id}) indexing failed`;
  }
};

const productIndexer = () => [
  {
    name: 'product:indexer:build:current',
    desc: 'Rebuild search index for the currently selected product',
    async get() {
      const { product } = productStore.state;
      const { tenant } = authStore.state;
      return indexProduct(tenant, product);
    }
  },
  {
    name: 'product:indexer:build:all',
    desc: 'Rebuild ALL search index for the currently selected tenant',
    async get() {
      let chunk = 1;
      let page = 1;
      const count = 10;
      let current = 0;
      let currentTotal = 0;
      const type = productTypes.PRODUCT;
      const { tenant } = authStore.state;
      try {
        const batch = async () => {
          let { data, limit, total } = await findAll({
            tenant,
            page,
            count,
            type
          });

          let currentLimit = limit > total ? total : limit;

          const pageTotal = Math.ceil(total / currentLimit);

          const next = () =>
            new Promise((r) => {
              current += 1;
              if (current > currentLimit) {
                page += 1;
                chunk += 1;
                findAll({
                  tenant,
                  page,
                  count,
                  type
                }).then((response) => {
                  data = response.data;
                  limit = response.limit;
                  total = response.total;
                  current = 0;

                  currentLimit = limit > total ? total : limit;
                  r([`Start Product chunk ${chunk}/${pageTotal}`, next]);
                });
              } else {
                const product = data[current - 1];
                currentTotal += 1;
                indexProduct(tenant, product).then((result) => {
                  const message = `${currentTotal}/${total} ${result}`;
                  return total > currentTotal ? r([message, next]) : r(message);
                });
              }
            });

          return [`Start Product chunk ${chunk}/${pageTotal}`, next];
        };
        return await batch();
      } catch (error) {
        return 'Unable to fetch product list';
      }
    }
  }
];

export default productIndexer();
