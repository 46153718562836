/**
 * Copyright 2020 AXA Group Operations S.A.
 *
 * Licensed under the Apache License, Version 2.0 (the "License")
 * you may not use this file except in compliance with the License
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { mapActions, mapState } from 'vuex';

export default {
  computed: {
    ...mapState('i18n', [
      'availableLanguages',
      'sourceLanguage',
      'targetLanguage'
    ]),
    targetLanguages() {
      return this.availableLanguages.filter(
        (v) => v.key !== this.sourceLanguage
      );
    }
  },
  methods: {
    ...mapActions('i18n', ['setSourceLanguage', 'setTargetLanguage']),
    switchTargetLanguage(key) {
      this.setTargetLanguage(key);
    },
    switchSourceLanguage(key) {
      this.setSourceLanguage(key);
    }
  }
};
