/**
 * Copyright 2020 AXA Group Operations S.A.
 *
 * Licensed under the Apache License, Version 2.0 (the "License")
 * you may not use this file except in compliance with the License
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

/* eslint-disable no-param-reassign */
import actions from './products.actions';
import getters from './products.getters';
import mutations from './products.mutations';
import { productTypes } from '../../const/product';

const getInitialState = () => ({
  isLoading: false,
  type: productTypes.PRODUCT,
  list: null,
  pagination: {
    limit: null,
    total: null,
    page: null
  },
  source: 'all',
  sources: [],
  search: null,
  isExporting: false,
  lastExport: null,
  lastExportType: null,
  lastExportTimestamp: 0,
  templates: []
});
export default {
  namespaced: true,
  state: getInitialState(),
  getters,
  actions,
  mutations
};
