import axios from 'axios';

export function impersonateIfValueInLocalStorage(target) {
  if (localStorage && localStorage.impersonate !== undefined) {
    // eslint-disable-next-line no-console
    console.debug(`Impersonating user#${localStorage.impersonate}`);
    // eslint-disable-next-line no-param-reassign
    target.defaults.headers.common['X-Impersonate'] = localStorage.impersonate;
  }
}

// Add a request interceptor
axios.interceptors.request.use(
  (config) => {
    impersonateIfValueInLocalStorage(axios);
    return config;
  },
  (error) => {
    // Do something with request error
    return Promise.reject(error);
  }
);

export default axios;
