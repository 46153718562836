/**
 * Copyright 2020 AXA Group Operations S.A.
 *
 * Licensed under the Apache License, Version 2.0 (the "License")
 * you may not use this file except in compliance with the License
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

export default {
  methods: {
    termAlreadyAdded(name, columname = null, multiTerm = false) {
      const checkTermInDepth = (definitions, termname, column) => {
        return (
          definitions.length === 1 &&
          definitions[0].columns[column] === termname
        );
      };

      if (!this.property) {
        throw new Error('Property is undefined');
      }
      if (!this.product.terms[this.property]) {
        return false;
      }

      let foundTerm = this.product.terms[this.property].some(
        (productTerm) => productTerm.name === name
      );

      if (!foundTerm && !multiTerm) {
        if (columname) {
          // name of the term may have been added from rule editor within its display name
          // and here in the process, the term name may refer to another column than display name.
          // This check is only applicable to monoterm.
          foundTerm = this.product.terms[this.property].some((productTerm) =>
            checkTermInDepth(productTerm.definitions, name, columname)
          );
        }
      }

      return foundTerm;
    }
  }
};
