var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex flex-column flex-grow-1 overflow-hidden"},[_c('input',{ref:"fileUpload",attrs:{"type":"file","hidden":""},on:{"input":_vm.handleSelectedFile}}),_vm._v(" "),_c('UiDialog',{attrs:{"id":"definitionListImport","title":"Import definition list","visible":_vm.displayDefinitionListOverview,"variant":"default","confirm-label":"Confirm import","disable-confirm":true,"hide-confirm":true},on:{"close":function($event){return _vm.closeImportListOverview()},"cancel":function($event){return _vm.cancelImportListOverview()}},scopedSlots:_vm._u([{key:"dialog-content",fn:function(){return [(_vm.displayDefinitionListOverview)?_c('definition-lists-overview',{attrs:{"definitions-lists":_vm.definitionListForImport,"definition-list-suggestions":_vm.definitionListSuggestions},on:{"callback":(response) => _vm.onListImported(response)}}):_vm._e()]},proxy:true}])}),_vm._v(" "),_c('div',{staticClass:"flex-grow-1 flex-shrink-1 p-2 d-flex flex-column overflow-auto"},[_c('UiPaddedArea',{staticClass:"p-0"},[_c('UiToolbar',{scopedSlots:_vm._u([{key:"side",fn:function(){return [_c('b-dropdown',{attrs:{"variant":"outline-primary","right":"","no-caret":""},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('b-icon-three-dots')]},proxy:true}])},[_vm._v(" "),(!_vm.isReadOnlyMode && _vm.isAdmin(_vm.tenant))?[_c('b-dropdown-item',[_c('b-button',{on:{"click":function($event){return _vm.$refs.fileUpload.click()}}},[_vm._v("\n                  "+_vm._s(_vm.$t(
                      'product.import-definitions-list.import-new-definition-list'
                    ))+"\n                ")])],1),_vm._v(" "),_c('b-dropdown-divider')]:_vm._e(),_vm._v(" "),_c('b-dropdown-item',{on:{"click":function($event){return _vm.exportAs('xlsx')}}},[_c('i',{staticClass:"fas fa-download"}),_vm._v("\n              "+_vm._s(_vm.$t('common.download-as-excel'))+"\n            ")]),_vm._v(" "),_c('b-dropdown-item',{on:{"click":function($event){return _vm.exportAs('csv')}}},[_c('i',{staticClass:"fas fa-download"}),_vm._v("\n              "+_vm._s(_vm.$t('common.download-as-csv'))+"\n            ")])],2)]},proxy:true}])},[_c('div',{staticClass:"flex-spacer"}),_vm._v(" "),_c('b-input-group',{staticClass:"search-group"},[_c('b-form-input',{staticClass:"search-input",attrs:{"type":"search","placeholder":_vm.$t('product.search_definitions')},model:{value:(_vm.searchStr),callback:function ($$v) {_vm.searchStr=$$v},expression:"searchStr"}}),_vm._v(" "),_c('b-icon',{staticClass:"search-input-icon",attrs:{"icon":"search"}})],1),_vm._v(" "),_c('div',{staticClass:"flex-spacer"}),_vm._v(" "),(!_vm.isGuest(_vm.tenant))?_c('b-button',{attrs:{"variant":"primary"},on:{"click":_vm.createNewDefinitionList}},[_c('i',{staticClass:"fas fa-plus mr-2"}),_vm._v(_vm._s(_vm.$t('definition-lists.create'))+"\n        ")]):_vm._e()],1)],1),_vm._v(" "),_c('UiLoadingArea',{attrs:{"is-loading":_vm.isLoading}},[_c('UiTable',{staticClass:"ui_table_definitions",attrs:{"data":_vm.definitionLists,"default-sort":{ prop: _vm.sortField, order: _vm.sortOrder },"variant":"padded"},on:{"sort-change":_vm.onSort}},[_c('el-table-column',{attrs:{"label":_vm.$t('definition-lists.list'),"prop":"name","sortable":"custom"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('router-link',{staticClass:"definition-list-name ui_link_definition",attrs:{"to":{
              name: 'definition-lists-edit',
              params: { definitionListId: scope.row._id }
            }}},[_vm._v(_vm._s(scope.row.name))]),_vm._v(" "),(
              _vm.definitionListValidations[scope.row._id] &&
              _vm.definitionListValidations[scope.row._id].isValid === false
            )?_c('el-tooltip',[_c('i',{staticClass:"el-icon-warning-outline"}),_vm._v(" "),_c('template',{slot:"content"},_vm._l((_vm.definitionListValidations[
                  scope.row._id
                ].errors),function(error,index){return _c('div',{key:index},[(!error.isValid)?_c('div',[_c('span',[_vm._v(_vm._s(error.code))]),_vm._v(" "),_c('ul',[_vm._l((error.invalidValues.slice(0, 5)),function(value,k){return _c('li',{key:k},[_vm._v("\n                        "+_vm._s(_vm.formatInvalidValue(value))+"\n                      ")])}),_vm._v(" "),(error.invalidValues.length > 5)?_c('li',[_c('i',[_c('br'),_vm._v("\n                          And\n                          "+_vm._s(error.invalidValues.length - 5)+"\n                          more...")])]):_vm._e()],2)]):_vm._e()])}),0)],2):_vm._e()]}}])}),_vm._v(" "),_c('el-table-column',{attrs:{"label":_vm.$t('definition-lists.definition'),"prop":"definitionsCount","class-name":"ui_label_def_count"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_vm._v("\n            "+_vm._s((_vm.definitionListValidations[scope.row._id] &&
                _vm.definitionListValidations[scope.row._id].definitions &&
                _vm.definitionListValidations[scope.row._id].definitions.count) ||
              0)+"\n          ")]}}])}),_vm._v(" "),_c('el-table-column',{attrs:{"label":_vm.$t('definition-lists.last-update'),"prop":"_updatedAt","sortable":"","class-name":"ui_label_update_date"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_vm._v(_vm._s(_vm.getModifiedDate(scope.row)))]}}])}),_vm._v(" "),_c('el-table-column',{attrs:{"fixed":"right","label":_vm.$t('definition-lists.operation'),"width":"320"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(!_vm.isReadOnlyMode && _vm.isAdmin(_vm.tenant))?_c('el-tooltip',{attrs:{"disabled":_vm.isDeletable(scope.row)}},[_c('span',[_c('el-button',{staticClass:"ui_button_delete",attrs:{"disabled":!_vm.isDeletable(scope.row),"type":"danger","plain":"","size":"mini","data-cy":"definition-button-delete"},on:{"click":function($event){return _vm.deleteDefinitionList(scope.row)}}},[_vm._v("\n                  "+_vm._s(_vm.$t('action.delete'))+"\n                ")])],1),_vm._v(" "),_c('template',{slot:"content"},[(_vm.definitionListValidations[scope.row._id])?_c('div',[_vm._v("\n                  Used by "+_vm._s(_vm.productsUsedByDefinitionList(scope.row._id))+"\n                  products as term\n                ")]):_vm._e(),_vm._v(" "),(_vm.definitionListValidations[scope.row._id])?_c('div',[_vm._v("\n                  Used by\n                  "+_vm._s(_vm.propertiesUsedByDefinitionList(scope.row._id))+"\n                  properties\n                ")]):_vm._e(),_vm._v(" "),(_vm.definitionListValidations[scope.row._id])?_c('div',[_vm._v("\n                  Used by\n                  "+_vm._s(_vm.productPropertiesUsedByDefinitionList(scope.row._id))+"\n                  product as properties\n                ")]):_vm._e()])],2):_vm._e(),_vm._v(" "),_c('router-link',{staticClass:"ui_link_to",attrs:{"to":{
              name: 'definition-lists-edit',
              params: { definitionListId: scope.row._id }
            }}},[_c('el-button',{attrs:{"size":"mini","plain":""}},[_vm._v(_vm._s(_vm.$t('action.view')))])],1)]}}])})],1),_vm._v(" "),(!!_vm.definitionLists)?_c('UiPagination',{attrs:{"current-page":_vm.page,"page-size":_vm.count,"total":_vm.definitionCount},on:{"update:currentPage":function($event){_vm.page=$event},"update:current-page":function($event){_vm.page=$event}}}):_vm._e()],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }