<template>
  <div v-selector.view class="container">
    <div class="row justify-content-center">
      <div class="col-xs-6">
        <transition mode="out-in" enter-active-class="animated fadeIn" leave-active-class="animated fadeOut">
          <Loading v-if="isLoading || isLoggedIn" message="Logging you in…" class="py-4" />
          <form v-if="!isLoading" class="form pb-4 mt-4 px-5" @submit.prevent="login()">
            <h2 class="form-title mt-5 mb-3 pb-3" data-cy="login-title">
              {{ $t('auth.login') }}
            </h2>
            <div class="form-group">
              <label for="username" data-cy="login-username-label">{{
                $t('auth.username')
                }}</label>
              <input id="username" ref="username" v-model="username" autofocus autocomplete="off" type="text"
                :placeholder="$t('auth.login_or_username')" class="form-control" data-cy="login-username-textfield" />
            </div>
            <div class="form-group" :class="{ 'has-danger': passwordError }">
              <label for="password" data-cy="login-password-label">Password</label>
              <input id="password" v-model="password" autocomplete="off" type="password" placeholder="Password"
                class="form-control" data-cy="login-password-textfield"
                :class="{ 'form-control-danger': passwordError }" />
              <div v-if="passwordError" class="form-control-feedback">
                {{ passwordError }}
              </div>
            </div>
            <el-alert v-if="error" class="mb-3" :title="error" type="error" data-cy="login-error-message">
            </el-alert>
            <div class="form-group">
              <b-button type="submit" block variant="primary" data-cy="login-button">
                {{ $t('auth.log_in') }}
              </b-button>
            </div>
          </form>
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import Loading from '../components/Loading.vue';

export default {
  name: 'LoginLegacy',
  components: { Loading },
  data: () => ({
    username: '',
    password: ''
  }),
  computed: {
    ...mapState('auth', ['isLoading', 'error']),
    ...mapGetters('auth', { user: 'getUser' }),
    ...mapGetters('auth', ['isLoggedIn']),
    passwordError() {
      return false;
    },
    intended() {
      const { query } = this.$route;
      return query && query.intended;
    }
  },
  watch: {
    isLoggedIn: {
      immediate: true,
      handler(isLoggedIn) {
        const islogin = isLoggedIn;
        if (islogin) {
          const redirectTo = this.intended || '/';
          this.$router.replace(redirectTo).catch(() => { });
        }
      }
    }
  },
  mounted() {
    if (this.$refs.username) {
      this.$refs.username.focus();
    }
  },
  methods: {
    ...mapActions('auth', ['attemptLocalLogin']),
    async login() {
      const { username, password } = this;
      await this.attemptLocalLogin({ username, password });
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@axatechlab/assets/scss/_variables';

.form {
  border: 1px solid rgba(43, 48, 52, 0.1);
  background: white;
}

.form-title {
  font-family: $font-family-serif;
  font-size: 24px;
  font-weight: 700;
  letter-spacing: 0.02em;
  line-height: 1.17;
  border-bottom: 1px solid #ccc;
}

@media (min-width: 576px) {
  .form {
    padding: 0 70px;
  }

  .form-title {
    font-family: $font-family-serif;
    font-size: 36px;
    font-weight: 700;
    letter-spacing: 0.02em;
    line-height: 1.17;
  }
}
</style>
