<template>
  <el-input
    v-if="mask && (current || mode === 'form')"
    ref="input"
    v-model="value"
    v-mask="mask"
    class="question"
    :clearable="mode === 'form'"
    :data-cy="`input-${question.id}`"
    :disabled="question.loading"
    :placeholder="`${$t('enter-text')} (${maskPlaceholder})`"
    type="text"
    :maxlength="maxlength"
    :show-word-limit="!!maxlength"
    @input="
      $emit('show');
      setValue($event);
    "
    @keydown.enter.exact.native.prevent.stop="
      setValue($event.target.value);
      submit(true);
    "
    @clear="submitClear"
    @keydown.tab.native.capture="captureTab($event)"
    @focus="$emit('focus')"
    @blur="setValueOnBlur($event)"
  >
    <i
      v-if="question.loading"
      slot="prefix"
      class="el-icon-loading el-input__icon"
    ></i>
  </el-input>
  <el-input
    v-else-if="current || mode === 'form'"
    ref="input"
    v-model="value"
    class="question"
    :clearable="mode === 'form'"
    :data-cy="`input-${question.id}`"
    :disabled="question.loading"
    :placeholder="`${$t('enter-text')}`"
    :class="{ textarea: multiline }"
    :type="inputType"
    :maxlength="maxlength"
    :show-word-limit="!!maxlength"
    :autosize="multiline ? { minRows: 2, maxRows: 10 } : false"
    @input="
      $emit('show');
      setValue($event);
    "
    @keydown.enter.exact.native.prevent.stop="
      setValue($event.target.value);
      submit(true);
    "
    @clear="submitClear"
    @keydown.tab.native.capture="captureTab($event)"
    @focus="$emit('focus')"
    @blur="setValueOnBlur($event)"
  >
    <i
      v-if="question.loading"
      slot="prefix"
      class="el-icon-loading el-input__icon"
    ></i>
  </el-input>
</template>
<script>
import { mask } from 'vue-the-mask';
import captureTabMixin from './mixins/captureTabMixin';
import inputMixin from './mixins/inputMixin';

export default {
  name: 'InputTextComponent',
  directives: {
    mask
  },
  mixins: [captureTabMixin, inputMixin],
  computed: {
    inputType() {
      if (this.multiline) {
        return 'textarea';
      }
      const metaInputType = this.question.getMeta('inputType');
      if (metaInputType) {
        return metaInputType;
      }
      return 'text';
    },
    maxlength() {
      const metaInputMaxlength = this.question.getMeta('inputMaxlength');
      if (metaInputMaxlength) {
        return metaInputMaxlength;
      }
      return undefined;
    },
    multiline() {
      const metaInputMultiline = this.question.getMeta('inputMultiline');
      if (metaInputMultiline) {
        return metaInputMultiline;
      }
      return undefined;
    },
    mask() {
      const metaInputMask = this.question.getMeta('inputMask');
      if (metaInputMask) {
        return metaInputMask.split(',');
      }
      return undefined;
    },
    maskPlaceholder() {
      const metaInputMask =
        this.question.getMeta('inputMask') ||
        this.question.getMeta('inputMaskPlaceholder');
      if (metaInputMask) {
        return metaInputMask;
      }
      return undefined;
    }
  },
  methods: {
    setValueOnBlur(event) {
      if (this.mode === 'form') {
        this.setValue(event.target.value);
        this.submit(false);
      }
    },
    async focusInput() {
      await this.$nextTick();
      if (this.$refs.input) {
        const inputType = this.multiline ? 'textarea' : 'input';
        this.$refs.input.$el
          .querySelector(inputType)
          .focus({ preventScroll: true });
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.question {
  max-width: 100%;
  .textarea {
    width: 400px;
    max-width: 100% !important;
  }
}
</style>
