<template>
  <el-dialog
    v-selector
    append-to-body
    :visible="isVisible"
    @close="$emit('close')"
  >
    <h5 slot="title" class="title">
      {{ title || $t('team.create-new-team') }}
    </h5>
    <div class="container">
      <div class="row justify-content-center align-items-center">
        <form class="form pb-4 px-2 col-9">
          <div
            class="form-team col-12"
            :class="{ 'has-danger': errors.length }"
          >
            <div v-for="(error, index) in errors" :key="error + index">
              {{ error }}
            </div>
            <label class="team-name">{{ $t('team.team-name') }}</label>
            <input
              id="team_name"
              ref="newTeamName"
              v-model="newTeamName"
              type="text"
              :placeholder="$t('team.team-name-placeholder')"
              class="form-control ui_input_name"
              :class="{ 'form-control-danger': errors.length }"
              @keydown.enter.prevent
            />
          </div>
        </form>
      </div>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button class="btn ui_button_back" @click="createNewTeam">{{
        $t('action.save')
      }}</el-button>
      <el-button class="btn ui_button_back" @click="$emit('close')">{{
        $t('action.cancel')
      }}</el-button>
    </span>
  </el-dialog>
</template>

<script>
import Vue from 'vue';
import { mapActions, mapState } from 'vuex';
import { slugify } from '../../filters/slugify';

export default {
  name: 'NewTeamCreationModal',
  components: {},
  props: {
    tenant: { type: Object, default: undefined },
    visible: Boolean,
    title: { type: String, default: undefined }
  },
  data: () => ({
    errors: [],
    newTeamName: '',
    newTeamSlug: '',
    isVisible: false
  }),
  computed: {
    ...mapState('teams', ['tenants', 'teams'])
  },
  watch: {
    visible(visible) {
      this.newTeamName = null;
      this.isVisible = visible;
      if (this.isVisible) {
        Vue.nextTick(() => {
          if (this.$refs.newTeamName) this.$refs.newTeamName.focus();
        });
      }
    },
    newTeamName(newName) {
      if (newName) {
        this.newTeamSlug = slugify(newName, {
          lowercase: true
        });
      }
    }
  },
  methods: {
    ...mapActions('teams', ['createTeam']),
    async createNewTeam() {
      this.errors = [];
      if (!this.newTeamName) {
        this.errors = [`${this.$t('team.name-required')}`];
        return;
      }
      if (
        this.tenants.some(
          (t) =>
            t.slug === this.newTeamSlug ||
            t.label.toLowerCase() === this.newTeamName.toLowerCase()
        ) ||
        this.teams.some(
          (t) =>
            t.slug === this.newTeamSlug ||
            t.label.toLowerCase() === this.newTeamName.toLowerCase()
        )
      ) {
        this.errors = [`${this.$t('team.name-duplicated')}`];
        return;
      }
      try {
        await this.createTeam({
          data: {
            label: this.newTeamName,
            slug: this.newTeamSlug,
            tenant: this.tenant.slug
          }
        });

        this.newTeamName = null;
        this.isVisible = false;
        this.$emit('close', true);
      } catch (err) {
        this.errors = [err.message || err];
      }
    }
  }
};
</script>

<style scoped lang="scss">
@import '@axatechlab/assets/scss/_variables';
.form {
  background-color: white;
}

.form-control {
  max-width: 100%;
  text-align: center;
}

.el-dialog__header {
  height: 58px;
  width: 1003px;
  border-radius: 4px 4px 0 0;
  background-color: #ededf4;
}
.title {
  height: 25px;
  color: #2b3034;
  font-size: 20px;
  line-height: 25px;
}

.fas,
.far {
  font-size: 30px;
}

.mask {
  box-sizing: border-box;
  height: 170px;
  width: 200px;
  border: 1px solid rgba(43, 48, 52, 0.1);
  background-color: #ffffff;
  box-shadow: 0 0 0 2px rgba(0, 0, 143, 0.03);
  border-radius: 10px;

  &:hover {
    border: 1px solid #00008f;
    background-color: rgba(0, 0, 143, 0.03);
    color: #00008f;
    cursor: pointer;

    svg {
      filter: none;
    }
  }
}

.icon-category {
  height: 60px;
  width: 60px;
  color: #8f8f8f;
  filter: invert(63%) sepia(0%) saturate(16%) hue-rotate(156deg) brightness(92%)
    contrast(76%);
}

.icon {
  color: #8f8f8f;
  filter: invert(63%) sepia(0%) saturate(16%) hue-rotate(156deg) brightness(92%)
    contrast(76%);
  height: 45px;
  width: auto;
}

.legend {
  width: 100%;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0.17px;
  line-height: 20px;
  text-align: center;
}

.bl-title {
  height: 20px;
  opacity: 0.5;
  color: #000000;
  font-size: 16px;
  letter-spacing: 0.17px;
  line-height: 20px;
}

input.form-control {
  box-sizing: border-box;
  height: 43px;
  border: 1px solid rgba(0, 0, 143, 0.2);
  background-color: #ffffff;
  box-shadow: 0 0 0 2px rgba(0, 0, 143, 0.03);

  &::placeholder {
    height: 20px;
    width: 145px;
    opacity: 0.4;
    color: #00008f;
    font-family: 'Source Sans Pro';
    font-size: 16px;
    font-style: italic;
    letter-spacing: 0.17px;
    line-height: 20px;
  }
}

.team-name {
  height: 18px;
  opacity: 0.78;
  color: #2b3034;
  text-transform: uppercase;
  font-size: 14px;
  letter-spacing: 0.15px;
  line-height: 18px;
}

.has-danger {
  color: red;
}
</style>
