/**
 * Copyright 2020 AXA Group Operations S.A.
 *
 * Licensed under the Apache License, Version 2.0 (the "License")
 * you may not use this file except in compliance with the License
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

const testInterval = (
  min = '',
  max = '',
  stepper = '',
  stepDisabled = false
) => {
  let code = null;
  const parseUnknowValue = (val) => {
    if (val === null || val === undefined) {
      return '';
    }
    return val;
  };

  let minVal = parseUnknowValue(min);
  const maxVal = parseUnknowValue(max);

  if (min === '') {
    minVal = 0;
  }

  if (maxVal === '') {
    return {
      status: true,
      code,
      interval: { min: minVal, max: maxVal, stepper }
    };
  }

  if (minVal >= maxVal) {
    code = 'MAX_MUST_BE_HIGHER_THAN_MIN';
    return {
      status: false,
      code,
      interval: { min: minVal, max: maxVal, stepper }
    };
  }

  const status = !stepDisabled
    ? parseInt(
        (parseFloat(maxVal) - parseFloat(minVal)) % parseFloat(stepper),
        10
      ) === 0
    : parseFloat(maxVal) > parseFloat(minVal);

  if (!stepDisabled && !status) {
    code = 'MIN_MAX_RANGE_UNSATISFIED_WITH_GIVEN_STEP';
    return {
      status: false,
      code,
      interval: { min: minVal, max: maxVal, stepper }
    };
  }

  if ((maxVal - minVal) / stepper > 10000) {
    code = 'MAXIMUM_STEPS_EXCEEDED';
    return {
      status: false,
      code,
      interval: { min: minVal, max: maxVal, stepper }
    };
  }

  return {
    status,
    code,
    interval: { min: minVal, max: maxVal, stepper }
  };
};
module.exports = { testInterval };
