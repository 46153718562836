/**
 * Copyright 2020 AXA Group Operations S.A.
 *
 * Licensed under the Apache License, Version 2.0 (the "License")
 * you may not use this file except in compliance with the License
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import DOMPurify from 'dompurify';
import { ArrayNode, ArrayNodeFactory } from './index';
import './annotation.css';
import linkStyleForNode from './AnnotationStyle';

export default class AnnotatedArrayNode extends ArrayNode {
  constructor(graph, parent, model, children) {
    super(graph, parent, model, children);
    this.value.collapsed = false;
  }

  renderCollapseHandle() {}

  renderExpanded(x, y) {
    const frame = super.renderExpanded(x, y);
    if (this.parent) return frame;

    // Make a bit of room in front for the path annotation
    frame.width += 10;
    this.nodes.slice(1).forEach((child) => {
      child.moveBy(10, 0);
    });

    // Delete old annotations
    this.div.childNodes.forEach((node) => {
      if (node.className === 'annotationGroup') {
        this.div.removeChild(node);
      }
    });

    this.nodes.forEach((child) => {
      const annotation = document.createElement('div');
      annotation.className = 'annotationGroup clickable';

      annotation.onclick = (e) => {
        e.preventDefault();
        e.stopPropagation();
        this.showAnnotation(annotation, child);
      };

      const successes = this.value.annotations
        ? this.value.annotations.test.success
        : '0';
      if (successes !== '0') {
        const successSpan = document.createElement('span');
        successSpan.textContent = successes;
        successSpan.className = 'annotationPill success';
        annotation.appendChild(successSpan);
      }

      const fails = this.value.annotations
        ? this.value.annotations.test.fail
        : '0';
      if (fails !== '0') {
        const failSpan = document.createElement('span');
        failSpan.textContent = fails;
        failSpan.className = 'annotationPill fail';
        annotation.appendChild(failSpan);
      }

      const firstNodeExit = this.nodes[0].exitPoint();

      annotation.style.left = `${firstNodeExit.x - 20}px`;
      annotation.style.top = `${firstNodeExit.y - 40}px`;

      this.div.appendChild(annotation);
    });

    return frame;
  }

  showAnnotation(annotation, child) {
    // Remove other annotations
    document.querySelectorAll('.pathAnnotation').forEach((node) => {
      node.remove();
    });
    const childExit = child.exitPoint();
    const txt = document.createElement('div');
    txt.className = 'pathAnnotation';
    txt.style.position = 'absolute';
    txt.style.left = annotation.style.left;
    txt.style.top = `${childExit.y - this.absoluteFrame().y + 10}px`;
    if (child.value.annotations && child.value.annotations.test) {
      const annotations = child.value.annotations.test;
      const testResults = [];
      Object.values(annotations.tests).forEach((test) => {
        testResults.push(
          `<span class="annotationPill ${
            test.status ? 'success' : 'fail'
          }">${test.name.replace(/ /g, '\u00a0')}</span>`
        );
      });
      txt.innerHTML = DOMPurify.sanitize(testResults.join(' '));
    } else {
      txt.innerHTML = '<span>Path is not covered by tests.</span>';
    }
    txt.onclick = (eTxt) => {
      txt.remove(txt);
      eTxt.preventDefault();
      eTxt.stopPropagation();
    };
    this.div.appendChild(txt);
  }

  renderConnectionsExpanded(x, y, drawingContext) {
    const { color } = linkStyleForNode(this);
    if (this.parent) {
      const rect = this.absoluteFrame();
      const start = { x: x + rect.x, y: y + rect.y + rect.height / 2 };
      const end = this.nodes[0].entryPoint();
      this.graph.renderLine(
        drawingContext,
        start,
        { x: end.x + x, y: end.y + y },
        color
      );
    }

    for (let i = 0; i < this.nodes.length - 1; i += 1) {
      const left = this.nodes[i].exitPoint();
      const right = this.nodes[i + 1].entryPoint();
      this.graph.renderLine(
        drawingContext,
        { x: left.x + x, y: left.y + y },
        { x: right.x + x, y: right.y + y },
        color
      );
      this.nodes[i].renderConnections(x, y, drawingContext);
    }

    if (this.nodes.length > 0) {
      this.nodes[this.nodes.length - 1].renderConnections(x, y, drawingContext);
    }
  }
}

export class AnnotatedArrayNodeFactory extends ArrayNodeFactory {
  nodeFromModel(graph, parent, model) {
    return new AnnotatedArrayNode(graph, parent, model);
  }

  nodeWithChildren(graph, parent, children) {
    return new AnnotatedArrayNode(graph, parent, null, children);
  }
}
