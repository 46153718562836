<template>
  <div class="ProductProperties overflow-hidden flex-grow-1 flex-shrink-1 d-flex flex-column">
    <UiStickyNotification ref="notification" />
    <PropertyImportDialog :visible="isPropertyImportDialogVisible" :property="property"
      :properties="filteredSharedProperties" :default-related-properties="defaultRelatedSharedProperties"
      :definitions="definitions" @create="handleImport" @cancel="hideImportDialog" @close="hideImportDialog" />
    <UiLoadingArea :is-loading="isLoading">
      <ProductConfigurationTable
        ref="productConfigTable"
        :property="property"
        :properties="propertiesSorted"
        :sort-field="sortField"
        :sort-order="sortOrder"
        :expand-row-keys="expandedRows"
        :row-class-name="
          ({ row, rowIndex }) => tableRowClassName(row, rowIndex)
        "
        @dblclick="(row) => editRow(propertiesSorted.indexOf(row))"
        @sort-change="onSort"
      >
        <template #expand="{ row, index }">
          <PropertyMetadata
            :edited-row="editedRow"
            :is-creating-property="isCreatingProperty"
            :is-read-only="isReadOnlyMode"
            :inherit-from-csp="inheritFromCsp(index)"
            :property="metadata(row, index)"
            :default-related-properties="defaultRelatedProperties"
            :selectable-terms="getSuggestions(row, product.id)"
            :is-dimension="isDimension()"
            :default-property-metadata="propertyMetadata"
            :source-language="sourceLanguage"
            :target-language="targetLanguage"
            @source-language-change="switchSourceLanguage"
            @target-language-change="switchTargetLanguage"
            @save="onMetadataSave"
            @remove="onMetadataRemove"
          />
        </template>
        <!-- Name -->
        <TableColumnName v-if="columns.includes('name')" :label="$t('product.name')" :sortable="sortable" :name="editedRow.value && editedRow.value.name !== placeholderRowName
      ? editedRow.value.name
      : undefined
      " :color="editedRow.value ? editedRow.value.color : undefined" :colors="colors" :product="product"
          :display-name="(row) => displayName(row, sourceLanguage)" :edit-row="editRow" :is-editable="isEditable"
          :is-editing="isEditing" :save-item="saveItem" :has-color="columns.includes('name')"
          @color-change="(v) => (editedRow.value.color = v)" @change="(v) => {
      return filterName(
        editedRow.value,
        'name',
        v,
        property === 'dimensions'
      );
    }
      ">
          <template #tooltip="{ row, $index }">
            <el-tooltip v-if="isUnusedProperty(row)">
              <i class="el-icon-question"></i>
              <template slot="content">
                <div>{{ $t(`product.validation.UNUSED`) }}</div>
              </template>
            </el-tooltip>
            <el-tooltip v-if="!isEditing($index) && getValidationErrors(row.name).length">
              <i class="el-icon-warning-outline"></i>
              <template slot="content">
                <div v-for="(error, index) of getValidationErrors(row.name, 5)" :key="index">
                  {{
      $t(`product.validation.${error.code}`, {
        definition: error.definition,
        property: error.property,
        product: product.name
      })
    }}
                </div>
                <div v-if="getValidationErrors(row.name).length > 5">
                  <br />
                  <i>
                    And
                    {{ getValidationErrors(row.name).length - 5 }} more...
                  </i>
                </div>
              </template>
            </el-tooltip>
          </template>
        </TableColumnName>

        <!-- Definition list -->
        <el-table-column v-if="columns.includes('definitionList')" prop="key" min-width="60"
          :label="$t('product.definition_list')" data-cy="property-definition-list">
          <template #default="{ row, $index }">
            <DefinitionListsDropdown v-if="showDefListSelector(row)" class="ui_select_definition_list"
              cy-test="property-definition-list" :is-editable="isEditable(row, $index)" :is-editing="isEditing($index)"
              :definition-list-id="row.definitionListId" :data-cy="`property-definition-list-${row.name}`"
              :definitions="definitions" @change="handleChangeDefinitionList" @save="saveItem($index)" />
          </template>
        </el-table-column>

        <!-- Type -->
        <el-table-column v-if="columns.includes('type')" prop="type" :label="$t('product.type')" width="130"
          data-cy="property-type">
          <template #default="{ row, $index }">
            <el-select v-if="isEditing($index) && isEditable(row, $index)" v-model="editedRow.value.type"
              :data-cy="`property-type-${row.name}`" data-test="property-type" placeholder="Type" class="ui_item_type"
              @change="saveItem($index)">
              <el-option v-for="type in types" :key="type" :value="type" :label="translateValueType(type)" />
            </el-select>
            <span v-else class="ui_item_type">{{
      row.type ? translateValueType(row.type) : null
    }}</span>
          </template>
        </el-table-column>

        <!-- Type -->
        <el-table-column v-if="columns.includes('dialectType')" prop="type" :label="$t('product.dialectType')"
          width="130" data-cy="property-dialectType">
          <template #default="{ row, $index }">
            <el-select v-if="isEditing($index) && isEditable(row, $index)" v-model="editedRow.value.dialectType"
              :data-cy="`property-dialectType-${row.name}`" data-test="property-dialectType" placeholder="Dialect type"
              class="ui_item_type" @change="saveItem($index)">
              <el-option v-for="type in dialectTypes" :key="type" :value="type !== 'NONE' ? type : null"
                :label="$t(`product.field_type.${type}`)" />
            </el-select>
            <span v-else class="ui_item_type">{{
      row.dialectType ? translateValueType(row.dialectType) : null
    }}</span>
          </template>
        </el-table-column>

        <!-- Priority -->
        <el-table-column v-if="columns.includes('priority')" prop="priority" :label="$t('product.priority')" width="120"
          data-test="property-priority">
          <template #default="{ row, $index }">
            <UiInlineEditable as="number" :data-cy="`priority-${row.name}`" cy-test="property-priority"
              :label="row.priority || $t('product.not_prioritised')" :value="row.priority"
              :toggle-editing="isEditing($index)" :handle-change="(v) => (editedRow.value.priority = v)"
              @save="saveItem($index)" />
          </template>
        </el-table-column>

        <!-- API -->
        <el-table-column v-if="showAPIColumn" prop="exposeInAPI" label="API" width="60" class-name="centered">
          <template slot="header">
            API
            <el-tooltip :content="$t('product.api_tooltip')" placement="top" effect="light">
              <el-button circle icon="el-icon-question" />
            </el-tooltip>
          </template>
          <template #default="{ row, $index }">
            <el-checkbox v-if="isEditing($index)" v-model="editedRow.value.api" class="ui_dimension_expose" />
            <el-checkbox v-else v-model="row.api" class="ui_dimension_expose" disabled />
          </template>
        </el-table-column>

        <!-- Allow multiple -->
        <el-table-column v-if="columns.includes('allowMulitple')"
          :label="`${$t('product.allow_multiple')} ${$t('product.per_claim')}`" class-name="centered" width="200">
          <template #default="{ row, $index }">
            <el-checkbox v-if="isEditing($index)" v-model="editedRow.value['allowMultiplePerClaim']"
              class="ui_item_multiple" />
            <el-checkbox v-else v-model="row['allowMultiplePerClaim']" class="ui_item_multiple" disabled />
          </template>
        </el-table-column>

        <!-- Operations -->
        <el-table-column width="180">
          <RowOperations slot-scope="{ $index }" :read-only="isReadOnlyMode" :is-read-only="isReadOnlyMode"
            :restrict-edit="inheritFromCsp($index)" :is-editing="isEditing($index)"
            :is-overridden="isOverridden($index)" :is-inherited="isInheritedFromTemplate($index)"
            class="ui_buttons_operations" @command="(command) => handleRowItemCommand(command, $index)" />
        </el-table-column>
      </ProductConfigurationTable>
      <UiPagination v-if="propertiesSorted.length" ref="paginator" :key="Date.now()" :current-page.sync="page"
        :page-size="count" :total="properties.length" />
    </UiLoadingArea>
  </div>
</template>

<script>
import ComputedMixin from '@axa-getd/ui-components/src/mixins/ComputedMixin';
import { Message, MessageBox } from 'element-ui';
import { isEqual } from 'lodash';
import { mapActions, mapGetters, mapState } from 'vuex';
import Vue from 'vue';
import { propertyMetadataName } from '../domain/model/productMetadata';
import { noop } from '../util';
import DefinitionListMixin from './DefinitionListMixin';
import PropertyMetadataMixin from './Metadata/PropertyMetadataMixin';
import ProductSaveMixin from './ProductSaveMixin';
import RowOperations from './RowOperations.vue';
import PropertiesMixin from './PropertiesMixin';
import { displayName, generateHex } from '../helpers';
import PropertyMetadata from './Metadata/PropertyMetadata.vue';
import ProductConfigurationTable from './Product/Configuration/ProductConfigurationTable.vue';
import TableColumnName from './Product/Configuration/TableColumnName.vue';
import PropertyImportDialog from './Product/Configuration/PropertyImportDialog.vue';
import DefinitionListsDropdown from './DefinitionLists/DefinitionListsDropdown.vue';
import I18nMixin from './I18nMixin';
import WithNotificationMixin from './WithNotificationMixin';
import * as api from '../api';

// This is temporary mapping between types from CSP and Product Property
// todo remove this mapping when the types as been standardized everywhere
// Code duplicated in the API package @axa-getd/api-editor/src/services/products/properties/productProperties.class
const propertyValueTypeMapping = {
  monetary: 'MonetaryAmount',
  'monetary amount': 'MonetaryAmount',
  date: 'Date',
  datetime: 'DateTime',
  distance: 'Distance',
  night: 'Night',
  day: 'Day',
  week: 'Week',
  month: 'Month',
  number: 'Number',
  boolean: 'Boolean',
  text: 'Text',
  enum: 'Enum'
};

const propertyValueTypeMapper = (type) =>
  propertyValueTypeMapping[type.toLowerCase()];

const typeMapping = {
  dimensions: 'DIMENSION',
  in: 'INPUT',
  computes: 'COMPUTED',
  out: 'OUTPUT',
  units: 'UNIT',
  endorsements: 'ENDORSEMENT'
};

const propertyMapping = {
  inputs: 'in',
  outputs: 'out'
};

const typeMapper = (type) => typeMapping[type];

export default {
  name: 'ProductProperties',
  components: {
    PropertyMetadata,
    RowOperations,
    DefinitionListsDropdown,
    PropertyImportDialog,
    ProductConfigurationTable,
    TableColumnName
  },
  mixins: [
    DefinitionListMixin,
    ProductSaveMixin,
    PropertiesMixin,
    PropertyMetadataMixin,
    I18nMixin,
    WithNotificationMixin,
    ComputedMixin
  ],
  props: {
    defaultRelatedProperties: {
      type: Array,
      default: () => []
    },
    nested: {
      type: Object,
      default: () => { }
    },
    defaultRelatedSharedProperties: {
      type: Array,
      default: () => []
    },
    columns: {
      type: Array[String],
      default: () => []
    },
    label: {
      type: String,
      required: true
    },
    property: {
      type: String,
      required: true
    },
    types: {
      type: Array[String],
      default: () => []
    },
    dialectTypes: {
      type: Array[String],
      default: () => []
    },
    search: {
      type: String,
      required: true,
      default: ''
    },
    sharedProperties: {
      type: Array,
      required: true
    },
    sharedPropertiesAll: {
      type: Array,
      default: () => []
    },
    definitions: {
      type: Array,
      validator: (prop) =>
        prop.every(
          (e) =>
            e.key &&
            (typeof e.key === 'string' || typeof e.key === 'number') &&
            e.label &&
            typeof e.label === 'string'
        ),
      required: true
    },
    highlightedProperty: {
      type: String,
      required: false,
      default: null
    }
  },
  data() {
    return {
      page: 1,
      count: 10,
      sortField: 'name',
      sortOrder: 'ascending',
      propertiesSorted: [],
      validationErrorType: '',
      propertyTypesMap: {
        DIMENSION: 'dimensions',
        INPUT: 'in',
        COMPUTED: 'computes',
        OUTPUT: 'out',
        UNIT: 'units',
        ENDORSEMENT: 'endorsements'
      },
      previousSearch: '',
      activeProperty: null,
      placeholderRowName: 'placeholderRowName',
      isCreatingProperty: false,
      expandedRows: null,
      newPropertyMetadata: {},
      isPropertyImportDialogVisible: false
    };
  },
  computed: {
    ...mapGetters('product', ['isReadOnly']),
    ...mapGetters('productProperty', ['unusedProperties']),
    ...mapState('sharedProperty', ['productValidations']),
    ...mapState('auth', ['tenant']),
    ...mapState('productProperty', {
      isProductPropertyLoading: 'isLoading',
      allProperties: 'properties'
    }),
    ...mapGetters('auth', ['isGuest']),
    isReadOnlyMode() {
      return this.isReadOnly || this.isGuest(this.product.team.slug);
    },
    isLoading: {
      get() {
        return (
          this.isProductPropertyLoading && this.propertiesSorted.length === 0
        );
      }
    },
    showAPIColumn() {
      return (
        this.columns.includes('API') &&
        this.product.technicalName != null &&
        this.product.technicalName.length > 0
      );
    },
    filteredSharedProperties() {
      return this.sharedProperties.filter(
        (sp) => !this.allProperties.map((p) => p.name).includes(sp.name)
      );
    },
    sortable() {
      return this.isCreatingProperty ? null : 'custom';
    }
  },
  watch: {
    page() {
      this.stopEditing();
      this.updatePaginatedProperties();
    },
    properties() {
      if (this.previousSearch !== this.search) {
        this.page = 1;
        this.previousSearch = this.search;
      }
      this.focusOnActivePropertyIfNeeded();
      if (this.isCreatingProperty) {
        this.hideCreationRow();
      }
    },
    highlightedProperty(value) {
      this.activeProperty = value;
      this.focusOnActivePropertyIfNeeded();
    }
  },
  mounted() {
    this.page = 1;
    this.activeProperty = this.highlightedProperty;
    this.focusOnActivePropertyIfNeeded();
  },
  methods: {
    ...mapActions('productProperty', [
      'fetchProductProperties',
      'updateProductProperty',
      'deleteProductProperty',
      'createProductProperty'
    ]),
    ...mapActions('sharedProperty', ['fetchProductValidation']),
    disableActiveProperty(event) {
      this.activeProperty = null;
      this.updatePaginatedProperties();
      event.preventDefault();
      this.$router.push({
        name: 'product-configuration-direct',
        params: {
          ...this.$route.params,
          propertyName: null
        }
      });
    },
    matchActiveProperty(name) {
      return (
        this.hasActiveProperty() &&
        name &&
        typeof name === 'string' &&
        name.toUpperCase() === this.activeProperty.toUpperCase()
      );
    },
    hasActiveProperty() {
      return (
        this.activeProperty &&
        typeof this.activeProperty === 'string' &&
        this.activeProperty.trim() !== ''
      );
    },
    tableRowClassName(row) {
      return {
        ProductProperty__Row: true,
        'ProductProperty__Row--Active': this.matchActiveProperty(row.name)
      };
    },
    isActiveProperty(name) {
      return this.activeProperty === name;
    },
    focusOnActivePropertyIfNeeded() {
      if (this.hasActiveProperty()) {
        const currentProperty =
          propertyMapping[this.$route.params.propertyType] ||
          this.$route.params.propertyType;
        if (currentProperty !== this.property) {
          this.updatePaginatedProperties();
        } else {
          const activeProperty = this.properties.findIndex((p) =>
            this.matchActiveProperty(p.name)
          );
          this.page = Math.abs(Math.ceil((activeProperty + 1) / this.count));
          this.updatePaginatedProperties();
        }
      } else {
        this.updatePaginatedProperties();
      }
    },
    isEditable(row, $index) {
      return (
        ((this.getValidationErrors(row.name).length > 0 ||
          row.__type === 'ENDORSEMENT') &&
          !this.isInheritedFromTemplate($index)) ||
        row.name === this.placeholderRowName
      );
    },
    getValidationErrors(property, limit = 0) {
      if (!this.productValidations[this.product.id]) {
        return [];
      }
      const errors = this.productValidations[this.product.id].errors.filter(
        (e) => e.property === property
      );

      return limit > 0 && errors ? errors.slice(0, limit) : errors;
    },
    isDimension() {
      return this.property === 'dimensions';
    },
    isComputed() {
      return this.property === 'computes';
    },
    isOverridden(index) {
      const prop = this.propertiesSorted[index];
      const { name } = prop;
      const templateValue = this.product.template
        ? this.product.template.specification[this.property][name]
        : null;

      const ownValue = this.propertyByName(name);
      if (!templateValue || !ownValue) return false;
      return !isEqual(templateValue, ownValue);
    },
    isInheritedFromTemplate(index) {
      const prop = this.propertiesSorted[index];
      const { name } = prop;
      const templateValue = this.product.template
        ? this.product.template.specification[this.property][name]
        : null;

      const ownValue = this.propertyByName(name);
      if (!templateValue || !ownValue) return false;
      return isEqual(templateValue, ownValue);
    },
    handleChangeDefinitionList(v) {
      if (!v) {
        // If no def list, we have to remove all terms and definitions in the terms
        const dimName = this.editedRow.value.name;
        (this.product.terms[dimName] || []).forEach(async (t) => {
          await api.unlinkProductTerm(this.product.id, t.id, this.tenant);
        });
      }
      this.editedRow.value.definitionListId = v || null;
    },
    handleRowItemCommand(command, index) {
      if (!this.isReadOnlyMode) {
        switch (command) {
          case 'edit':
            if (this.isCreatingProperty) {
              this.hideCreationRow();
              // eslint-disable-next-line no-param-reassign
              index -= 1;
            }
            this.editRow(index);
            break;
          case 'save':
            if (this.isCreatingProperty) {
              this.handleCreate();
            } else {
              this.saveItem(index);
            }
            break;
          case 'delete':
            this.deleteItem(index);
            break;
          case 'cancel':
            this.stopEditing();
            if (this.isCreatingProperty) {
              this.hideCreationRow();
            }
            break;
          default:
            throw new Error(`Unknown command ${command}`);
        }
      }
    },
    isValidName(name) {
      this.validationErrorType = '';
      if (this.ruleExists(name)) {
        this.validationErrorType = 'prop_name_is_rule';
        return false;
      }
      if (
        this.propertyExists(name) ||
        name === this.placeholderRowName.toUpperCase()
      ) {
        this.validationErrorType = 'prop_name_exists';
        return false;
      }
      if (this.isReservedWord(name)) {
        this.validationErrorType = 'prop_name_reserved_word';
        return false;
      }
      return true;
    },
    async handleCreate() {
      const currentProperty = this.editedRow.value;
      const { name } = currentProperty;

      currentProperty.metadata = this.newPropertyMetadata;

      try {
        const validName = this.toValidName(name);
        // eslint-disable-next-line no-nested-ternary
        const type = this.isDimension()
          ? 'Enum'
          : currentProperty.type || 'number';
        const priority = parseInt(currentProperty.priority, 10);
        const propertyData = {
          ...currentProperty,
          name: validName,
          type,
          priority,
          color: generateHex(validName),
          __type: typeMapper(propertyMapping[this.property] || this.property)
        };
        if (!this.isValidName(validName)) {
          Message.error(
            this.$t(`product.${this.validationErrorType}`, { name: validName })
          );
        } else {
          this.hideCreationRow();

          // noinspection JSValidateTypes
          await this.createProductProperty({
            productId: this.product.id,
            propertyData,
            tenant: this.tenant
          });

          this.showNotification(
            'Success',
            this.$t('product.successfull_new_prop'),
            validName,
            this.showItemOnTable
          );
        }
      } catch (e) {
        this.newPropertyMetadata = {};
        // eslint-disable-next-line no-console
        console.error(e);
        Message.error(e);
      }
      this.refreshProductValidation();
    },
    async handleImport(data) {
      const properties = Object.values(data);
      if (data.length === 0) return;
      try {
        properties.forEach(async (p) => {
          const propertyData = this.mergeSharedProperty(p.name, p);
          // noinspection JSValidateTypes
          await this.createProductProperty({
            productId: this.product.id,
            propertyData,
            tenant: this.tenant
          });
        });

        this.showNotification(
          'Success',
          this.$t('product.successfull_new_prop')
        );
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
        Message.error(e);
      }
    },
    refreshProductValidation() {
      // noinspection JSValidateTypes
      this.fetchProductValidation({
        productId: this.product.id,
        force: true
      });
    },
    propertyExists(name) {
      const propertiesToCheck = [
        ...this.allProperties,
        ...this.sharedPropertiesAll
      ];

      // name properties of type METADATA can be lower case
      return (
        propertiesToCheck.filter(
          (p) => p.name.toLowerCase() === name.toLowerCase()
        ).length > 0
      );
    },
    ruleExists(name) {
      return this.product.rules.some(
        (r) => r.name.toLowerCase() === name.toLowerCase()
      );
    },
    isReservedWord(name) {
      const reservedWords = ['IF'];
      return reservedWords.includes(name.toUpperCase());
    },
    mergeSharedProperty(name, property) {
      const sharedProperty = this.sharedProperties.find(
        (_property) => _property.name === name
      );

      if (sharedProperty) {
        const valueType = propertyValueTypeMapper(
          sharedProperty.valueType || sharedProperty.value_type || 'number'
        );
        if (!valueType) {
          throw new Error(
            `Unable to map value type between shared property (${sharedProperty.value_type}) and product property`
          );
        }
        Object.assign(property, {
          displayName: sharedProperty.displayName,
          type: valueType,
          definitionListId: sharedProperty.definitionListId,
          color: sharedProperty.color,
          metadata: sharedProperty.metadata
        });
      }

      return property;
    },
    editRow(index) {
      if (this.isReadOnlyMode || this.editedRow.index === index) return;
      const entry = this.propertiesSorted[index];
      this.editedRow = {
        index,
        value: {
          ...entry,
          color: this.colors[entry.color]
            ? this.colors[entry.color]
            : entry.color
        }
      };
    },
    isEditing(index) {
      return this.editedRow.index === index;
    },
    propertyByName(name) {
      if (name === this.placeholderRowName) {
        return {};
      }
      const currentProperty = this.properties.find((p) => p.name === name);
      if (!currentProperty) {
        throw new Error(`Property not found with the current name ${name}`);
      }
      return {
        ...currentProperty,
        metadata: { ...(currentProperty.metadata || {}) }
      };
    },
    async onMetadataRemove(entry, isNew) {
      if (isNew) return;

      if (this.isCreatingProperty) {
        delete this.newPropertyMetadata[entry.key];
        return;
      }

      const { index, key, previousKey } = entry;

      const { name } = this.propertiesSorted[index];
      const currentProperty = this.propertyByName(name);
      delete currentProperty.metadata[key !== previousKey ? previousKey : key];

      // noinspection JSValidateTypes
      await this.updateProductProperty({
        productId: this.product.id,
        propertyName: name,
        propertyData: currentProperty,
        tenant: this.tenant
      }).then(() => this.fetch());

      this.showNotification(
        'Success',
        this.$t('product.msg-success-delete-property', { key: name })
      );

      this.fetch();
    },
    async onMetadataSave(entry, isNew, needToInformUser = true) {
      if (this.isCreatingProperty) {
        this.newPropertyMetadata[entry.key] = entry.value;
        return;
      }

      const { index, key, previousKey, value } = entry;

      const { name } = this.propertiesSorted[index];
      const currentProperty = this.propertyByName(name);

      if (key.trim() === '') return;

      if (key !== previousKey) delete currentProperty.metadata[previousKey];

      currentProperty.metadata[isNew ? propertyMetadataName(key) : key] = value;

      // noinspection JSValidateTypes
      await this.updateProductProperty({
        productId: this.product.id,
        propertyName: name,
        propertyData: currentProperty,
        tenant: this.tenant
      });

      if (needToInformUser) {
        this.fetch();
        this.showNotification(
          'Success',
          this.$t('product.msg-success-update-property', { key: name })
        );
        this.stopEditing();
      }
    },
    async saveItem(index) {
      if (this.isCreatingProperty) {
        return;
      }

      const previousProperty = this.propertiesSorted[index];
      const currentProperty = this.editedRow.value;
      const { name } = currentProperty;
      if (name !== previousProperty.name && !this.isValidName(name)) {
        MessageBox.alert(
          this.$t(`product.${this.validationErrorType}`, { name })
        ).then(noop);
        return;
      }

      let continueWithSave = true;
      if (
        currentProperty.__type === 'OUTPUT' &&
        currentProperty.type !== 'Text' &&
        currentProperty.definitionListId
      ) {
        await MessageBox.confirm(this.$t('product.output_text_warning'))
          .then(() => {
            currentProperty.definitionListId = null;
          })
          .catch(() => {
            continueWithSave = false;
          });
      }
      if (!continueWithSave) return;

      // noinspection JSValidateTypes
      await this.updateProductProperty({
        productId: this.product.id,
        propertyName: previousProperty.name,
        propertyData: {
          ...currentProperty,
          priority: parseInt(currentProperty.priority, 10)
        },
        tenant: this.tenant
      });

      if (name !== previousProperty.name) {
        this.fetchProductProperties(this.product.id);
      }

      this.refreshProductValidation();

      this.stopEditing();

      this.showNotification(
        'Success',
        this.$t('product.msg-success-update-property', { key: name }),
        name,
        this.showItemOnTable
      );

      this.fetch();
    },
    deleteItem(index) {
      const property = this.propertiesSorted[index];

      const name = property.displayName
        ? `${property.displayName} (${property.name})`
        : property.name;
      if (!this.isUnusedProperty(property)) {
        MessageBox.alert(`${this.$t('product.confirm.beware')} `).then(noop);
        return;
      }

      if (
        this.nested &&
        this.nested[property.name] &&
        this.nested[property.name].hosts.length > 0
      ) {
        MessageBox.alert(
          `${this.$t('product.confirm.beware-nested-property')} `
        ).then(noop);
        return;
      }

      MessageBox.confirm(this.$t('product.confirm.delete', { name }))
        .then(async () => {
          // noinspection JSValidateTypes
          await this.deleteProductProperty({
            productId: this.product.id,
            name: property.name
          });

          if (this.propertiesSorted.length === 0 && this.page > 1) {
            this.page -= 1;
          }
          this.showNotification(
            'Success',
            this.$t('product.msg-success-delete-property', { name })
          );
        })
        .catch(noop);
    },
    showItemOnTable(itemIdentifier) {
      const index = this.properties.map((e) => e.name).indexOf(itemIdentifier);

      if (index !== -1) {
        const propPage = Math.floor(index / this.count);
        this.$refs.paginator.currentPageData = propPage + 1;
        Vue.nextTick(() => {
          this.hightlightRow(index % this.count);
        });
      }
    },
    hightlightRow(index) {
      const rows = document.querySelectorAll(
        `.ui_table_${this.property} .el-table__row`
      );
      const row = rows[index];
      if (row) {
        row.className += ' hover-row';
        setTimeout(() => {
          row.className -= ' hover-row';
        }, 2000);
      }
    },
    isUnusedProperty(row) {
      if (row.name === this.placeholderRowName) return false;
      const propType = this.propertyTypesMap[row.__type];
      return (
        this.unusedProperties &&
        this.unusedProperties[propType] &&
        this.unusedProperties[propType].includes(row.name)
      );
    },
    showDefListSelector(row) {
      return (
        row.__type === 'DIMENSION' ||
        (row.__type === 'OUTPUT' && row.type === 'Text')
      );
    },
    showCreationRow() {
      if (!this.isCreatingProperty) {
        this.isCreatingProperty = true;
        this.newPropertyMetadata = {};
        const type = typeMapper(this.property);
        const templateRow = {
          name: this.placeholderRowName,
          __type: type
        };
        this.propertiesSorted.unshift(templateRow);
        this.editRow(0);
        this.expandedRows = [this.placeholderRowName];
      }
    },
    hideCreationRow() {
      this.stopEditing();
      this.expandedRows = null;
      this.propertiesSorted.shift();
      this.isCreatingProperty = false;
    },
    showImportDialog() {
      if (this.isCreatingProperty) {
        this.hideCreationRow();
      }
      this.isPropertyImportDialogVisible = true;
    },
    hideImportDialog() {
      this.isPropertyImportDialogVisible = false;
    },
    getSuggestions(row, productId) {
      if (
        row.__type === 'INPUT' &&
        ['Number', 'MonetaryAmount'].includes(row.type)
      ) {
        return () => {
          const availableUnits = [];
          Object.keys(this.product.specification.units).map((u) =>
            availableUnits.push({
              label: displayName(this.product.specification.units[u]),
              key: u
            })
          );
          return availableUnits;
        };
      }
      return () =>
        this.suggestionsByProperty(
          row.type,
          row.name,
          row.definitionListId,
          productId,
          false
        );
    },
    inheritFromCsp(index) {
      const prop = this.propertiesSorted[index];
      if (!prop) {
        return false;
      }
      return (prop.metadata || {}).inherit || false;
    }
  }
};
</script>

<style scoped>
.el-input-number {
  width: 100%;
}

.el-button.el-tooltip.is-circle {
  padding: 0;
  border: none;
  background-color: transparent;
  font-size: 18px;
}

.icon-paper-write {
  text-align: center;
  font-size: 20px;
  cursor: pointer;
}

.icon-paper-write.disabled {
  cursor: default;
}

.el-icon-warning-outline,
.el-icon-question {
  font-size: 20px;
  font-weight: bold;
}

.el-table>>>.inherited {
  color: #aaa;
}

.el-table>>>.overridden {
  font-style: italic;
}

.el-dialog__wrapper>>>.add-variable-dialog {
  border-radius: 4px;
  border: 1px solid #ebeef5;
}

.el-dialog__wrapper>>>.el-dialog__header {
  padding: 15px;
  padding-bottom: 10px;
  background-color: transparent;
}

.el-dialog__wrapper>>>.el-dialog__body {
  padding: 10px 15px;
}

.italic {
  font-style: italic;
}

.creation-alert {
  margin: -1em 0 0 0;
}

@-webkit-keyframes focused {
  from {
    background-color: rgba(0, 0, 143, 0.1);
  }

  to {
    background-color: inherit;
  }
}

.ProductProperties>>>.ProductProperty__Row--Active {
  background-color: rgba(0, 0, 143, 0.1);
  animation: focused 0.6s 3;
  animation-delay: 0.4s;
  animation-timing-function: ease-in-out;
}

.ProductProperties>>>.ProductProperty__Row--Active .UiLabel {
  color: #343c3d;
}

.ProductProperties>>>.el-checkbox {
  margin: auto !important;
}
</style>
