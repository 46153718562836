<template>
  <el-input
    v-if="mask && (current || mode === 'form')"
    ref="input"
    v-model="value"
    v-mask="mask"
    class="question"
    :clearable="mode === 'form'"
    :data-cy="`input-${question.id}`"
    :placeholder="placeHolder"
    inputmode="numeric"
    pattern="[0-9]*"
    :disabled="question.loading"
    @input="
      $emit('show');
      setValue(substitute($event));
    "
    @keydown.enter.exact.native.prevent.stop="
      setValue(substitute($event.target.value));
      submit(true);
    "
    @clear="submitClear"
    @keydown.tab.native.capture="captureTab($event)"
    @focus="$emit('focus')"
    @blur="setValueOnBlur($event)"
  >
    <i
      v-if="question.loading"
      slot="prefix"
      class="el-icon-loading el-input__icon"
    ></i>
  </el-input>
  <el-input
    v-else-if="current || mode === 'form'"
    ref="input"
    v-model="value"
    class="question"
    inputmode="numeric"
    :clearable="mode === 'form'"
    pattern="[0-9]*"
    :data-cy="`input-${question.id}`"
    :placeholder="placeHolder"
    @input="
      $emit('show');
      setValue(substitute($event));
    "
    @keydown.enter.exact.native.prevent.stop="
      setValue(substitute($event.target.value));
      submit(true);
    "
    @clear="submitClear"
    @keydown.tab.native.capture="captureTab($event)"
    @focus="$emit('focus')"
    @blur="setValueOnBlur($event)"
  >
    <i
      v-if="question.loading"
      slot="prefix"
      class="el-icon-loading el-input__icon"
    ></i>
  </el-input>
</template>
<script>
import { mask } from 'vue-the-mask';
import captureTabMixin from './mixins/captureTabMixin';
import inputMixin from './mixins/inputMixin';

export default {
  name: 'InputNumberComponent',
  directives: {
    mask
  },
  mixins: [captureTabMixin, inputMixin],
  computed: {
    mask() {
      const metaInputMask = this.question.getMeta('inputMask');
      if (metaInputMask) {
        return metaInputMask.split(',');
      }
      return undefined;
    },
    maskPlaceholder() {
      const metaInputMask =
        this.question.getMeta('inputMaskPlaceholder') ||
        this.question.getMeta('inputMask');
      if (metaInputMask) {
        return metaInputMask;
      }
      return undefined;
    },
    placeHolder() {
      if (this.mask) {
        return `${this.$t('enter-number')} (${this.maskPlaceholder})`;
      }
      return this.$t('enter-number');
    }
  },
  methods: {
    setValueOnBlur(event) {
      if (this.mode === 'form') {
        this.setValue(event.target.value);
        this.submit(false);
      }
    },
    focusInput() {
      if (this.$refs.input) {
        this.$refs.input.$el
          .querySelector('input')
          .focus({ preventScroll: true });
      }
    },
    substitute(val) {
      return val.replace(/,/g, '.');
    }
  }
};
</script>

<style lang="scss" scoped>
.question {
  max-width: 100%;
}
</style>
