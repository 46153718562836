/**
 * Copyright 2020 AXA Group Operations S.A.
 *
 * Licensed under the Apache License, Version 2.0 (the "License")
 * you may not use this file except in compliance with the License
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import DataLoader from 'dataloader';
import { LRUMap } from 'lru_map';
import feathersClient from '../../feathers';
import types from './sharedProperty.types';

const fetchProductValidations = async (ids) => {
  const productIds = ids.map((id) => parseInt(id.split(':')[0], 10));
  const tenant = ids[0].split(':')[1];
  const isGroupEqual = ids.every((id) => id.split(':')[1] === tenant);
  if (!isGroupEqual) {
    throw Error('Error fetching product validation: groups are not equal');
  }
  const id = productIds[0];
  if (!tenant)
    throw Error('Error fetching product validation: tenant is missing');

  return feathersClient
    .service('2.0/shared-properties')
    .get(id, { query: { batch: productIds.join(','), tenant } });
};

const productValidationLoader = new DataLoader(fetchProductValidations, {
  maxBatchSize: 40,
  cacheMap: new LRUMap(150)
});

export default {
  fetchSharedProperties: async ({ commit, rootState }) => {
    commit(types.FETCH_SHARED_PROPERTIES_REQUEST);
    try {
      const properties = await feathersClient
        .service('2.0/properties')
        .find({ query: { tenant: rootState.auth.tenant } });
      commit(types.FETCH_SHARED_PROPERTIES_SUCCESS, { properties });
    } catch (e) {
      commit(types.FETCH_SHARED_PROPERTIES_ERROR);
    }
  },
  fetchProductValidation: async (
    { commit, rootState, state },
    { productId, force = false }
  ) => {
    const { tenant } = rootState.auth;
    // We silently ignore the action if the current tenant is not in the state
    if (!tenant) return;
    const id = `${productId || rootState.product.product.id}:${
      rootState.auth.tenant
    }`;
    if (
      !force &&
      state.productValidationsChecked &&
      state.productValidationsChecked.includes(productId.toString())
    )
      return;
    productValidationLoader.load(id).then((validation) => {
      commit(types.SET_PRODUCT_VALIDATION, {
        productId: validation.id,
        validation
      });
    });
  },
  clearProductValidation: async ({ rootState }, { productId }) => {
    const { tenant } = rootState.auth;
    productValidationLoader.clear(`${productId}:${tenant}`);
  },
  createSharedProperty: async (
    { commit },
    { propertyData, propertyParams, tenant }
  ) => {
    try {
      propertyParams.query = tenant ? { team: tenant } : {};

      const property = await feathersClient.service('2.0/properties').create(
        {
          ...propertyData,
          valueType:
            propertyData.__type === 'DIMENSION'
              ? 'Enum'
              : propertyData.valueType || 'Number'
        },
        propertyParams
      );

      commit(types.CREATE_SHARED_PROPERTY, { property });
      productValidationLoader.clearAll();
      return property;
    } catch (e) {
      const err = Error(e);
      err.code = e.code;
      throw err;
    }
  },
  deleteSharedProperty: async ({ commit }, { id, tenant }) => {
    try {
      const query = tenant ? { tenant } : {};
      await feathersClient.service('2.0/properties').remove(id, { query });
      commit(types.DELETE_SHARED_PROPERTY, id);
      productValidationLoader.clearAll();
    } catch (e) {
      throw Error(e);
    }
  },
  updateSharedProperty: async ({ commit }, { propertyData, tenant }) => {
    try {
      if (!propertyData) throw Error('Missing propertyData parameter');
      const { id, ...data } = propertyData;
      const query = tenant ? { tenant } : {};

      const property = await feathersClient
        .service('2.0/properties')
        .update(id, data, { query });

      commit(types.UPDATE_SHARED_PROPERTY, { property });
      productValidationLoader.clearAll();
    } catch (e) {
      throw Error(e);
    }
  },
  importSharedProperty: async (
    { commit },
    { propertyData, propertyParams, tenant }
  ) => {
    const { id, ...data } = propertyData;

    if (!id) {
      propertyParams.query = tenant ? { team: tenant } : {};
      const property = await feathersClient.service('2.0/properties').create(
        {
          ...propertyData,
          valueType:
            propertyData.__type === 'DIMENSION'
              ? 'Enum'
              : propertyData.valueType || 'Number'
        },
        propertyParams
      );
      commit(types.CREATE_SHARED_PROPERTY, { property });
      return 'created';
    }

    const query = tenant ? { tenant } : {};
    const property = await feathersClient
      .service('2.0/properties')
      .update(id, data, { query });

    commit(types.UPDATE_SHARED_PROPERTY, { property });
    productValidationLoader.clearAll();
    return 'updated';
  }
};
