<template>
  <div>
    <el-dialog visible :before-close="close">
      <div
        slot="title"
        class="d-flex align-items-center justify-content-between pr-5"
      >
        <div
          class="modal-title d-flex align-items-center justify-content-between"
        >
          <span class="pr-4">{{
            $t('product.terms.order-definitions-modal.title')
          }}</span>
          <UiColorPicker
            :toggle-editing="false"
            :value="color"
            :palette="colors"
            :data-cy="`color-picker-${property}`"
          />
          <span class="pl-2">{{ property }} </span>
          <span class="pl-2 mr-2 label-definitionlist"
            >{{ definitionList.name }}
          </span>
          <router-link
            :to="{
              name: 'definition-lists-edit',
              params: { definitionListId: definitionList._id }
            }"
            target="_blank"
          >
            <i class="el-icon-link"></i>
          </router-link>
        </div>
      </div>
      <div class="d-flex align-items-center justify-content-between">
        <UiButton
          data-cy="button-reset-priorities"
          variant="secondary"
          icon="el-icon-plus"
          @click="addPriority"
        >
          {{ $t('product.terms.order-definitions-modal.add-priority') }}
        </UiButton>
        <UiButton
          :disabled="!canReset"
          data-cy="button-reset-priorities"
          variant="secondary"
          icon="el-icon-magic-stick"
          @click="resetPriorities"
        >
          {{ $t('product.terms.order-definitions-modal.reset-priorities') }}
        </UiButton>
      </div>
      <UiTable
        ref="table"
        :data="definitionsSorted"
        row-key="_id"
        :default-sort="{ prop: 'primaryKey', order: 'descending' }"
      >
        <el-table-column label="Definition">
          <template #default="{ row }">
            <div class="rounded pr-2">{{ priorityValue(row) }}</div>
            <span>{{ row.displayName || row.primaryKey }}</span>
          </template>
        </el-table-column>
        <el-table-column width="55">
          <template #default="{ row }">
            <el-button
              type="text"
              size="mini"
              @click="deletePriority(row, true)"
            >
              <i class="icon-close"></i>
            </el-button>
          </template>
        </el-table-column>
      </UiTable>
      <UiPagination
        v-if="sortedTermDefinitions.length"
        ref="paginator"
        :current-page.sync="page"
        :page-size="count"
        :total="paginationTotal"
      />
      <div slot="footer">
        <el-button size="small" @click="$emit('close')">{{
          $t('product.terms.order-definitions-modal.close')
        }}</el-button>
      </div>
    </el-dialog>
    <definition-add-priority
      v-if="showAddPriority"
      :definitions="definitionsToAdd"
      :priorities="openPriorities"
      :property="property"
      @update="$emit('update')"
      @close="showAddPriority = false"
    />
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { Message } from 'element-ui';
import { colors } from '../config';
import DefinitionAddPriority from './DefinitionAddPriority.vue';
import * as api from '../api';
import { noop } from '../util';
import { definitionOrderPriorities } from '../helpers/definitionOrderPriorities';

export default {
  name: 'EditDefinitionPriorityModal',
  components: { DefinitionAddPriority },
  mixins: [],
  props: {
    termDefinitions: {
      type: Array,
      required: true
    },
    property: {
      type: String,
      required: true
    },
    color: {
      type: String,
      required: true
    },
    definitionList: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      page: 1,
      count: 5,
      priorities: [],
      search: '',
      paginationTotal: 0,
      colors,
      showAddPriority: false
    };
  },
  computed: {
    ...mapState('auth', ['tenant']),
    ...mapState('product', ['product']),
    ...mapState('productProperty', {
      productDimensions: 'dimensions'
    }),
    openPriorities() {
      const { nbLeft, currentPrioritiesLength } = definitionOrderPriorities(
        10,
        this.sortedTermDefinitions
      );
      const priorities = Array.from(
        new Array(currentPrioritiesLength + nbLeft),
        (x, i) => ({
          key: i + 1,
          label: i + 1
        })
      );
      return priorities.filter(
        (p) => !this.sortedTermDefinitions.some((df) => df.priority === p.key)
      );
    },
    definitionsSorted() {
      return this.sortedTermDefinitions
        .filter((df) => !!df.priority)
        .sort((a, b) => a.priority - b.priority)
        .slice(this.count * (this.page - 1), this.count * this.page);
    },
    prioritiesFiltered() {
      return this.priorities.filter(
        (p) => !this.sortedTermDefinitions.some((df) => df.priority === p.key)
      );
    },
    definitionsToAdd() {
      return this.sortedTermDefinitions.filter((df) => !df.priority);
    },
    canReset() {
      return this.definitionsSorted.length > 0;
    },
    sortedTermDefinitions() {
      if (this.termDefinitions) {
        return [...this.termDefinitions].sort((a, b) => {
          const sortA = a.priority === null ? 999999999999 : a.priority;
          const sortB = b.priority === null ? 999999999999 : b.priority;
          return sortA - sortB;
        });
      }
      return [];
    }
  },
  watch: {
    definitionsSorted() {
      this.paginationTotal = this.sortedTermDefinitions.filter(
        (df) => !!df.priority
      ).length;
    }
  },
  mounted() {
    this.priorities = Array(this.termDefinitions.length)
      .fill(0)
      .map((v, k) => ({ key: k + 1, label: k + 1 }));

    this.paginationTotal = this.termDefinitions.length;
  },
  methods: {
    ...mapActions('productProperty', ['fetchDimensions']),
    async resetPriorities() {
      this.deleteAllPriorities();
    },
    addPriority() {
      this.showAddPriority = true;
    },
    async deletePriority(row, confirm) {
      try {
        await api.updateTermDefinitionsOrder(
          { id: row._id, priority: null },
          this.property,
          this.product.id,
          this.tenant
        );
        await this.fetchDimensions(this.product.id);
        if (confirm) {
          this.update();
          Message.success(
            this.$t(
              'product.terms.order-definitions-modal.deleted-successfully'
            )
          );
        }
      } catch (err) {
        noop(err);
        Message.error(
          this.$t('product.terms.order-definitions-modal.deleted-failed')
        );
      }
    },
    deleteAllPriorities() {
      this.sortedTermDefinitions
        .filter((df) => !!df.priority)
        .forEach((df) => {
          this.deletePriority(df, false);
        });
      this.update();
      Message.success(
        this.$t('product.terms.order-definitions-modal.deleted-successfully')
      );
    },
    priorityValue(row) {
      const { primaryKey } = row;
      const { priority } = this.sortedTermDefinitions.find(
        (df) => df.primaryKey === primaryKey
      );
      return priority;
    },
    close() {
      this.priorities = [];
      this.$emit('close');
    },
    update() {
      this.$emit('update');
    }
  }
};
</script>

<style scoped lang="scss">
.label-definitionlist {
  font-weight: bold;
  font-size: 13px;
}
.rounded {
  color: #a6a6a6;
  border: 1px solid #e1e1e1;
  border-radius: 4em !important;
  padding: 3px 10px !important;
}
</style>
