import axios from '../axios';
import { configureClient } from './helpers';

export const settings = { baseURL: `${window.__env__.VUE_APP_API_URL}/` };

const client = axios.create(settings);
configureClient(client, settings);

const flushAllUrl = () => '/api/editor/cache/1.0/flush/all';

export const flushAllClient = async () => {
  const { status, data } = await client.post(flushAllUrl());
  if (status === 200) return data;
  // @todo better error handling
  throw new Error('Todo better error handling');
};
