/* eslint-disable no-param-reassign */
/**
 * Copyright 2020 AXA Group Operations S.A.
 *
 * Licensed under the Apache License, Version 2.0 (the "License")
 * you may not use this file except in compliance with the License
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import feathers from '@feathersjs/feathers';
import rest from '@feathersjs/rest-client';
import Cookies from 'js-cookie';
import stringify from 'qs-stringify';
import axios from '../axios';

const feathersClient = feathers();

function applyTenantUrlSegment() {
  return (hook) => {
    if (hook.type !== 'before') {
      return Promise.reject(
        new Error(
          "The 'applyTenantUrlSegment' hook should only be used as a 'before' hook."
        )
      );
    }

    if (!hook.path.includes('authentication')) {
      if (!hook.params.headers) hook.params.headers = {};
      const AUTH_COOKIE = window.__env__.VUE_APP_AUTH_COOKIE;
      const cookie = Cookies.get(AUTH_COOKIE);
      hook.params.accessToken = cookie;
      hook.params.headers.Authorization = cookie;
    }

    if (hook.params && hook.params.query && hook.params.query.group) {
      const { group, ...query } = hook.params.query;
      const qs = stringify(query);
      hook.params.connection = {
        ...(hook.params.connection || {}),
        url: `/tenants/${group}${hook.service.base}${
          hook.id ? `/${hook.id}` : ''
        }${qs ? `?${qs}` : ''}`
      };
    }

    return Promise.resolve(hook);
  };
}

function checkResponseStatus() {
  return (hook) => {
    if (hook.error.status === 401 || hook.error.code === 401) {
      window.location.href = '/';
    }
  };
}

// Needed for the unit tests where wondow.__env__ is not defined
const apiUrl = window && window.__env__ ? window.__env__.VUE_APP_API_URL : '';

feathersClient.configure(rest(`${apiUrl}/api/editor`).axios(axios)).hooks({
  before: applyTenantUrlSegment(),
  error: checkResponseStatus()
});

export default feathersClient;
