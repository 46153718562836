/**
 * Copyright 2020 AXA Group Operations S.A.
 *
 * Licensed under the Apache License, Version 2.0 (the "License")
 * you may not use this file except in compliance with the License
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { v4 as uuidv4 } from 'uuid';

const validNotificationTypes = ['Danger', 'Warning', 'Notice', 'Success'];
const assertValidNotificationType = (t) => {
  if (!validNotificationTypes.includes(t))
    throw new Error(`Invalid notification type (${t})`);
  return t;
};

const toNotification = (type, { code, message, data }) => ({
  type: assertValidNotificationType(type),
  code,
  message,
  data
});

const notification = (type, code, message, data) => ({
  type,
  code,
  message,
  data,
  identifier: uuidv4(),
  unread: true
});

export const dangerNotification = (code, message, data = {}) =>
  notification('Danger', code, message, data);
export const warningNotification = (code, message, data = {}) =>
  notification('Warning', code, message, data);
export const noticeNotification = (code, message, data = {}) =>
  notification('Notice', code, message, data);
export const successNotification = (code, message, data = {}) =>
  notification('Success', code, message, data);

export const toDangerNotification = (error) => toNotification('Danger', error);
export const toWarningNotification = (error) =>
  toNotification('Warning', error);
export const toNoticeNotification = (error) => toNotification('Notice', error);
export const toSuccessNotification = (error) =>
  toNotification('Success', error);

export const errorToNotifications = (errors, exposables, exposableKeys) => {
  return Object.keys(errors)
    .filter((k) => exposableKeys.includes(k))
    .map((k) => ({
      ...exposables[k].toNotification(errors[k]),
      identifier: uuidv4(),
      unread: true
    }));
};
