/**
 * Copyright 2020 AXA Group Operations S.A.
 *
 * Licensed under the Apache License, Version 2.0 (the "License")
 * you may not use this file except in compliance with the License
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import {
  RuleEditor,
  NodeFactory,
  IfNodeConfig,
  LimitNodeConfig
} from './index';
import { AnnotatedArrayNodeFactory } from './AnnotatedArrayNode';
import { AnnotatedForkNodeFactory } from './AnnotatedForkNode';
import { AnnotatedNamedNodeFactory } from './AnnotatedNamedNode';
import { AnnotatedIfNodeFactory } from './AnnotatedIfNode';
import { AnnotatedLimitNodeFactory } from './AnnotatedLimitNode';

export default class AnnotatedRuleEditor extends RuleEditor {
  registerNodeFactories() {
    const namedNodeFactory = new AnnotatedNamedNodeFactory();
    NodeFactory.shared.registerDefaultNodeFactory(namedNodeFactory);

    // The order of registration is important!
    // In case of multiple factories announcing they can create
    // a node from a model, the last one is used.
    NodeFactory.shared.registerNodeFactory(new AnnotatedArrayNodeFactory());
    NodeFactory.shared.registerNodeFactory(new AnnotatedForkNodeFactory());
    NodeFactory.shared.registerNodeFactory(namedNodeFactory);
    const ifNodeConfig = new IfNodeConfig(this.product.specification);
    NodeFactory.shared.registerNodeFactory(
      new AnnotatedIfNodeFactory(ifNodeConfig)
    );
    const limitNodeConfig = new LimitNodeConfig(
      this.product.specification,
      this.termIndex
    );
    NodeFactory.shared.registerNodeFactory(
      new AnnotatedLimitNodeFactory(limitNodeConfig)
    );
  }
}
