const getInitialState = () => ({
  focusedNode: null
});

export default {
  namespaced: true,
  state: getInitialState(),
  actions: {
    reset: ({ commit }) => commit('RESET'),
    nodeFocus({ commit }, data) {
      commit('NODE_FOCUS', data);
    },
    resetFocus({ commit }) {
      commit('RESET_FOCUS');
    }
  },
  getters: {},
  mutations: {
    RESET: (state) => Object.assign(state, getInitialState()),
    NODE_FOCUS(state, configuration) {
      state.focusedNode = {
        ...configuration
      };
    },
    RESET_FOCUS(state) {
      state.focusedNode = null;
    }
  }
};
