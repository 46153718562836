<template>
  <div class="ProductRulesSummary product-summary">
    <div class="title d-flex ui_label_tite">
      <div class="icon icon-hierarchy-2 mr-2" />
      <div class="label">{{ $t('product.nav.rules') }}</div>
    </div>
    <div class="block">
      <div class="d-flex flex-row flex-wrap">
        <div v-for="rule in firstRules" :key="rule.id" class="col-6 ui_link_rule">
          <ProductLink name="rules-edit" tag="div" class="d-flex flex-grow-1" :product="product"
            :params="{ ruleId: rule.id }">
            <a class="text-truncate">{{ rule.name }}</a>
          </ProductLink>
        </div>
      </div>
      <router-link :to="{
        name: 'product-rules-index',
        params: { productId: product.version.latest },
        query: $router.currentRoute.query
      }" tag="div" class="text-right mt-3">
        <a class="all ui_link_all_rules">
          <i class="icon-keyboard-arrow-right"></i> All rules
        </a>
      </router-link>
    </div>
  </div>
</template>

<script>
import RulesIcon from '../assets/images/rules.svg';
import ProductLink from './Product/ProductLink';

export default {
  name: 'ProductRulesSummary',
  components: { ProductLink },
  props: { product: { type: Object, required: true } },
  computed: {
    icon() {
      return RulesIcon;
    },
    firstRules() {
      const numberOfDims = 8;
      return this.product.rules.slice(0, numberOfDims);
    }
  }
};
</script>

<style scoped lang="scss">
@import '@axatechlab/assets/scss/_variables';

.title {
  position: relative;
  font-size: 150%;

  .icon {
    display: inline-block;
  }
}

.all {
  position: relative;
}

.block {
  background-color: #f8f8fb;
  border: 1px solid rgba(43, 48, 52, 0.1);
  border-radius: 10px;
  padding: 1em 1.5em;
  margin-top: 1em;
}
</style>
