import Question from './question.model';

export default class DateQuestion extends Question {
  constructor(question, locale, lastPriority = 0) {
    super(question, locale, lastPriority);
    this.type = 'date';
  }

  responseDisplay() {
    if (this.response) {
      return new Date(
        Number(this.response.id) - new Date().getTimezoneOffset() * 60 * 1000
      )
        .toISOString()
        .slice(0, 10);
    }
    return undefined;
  }
}
