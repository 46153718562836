<template>
  <div class="ProductDimensionsSummary product-summary">
    <div class="title d-flex align-items-center ui_label_title">
      <component :is="icon" class="icon mr-2" />
      <div class="label">{{ $t('product.terms_dimensions') }}</div>
    </div>
    <div class="block">
      <div class="d-flex flex-wrap">
        <div v-for="dimension in firstDimensions" :key="dimension.id" class="col-6 ui_link_dimension">
          <ProductLink name="terms-index" tag="div" class="d-flex flex-grow-1" :product="product"
            :hash="`#t/${dimension.id}`">
            <a class="text-truncate">
              {{ getDisplayName(dimension) }}
              {{ dimension.termCount ? ' (' + dimension.termCount + ')' : '' }}
            </a>
          </ProductLink>
        </div>
      </div>
      <div class="d-flex justify-content-end align-items-center mt-3">
        <ProductLink name="terms-index" tag="div" class="all" :product="product">
          <a class="all ui_link_all_dimensions">
            <i class="icon-keyboard-arrow-right"></i>
            {{ $t('product.nav.all_terms') }}
          </a>
        </ProductLink>
        <ProductLink name="configuration" tag="div" class="config ui_link_configuration" :product="product">
          <a>{{ $t('product.configuration') }}</a>
        </ProductLink>
      </div>
    </div>
  </div>
</template>

<script>
import DimensionsIcon from '../assets/images/layout-module.svg';
import { displayName } from '../helpers';
import ProductLink from './Product/ProductLink';

export default {
  name: 'ProductDimensionsSummary',
  components: {
    ProductLink,
    DimensionsIcon
  },
  props: {
    product: {
      type: Object,
      required: true
    },
    sourceLanguage: {
      type: String,
      required: true
    }
  },
  computed: {
    icon() {
      return DimensionsIcon;
    },
    firstDimensions() {
      return Object.entries(this.product.specification.dimensions)
        .map(([key, dimension]) => {
          const terms =
            this.product.terms && this.product.terms[key]
              ? this.product.terms[key]
              : [];
          return {
            ...dimension,
            terms,
            id: key,
            termCount: terms.length
          };
        })
        .slice(0, 8);
    }
  },
  methods: {
    getDisplayName(variable) {
      return displayName(variable);
    }
  }
};
</script>

<style scoped lang="scss">
@import '@axatechlab/assets/scss/_variables';

.title {
  position: relative;
  font-size: 150%;

  .icon {
    display: inline-block;
  }
}

.block {
  border: 1px solid rgba(43, 48, 52, 0.1);
  border-radius: 10px;
  padding: 1em 1.5em;
  margin-top: 1em;
}

.col-6 {
  padding-left: 0;
}

.config {
  position: relative;
  margin-left: 1em;
  padding: 0.15em 0.7em;
  border: solid 1px $color-active;
  border-radius: 5px;
  box-shadow: 0 0 0 2px #f5f5f5;
}

.all {
  position: relative;
}
</style>
