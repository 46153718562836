import { render, staticRenderFns } from "./DefinitionAddPriority.vue?vue&type=template&id=1b276794&scoped=true"
import script from "./DefinitionAddPriority.vue?vue&type=script&lang=js"
export * from "./DefinitionAddPriority.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1b276794",
  null
  
)

export default component.exports