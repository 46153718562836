<template>
  <div>
    <h3>{{ $t('team.basic-settings') }}</h3>
    <el-row
      v-if="tenant && (isSuperAdmin() || isOwner(tenant.slug))"
      class="d-flex tenantDataHeader p-2 mb-4"
    >
      <div>
        <el-row class="pb-2"
          ><span class="font-weight-bold">Tenant name</span></el-row
        >
        <el-row width="30%"
          ><el-input
            v-model="tenantName"
            :placeholder="$t('team.tenant-name-placeholder')"
          ></el-input
        ></el-row>
      </div>
      <div class="pl-2">
        <el-row class="pb-2"
          ><span class="font-weight-bold">Tenant slug</span>
        </el-row>
        <el-row width="30%"
          ><el-input
            :value="tenantSlug"
            :disabled="disabled"
            :placeholder="$t('team.tenant-name-placeholder')"
          ></el-input
        ></el-row>
      </div>
    </el-row>
    <el-row v-else class="d-flex">
      <div class="p-2">
        <el-row class="pb-2"><span>Tenant name:</span></el-row>
        <el-row width="30%">{{ tenant ? tenant.label : '' }}</el-row>
      </div>
    </el-row>
    <h3>{{ $t('team.members') }}</h3>

    <div>
      <ul>
        <li v-for="(member, index) in delMembers" :key=index>
          <span style="color:red;">{{ member.email }}</span>
          &nbsp;<i @click="restoreMember(member.email)" class="el-icon-refresh-left" style="cursor:pointer;"></i>
          &nbsp;<span  style="cursor:pointer;" @click="restoreMember(member.email)"><b>restore user</b></span>
        </li>
        
      </ul>
    </div>
    <el-row class="modalSearch pl-2">
      <el-col class="d-flex justify-content-end">
        <UiButton
          v-if="tenant && (isSuperAdmin() || isOwner(tenant.slug))"
          class="addButton"
          :data-cy="'tenant-add-button'"
          :disabled="editingUserId === 0"
          @click="addNewMember"
        >
          {{ $t('team.add-member') }}
        </UiButton>
        <UiSearchBox
          v-model="searchStr"
          :placeholder="$t('user.search_user')"
        />
      </el-col>
    </el-row>
    <UiTable class="membersTable" :data="searchedRoles" @click="setEditingRow">
      <el-table-column label="E-mail address">
        <template slot-scope="scope">
          <template v-if="scope.row.userId !== editingUserId">
            <div class="d-flex justify-content-between">
              <div
                v-if="scope.row.name"
                class="avatar-circle"
                :style="{ background: colorForName(scope.row.name) }"
              >
                <span class="avatar-letters">{{
                  initialsFromName(scope.row.name)
                }}</span>
              </div>
              <div class="d-flex flex-column">
                <div>{{ scope.row.name }}</div>
                <div>{{ scope.row.email }}</div>
              </div>
            </div>
          </template>
          <template v-if="scope.row.userId === editingUserId">
            <div class="user-select">
              <el-select
                v-model="editingEmail"
                filterable
                placeholder="Select"
                @change="checkUserEditChanges"
              >
                <el-option
                  v-for="(user, index) in allUsers"
                  :key="index"
                  :label="user.email"
                  :value="user.email"
                >
                </el-option>
              </el-select>
            </div>
          </template>
        </template>
      </el-table-column>
      <el-table-column label="Assignments">
        <template slot-scope="scope">
          <div v-if="scope.row.teams && scope.row.teams.length">
            <RoleTag
              v-for="(team, index) of scope.row.teams"
              :key="index"
              :data="{ userId: scope.row.userId, teamId: team.team_id }"
              :left="
                findTeamById(team.team_id) && findTeamById(team.team_id).label
              "
              :right="userRoleLabelById(scope.row, team.team_id)"
              :left-condition="
                () =>
                  userRoleLabelById(scope.row, team.team_id) !== 'Super Admin'
              "
              :delete-enabled="canDeleteMember(scope.row, team)"
              :class="{
                'default-team': isDefaultTeam(team)
              }"
              @delete="deleteRole"
            />
          </div>
          <span v-else>No team assignment</span>
        </template>
      </el-table-column>
      <el-table-column>
        <template slot-scope="scope">
          <el-row
            v-if="scope.row.userId === editingUserId"
            class="d-flex align-items-end"
          >
            <div class="new-member-team-role">
              Edit/Add:
              <el-select
                v-model="editingTeam"
                filterable
                placeholder="Select"
                @change="checkUserEditChanges"
              >
                <el-option
                  v-for="team in teamsList()"
                  :key="team.id"
                  :label="team.label"
                  :value="team.slug"
                >
                </el-option>
              </el-select>
              as
              <el-select
                v-model="editingRole"
                filterable
                placeholder="Select"
                :disabled="!editingTeam"
                @change="checkUserEditChanges"
              >
                <el-option
                  v-for="(role, index) in getPossibleRoles(editingTeam)"
                  :key="index"
                  :label="role.label"
                  :value="role.id"
                >
                </el-option>
              </el-select>
            </div>
          </el-row>
        </template>
      </el-table-column>
      <el-table-column :min-width="10">
        <template slot-scope="scope">
          <i
            v-if="canDeleteMember(scope.row, tenant)"
            class="deleteIcon el-icon-close"
            @click="deleteMember(scope.$index)"
          />
        </template>
      </el-table-column>
    </UiTable>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import RoleTag from './RoleTag';
import { slugify } from '../../filters/slugify';

export default {
  name: 'TenantEditionGeneral',
  components: {
    RoleTag
  },
  props: { tenant: { type: Object, default: undefined } },
  data: () => ({
    tenantName: null,
    disabled: true,
    tenantSlug: '',
    selectedTeam: null,
    searchStr: '',
    selectedRoles: [],
    editingUserId: null,
    editingEmail: '',
    editingTeam: '',
    editingRole: '',
    delMembers: []
  }),
  computed: {
    ...mapState('users', ['user', 'users', 'roles']),
    ...mapGetters('auth', { user: 'getUser' }),
    ...mapState('teams', ['teams']),
    ...mapGetters('auth', ['isOwner', 'isSuperAdmin', 'isAdmin']),
    searchedRoles() {
      const str = this.searchStr.toLowerCase();
      return this.selectedRoles
        .filter((r) => r.email.includes(str))
        .filter((r) =>
          this.selectedTeam
            ? r.teams.some((t) => t.team_id === this.selectedTeam)
            : true
        );
    }
  },
  watch: {
    tenant: {
      async handler() {
        this.updateData();
      }
    },
    tenantSlug(newTeamSlug) {
      this.tenantSlug = slugify(newTeamSlug.toLowerCase(), {
        lowercase: true
      });
      this.selectedRoles = [];
      this.$emit('slug-changed', this.tenantSlug);
    },
    tenantName(newTenantName) {
      this.$emit('name-changed', newTenantName);
    }
  },
  mounted() {
    this.updateData();
  },
  methods: {
    ...mapActions('users', ['getUsersByTenant', 'getOrphanUsers']),
    ...mapActions('teams', ['saveTenant']),
    addNewMember() {
      this.searchStr = '';
      this.editingUserId = 0;
      this.editingEmail = '';
      this.selectedRoles.unshift({
        userId: 0,
        email: '',
        roleId: null,
        team: null
      });
    },
    userRoleById(user, teamId) {
      try {
      const team = (user.teams||[]).find((userTeam) => {
        return teamId
          ? userTeam.team_id === teamId
          : userTeam.tenant === this.tenant.slug
      });

      if(!team) {
        return null;
      }
      const roleId = (team.UserTeam && team.UserTeam.team_role_id) || team.team_role_id;
      return this.roleById(roleId);
      } catch (error) {
        console.log('error', error);
      }

    },
    roleById(roleId) {
      return this.roles.find((role) => role.id === roleId);
    },
    async updateData() {
      const currentUserTeam = this.user.teams[0];
      const orphanUsers =  this.isSuperAdmin() ? await this.getOrphanUsers() : [];
      const orphanUsersUpdated = orphanUsers
      .filter( u => {
        return u.email.indexOf('axa') !== -1 // avoid token user
      })
      .map( u => {
        return {
          email: u.email,
          userId: u.id,
          roleId: 5,
          teams: [
            {
              tenant: currentUserTeam.slug,
              team_id: currentUserTeam.id,
              team_role_id: 5,
              tenant_role_id: 5
            }
          ]
        }
      });
      this.delMembers = [...this.delMembers, ...orphanUsersUpdated]

      if (this.tenant) {
        this.tenantName = this.tenant.label;
        this.tenantSlug = this.tenant.slug;
        const members = await this.getUsersByTenant({
          tenantSlug: this.tenantSlug
        });
        members
          .filter(
            (member) =>
              this.userRoleById(member) && this.userRoleById(member).id !== 1
          )
          .sort(this.sortByEmail)
          .forEach((member) => {
            this.selectedRoles.push({
              email: member.email,
              userId: member.id,
              roleId: this.userRoleById(member).id,
              teams: member.teams.map((t) => {
                return {
                  tenant: t.tenant,
                  team_id: t.id,
                  team_role_id: t.UserTeam.team_role_id,
                  tenant_role_id: t.UserTeam.tenant_role_id
                };
              })
            });
          });
        this.$emit('roles-changed', this.selectedRoles);
        this.allUsers = this.notAddedAllowedMembers(this.users);
      }
    },
    notAddedAllowedMembers(users) {
      return users
        .filter(
          (user) =>
            !this.selectedRoles.some((member) => member.userId === user.userId)
        )
        .filter((user) => !this.isSuperAdmin(user))
        .sort(this.sortByEmail);
    },
    userRoleLabelById(user, teamId) {
      const role = this.userRoleById(user, teamId);
      return role ? role.label : '';
    },
    canDeleteMember(member, team) {
      const hasTeamPrivileges =
        this.isSuperAdmin() || this.isOwner(team.tenant);
      const isCurrentUser = member.email === this.user.email;
      return hasTeamPrivileges && !isCurrentUser && !this.isDefaultTeam(team);
    },
    sortByEmail(a, b) {
      return a.email.localeCompare(b.email);
    },
    colorForName(name, saturation = 30, lightness = 80) {
      if (!name) return 'transparent';
      let hash = 0;
      for (let i = 0; i < name.length; i += 1) {
        // eslint-disable-next-line no-bitwise
        hash = name.charCodeAt(i) + ((hash << 5) - hash);
      }
      const h = hash % 360;
      return `hsl(${h}, ${saturation}%, ${lightness}%)`;
    },
    initialsFromName(name) {
      return name
        ? name
            .replace(/[^a-zA-Z- ]/g, '')
            .match(/\b\w/g)
            .join('')
        : '';
    },
    newUserSelected() {
      const editingUser = this.selectedRoles.find(
        (r) => r.userId === this.editingUserId
      );
      if (this.editingUserId === 0) {
        const foundUser = this.users.find((r) => r.email === this.editingEmail);
        editingUser.userId = foundUser.id;
      }
      editingUser.email = this.editingEmail;
      const foundTeamIndex =
        editingUser.teams &&
        editingUser.teams.findIndex((t) => {
          const team = this.findTeamById(t.team_id);
          return team && team.slug === this.editingTeam;
        });
      if (foundTeamIndex > -1) {
        editingUser.teams[foundTeamIndex] = {
          tenant: this.tenant.slug,
          team_id: editingUser.teams[foundTeamIndex].team_id,
          team_role_id: this.editingRole,
          tenant_role_id: this.isDefaultTeam(editingUser.teams[foundTeamIndex])
            ? this.editingRole
            : editingUser.tenant_role_id
        };
      } else {
        const newTeamData = this.teams.find((t) => t.slug === this.editingTeam);

        const newTeam = {
          tenant: this.tenant.slug,
          team_id: newTeamData.id,
          team_role_id: this.editingRole,
          tenant_role_id: editingUser.tenant_role_id
        };
        if (!editingUser.teams) editingUser.teams = [];
        editingUser.teams.push(newTeam);
      }
      this.allUsers = this.notAddedAllowedMembers(this.users);
      this.cancelEditingRow();
      this.$emit('roles-changed', this.selectedRoles);
    },
    checkUserEditChanges(row) {
      if (this.editingEmail && this.editingRole && this.editingTeam) {
        this.newUserSelected(row);
      }
    },
    getPossibleRoles(teamSlug) {
      if (teamSlug !== this.tenant.slug) {
        // Teams only have members and guests
        return this.roles.filter((r) => r.id > 3 && r.id <= 5);
      }
      return this.roles.filter((r) => r.id > 1 && r.id <= 5);
    },
    deleteMember(index) {
      this.delMembers.push(this.selectedRoles[index]);
      this.selectedRoles.splice(index, 1);
      this.$emit('roles-changed', this.selectedRoles);
    },
    restoreMember(email) {
      const member = this.delMembers.find(m => m.email === email);
      const roles = [...this.selectedRoles];
      roles.push(member);
      this.selectedRoles = roles.sort(this.sortByEmail);
      this.delMembers = this.delMembers.filter(m => m.email !== email);
      this.$emit('roles-changed', this.selectedRoles);
    },
    deleteRole(value) {
      const { data } = value;
      const user = this.selectedRoles.find((r) => r.userId === data.userId);
      if (user) {
        const teamIndex = user.teams.findIndex(
          (t) => data.teamId === t.team_id
        );
        if (teamIndex > -1) {
          user.teams.splice(teamIndex, 1);
          this.$emit('roles-changed', this.selectedRoles);
          this.cancelEditingRow();
        }
      }
    },
    cancelEditingRow() {
      this.editingUserId = null;
      this.editingEmail = null;
      this.editingRole = null;
      this.editingTeam = null;
    },
    setEditingRow(value) {
      if (this.isSuperAdmin() || this.isOwner(this.tenant.slug)) {
        this.editingUserId = value.userId;
        this.editingEmail = value.email;
        this.editingRole = null;
        this.editingTeam = null;
      }
    },
    findTeamById(id) {
      return this.teams.find((t) => t.id === id);
    },
    isDefaultTeam(team) {
      if (team) {
        const foundTeam = this.findTeamById(team.team_id);
        return foundTeam && this.tenant && foundTeam.slug === this.tenant.slug;
      }
      return false;
    },
    teamsList() {
      const user = this.users.find((u) => u.id === this.editingUserId);
      const isOwner =
        user &&
        user.teams.some(
          (t) => t.tenant === this.tenant.slug && t.UserTeam.tenant_role_id <= 3
        );
      return (
        (this.teams &&
          this.tenant &&
          this.teams.filter((t) => {
            if (isOwner) {
              return t.tenant === this.tenant.slug && t.slug === t.tenant;
            }
            return t.tenant === this.tenant.slug;
          })) ||
        []
      );
    }
  }
};
</script>

<style lang="scss">
@import '@axatechlab/assets/scss/_variables';
.role-select .el-select .el-input__inner,
.user-select .el-select .el-input__inner {
  border: 0;
}
.modalSearch .search-box {
  font-size: medium;
  max-width: 15%;
  padding-left: 10px;
}
.modalSearch .search-box input.ui_input_search {
  overflow: hidden;
  font-size: medium;
}
.addButton {
  margin-left: 0.5rem;
}
.edit-tenant .el-dialog__body {
  padding: 0;
}

.edit-tenant .el-dialog__title {
  font-size: 24px;
}

.edit-tenant .el-dialog__header {
  padding: 22px 28px;
}
.edit-tenant .ui-large-tabs .el-tabs__header {
  padding: 0 28px;
  background: #ededf4;
}

.edit-tenant .el-tabs__item.is-active {
  font-weight: bold;
}

.edit-tenant .ui-large-tabs .el-tabs__item:not(.is-active) {
  color: #00008f;
}

.edit-tenant .el-tabs__content {
  padding: 35px 28px;
}

.edit-tenant h3 {
  font-size: 24px;
  font-weight: bold;
  color: #00008f;
}

.team-select {
  min-width: 150px;
}

.edit-tenant .leftContainer {
  background: #e8e8f0;
  font-size: 10px;
  font-weight: 600;
  border: solid 1px transparent;
}

.edit-tenant .rightContainer {
  background: #fff;
  border: solid 1px #ebebf2;
  font-size: 10px;
  font-weight: 600;
}

.edit-tenant .default-team .leftContainer {
  background-color: #00008f;
  color: #fff;
}

.edit-tenant .default-team .rightContainer {
  color: #00008f;
  border-color: #00008f;
}

.edit-tenant .user-select {
  width: 100%;

  .el-select {
    width: 100%;
  }
}

.new-member-team-role .el-select {
  width: 100px;
}
</style>
