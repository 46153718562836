/**
 * Copyright 2020 AXA Group Operations S.A.
 *
 * Licensed under the Apache License, Version 2.0 (the "License")
 * you may not use this file except in compliance with the License
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { mapGetters, mapState, mapActions } from 'vuex';
import { MessageBox } from 'element-ui';

export default {
  computed: {
    ...mapGetters('auth', ['isOwner', 'isGuest', 'isAdmin']),
    ...mapState('auth', ['tenant']),
    ...mapState('tokens', ['tokens']),
    isReadOnlyMode() {
      return !this.isAdmin(this.tenant);
    }
  },
  methods: {
    ...mapActions('tokens', ['fetchTokens']),
    async getTokens() {
      await this.fetchTokens(this.tenant).then(() => {
        if (this.tokens.length) {
          this.token = this.tokens[0].hash;
        } else {
          MessageBox.alert(
            this.$t('widget.missing-token-help'),
            this.$t('widget.missing-token'),
            {
              callback: () =>
                this.$router.push(
                  `/roles?tenant=${this.tenant}&tab=tenants-tokens-tab`
                )
            }
          );
        }
      });
    }
  }
};
