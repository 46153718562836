var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"RowOperation",on:{"click":function($event){$event.stopPropagation();$event.preventDefault();}}},[(_vm.isEditing)?_c('div',{directives:[{name:"selector",rawName:"v-selector"}],staticClass:"d-flex justify-content-between"},[_c('UiCircularButton',{attrs:{"variant":"success","icon":"el-icon-check"},on:{"click":function($event){return _vm.$emit('command', 'save')}}}),_vm._v(" "),_c('UiCircularButton',{attrs:{"variant":"danger","icon":"el-icon-close"},on:{"click":function($event){return _vm.$emit('command', 'cancel')}}})],1):_c('div',[_c('UiThreeDotsDropDown',{attrs:{"items":[
    _vm.showLambdaRetry
      ? {
        command: 'retryLambda',
        label: 'Retry publication',
        class: 'ui_menu_retryLambda',
        icon: 'fas fa-undo',
      }
      : null,
    !_vm.isActive
      ? {
        command: 'switch',
        label: _vm.$t('product.versions.switchOn'),
        class: 'ui_menu_switch',
        icon: 'fas fa-toggle-on',
      }
      : null,
    _vm.isMember && !_vm.isFallback && !_vm.isQuestionnaire && !_vm.isInitialProduct
      ? {
        command: 'toggleFallbackRelease',
        label: _vm.$t('action.toggleFallbackRelease'),
        class: 'ui_menu_toggleFallbackRelease',
        icon: 'fas fa-cog',
      }
      : null,
    _vm.isMember
      ? {
        command: 'delete',
        label: _vm.$t('action.delete'),
        class: 'ui_menu_delete',
        icon: 'far fa-trash-alt',
        variant: 'danger',
      }
      : null,
  ]},on:{"command":(command) => _vm.$emit('command', command)}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }