/**
 * Copyright 2020 AXA Group Operations S.A.
 *
 * Licensed under the Apache License, Version 2.0 (the "License")
 * you may not use this file except in compliance with the License
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import Vue from 'vue';
import VueGtm from 'vue-gtm';

export default {
  methods: {
    setGtm(tenant) {
      if (
        window.__env__ &&
        window.__env__.VUE_APP_GTM_ENABLED &&
        window.__env__.VUE_APP_GTM_ID
      ) {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          environment: window.__env__.VUE_APP_ENV || 'development',
          appversion: process.env.VUE_APP_VERSION,
          tenant
        });

        Vue.use(VueGtm, {
          id: window.__env__.VUE_APP_GTM_ID,
          queryParams: {},
          defer: false,
          enabled: window.__env__.VUE_APP_GTM_ENABLED || false,
          debug: window.__env__.VUE_APP_GTM_DEBUG || false,
          loadScript: true,
          vueRouter: this.$router,
          ignoredViews: [],
          trackOnNextTick: false
        });
      }
    }
  }
};
