/**
 * Copyright 2020 AXA Group Operations S.A.
 *
 * Licensed under the Apache License, Version 2.0 (the "License")
 * you may not use this file except in compliance with the License
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import Vue from 'vue';
import types from './sharedProperty.types';

export default {
  [types.FETCH_SHARED_PROPERTIES_REQUEST](state) {
    state.isLoading = true;
  },
  [types.FETCH_SHARED_PROPERTIES_SUCCESS](state, { properties }) {
    state.isLoading = false;
    state.properties = properties;
  },
  [types.FETCH_SHARED_PROPERTIES_ERROR](state) {
    state.isLoading = false;
  },
  [types.CREATE_SHARED_PROPERTY](state, { property }) {
    state.properties.push(property);
  },
  [types.DELETE_SHARED_PROPERTY](state, id) {
    state.properties = state.properties.filter(
      (property) => property.id !== id
    );
  },
  [types.UPDATE_SHARED_PROPERTY](state, { property }) {
    const propertyIndex = state.properties.findIndex(
      (_property) => _property.id === property.id
    );
    state.properties.splice(propertyIndex, 1, {
      ...state.properties[propertyIndex],
      ...property
    });
  },
  [types.SET_PRODUCT_VALIDATION](state, { productId, validation }) {
    state.productValidationsChecked.push(productId);
    Vue.set(state.productValidations, productId, {
      ...validation
    });
  }
};
