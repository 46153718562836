var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.current || _vm.mode === 'form')?_c('el-radio-group',{ref:"input",staticClass:"question boolean-question",style:({ flexFlow: `${_vm.mode === 'widget' ? 'column' : 'row'} nowrap` }),attrs:{"data-cy":`input-${_vm.question.id}`},on:{"change":function($event){_vm.$emit('show');
    _vm.setValue($event);},"focus":function($event){return _vm.$emit('focus')}},nativeOn:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;if($event.ctrlKey||$event.shiftKey||$event.altKey||$event.metaKey)return null;$event.preventDefault();$event.stopPropagation();return _vm.submit(true)},"!keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"tab",9,$event.key,"Tab"))return null;return _vm.captureTab($event)}},model:{value:(_vm.value),callback:function ($$v) {_vm.value=$$v},expression:"value"}},[_vm._l((_vm.sortedAnswers),function(answer,index){return [_c('el-radio-button',{key:`${index + 1}-keyboard`,ref:answer.id,refInFor:true,staticStyle:{"flex":"1"},attrs:{"label":answer.id,"disabled":_vm.question.loading,"value":answer.id,"data-cy":`answer-${_vm.question.id}-${answer.id}`,"border":""},nativeOn:{"!click":function($event){$event.preventDefault();_vm.$emit('show');
        _vm.setValue(answer.id);
        _vm.onClick($event);}}},[_c('keyboard-shortcut',{attrs:{"key-code":index + 49,"label":`${index + 1}`,"loading":_vm.question.loading && _vm.question.response.id === answer.id,"active":_vm.current},on:{"action":function($event){_vm.$emit('show');
          _vm.setValue(answer.id);
          _vm.submit();}}},[_c('span',{staticClass:"center"},[_vm._v(_vm._s(_vm.$t(answer.displayName)))])])],1)]}),_vm._v(" "),(_vm.mode === 'form')?_c('el-radio-button',{key:`3-keyboard`,ref:"na",staticClass:"radio-button",staticStyle:{"flex":"1"},attrs:{"disabled":_vm.question.loading,"label":undefined,"value":undefined,"data-cy":`answer-${_vm.question.id}-na`,"border":""},nativeOn:{"!click":function($event){$event.preventDefault();_vm.$emit('show');
      _vm.setValue(undefined);
      _vm.onClickClear($event);}}},[_c('keyboard-shortcut',{attrs:{"key-code":52,"label":`52`,"loading":_vm.question.loading && _vm.question.response.id === _vm.answer.id,"active":_vm.current},on:{"action":function($event){_vm.$emit('show');
        _vm.setValue(undefined);
        _vm.submitClear();}}},[_c('span',{staticClass:"center"},[_vm._v("N/A")])])],1):_vm._e()],2):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }