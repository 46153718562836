<template>
  <UiStickyNotification ref="notification" />
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import WithNotificationMixin from './WithNotificationMixin';

export default {
  name: 'StickyNotification',
  mixins: [WithNotificationMixin],
  computed: {
    ...mapGetters('notifications', [
      'unreadNotifications',
      'hasUnreadNotifications'
    ])
  },
  watch: {
    unreadNotifications(value) {
      value.forEach(this.prepareNotification);
    }
  },
  methods: {
    ...mapActions('notifications', ['remove']),
    prepareNotification({ type, message, identifier }) {
      // todo add support for localization
      // todo add support for multiple notifications
      this.showNotification(type, message);
      this.remove(identifier);
    }
  }
};
</script>
