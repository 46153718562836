<template>
  <UiInlineDropDown
    clearable
    :is-editable="isEditable"
    :label="definitionListName"
    :value="currentDefinitionListId"
    :variant="definitionListNameVariant"
    :items="definitions"
    :toggle-editing="isEditing"
    :handle-change="handleChange"
    @save="handleSave"
  />
</template>

<script>
export default {
  name: 'DefinitionListsDropdown',
  components: {},
  props: {
    isEditable: {
      type: Boolean,
      required: true
    },
    isEditing: {
      type: Boolean,
      required: true
    },
    definitionListId: {
      type: Number,
      default: null
    },
    definitions: {
      type: Array,
      default: () => [],
      validator: (prop) =>
        prop.every(
          (e) =>
            e.key &&
            (typeof e.key === 'string' || typeof e.key === 'number') &&
            e.label &&
            typeof e.label === 'string'
        )
    }
  },
  data: () => {
    return {
      currentDefinitionListId: null,
      currentDefinitionList: null,
      isLoading: true
    };
  },
  computed: {
    definitionListName() {
      if (this.isLoading) return 'Loading definition';
      if (!this.currentDefinitionListId) return 'Select a definition list';
      if (!this.currentDefinitionList)
        return `Definition list (${this.currentDefinitionListId}) missing`;
      const { label } = this.currentDefinitionList;
      return label;
    },
    definitionListNameVariant() {
      if (this.isLoading) return 'light';
      if (!this.definitionListId) return 'error';
      return this.currentDefinitionList ? 'default' : 'error';
    }
  },
  watch: {
    definitions() {
      this.setCurrentDefinitionList(this.definitionListId);
      this.isLoading = false;
    }
  },
  mounted() {
    this.setCurrentDefinitionList(this.definitionListId);
    this.isLoading = this.definitions.length === 0;
  },
  methods: {
    setCurrentDefinitionList(id) {
      this.currentDefinitionListId = id;
      this.currentDefinitionList = this.definitions.find(
        (list) => list.key === this.currentDefinitionListId
      );
    },
    handleChange(value) {
      this.setCurrentDefinitionList(value);
      this.$emit('change', value);
    },
    handleSave() {
      this.$emit('save');
    }
  }
};
</script>

<style lang="scss" scoped></style>
