import defaultQueryClient from '../../../api/queryClient';
import filterObject from '../../../helpers/filterObject';
import { apiMutations, getProduct, getTenant, queryApi } from '../../helper';

const getInitialState = () => ({
  isLoading: false,
  snapshots: [],
  pagination: { total: 0 },
  search: null,
  currentDownload: null,
  downloadedSnapshots: {},
  errors: []
});

export default {
  namespaced: true,
  state: getInitialState(),
  actions: {
    reset: ({ commit }) => commit('RESET'),
    downloadProductSnapshot: async (
      { commit, state, rootState },
      { snapshotId }
    ) => {
      if (state.downloadedSnapshots[snapshotId]) {
        commit('DOWNLOAD_PRODUCT_SNAPSHOT_SUCCESS', {
          snapshot: state.downloadedSnapshots[snapshotId]
        });
        return;
      }
      const tenant = getTenant(rootState);

      await queryApi(
        commit,
        'DOWNLOAD_PRODUCT_SNAPSHOT',
        async () => {
          const snapshot = await defaultQueryClient(
            tenant,
            'products',
            'snapshotDump',
            {
              snapshot: snapshotId
            }
          );
          return { snapshot };
        },
        () => ({ snapshotId }),
        () => ({ snapshotId })
      );
    },
    fetchProductSnapshots: async ({ commit, rootState }, params) => {
      const {
        page = 1,
        count = 25,
        sort = { field: 'created_at', direction: 'DESC' }
      } = params || {};
      const { latest: productId } = getProduct(rootState).version;

      const tenant = getTenant(rootState);

      await queryApi(commit, 'FETCH_PRODUCT_SNAPSHOTS', async () => {
        const { data, total, limit } = await defaultQueryClient(
          tenant,
          'products',
          'allSnapshots',
          filterObject(
            {
              productId,
              page,
              count,
              sort
            },
            (v) => v !== undefined
          )
        );
        return { snapshots: data, limit, page, total };
      });
    }
  },
  getters: {
    hasError: (state) => state.errors.length > 0
  },
  mutations: {
    RESET: (state) => Object.assign(state, getInitialState()),
    ...apiMutations(
      'FETCH_PRODUCT_SNAPSHOTS',
      (state) => {
        state.isLoading = true;
        state.snapshots = [];
      },
      (state, { snapshots, total, search }) => {
        state.snapshots = snapshots;
        state.pagination = { total };
        state.search = search;
        state.isLoading = false;
      },
      (state, error) => {
        const { code, message } = error;
        state.snapshots = [];
        state.isLoading = false;
        state.errors = [{ code, message }];
      }
    ),
    ...apiMutations(
      'DOWNLOAD_PRODUCT_SNAPSHOT',
      (state, { snapshotId }) => {
        state.currentDownload = snapshotId;
        state.downloadedSnapshots[snapshotId] = true;
      },
      (state, { snapshot }) => {
        const { id, dump, filename, createdAt } = snapshot;
        state.downloadedSnapshots = {
          ...state.downloadedSnapshots,
          [snapshot.id]: { id, dump, createdAt, filename }
        };
      },
      (state, error) => {
        const { code, message } = error;
        state.downloadedSnapshots[state.currentDownload] = null;
        state.currentDownload = null;
        state.errors = [{ code, message }];
      }
    )
  }
};
