/**
 * Copyright 2020 AXA Group Operations S.A.
 *
 * Licensed under the Apache License, Version 2.0 (the "License")
 * you may not use this file except in compliance with the License
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import Question from './question.model';

export default class EnumQuestion extends Question {
  constructor(question, locale, lastPriority = 0) {
    super(question, locale, lastPriority);
    this.type = 'enum';

    if (question.answers) {
      this.updateAnswers(question.answers, locale);
    }
    if (question.possibleAnswers) {
      this.possibleAnswers = question.possibleAnswers;
    }
  }

  update(question, locale, lastPriority = 0) {
    super.update(question, locale, lastPriority);

    if (question.answers) {
      this.updateAnswers(question.answers, locale);
    }
    if (question.possibleAnswers) {
      this.possibleAnswers = question.possibleAnswers;
    }
  }
}
