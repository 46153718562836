import { assertValidRevisionType, revisionTypes } from '../../model/revision';

const revisionIconComponent = (type) => {
  assertValidRevisionType(type);
  switch (type) {
    case revisionTypes.REVISION:
      return 'UiTimeClockCircleIcon';
    case revisionTypes.RELEASE:
      return 'UiTagDoubleIcon';
    case revisionTypes.RELEASE_DELETED:
      return 'UiTrashIcon';
    case revisionTypes.RELEASE_STATUS_CHANGED:
      return 'UiTagDoubleIcon';
    case revisionTypes.IMPORT:
      return 'UiUploadSquareIcon';
    case revisionTypes.EXPORT:
      return 'UiCommonFileDownloadIcon';
    case revisionTypes.LOCK:
      return 'UiLockIcon';
    case revisionTypes.UNLOCK:
      return 'UiUnlockIcon';
    default:
      return 'UiTimeClockCircleIcon';
  }
};

export default revisionIconComponent;
