/**
 * Copyright 2020 AXA Group Operations S.A.
 *
 * Licensed under the Apache License, Version 2.0 (the "License")
 * you may not use this file except in compliance with the License
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

// TODO  : Transform into questionsFormMixin !!!!!

/* eslint-disable no-param-reassign */
export default {
  methods: {
    getRulesForQuestions(questions) {
      const reducedRules = questions.reduce(
        (rules, question) => {
          if (question.allowMultiplePerClaim) {
            if (!rules.lines[question.lineRef]) {
              rules.lines[question.lineRef] = {};
            }
            rules.lines[question.lineRef][question.id] =
              this.getQuestionRules(question);
          } else {
            rules.global[question.id] = this.getQuestionRules(question);
          }
          return rules;
        },
        { lines: {}, global: {} }
      );
      return reducedRules;
    },
    getQuestionRules(question) {
      const rules = [];
      if (question.required) {
        rules.push({
          required: true,
          message: this.$t('field_required'),
          trigger: ['change']
        });
      }

      if (
        question.type &&
        ['NUMBER', 'MONETARYAMOUNT'].includes(question.type.toUpperCase())
      ) {
        rules.push({
          validator: this.numberRule,
          trigger: ['change']
        });
      }

      if (question.getMask()) {
        rules.push({
          validator: this.regexpRule,
          trigger: ['change']
        });
      }

      if (
        question.getMeta('inputType') === 'email' &&
        (this.dirty || question.submitted)
      ) {
        rules.push({
          validator: this.emailRule,
          trigger: ['change']
        });
      }

      return rules;
    },
    numberRule(rule, value, callback) {
      if (value && Number.isNaN(Number(value))) {
        callback(new Error(this.$t('number_fail')));
      } else {
        callback();
      }
    },
    regexpForMaskSegments(question) {
      if (question.getMask()) {
        const patterns = {
          '#': { pattern: '\\d' },
          X: { pattern: '[0-9a-zA-Z]' },
          S: { pattern: '[a-zA-Z]' },
          A: { pattern: '[a-zA-Z]', transform: (v) => v.toLocaleUpperCase() },
          a: { pattern: '[a-zA-Z]', transform: (v) => v.toLocaleLowerCase() }
        };
        return question.getMask().map((maskSegment) => {
          return new RegExp(
            maskSegment
              // Important : keep '' (= empty string) and not ""
            .split('')
              .map((char) => {
                if (patterns[char]) {
                  return patterns[char].pattern;
                }
                return `\\${char}`;
              })
              .join('')
          );
        });
      }
      return undefined;
    },
    regexpRule: (question) => (rule, value, callback) => {
      if (
        value &&
        question.mask &&
        !this.regexpForMaskSegments(question).some((regexp) =>
          regexp.test(value)
        )
      ) {
        callback(
          new Error(
            this.$t('regexp_fail', { mask: question.getMaskPlaceholder })
          )
        );
      } else {
        callback();
      }
    },
    emailRule(rule, value, callback) {
      const re = /^[^@ ]+@[^@ ]+$/;
      if (value && !re.test(value)) {
        callback(new Error(this.$t('regexp_email_fail')));
      } else {
        callback();
      }
    }
  }
};
