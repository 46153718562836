/**
 * Copyright 2020 AXA Group Operations S.A.
 *
 * Licensed under the Apache License, Version 2.0 (the "License")
 * you may not use this file except in compliance with the License
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { NamedNodeFactory, NamedNode } from './index';
import linkStyleForNode from './AnnotationStyle';

export default class AnnotatedNamedNode extends NamedNode {
  linkStyle() {
    return linkStyleForNode(this);
  }
}

export class AnnotatedNamedNodeFactory extends NamedNodeFactory {
  nodeFromModel(graph, parent, model) {
    return new AnnotatedNamedNode(graph, parent, model);
  }

  newNodeWithTemplate(graph, node) {
    return new AnnotatedNamedNode(graph, null, {
      type: node.value.type || 'Node',
      source: 'CGI'
    });
  }
}
