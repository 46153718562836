/**
 * Copyright 2020 AXA Group Operations S.A.
 *
 * Licensed under the Apache License, Version 2.0 (the "License")
 * you may not use this file except in compliance with the License
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import types from './tokens.types';

export default {
  // CREATE
  [types.CREATE_TOKENS_REQUEST]() {},
  [types.CREATE_TOKENS_SUCCESS]() {},
  [types.CREATE_TOKENS_ERROR]() {},

  // DELETE
  [types.DELETE_TOKENS_REQUEST]() {},
  [types.DELETE_TOKENS_SUCCESS]() {},
  [types.DELETE_TOKENS_ERROR]() {},

  // FETCH
  [types.FETCH_TOKENS_REQUEST](state) {
    state.tokens = [];
  },
  [types.FETCH_TOKENS_SUCCESS](state, { tokens }) {
    state.tokens = tokens;
  },
  [types.FETCH_TOKENS_ERROR](state) {
    state.tokens = [];
  }
};
