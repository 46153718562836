/**
 * Copyright 2020 AXA Group Operations S.A.
 *
 * Licensed under the Apache License, Version 2.0 (the "License")
 * you may not use this file except in compliance with the License
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import semver from 'semver';
import { v4 } from 'uuid';

export const createFakeRelease = (productId, { name, major, minor, patch }) => {
  const date = new Date().toISOString();
  return {
    id: v4(),
    productId,
    name,
    publicationStatus: 'INITIALIZATION',
    version: { major, minor, patch },
    versionAsString: `${major}.${minor}.${patch}`,
    readonly: true,
    createdAt: date,
    updatedAt: date
  };
};

export const productReleaseLabel = (currentVersion) => {
  if (currentVersion === 'initial') {
    return 'Initial release';
  }

  return currentVersion;
};

const compareRelease =
  (k = 'value') =>
  (a, b) =>
    semver.compare(b[k], a[k]);

export const sortReleases = (field, releases) =>
  releases.sort(compareRelease(field));

export const getReleaseVersion = (v) => `${v.major}.${v.minor}.${v.patch}`;

export const PublicationStatus = Object.freeze({
  PRODUCTION: 'PRODUCTION',
  EXPIRED: 'EXPIRED',
  DRAFT: 'DRAFT',
  INITIALIZATION: 'INITIALIZATION'
});

export const isExpired = (s) => s === PublicationStatus.EXPIRED;
export const isDraft = (s) => s === PublicationStatus.DRAFT;
export const isProduction = (s) => s === PublicationStatus.PRODUCTION;
export const isInitializationInProgress = (s) =>
  s === PublicationStatus.INITIALIZATION;

export const isPublicationStatusEditable = ({
  isAdmin = false,
  publicationStatus = undefined,
  isReadOnly = false
}) => {
  if (publicationStatus === PublicationStatus.INITIALIZATION) return false;
  return (
    isAdmin ||
    (![
      PublicationStatus.PRODUCTION,
      PublicationStatus.EXPIRED,
      PublicationStatus.INITIALIZATION
    ].includes(publicationStatus) &&
      !isReadOnly)
  );
};

export default {
  isPublicationStatusEditable,
  isExpired,
  isDraft,
  isProduction,
  isInitializationInProgress
};
