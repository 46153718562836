<template>
  <div v-if="isLoggedIn" v-selector.view v-loading.fullscreen.lock="loading"
    class="flex-grow-1 flex-shrink-1 d-flex flex-column overflow-hidden">
    <ProductNav v-if="!!product" :key="'pn-' + $route.path" :product="product" :stats="{
      ...stats,
      isLoading: isLoading || stats.progress >= 0,
      error: stats.error || (retry >= 10 ? 'Timeout' : null)
    }" :product-type="product.type" />
    <div v-if="error" class="container py-3">
      <h3>Error</h3>
      <p>{{ error.data }}</p>
    </div>
    <div v-if="product && !error" :class="productViewClassNames()"
      class="d-flex flex-grow-1 flex-shrink-1 overflow-auto">
      <ComplexityChecker type="mainCheck" @complexity-fetched="fetchTests()"></ComplexityChecker>
      <router-view :product="product" :stats="stats" />
      <SearchSidebar :product="product" />
    </div>
  </div>
</template>

<script>
/* eslint-disable guard-for-in */

import { mapState, mapActions, mapGetters } from 'vuex';
import ProductNav from '../components/ProductNav.vue';
import SearchSidebar from '../components/SearchSidebar/SearchSidebar.vue';
import ProductReleasesMixin from '../components/ProductReleases/ProductReleasesMixin';
import ComplexityChecker from '../components/Product/ComplexityChecker.vue';

export default {
  name: 'Product',
  components: {
    ProductNav,
    SearchSidebar,
    ComplexityChecker
  },
  mixins: [ProductReleasesMixin],
  beforeRouteUpdate(to, from, next) {
    if (+this.productId !== +to.params.productId) {
      this.onRouteUpdate(to, from, next);
    }
    next();
  },
  props: {
    productId: { type: [String, Number], default: undefined },
    onRouteUpdate: { type: Function, default: undefined }
  },
  data: () => ({
    retry: 0
  }),
  computed: {
    ...mapState('product', ['sidebarIsVisible', 'product', 'error']),
    ...mapState('uiLoading', ['loading']),
    ...mapState('productTests', ['stats', 'isLoading']),
    ...mapState('productRelease', ['activeReleases']),
    ...mapState('auth', ['tenant']),
    ...mapGetters('auth', ['isLoggedIn'])
  },
  watch: {
    stats: {
      immediate: true,
      async handler(value) {
        if (value.progress >= 0) {
          if (this.timeout) {
            clearTimeout(this.timeout);
          }
          let time = 5000;
          if (this.retry < 3) {
            time = 2000;
          } else if (this.retry >= 10) {
            time = 20000;
          }
          this.timeout = setTimeout(async () => {
            await this.fetchTests();
            this.timeout = null;
            if (value.progress !== this.stats.progress) {
              this.retry = 1;
            } else {
              this.retry += 1;
            }
          }, time);
        } else {
          this.retry = 0;
        }
      }
    }
  },
  created() {
    this.resetTests();
    if (this.activeReleases[this.productId]) {
      this.fetchTests();
    }
  },
  mounted() {
    window.onbeforeunload = this.unsetProductLocale;
  },
  beforeDestroy() {
    this.unsetProductLocale();
  },
  methods: {
    ...mapActions({
      resetTests: 'productTests/reset',
      fetchTests: 'productTests/fetch'
    }),
    unsetProductLocale() {
      window.localStorage.removeItem('productLocale');
    },
    productViewClassNames() {
      return {
        'product-view': true,
        'sidebar-is-visible': this.sidebarIsVisible,
        'is-home': this.$router.currentRoute.name === 'product-home'
      };
    }
  }
};
</script>

<style scoped>
.view-wrapper {
  z-index: 1;
  height: 100%;
}

.flex-1 {
  flex: 1;
  position: relative;
  height: 100%;
}

.product-view {
  position: relative;
  width: 100%;
  overflow: auto;
}

.product-view.is-home {
  background-color: white;
}

.toggle-sidebar {
  cursor: pointer;
  background: white;
  border: 0;
  color: gray;
  text-transform: uppercase;
  font-size: 12px;
  letter-spacing: 1px;
  background: none;
  position: absolute;
  top: 10px;
  left: 0;
  z-index: 2;
}
</style>
