<template>
  <div v-selector.view class="d-flex flex-grow-1">
    <router-view ref="router" :product="product"></router-view>
  </div>
</template>

<script>
export default {
  name: 'ProductConfigurationWrapper',
  props: {
    product: {
      type: Object,
      required: true
    }
  }
};
</script>
