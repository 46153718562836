/**
 * Copyright 2020 AXA Group Operations S.A.
 *
 * Licensed under the Apache License, Version 2.0 (the "License")
 * you may not use this file except in compliance with the License
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { mapActions, mapState } from 'vuex';
import uniqSorter from '../helpers/uniqSorter';

export default {
  computed: {
    ...mapState('definitions', ['definitionItems']),
    ...mapState('productProperty', ['dimensions'])
  },
  methods: {
    ...mapActions('definitions', [
      'fetchItemsForDefinition',
      'fetchDefinitions'
    ]),
    definitionListName(id) {
      if (this.isBusy) return 'Loading definition';
      if (!id) return 'Select a definition list';
      const list = this.findDefinitionListById(id);
      return list ? list.name : 'Definition list not found';
    },
    definitionListNameVariant(id) {
      if (this.isBusy) return 'light';
      if (!id) return 'error';
      return this.findDefinitionListById(id) ? 'default' : 'error';
    },
    findDefinitionListById(id) {
      return this.definitions.find((list) => list._id === id);
    },
    findDefinitionListItemsById(id) {
      return this.definitionItems[id];
    },
    async suggestionsByProperty(
      type,
      name,
      definitionListId,
      productId,
      opts = { includeAllDefinitions: true, includeTermBuckets: true }
    ) {
      try {
        if (type === 'Boolean') return Promise.resolve(['TRUE', 'FALSE']);

        if (type === 'Enum' || type === 'Text') {
          const o = (key, label, metadata = {}) => ({ key, label, metadata });

          const { unmatchedTerms = [], terms = [] } = this.dimensions;
          const definitions =
            !opts.includeAllDefinitions || !definitionListId
              ? (terms[name] || []).map((t) => t.definitions).flat()
              : await this.fetchItemsForDefinition({ definitionListId });
          const definitionTerms = definitions.map((data) => {
            const { primaryKey, displayName, _id: definitionId } = data;
            return o(primaryKey, displayName || primaryKey, { definitionId });
          });
          return uniqSorter(
            definitionTerms
              .concat(
                opts.includeTermBuckets
                  ? (terms[name] || []).map((value) =>
                      o(value.name, value.name, {})
                    )
                  : []
              )
              .concat(
                // filter unmatchedTerms to exclude empty strings (i.e. when creating an empty node)
                (
                  (unmatchedTerms[name] || []).filter(
                    (t) => t && t !== '_OTHER'
                  ) || []
                ).map((value) => o(value, value, { isUnmatched: true }))
              )
          );
        }
      } catch {
        return Promise.resolve([]);
      }

      return Promise.resolve([]);
    }
  }
};
