/**
 * Copyright 2020 AXA Group Operations S.A.
 *
 * Licensed under the Apache License, Version 2.0 (the "License")
 * you may not use this file except in compliance with the License
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { set } from 'lodash';

export default {
  data() {
    return {
      value: undefined
    };
  },
  props: {
    question: { type: Object, default: undefined },
    label: { type: String, default: undefined },
    mode: { type: String, default: 'widget' },
    current: { type: Boolean, default: false },
    answered: { type: Boolean, default: false }
  },
  computed: {
    form() {
      return this.$parent.form;
    }
  },
  mounted() {
    if (this.question.response) {
      this.setValueFromQuestion();
    } else {
      this.value = this.question
        .formProp()
        .split('.')
        .reduce((value, key) => {
          // eslint-disable-next-line no-prototype-builtins
          if (value && value.hasOwnProperty(key)) {
            return value[key];
          }
          return undefined;
        }, this.form.model);
    }
  },
  methods: {
    setValue(val) {
      set(this.form.model, this.question.formProp(), val);
      this.value = val;
    },
    inputAnswer(forceTrigger = false) {
      this.$emit('answer-question', {
        question: this.question,
        answer: this.value,
        cancelTrigger: !forceTrigger && this.mode === 'form',
        triggerGraph: this.mode === 'form'
      });
    },
    submit(forceTrigger = false) {
      if (
        this.value !== undefined &&
        this.value !== null &&
        this.value !== ''
      ) {
        if (!this.question.loading) {
          if (!this.question.submitted) {
            this.$emit('question-submitted');
          }
          this.form.validateField(this.question.formProp(), (errors) => {
            if (!errors) {
              this.inputAnswer(forceTrigger);
            }
          });
        }
      } else {
        this.form.validateField(this.question.formProp());
      }
    },
    submitClear() {
      this.$emit('clear-question', { question: this.question });
      this.$emit('question-answered', {
        question: this.question,
        answer: undefined
      });
    },
    setValueFromQuestion() {
      if (this.question.response) {
        if (this.question.allowMultiplePerClaim) {
          if (Array.isArray(this.question.response)) {
            this.value = this.question.response.map((r) => r.id);
          } else {
            this.value = [this.question.reponse];
          }
        } else {
          this.value = this.question.response.id;
        }
      }
    }
  },
  watch: {
    question: {
      handler() {
        if (this.question.response) {
          this.setValueFromQuestion();
        } else if (this.value) {
          this.value = undefined;
        }
      },
      deep: true
    },
    answered: {
      handler() {
        if (this.answered === true) {
          this.$emit('question-answered', {
            question: this.question,
            answer: this.value
          });
        } else if (this.answered !== null && this.question.response) {
          this.value = this.question.response.map((r) => r.id);
        }
      }
    }
  }
};
