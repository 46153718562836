/**
 * Copyright 2020 AXA Group Operations S.A.
 *
 * Licensed under the Apache License, Version 2.0 (the "License")
 * you may not use this file except in compliance with the License
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import * as api from '../../../api';
import { apiMutations, queryApi } from '../../helper';

const FETCH_SETTINGS = 'FETCH_SETTINGS';

const getInitialState = () => ({
  isLoading: true,
  settings: {},
  errors: []
});

export default {
  namespaced: true,
  state: getInitialState(),
  actions: {
    reset: ({ commit }) => commit('RESET'),
    getSettings: async ({ commit, dispatch }) => {
      await dispatch('reset');
      await queryApi(commit, FETCH_SETTINGS, async () => {
        const { data } = await api.getMaintenanceProperties();
        return data.configuration;
      });
    },

    saveSettings: async (
      { commit, dispatch },
      // eslint-disable-next-line camelcase
      { maintenance, displayMessage, message }
    ) => {
      await queryApi(commit, FETCH_SETTINGS, async () => {
        await api.saveMaintenanceMessage(maintenance, displayMessage, message);
        await dispatch('reset');
        await dispatch('getSettings');
      });
    }
  },
  getters: {
    hasError: (state) => state.errors.length > 0,
    isMaintenance: (state) => {
      return state.settings && state.settings.maintenance;
    }
  },
  mutations: {
    RESET: (state) => Object.assign(state, getInitialState()),
    ...apiMutations(
      FETCH_SETTINGS,
      (state) => {
        state.isLoading = true;
      },
      (state, settings) => {
        state.settings = Object.freeze(settings);
        state.isLoading = false;
      },
      (state, errors) => {
        state.errors = [Object.freeze(errors)];
        state.isLoading = false;
      }
    )
  }
};
