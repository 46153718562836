<template>
  <div class="ProductDocumentsSummary product-summary">
    <div class="title d-flex ui_label_tite">
      <div class="icon icon-hyperlink-circle mr-2" />
      <div class="label">{{ $t('product.nav.documents') }}</div>
    </div>
    <div
      v-if="copilotActivated && !isGuest(tenant)"
      class="block"
      :class="{ 'a4-aspect': getCopilotStatus === 'success' }"
    >
      <b-card
        v-if="['running', 'queued'].includes(getCopilotStatus)"
        no-body
        img-bottom
      >
        <b-card-body>
          <div class="d-flex align-items-center text-indigo">
            <b-spinner small class="mr-2" />
            <span> {{ getCopilotStatus }}... </span>
          </div>
        </b-card-body>
        <b-skeleton-img card-img="bottom" aspect="1:1.414"></b-skeleton-img>
      </b-card>
      <label
        v-if="
          !isReadOnlyMode && ['failed', undefined].includes(getCopilotStatus)
        "
        for="pdf"
        accept="application/pdf"
        class="drag-upload dropzone d-flex align-items-center"
        :class="{ 'dragging-over': isDragging }"
        @drop="
          onDrop($event);
          isDragging = false;
        "
        @dragover.prevent="isDragging = true"
        @dragleave.prevent="isDragging = false"
        @dragenter.prevent
      >
        <input
          id="pdf"
          type="file"
          name="pdf"
          hidden
          @input="uploadPdf({ product, pdf: $event.target.files[0] })"
        />
        <b-icon-cloud-upload font-scale="5" />
        <div class="el-upload__text">
          {{ $t('document.dragdrop.drop') }}
          {{ $t('document.dragdrop.or') }}
          <em>{{ $t('document.dragdrop.click') }}</em>
        </div>
      </label>
      <template v-if="getCopilotStatus === 'success'">
        <iframe :src="pdfUrl" class="iframe-pdf" />
      </template>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex';
import { Message, MessageBox } from 'element-ui';
import moment from 'moment';
import _ from 'lodash';
import * as api from '../api';

export default {
  name: 'ProductDocumentsSummary',
  props: { product: { type: Object, required: true } },
  data() {
    return {
      isDragging: false,
      dragType: 'none',
      allowedDragTypes: ['application/pdf'],
      documents: [],
      pdfUrl: undefined
    };
  },
  computed: {
    ...mapGetters('product', [
      'isReadOnly',
      'getCopilotStatus',
      'copilotActivated'
    ]),
    ...mapGetters('auth', ['isGuest']),
    ...mapState('auth', ['tenant']),
    isReadOnlyMode() {
      return this.isReadOnly || this.isGuest(this.product.team.slug);
    }
  },
  watch: {
    getCopilotStatus: {
      handler() {
        if (this.getCopilotStatus === 'success') {
          this.makePdfUrl();
        }
      },
      immediate: true
    }
  },
  async mounted() {
    if (this.product.metadata && this.product.metadata.documents) {
      this.documents = this.product.metadata.documents.map(this.prettyFileName);

      this.documents.map(async (doc) => {
        Object.assign(doc, { url: await this.getDocumentUrl(doc) });
      });
    }
  },
  methods: {
    ...mapActions('product', ['fetchProduct', 'uploadPdf', 'downloadPdf']),
    getPdfUploadHeaders() {
      return api.getPdfUploadHeaders();
    },
    async deleteFile(file) {
      const confirmMsg = this.$t('document.confirm.delete', {
        name: file.name
      });
      try {
        await MessageBox.confirm(confirmMsg);
        try {
          await api.deletePdfFile(this.product.id, file.name, this.tenant);
        } catch (e) {
          Message.error(e.message);
        }
        this.fetchProduct({
          productId: this.product.id
        });
        await this.handleSuccess();
        Message.success(
          this.$t('product.msg-success-delete-property', { name: file.name })
        );
      } catch (e) {
        // Deletion cancelled
      }
    },
    prettyFileName(fileName) {
      const file = {
        url: fileName,
        rawFileName: '',
        date: 0,
        name: ''
      };
      file.name = _.last(fileName.split('/'));
      const date = new Date();
      file.date = moment(date).format('DD-MM-YY');
      file.rawFileName = fileName;
      return file;
    },
    async getDocumentUrl(file) {
      const request = await api.getPdfFile(
        this.product.id,
        file.name,
        this.tenant
      );
      const url = window.URL.createObjectURL(
        new Blob([request.data], { type: 'application/pdf' })
      );
      return url;
    },
    async handleSuccess() {
      await this.fetchProduct({
        productId: this.product.id,
        tenant: this.tenant
      });
      this.documents = this.product.metadata.documents.map(this.prettyFileName);
      this.documents.map(async (doc) => {
        Object.assign(doc, { url: await this.getDocumentUrl(doc) });
      });
    },
    onDrop(e) {
      e.preventDefault();
      this.uploadPdf({ product: this.product, pdf: e.dataTransfer.files[0] });
    },
    async makePdfUrl() {
      const data = await this.downloadPdf(this.product.id);
      const url = URL.createObjectURL(
        new Blob([data], { type: 'application/pdf' })
      );
      this.pdfUrl = url;
    }
  }
};
</script>

<style scoped lang="scss">
@import '@axatechlab/assets/scss/_variables';

.title {
  position: relative;
  font-size: 150%;
  .icon {
    display: inline-block;
  }
}

.block {
  background-color: #f8f8fb;
  border: 1px solid rgba(43, 48, 52, 0.1);
  border-radius: 10px;
  padding: 1em 1.5em;
  margin-top: 1em;
}

.a4-aspect {
  aspect-ratio: 1 / 1.411;
}

.iframe-pdf {
  width: 100%;
  height: 100%;
}

.dropzone {
  width: 100%;
  padding: 8px;
  text-align: center;
  border-radius: 8px;
  border: dashed 2px #0087f7;
  &.dragging-over {
    opacity: 1;
    border-style: solid;
    * {
      opacity: 1;
      pointer-events: none;
    }
  }
}

.delete-button {
  cursor: pointer;
  color: red;
  background: transparent;
  border: 0;
  transition: opacity 225ms ease-in-out;
  &:hover {
    opacity: 0.6;
  }
}

.drag-upload {
  padding: 16px;
  gap: 8px;
  width: 100%;
}

.document-list-item {
  cursor: pointer;
  &:hover {
    opacity: 0.7;
  }
}
</style>
