/**
 * Copyright 2020 AXA Group Operations S.A.
 *
 * Licensed under the Apache License, Version 2.0 (the "License")
 * you may not use this file except in compliance with the License
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

export const propertyMetadataName = (name) => {
  if (typeof name !== 'string')
    throw new Error('Property Metadata name must be a string');
  const normalizedName = name.replace(/[^a-z0-9_-]/gi, '').trim();
  if (normalizedName === '')
    throw new Error('Property name must not be an empty string');
  return normalizedName;
};
