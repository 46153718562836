<template>
  <ul class="List">
    <li
      v-for="version in versions"
      :key="`${version.label}-${getVersion(version.version)}`"
      :class="listItemClassNames(version.version)"
    >
      <a
        class="Version__Link"
        href="#"
        @click="(event) => handleSwitchCurrentActiveRelease(event, version)"
      >
        <UiTagDoubleIcon
          v-if="!isActive(version.version)"
          :variant="releaseIconByPublicationStatus(version.status)"
        />
        <UiPencilIcon
          v-if="isActive(version.version)"
          :variant="releaseIconByPublicationStatus(version.status)"
        />
        <span class="Version__Name">{{ version.name }}</span>
        <span class="Version__Number">{{
          versionAsString(version.version)
        }}</span>
      </a>
    </li>
  </ul>
</template>

<script>
import { mapGetters } from 'vuex';
import ProductReleasesMixin from '../ProductReleasesMixin';
import ProductSnapshotMixin from '../ProductSnapshotMixin';

export default {
  name: 'ProductReleasesSummaryList',
  mixins: [ProductSnapshotMixin, ProductReleasesMixin],
  props: {
    versions: { type: Array, required: true },
    product: { type: Object, required: true },
    latest: { type: Number, required: true }
  },
  computed: {
    ...mapGetters('productRelease', ['activeRelease'])
  },
  methods: {
    listItemClassNames(version) {
      return {
        List__Item: true,
        'List__Item--Active': this.isActive(version),
        Version: true
      };
    },
    isActive(version) {
      return this.activeRelease(this.latest) === this.versionAsString(version);
    },
    versionAsString(version) {
      return `${version.major}.${version.minor}.${version.patch}`;
    },
    handleSwitchCurrentActiveRelease(event, version) {
      event.preventDefault();
      this.switchCurrentActiveRelease(
        version.id,
        version.version,
        this.product.id
      );
    },
    getVersion(versionObject) {
      return `${versionObject.major}.${versionObject.minor}.${versionObject.patch}`;
    }
  }
};
</script>

<style scoped lang="scss">
@import '@axatechlab/assets/scss/_variables';
.List {
  padding: 0;
  margin: 0;

  &__Item {
    display: flex;
    padding: 0;
    margin: 0;
    list-style: none;

    &--Active {
      font-weight: 600;
    }
  }
}

.Version {
  line-height: 1.8rem;

  &__Link {
    width: 100%;
    display: flex;
    align-items: center;
    color: rgb(33, 37, 41);
  }

  &__Name {
    flex: 1;
    margin: 0 0.4rem;
  }

  &__Number {
  }
}
</style>
