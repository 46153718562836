<template>
  <el-card :id="`t/${property}`" class="card dimension" :body-style="{ padding: 0 }">
    <div class="card-header">
      <h5 class="d-flex flex-row align-items-center flex-grow-1 max-100">
        <span class="circle" :style="{
          backgroundColor: colorForDimension(property)
        }" />
        <span class="card-title flex-fill">
          {{ nameForDimension(property) }}
        </span>
      </h5>
      <div>
        <UiSearchBox v-model="searchTerms" :placeholder="$t('common.search')" />
      </div>
      <el-checkbox v-model="checkAll" :disabled="disableAll" :indeterminate="isIndeterminate"
        @change="handleCheckAllChange">{{ $t('product.terms.select-all-terms') }}</el-checkbox>
    </div>
    <div class="card-body">
      <table v-if="anyTermsExist" class="table table-sm table-hover">
        <tr v-for="term in filteredTerms" :key="term._id">
          <td width="20" class="term-id">
            <el-checkbox-group v-model="selectedTerms" @change="handleCheckedTermsChange">
              <el-tooltip :disabled="canDelete(term._id)" class="item" effect="dark"
                :content="$t(`product.terms.used_monoterm`)" placement="top-start">
                <el-checkbox :disabled="isReadOnlyMode || !canDelete(term._id)" :label="term._id">{{ '' }}</el-checkbox>
              </el-tooltip>
            </el-checkbox-group>
          </td>
          <td>{{ term.definitions.length }}</td>
          <td class="term-flex">
            <a href="#" @click.prevent="editTerm(term, property)">
              <strong>{{ getTermLabel(term) }}</strong>
            </a>
            <el-tooltip v-if="getValidationErrors(term).length">
              <i class="el-icon-warning-outline"></i>
              <template slot="content">
                <div v-for="(error, index) of getValidationErrors(term, 5)" :key="index">
                  {{
                    $t(`product.validation.${error.code}`, {
                      definition: error.definition
                    })
                  }}
                </div>
                <div v-if="getValidationErrors(term).length > 5">
                  <br />
                  <i>And {{ getValidationErrors(term).length - 5 }} more...</i>
                </div>
              </template>
            </el-tooltip>
          </td>
        </tr>
        <tr v-if="unmatchedTermsExist" class="tr-accent">
          <td></td>
          <td></td>
          <th>
            {{ $t('product.terms.unmatched_terms') }}
            <el-tooltip :content="$t('product.terms.unmatched_hint')">
              <el-button type="text" icon="el-icon-info"></el-button>
            </el-tooltip>
          </th>
        </tr>
        <tr v-for="term in filteredUnmatchedTerms" :key="term._id">
          <td></td>
          <td></td>
          <td>
            <a href="#" class="unmatched-term" @click.prevent="editedTerm = { dimension: property, name: term }">{{ term
              }}</a>
          </td>
        </tr>
      </table>

      <p v-else class="text-muted p-5 text-center">
        {{ $t('product.terms.no_terms') }}
      </p>
    </div>
    <div class="card-footer text-right">
      <el-button v-if="canOrderDefinitions" :disabled="isReadOnlyMode" class="font-sans-serif" size="mini"
        @click="openDefinitionsPrioritiesModal()">{{ $t('product.terms.edit-definitions-order') }}
      </el-button>
      <el-button class="font-sans-serif" size="mini" :disabled="isReadOnlyMode"
        @click="openImportTermsModal(property)">{{
          $t('product.terms.import') }}
      </el-button>
      <el-button class="font-sans-serif" size="mini" :disabled="selectedTerms.length === 0" @click="deleteTerms">{{
        $t('product.delete') }}
      </el-button>
      <el-button class="font-sans-serif" style="margin-top: 10px" size="mini" icon="el-icon-plus" type="primary"
        :disabled="isReadOnlyMode" @click="editedTerm = { dimension: property, definitionListId }">{{
          $t('product.terms.create') }}
      </el-button>
    </div>

    <edit-definition-priority-modal v-if="showDefinitionsForOrder && definitionsForOrder" :property="property"
      :color="colorForDimension(property)" :term-definitions.sync="definitionsForOrder" :term-id="currentTermId"
      :definition-list="definitionList" @update="$emit('fetchData')" @close="stopConfigureOrder" />
    <import-term-modal v-if="importTermForDimension && !isReadOnlyMode" :property="importTermForDimension"
      :definition-list="definitionList" :definitions="definitions" :product="product" @update="postImportTermUpdate"
      @close="() => {
          importTermForDimension = null;
        }
        " />
    <edit-term-modal v-if="editedTerm.dimension" :term-id="editedTerm.id" :current-term="currentTerm"
      :duplicate-definitions="hasDuplicateDefinition(editedTerm.id)"
      :selected-definition-list-id="editedTerm.definitionListId" :property="editedTerm.dimension"
      :name="editedTerm.name" :product="product" :readonly="isReadOnlyMode" :edit-mode="!hasDefinitionLinked"
      @update="$emit('fetchData')" @close="stopEditingTerm" />
  </el-card>
</template>

<script>
import { mapGetters, mapState, mapActions } from 'vuex';
import { MessageBox, Message } from 'element-ui';
import { uniqBy } from 'lodash';
import EditTermModal from './EditTermModal.vue';
import ImportTermModal from './ImportTermModal.vue';
import EditDefinitionPriorityModal from './EditDefinitionPriorityModal.vue';
import { colors } from '../config';
import { displayName, termLabel } from '../helpers';
import * as api from '../api';

export default {
  name: 'PropertyCard',
  components: { EditTermModal, ImportTermModal, EditDefinitionPriorityModal },
  props: {
    property: { type: String, default: undefined },
    terms: { type: Array, default: undefined },
    unmatchedTerms: { type: Array, default: undefined },
    definitionListId: { type: Number, default: undefined },
    definitionList: { type: Object, default: undefined },
    sourceLanguage: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      importTermForDimension: null,
      colors,
      editedTerm: {},
      searchTerms: '',
      selectedTerms: [],
      checkAll: false,
      isIndeterminate: false,
      definitionsForOrder: null,
      showDefinitionsForOrder: false
    };
  },
  computed: {
    ...mapState('definitions', ['definitions']),
    ...mapGetters('product', ['isReadOnly']),
    ...mapState('sharedProperty', ['productValidations']),
    ...mapGetters('auth', ['isGuest']),
    ...mapState('auth', ['tenant']),
    ...mapState('product', ['product']),
    ...mapState('productProperty', ['dimensions']),
    isReadOnlyMode() {
      return this.isReadOnly || this.isGuest(this.product.team.slug);
    },

    termsExist() {
      return this.terms ? this.terms.length : false;
    },
    unmatchedTermsExist() {
      return this.unmatchedTerms ? this.unmatchedTerms.length : false;
    },
    anyTermsExist() {
      return this.termsExist || this.unmatchedTermsExist;
    },
    filteredUnmatchedTerms() {
      const terms = (this.unmatchedTerms || []).filter((t) => t !== '');
      if (terms) {
        if (terms.length > 0 && this.searchTerms) {
          return this.filterByTerms(terms);
        }
        return terms;
      }
      return [];
    },
    filteredTerms() {
      if (!this.terms) return;
      const terms = this.getSortedTerms(this.property);
      if (terms.length > 0 && this.searchTerms) {
        // eslint-disable-next-line consistent-return
        return this.filterByTerms(terms);
      }
      // eslint-disable-next-line consistent-return
      return terms;
    },
    hasDefinitionLinked() {
      return this.definitionListId > 0;
    },
    currentTermId() {
      return this.selectedTerms.length === 1 ? this.selectedTerms[0] : null;
    },
    currentTerm() {
      return (
        (this.filteredTerms || []).find((t) => t._id === this.editedTerm.id) ||
        {}
      );
    },
    termForPriority() {
      return this.selectedTerms.length === 1
        ? this.terms.find((t) => t._id === this.selectedTerms[0])
        : null;
    },
    canOrderDefinitions() {
      const terms = this.terms || [];
      return (
        terms.length > 0 && terms.some((t) => (t.definitions || []).length > 0)
      );
    },
    disableAll() {
      return !this.termsExist || !this.filteredTerms.some((t) => t.canDelete);
    }
  },
  watch: {
    selectedTerms(value) {
      this.checkAll = value.length > 0;
    },
    terms() {
      this.setDefinitionsForOrder();
    }
  },
  methods: {
    postImportTermUpdate(options) {
      this.$emit('fetchData', options);
    },
    ...mapActions('product', ['refreshProduct']),
    openImportTermsModal(dimension) {
      if (this.hasDefinitionLinked) {
        this.importTermForDimension = dimension;
      } else {
        MessageBox.alert(this.$t('product.terms.missing_def_list'));
      }
    },
    stopEditingTerm() {
      this.editedTerm = {};
    },
    stopConfigureOrder() {
      this.definitionsForOrder = null;
      this.showDefinitionsForOrder = false;
    },
    getValidationErrors(term, limit) {
      if (!this.productValidations[this.product.id]) return [];
      const errors = this.productValidations[this.product.id].errors.filter(
        (e) =>
          e.property === this.property &&
          term.definitions &&
          !!term.definitions.find((def) => def.primaryKey === e.definition)
      );
      return limit ? errors.slice(0, limit) : errors;
    },
    editTerm(term, dimension) {
      this.editedTerm = {
        // eslint-disable-next-line no-underscore-dangle
        id: term._id,
        dimension,
        definitionListId: this.definitionListId
      };
    },
    getSortedTerms() {
      return [...this.terms].sort((l, r) => l.name.localeCompare(r.name));
    },
    filterByTerms(terms) {
      return terms.filter((term) => {
        return this.searchTerms
          .toLowerCase()
          .split(' ')
          .every((searchTerm) => {
            let comp = term;
            if (typeof comp !== 'string') {
              comp = comp.name;
            }
            return comp.toLowerCase().includes(searchTerm);
          });
      });
    },
    openDefinitionsPrioritiesModal() {
      this.setDefinitionsForOrder();
      this.showDefinitionsForOrder = true;
    },
    setDefinitionsForOrder() {
      this.definitionsForOrder = uniqBy(
        (this.terms || []).flatMap((t) =>
          t.definitions.map((df) => {
            return { ...df, ...{ term: { id: t._id, name: t.name } } };
          })
        ),
        '_id'
      );
    },
    async deleteTerms() {
      try {
        await MessageBox.confirm(
          this.$t(`product.terms.delete_term_confirmation_message`),
          this.$t(`product.terms.delete_term_confirmation_title`)
        );
        const deleteProductPromise = [];
        // eslint-disable-next-line no-restricted-syntax
        for (const term of this.selectedTerms) {
          deleteProductPromise.push(
            api.unlinkProductTerm(this.product.id, term, this.tenant)
          );
        }
        await Promise.all(deleteProductPromise);
        Message.info(this.$t(`product.terms.term_successfully_deleted`));
        this.selectedTerms = [];
        this.refreshProduct({
          productId: this.product.id
        });
        this.$emit('fetchData');
      } catch (err) {
        Message.error(err.errorDescription);
        // eslint-disable-next-line no-console
        console.log(err);
      }
    },
    colorForDimension(property) {
      const dimSpec = this.product.specification.dimensions[property];
      return (
        (dimSpec && dimSpec.color && colors[dimSpec.color]) || 'transparent'
      );
    },
    nameForDimension(property) {
      const dimSpec =
        this.product.specification.dimensions[property] ||
        this.product.specification.out[property];
      return displayName(dimSpec);
    },
    handleCheckAllChange(isChecked) {
      this.selectedTerms = isChecked
        ? this.filteredTerms
          .filter((t) => this.canDelete(t._id))
          .map((t) => t._id)
        : [];
      this.isIndeterminate = false;
    },
    handleCheckedTermsChange(value) {
      const checkedCount = value.length;
      this.checkAll = checkedCount === this.filteredTerms.length;
      this.isIndeterminate =
        checkedCount > 0 && checkedCount < this.filteredTerms.length;
    },
    getTermLabel(term) {
      return termLabel(term);
    },
    canDelete(termId) {
      return this.filteredTerms.find((t) => t._id === termId).canDelete;
    },
    hasDuplicateDefinition(termId) {
      if (!termId) {
        return [];
      }
      return this.filteredTerms
        .find((t) => t._id === termId)
        .definitions.filter((df) => !df.singleAmongTerm)
        .map((df) => df.primaryKey);
    }
  }
};
</script>
<style scoped lang="scss">
@import '@axatechlab/assets/scss/_variables';

.dimension {
  // Following hack necessary to fix overflow problems with checkboxes
  box-shadow: 0 0 1px 0 rgba(0, 0, 0, 0) !important;
  border: solid 1px rgba(0, 0, 0, 0.125);
  margin-bottom: 30px;
}

.card-body {
  padding: 0;
  overflow-y: auto;
  height: 250px;

  .term-id {
    padding-left: 20px;
  }

  .term-flex {
    display: flex;

    a {
      flex: 1;
      max-width: calc(100% - 20px);
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      display: inline-block;
    }
  }

  .unmatched-term {
    color: gray;
    word-break: break-all;

    &:hover {
      text-decoration: underline;
    }
  }
}

.card-header {
  .card-title {
    margin: 0 8px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    &:hover {
      overflow: visible;
      white-space: initial;
    }
  }

  .circle {
    width: 20px;
    min-width: 20px;
    height: 20px;
    display: inline-block;
    border-radius: 10px;
  }
}

.max-100 {
  max-width: 100%;
}
</style>
