/**
 * Copyright 2020 AXA Group Operations S.A.
 *
 * Licensed under the Apache License, Version 2.0 (the "License")
 * you may not use this file except in compliance with the License
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

export default {
  computed: {
    dirty() {
      if (this.value && this.question) {
        if (this.question.allowMultiplePerClaim) {
          return (
            this.value.join() !==
            (this.question.response &&
              this.question.response.map((r) => r.id).join())
          );
        }
        return (
          this.value !== (this.question.response && this.question.response.id)
        );
      }
      return false;
    },
    captureTabDisabled() {
      return this.$parent.$parent.captureTabDisabled;
    }
  },
  methods: {
    captureTab(e) {
      if (!this.captureTabDisabled) {
        if (e.preventDefault) {
          e.preventDefault();
        }
        e.stopPropagation();
        if (this.dirty) {
          this.submit();
          return false;
        }
        return true;
      }
      return false;
    }
  }
};
