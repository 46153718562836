/**
 * Copyright 2020 AXA Group Operations S.A.
 *
 * Licensed under the Apache License, Version 2.0 (the "License")
 * you may not use this file except in compliance with the License
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

export default {
  dimensions(state) {
    return state.properties
      ? state.properties.filter((p) => p.__type === 'DIMENSION')
      : [];
  },
  getDimensionByName(state, getters) {
    return (name) =>
      getters.dimensions.find((dimension) => dimension.name === name);
  },
  inputs(state) {
    return state.properties
      ? state.properties.filter((p) => p.__type === 'INPUT')
      : [];
  },
  computes(state) {
    return state.properties
      ? state.properties.filter((p) => p.__type === 'COMPUTED')
      : [];
  },
  outputs(state) {
    return state.properties
      ? state.properties.filter((p) => p.__type === 'OUTPUT')
      : [];
  },
  getOutputByName(state, getters) {
    return (name) => getters.outputs.find((output) => output.name === name);
  },
  units(state) {
    return state.properties
      ? state.properties.filter((p) => p.__type === 'UNIT')
      : [];
  }
};
