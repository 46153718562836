/**
 * Copyright 2020 AXA Group Operations S.A.
 *
 * Licensed under the Apache License, Version 2.0 (the "License")
 * you may not use this file except in compliance with the License
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { productTypes } from '../../const/product';

const apps = [
  {
    path: 'coverage-matrix',
    name: 'Coverage Matrix',
    excludedTypes: [productTypes.QUESTIONNAIRE],
    description:
      'Display the coverage in a tabular form for two selected dimensions (in a similar way to the AXA Sure)',
    component: () => import('./CoverageMatrix.vue')
  },
  {
    path: 'api-console',
    name: 'Console',
    description:
      'Console that lets you run queries against the different technical APIs',
    component: () => import('./ApiConsole.vue')
  },
  {
    path: 'cover',
    name: 'Coverage',
    description: 'Claims widget configuration and demo.',
    component: () => import('./CoverageCheck.vue')
  },
  {
    path: `${window.__env__.VUE_APP_BPC_URL}/%tenant%/%productId%?version=%version%`,
    name: 'Business Process Manager',
    description: 'Customize quesiton flow and translations'
  }
];

const widgets = [
  // {
  //   path: "claims",
  //   name: "Claims Widget",
  //   description: "Claims widget configuration and demo.",
  //   component: () => import("./WidgetConfigurator.vue"),
  //   type: "widget"
  // },
  {
    path: 'api-first',
    name: 'API-First Widget',
    isBeta: false,
    isVisible: true,
    description: 'API-first widget configuration and demo.',
    component: () => import('./ApiWidgetConfigurator.vue'),
    type: 'widget'
  },
  {
    path: 'icc-widget',
    name: 'ICC Widget',
    isBeta: true,
    isVisible: true,
    description: 'ICC widget configuration and demo.',
    component: () => import('./ICCWidget.vue'),
    type: 'widget'
  },
  {
    path: 'icc',
    name: 'Instant Coverage',
    isBeta: true,
    isVisible: true,
    description: 'Instant Coverage configuration and demo.',
    component: () => import('./InstantCoverage.vue'),
    type: 'widget'
  }
];

export function getAppRoutes() {
  return [
    ...apps.map(({ path, component }) => ({
      path: `${path}`,
      component,
      name: `product-app-${path}`,
      props: true
    })),
    ...widgets.map(({ path, component }) => ({
      path: `${path}`,
      component,
      name: `product-widget-${path}`,
      props: true
    }))
  ];
}

export default [...apps, ...widgets];
