const displayName = (variable) => {
  const defaultName = variable.technicalName || variable.name;
  if (variable.metadata) {
    const metadataObj = variable.metadata;
    return metadataObj &&
      metadataObj.displayName &&
      typeof metadataObj.displayName === 'string'
      ? metadataObj.displayName
      : defaultName;
  }
  return defaultName;
};

module.exports = { displayName };
