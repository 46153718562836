<template>
  <b-form-select v-model="$i18n.locale" class="select-language py-0">
    <b-form-select-option v-for="lang in langs" :key="lang" :value="lang">
      {{ lang.toUpperCase() }}
    </b-form-select-option>
  </b-form-select>
</template>

<script>
export default {
  name: 'LocaleChanger',
  data() {
    return {
      langs: [
        'da',
        'de',
        'el',
        'en',
        'es',
        'fi',
        'fr',
        'it',
        'nl',
        'no',
        'pl',
        'pt',
        'sv'
      ]
    };
  },
  watch: {
    '$i18n.locale': {
      handler(locale) {
        const reload = window.localStorage.locale !== locale;
        if (reload) {
          window.localStorage.locale = locale;
          window.location.reload(true);
        }
      }
    }
  },
  mounted() {
    if (localStorage.getItem('locale') === null) {
      window.localStorage.locale = 'en';
    }
  }
};
</script>

<style lang="scss" scoped>
.select-language {
  color: #343c3d;
  font-size: 14px;
  font-weight: 600;
  border: 0;
  background: #ffffff00 url('data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 width=%274%27 height=%275%27 viewBox=%270 0 4 5%27%3e%3cpath fill=%27%23343a40%27 d=%27M2 0L0 2h4zm0 5L0 3h4z%27/%3e%3c/svg%3e') right 20px center/8px 10px no-repeat;
  text-transform: uppercase;
  max-width: 120px;
  text-overflow: ellipsis;
  max-height: 32px;
}
</style>
