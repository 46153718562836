/**
 * Copyright 2020 AXA Group Operations S.A.
 *
 * Licensed under the Apache License, Version 2.0 (the "License")
 * you may not use this file except in compliance with the License
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

/* eslint-disable no-return-assign */
import _ from 'lodash';
import fp from 'lodash/fp';
import { extractAllDimensionsFromProduct } from './productHelpers.js';
import { objectToArray } from '../../util';

const makeProperty = (sharedProperties = []) => {
  return (property) => {
    let valid = false;
    if (sharedProperties) {
      // eslint-disable-next-line prefer-const
      let sharedProperty = sharedProperties.find(
        (_property) => _property.name === property.key
      );
      valid = sharedProperty ? sharedProperty.isValid : false;
    }
    return {
      ...property,
      isValid: valid,
      exposeInAPI: property.exposeInAPI || false,
      allowMultiplePerClaim: property.allowMultiplePerClaim || false,
      allowMultiplePerPath: property.allowMultiplePerPath || false
    };
  };
};

const potentialInput = (state) => {
  function flattenGraph(graph) {
    if (graph.terms) {
      return _.flatMap(graph.terms, (e) => flattenGraph(e)).concat(graph);
    }
    return graph;
  }
  function matchInputs(value) {
    if (typeof value !== 'string') return value;
    const inputRegex = /\$([a-zA-Z0-9_]+)[\s+*\\/%]*/gm;
    const potentials = [];
    let foundInputs = inputRegex.exec(value);
    while (foundInputs !== null) {
      const input = foundInputs[1];
      potentials.push(input);
      foundInputs = inputRegex.exec(value);
    }
    return potentials.length ? potentials : [value.replace('$', '')];
  }
  function collectPotentialInput(node) {
    if (node.type === 'if') {
      return _.union(
        matchInputs(node.expressionStr[0], matchInputs(node.expressionStr[1]))
      );
    }
    if (state.product.specification.out[node.key])
      return matchInputs(node.expressionStr);
    if (node.type === 'ask') return matchInputs(node.value);
    return [];
  }
  return fp.pipe(
    fp.get('product.rules'),
    fp.map(fp.get('graph')),
    fp.flatMap(flattenGraph),
    fp.filter((node) => ['if', 'insuranceOutput', 'ask'].includes(node.type)),
    fp.flatMap(collectPotentialInput)
  )(state);
};

export default {
  isReadOnly(state, getters, rootState) {
    return (
      (state.product && state.product.readonly) ||
      (rootState.maintenance.settings &&
        rootState.maintenance.settings.maintenance)
    );
  },
  isTestCoverageMode(state) {
    return state.testCoverageMode;
  },
  usedInput(state) {
    if (!state.product) {
      return [];
    }
    const potentials = potentialInput(state);
    const inputs = _.map(state.product.specification.in, (obj, key) => ({
      technicalName: key,
      ...obj
    }));
    return _.filter(inputs, (e) => {
      return (
        // TODO ch961. We should be able to have only one of the or condition.
        potentials.includes(e.technicalName)
      );
    });
  },
  usedDimensions(state) {
    if (!state.product) {
      return [];
    }
    return extractAllDimensionsFromProduct(state.product);
  },
  fullDimensions(state) {
    if (!state.product) return [];
    return Object.entries(
      extractAllDimensionsFromProduct(state.product)
    ).reduce((result, [key, value]) => {
      result[key] = {
        ...state.product.specification.dimensions[key],
        answers: Object.values(value)
      };
      return result;
    }, {});
  },
  currencyUnits(state) {
    if (!state.product || !state.product.specification.units) {
      return [];
    }
    /* eslint-disable no-unused-vars */
    return Object.entries(state.product.specification.units)
      .filter(([key, unit]) => unit.type.toLowerCase().includes('monetary'))
      .map(([key, unit]) => ({
        id: key,
        ...unit
      }));
    /* eslint-enable no-unused-vars */
  },
  dimensions(state) {
    if (!state.product) {
      return [];
    }
    return objectToArray(state.product.specification.dimensions).map(
      makeProperty(state.sharedProperties)
    );
  },
  complexity(state) {
    return state.complexity;
  },
  inputs(state) {
    if (!state.product) {
      return [];
    }
    return objectToArray(state.product.specification.in).map(
      makeProperty(state.sharedProperties)
    );
  },
  outputs(state) {
    if (!state.product) {
      return [];
    }
    return objectToArray(state.product.specification.out).map(
      makeProperty(state.sharedProperties)
    );
  },
  units(state) {
    if (!state.product) {
      return [];
    }
    return objectToArray(state.product.specification.units).map(
      makeProperty(state.sharedProperties)
    );
  },
  propertyNames(state) {
    if (!state.product) {
      return [];
    }
    return Object.keys({
      ...state.product.specification.dimensions,
      ...state.product.specification.in,
      ...state.product.specification.out,
      ...state.product.specification.units
    });
  },
  isValidDefinition: (state) => (dimension, definitions) => {
    definitions = Array.isArray(definitions) ? definitions : [definitions];
    // eslint-disable-next-line prefer-const
    let errors = [];
    definitions.forEach((definition) => {
      if (definition.status !== 'VALIDATED') {
        errors.push(`${definition.primaryKey} is not validated`);
      }
      if (
        !state.sharedProperties.find(
          (p) =>
            p.name === dimension &&
            p.definition_list_id === definition.definitionList.id
        )
      ) {
        errors.push(
          `${definition.primaryKey} comes from a definition list not compliant with the property`
        );
      }
    });
    return errors;
  },
  // eslint-disable-next-line no-unused-vars
  isValidTerm: (state) => (dimension, terms) => {
    terms = Array.isArray(terms) ? terms : [terms];
    let errors = [];
    terms.forEach(
      (term) =>
        (errors = errors.concat(
          this.isValidDefinition(dimension, term.definitions)
        ))
    );
    return errors;
  },
  isValidProduct: (state) => (products) => {
    // eslint-disable-next-line prefer-const
    let errors = {};
    products = Array.isArray(products) ? products : [products];
    products.forEach((product) => {
      errors[product.id] = [];
      Object.keys({
        ...product.specification.dimensions,
        ...product.specification.in,
        ...product.specification.out,
        ...product.specification.units
      }).forEach((property) => {
        const matchedProperty = state.sharedProperties.find(
          (sharedProperty) => sharedProperty.name === property
        );
        if (matchedProperty) {
          errors[product.id].push(`Invalid ${property} dimension`);
        }
      });
      Object.keys(product.terms).forEach((dimension) => {
        errors[product.id] = errors[product.id].concat(
          this.isValidTerm(dimension, product.terms[dimension])
        );
      });
    });
    return errors;
  },
  getCopilotStatus(state) {
    return state.copilotStatus;
  },
  copilotActivated(state, getters, rootState) {
    return (
      window.__env__.VUE_APP_COPILOT_TENANTS &&
      window.__env__.VUE_APP_COPILOT_TENANTS.split(',').includes(
        rootState.auth.tenant
      )
    );
  },
  extractedDimensions(state) {
    if (!state.extractedDimensions) {
      return [];
    }
    return Object.keys(state.extractedDimensions).map((key) => {
      const extractingDim = state.extractingDimensions
        ? state.extractingDimensions[key]
        : undefined;

      return {
        name: key,
        label: key.replace(/_/g, ' '),
        status: extractingDim ? extractingDim.status : 'success'
      };
    });
  },
  extractedPendingDimensions(state) {
    return Object.keys(state.extractingDimensions || {})
      .filter((key) => state.extractingDimensions[key].status != 'success')
      .map((key) => {
        return {
          name: key,
          label: key.replace(/_/g, ' '),
          status: state.extractingDimensions[key].status
        };
      });
  }
};
