<template>
  <aside :class="classNames">
    <div class="Grab"></div>
    <div class="Content">
      <div class="Block Search">
        <el-input
          ref="searchInput"
          v-model="query"
          placeholder="Search in the current product"
          size="medium"
          prefix-icon="el-icon-search"
          clearable
          @input="search"
        />
      </div>
      <div v-if="hasFilters" class="Block Filter">
        <div class="BlockHeader">Filter by type</div>
        <div class="BlockContent BlockContent--Inline">
          <a
            v-for="[name, configuration] in getFilters"
            :key="name"
            :class="filterClassNames(configuration)"
            href="#"
            @click="toggleFilter(name)"
          >
            {{ filterLabel(name) }} ({{ configuration.total }})
          </a>
          <a
            v-if="hasActiveFilter"
            href="#"
            class="Badge Badge--Action"
            @click="resetFilter()"
            >Reset filter</a
          >
        </div>
      </div>
      <div
        v-if="total > 0"
        class="Block Block--Scrollable Block--Light Results"
      >
        <div class="BlockHeader">
          Results for <span class="Query">{{ query }}</span>
          <span v-if="!searching && total > 0" class="ResultTotal"
            >({{ total }})</span
          >
        </div>
        <div class="BlockContent">
          <UiLoadingArea :is-loading="showSearchingIndicator">
            <SearchResult :results="results" :handle-focus="handleFocus" />
          </UiLoadingArea>
        </div>
      </div>
    </div>
  </aside>
</template>

<script>
import { debounce } from 'lodash';
import { mapActions, mapState } from 'vuex';
import Mousetrap from 'mousetrap';
import searchClient from '../../api/searchClient';
import SearchResult from './SearchResult';
import RoutingMixin from '../RoutingMixin';

export default {
  name: 'SearchSidebar',
  components: {
    SearchResult
  },
  mixins: [RoutingMixin],
  props: {
    product: {
      required: true,
      type: Object
    }
  },
  data() {
    return {
      query: '',
      searching: false,
      results: null,
      filters: {},
      total: null,
      error: null
    };
  },
  computed: {
    ...mapState('auth', ['tenant']),
    ...mapState('uiProductSearch', ['searchSidebarVisible', 'searchTrigger']),
    classNames() {
      return {
        ProductSearchSidebar: true,
        ProductSearchSidebar__Visible: this.searchSidebarVisible
      };
    },
    hasFilters() {
      return (
        Object.values(this.filters).filter((a) => a[0] !== '_empty').length > 0
      );
    },
    hasActiveFilter() {
      return !!Object.values(this.filters).find((filter) => filter.enabled);
    },
    getFilters() {
      return Object.entries(this.filters).filter((a) => a[0] !== '_empty');
    },
    showSearchingIndicator() {
      return this.searching;
    }
  },
  watch: {
    searchTrigger() {
      this.directSearch(false);
    },
    searchSidebarVisible(value, previous) {
      if (value && !previous) {
        this.$nextTick(() => this.$refs.searchInput.focus());
      }
    }
  },
  mounted() {
    this.index(this.product.id);
    Mousetrap.bind(['p f'], () => {
      this.toggleSidebar();
    });
  },
  methods: {
    ...mapActions('ruleEditor', ['nodeFocus']),
    ...mapActions('search', ['index']),
    ...mapActions('uiProductSearch', ['toggleSidebar']),
    changeRouteToRuleEditIfNeeded(params) {
      return this.changeRouteIfNeeded({
        name: 'product-rules-edit',
        hash: `#${params.hash}`,
        params: {
          tenantSlug: this.tenant,
          productId: params.productId,
          ruleId: params.ruleId
        }
      });
    },
    handleFocus(item) {
      switch (item.type) {
        case 'node':
          this.handleNodeFocus(item);
          break;
        case 'rule':
          this.handleRuleFocus(item);
          break;
        case 'input':
          this.handleProductPropertyFocus('inputs', item);
          break;
        case 'output':
          this.handleProductPropertyFocus('outputs', item);
          break;
        case 'dimension':
          this.handleProductPropertyFocus('dimensions', item);
          break;
        case 'unit':
          this.handleProductPropertyFocus('units', item);
          break;
        case 'constraint':
          this.handleProductPropertyFocus('endorsements', item);
          break;
        default:
          // eslint-disable-next-line no-console
          console.error(`Handle focus on unsupported node type ${item.type}`);
      }
    },
    handleRuleFocus(item) {
      const { product_id: productId, rule_id: ruleId } = item;
      this.changeRouteToRuleEditIfNeeded({ productId, ruleId, hash: item.id });
    },
    handleNodeFocus(item) {
      const { product_id: productId, rule_id: ruleId } = item;
      this.changeRouteToRuleEditIfNeeded({ productId, ruleId, hash: item.id });
      this.nodeFocus({ node: item.id, rule: ruleId });
    },
    handleProductPropertyFocus(segment, item) {
      const propertyName = item.label
        .replaceAll('{{ ', '')
        .replaceAll(' }}', '')
        .toLowerCase();
      this.changeRouteWithCatch({
        name: 'product-configuration-direct',
        params: {
          tenantSlug: this.tenant,
          propertyType: segment,
          propertyName
        }
      });
    },
    // eslint-disable-next-line func-names
    directSearch(silent = false) {
      this.searching = !silent;
      if (this.query.trim() === '') {
        this.reset();
      } else {
        const options = {
          filters: Object.entries(this.filters)
            .filter((a) => a[1].enabled)
            .map((a) => `type:${a[0]}`)
        };
        searchClient(
          this.tenant,
          this.product.id,
          this.query,
          Buffer.from(JSON.stringify(options)).toString('base64')
        ).then((result) => {
          if (!result.success) return this.displayError();
          this.reset();
          const { data, types, total, enabledTypes } = result;
          this.results = data;
          this.filters = Object.entries(types).reduce(
            (a, v) => ({ ...a, [v[0]]: { total: v[1], enabled: false } }),
            {}
          );
          enabledTypes.forEach((filter) => {
            const parts = filter.split(':');
            if (this.filters[parts[1]]) {
              this.filters[parts[1]].enabled = true;
            }
          });
          this.total = total;
          this.searching = false;
          return result;
        });
      }
    },
    // eslint-disable-next-line func-names
    search: debounce(function () {
      this.directSearch();
    }, 300),
    reset() {
      this.results = null;
      this.filters = {};
      this.total = null;
      this.error = null;
    },
    resetFilter() {
      Object.entries(this.filters).forEach(([name, configuration]) => {
        this.filters[name] = {
          ...configuration,
          enabled: false
        };
      });
      this.search();
    },
    toggleFilter(selectedName) {
      if (!this.filters[selectedName]) return;
      Object.keys(this.filters).forEach((name) => {
        this.filters[name].enabled = name === selectedName;
      });
      this.search();
    },
    filterClassNames(configuration) {
      return {
        Badge: true,
        'Badge--Active': configuration.enabled
      };
    },
    filterLabel(value) {
      return (
        {
          'node.rule': 'Node / Rule',
          'node.enum': 'Node / Dimension',
          'node.exclusion': 'Node / Exclusion',
          'node.ask': 'Node / Ask',
          'node.textoutput': 'Node / Output',
          constraint: 'Endorsement',
          rule: 'Rule',
          input: 'Input',
          output: 'Output',
          unit: 'Unit',
          constant: 'Constant',
          dimension: 'Dimension'
        }[value] || value
      );
    },
    displayError() {
      this.error = 'We have an issue with your search request...';
      this.reset();
    }
  }
};
</script>

<style scoped lang="scss">
@import '@axatechlab/assets/scss/_variables';

.ResultTotal {
  margin-left: 0.25rem;
}

.ProductSearchSidebar {
  display: none;
  position: absolute;
  top: 0;
  right: -35vw;
  width: 35vw;
  height: 100%;
  background-color: #ededf4;
  border-left: 1px solid rgba(0, 0, 143, 0.1);
  transition: all ease-in-out 450ms;
  z-index: 1;

  &__Visible {
    right: 0;
    display: flex;
  }
}

.Query {
  font-style: italic;

  &::before,
  &::after {
    color: #999999;
    font-style: italic;
  }

  &::before {
    content: '"';
  }

  &::after {
    content: '"';
  }
}

.Content {
  display: flex;
  flex: 1;
  flex-direction: column;
  width: 100%;
}

.Block {
  position: relative;
  padding: 1rem;

  &--Scrollable {
    overflow: auto;
  }

  &--Light {
    background-color: #fff;

    .BlockHeader {
      background-color: #fff;
    }
  }
}

.BlockHeader {
  position: sticky;
  top: -1rem;
  font-size: 1.2rem;
  font-weight: 400;
  line-height: 1.8rem;
  white-space: nowrap;
  overflow: hidden;
  border-bottom: 1px solid rgba(0, 0, 143, 0.1);
  z-index: 1;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.BlockContent {
  margin: 1rem 0;

  &--Inline {
    display: flex;
    flex-wrap: wrap;
  }

  &--Scrollable {
    overflow: scroll;
  }
}

.BlockContent .Badge {
  border: 1px solid #00008f;
  border-radius: 1em;
  padding: 0.1em 0.7em;
  font-size: 1rem;
  line-height: 1.2em;
  margin-bottom: 0.3em;
  margin-right: 0.4em;

  &:last-child {
    margin-right: 0;
  }

  &--Active {
    color: #ffffff;
    background-color: #00008f;
  }

  &--Disabled {
    opacity: 0.3;
  }
}

.Search {
  background-color: #ededf4;

  ::v-deep .el-input__inner {
    border: 0;
    font-size: 1.2rem;

    &::placeholder {
      font-weight: 400;
    }
  }
}

.Display,
.Results {
}

.Results {
  flex: 1;
}

.Grab {
  flex-shrink: 0;
  background-color: #ededf4;
  border-right: 1px solid rgba(0, 0, 143, 0.1);
  width: 1rem;
}
</style>
