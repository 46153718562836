<template>
  <div v-selector.view class="d-flex flex-grow-1">
    <RulesSidebar :product="product" class="d-flex flex-grow-1" :unused-rules="unusedRules" :cycle-rules="cycleRules"
      @addRule="addRule" @change-made="setRefetchFlag" />
    <router-view ref="router" :product="product" @update="checkRules" @change-made="setRefetchFlag"></router-view>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import RulesSidebar from '../components/RulesSidebar.vue';
import * as api from '../api';

export default {
  name: 'ProductRules',
  components: { RulesSidebar },
  props: {
    product: {
      type: Object,
      required: true
    }
  },
  data() {
    const { ruleId } = this.$router.currentRoute.params;
    return {
      ruleId: ruleId || this.product.rules[0].id,
      unusedRules: [],
      cycleRules: [],
      shouldRefetchProduct: false
    };
  },
  computed: {
    ...mapState('auth', ['tenant'])
  },
  watch: {
    product() {
      const { params, query } = this.$router.currentRoute;
        if (!this.product.rules.map((r) => r.id.toString()).includes(this.ruleId.toString())) {
        this.$router.replace({
          name: 'product-rules-edit',
          params: {
            ...params,
            ruleId: this.product.rules[0].id
          },
          query
        });
      }
    }
  },
  async mounted() {
    await this.checkRules();
  },
  beforeDestroy() {
    if (this.shouldRefetchProduct) {
      this.refreshProduct({ productId: this.product.id, silent: true });
    }
  },
  methods: {
    ...mapActions('product', ['refreshProduct']),
    addRule(...args) {
      this.$refs.router.addRule(...args);
    },
    async checkRules(cancel = false) {
      const response = await api.checkRules(
        this.product.id,
        cancel,
        this.tenant
      );
      this.unusedRules =
        response && response.data.ok ? response.data.data.unusedRules : [];
      this.cycleRules =
        response && response.data.ok ? response.data.data.rulesWithCycle : [];
    },
    setRefetchFlag() {
      this.shouldRefetchProduct = true;
    }
  }
};
</script>
