import i18n from './i18n';
import loading from './loading';
import productReleases from './product/releases';
import search from './product/search';

export default {
  i18n,
  productReleases,
  loading,
  search
};
