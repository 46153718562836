<template>
  <UiDialog id="propertiesImportDialog" :title="$t('properties.import-wizard.title')" :visible="$attrs.visible"
    :freeze="freezeAll" :disable-confirm="freezeConfirm" :request-confirmation="{
    title: $t(`properties.import-wizard.confirm-title`),
    message: $t(`properties.import-wizard.confirm-message`)
  }" class="import-properties-dialog" variant="large" @close="onClose()" @cancel="cancelExport()"
    @confirm="requestImport()">
    <template #dialog-sub-title>
      <div class="header_subtitle">
        {{ subTitle }} <i class="el-icon-arrow-right"></i>
        <strong>{{ $t('properties.import-wizard.subtitle') }}</strong>
      </div>
    </template>
    <template #dialog-content>
      <UiLoadingArea :is-loading="isLoading" :message="loadingMessage">
        <div class="mb-4">
          <span class="label_bold">{{ $t(`properties.import-wizard.filename`) }} :</span>
          {{ importFileName }}
        </div>

        <UiButton data-test="button-clear-definition-list" variant="secondary" icon="el-icon-set-up"
          :disabled="clearDefinitionRequested || freezeConfirm" @click="clearDefinitionLists">
          {{ $t('properties.import-wizard.clear-definition-list') }}
        </UiButton>

        <div class="pt-4">
          <div v-if="definitionLists.length === 0">
            <span>{{
    $t(`properties.import-wizard.no-properties-found`)
  }}</span>
          </div>
          <UiTable v-if="!isLoading && definitionLists.length > 0" ref="importPropertiesTable" row-key="name"
            :data="definitionLists" :default-sort="{ prop: 'fieldname', order: 'descending' }"
            :row-class-name="getRowClass" @selectionChange="handleSelectionChange">
            >
            <el-table-column type="selection" reserve-selection width="55">
            </el-table-column>

            <template #expand="{ row, $index }">
              <PropertyMetadata :default-property-metadata="[
    `displayName`,
    `description`,
    `question`,
    `defaultValue`
  ]" :is-read-only="true" :property="metadata(row, $index)" :selectable-terms="() => selectableTerms(row)"
                :is-dimension="false" :source-language="'en'" :target-language="'en'" :tooltip="{
    message: $t(`properties.import-wizard.metadata-readyonly`)
  }" />
            </template>

            <el-table-column sortable="custom" label="Property">
              <template #default="{ row, $index }">
                <UiColorPicker :toggle-editing="false" :value="row.color" :palette="colors"
                  :data-cy="`color-picker-${row.dimensionName}`" data-test="property-color-picker" />

                <span :data-cy="`dimension-row-${$index}`">{{
    row.dimensionName
  }}</span>
              </template>
            </el-table-column>

            <el-table-column sortable="custom" label="Type">
              <template #default="{ row }">
                <span>{{ row.type }}</span>
              </template>
            </el-table-column>

            <el-table-column sortable="custom" label="Status">
              <template #default="{ row }">
                <span :class="{
    label_itemImported: isPropertyImported(row),
    label_itemExist:
      (row.imported === 'duplicate' ||
        row.imported === 'tobeoverriden') &&
      row.selected
  }">
                  <i v-if="isPropertyImported(row)" class="check-circle far fa-check-circle"></i>
                  {{ getStatus(row) }}</span>
              </template>
            </el-table-column>

            <el-table-column label="Definition list">
              <template slot-scope="scope">
                <el-select v-if="scope.row.type === `DIMENSION`" v-model="scope.row.definitionListId"
                  :data-cy="`select-definition-list-${scope.$index}`" data-test="select-definition-list"
                  placeholder="Definition list" filterable @change="updateDefinitionList(scope.row)">
                  <el-option v-for="item in definitionListsFormatted" :key="item._id" :label="item.name"
                    :value="item._id">
                  </el-option>
                </el-select>
              </template>
            </el-table-column>
          </UiTable>
        </div>
      </UiLoadingArea>
    </template>
  </UiDialog>
</template>
<script>
import { orderBy, groupBy } from 'lodash';
import { mapState, mapActions, mapGetters } from 'vuex';
import { colors } from '../config';
import { noop } from '../util';
import PropertyMetadataMixin from './Metadata/PropertyMetadataMixin';
import PropertyMetadata from './Metadata/PropertyMetadata.vue';

export default {
  name: 'PropertiesImportManager',
  components: { PropertyMetadata },
  mixins: [PropertyMetadataMixin],
  props: {
    propertiesJSON: {
      type: Array,
      required: true
    },
    importFileName: {
      type: String,
      required: true
    }
  },
  data: () => {
    return {
      subTitle: 'Properties',
      isLoading: false,
      colors,
      loadingMessage: '',
      freezeAll: false,
      clearDefinitionRequested: false,
      initializedProps: false,
      initializedList: false
    };
  },
  computed: {
    ...mapState('definitionListSuggestions', {
      definitionListSuggestions: 'suggestions'
    }),
    ...mapState('definitions', ['definitions']),
    ...mapState('auth', ['tenant']),
    ...mapGetters('auth', { user: 'getUser' }),
    definitionListsFormatted() {
      return orderBy(
        [
          ...this.definitionListSuggestions.map((dl) => ({
            _id: dl.key,
            name: dl.label
          })),
          { _id: 0, name: `<${this.$t('product.import-wizard.no-list')}>` }
        ],
        [(definition) => definition.name.toLowerCase(), ['desc']]
      );
    },
    definitionLists() {
      const list = [];

      const fetchDefinitionListName = (definitionListId) => {
        const defList = this.propertiesJSON._associatedContent
          ? this.propertiesJSON._associatedContent.find(
            (c) =>
              c.type === 'DEFINITION-LIST' &&
              parseInt(c.metadata.id, 10) === definitionListId
          )
          : null;

        if (defList) {
          return defList.metadata.name;
        }
        return '';
      };

      this.propertiesJSON.forEach((p) => {
        // clean definition list attribute
        const dlIds = [p.definition_list_id || 0, p.definitionListId || 0];

        const definitionListId = dlIds.reduce((acc, curval) => {
          return curval !== 0 ? curval : acc;
        }, 0);

        Object.assign(p, { definitionListId });

        if (p.definition_list_id) {
          // eslint-disable-next-line no-param-reassign
          delete p.definition_list_id;
        }

        if (p.definitionListId !== 0) {
          // find the definition based on the id + name (only id to support retro-compatibility)
          Object.assign(p, {
            definitionListName: fetchDefinitionListName(p.definitionListId)
          });

          const listFound = this.definitionListsFormatted.find((dff) => {
            // Here list with no definition list name (export v1 wo/package.json) returns always 0. As we only want to select list based on id+name

            return !this.initializedList
              ? dff._id === p.definitionListId &&
              dff.name.toUpperCase() === p.definitionListName.toUpperCase()
              : dff._id === p.definitionListId;
          });
          if (listFound === undefined) {
            // eslint-disable-next-line no-param-reassign
            p.definitionListId = 0;
          }
        }

        const getStatus = (propertyToImport) => {
          if (this.initializedList) {
            return propertyToImport.imported;
          }
          const property = this.properties.find(
            (p2) => p2.name === propertyToImport.name
          );
          if (!property) {
            return 'pending';
          }
          this.$set(propertyToImport, 'id', property.id);
          this.$set(propertyToImport, 'team', property.team);
          return 'duplicate';
        };

        const convertMetadata = (metadata) => {
          if (!Array.isArray(metadata)) {
            return metadata;
          }
          const result = {};
          (metadata || []).forEach((m) => {
            result[m.key] = m.value;
          });
          return result;
        };

        list.push(
          Object.assign(
            p,
            { metadata: convertMetadata(p.metadata || {}) },
            { imported: getStatus(p) },
            { dimensionName: p.name }
          )
        );
      });

      if (list.length > 0) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.initializedList = true;
      }

      return list;
    },
    freezeConfirm() {
      const props = groupBy(this.propertiesJSON, 'selected');
      return !props.true;
    }
  },
  watch: {
    propertiesJSON() {
      if (
        Array.isArray(this.propertiesJSON) &&
        this.propertiesJSON.length > 0 &&
        !this.initializedProps
      ) {
        this.propertiesJSON.forEach((p) => {
          if (p.id) {
            // eslint-disable-next-line no-param-reassign
            delete p.id;
          }
          this.$set(p, 'imported', 'pending');
          this.$set(p, 'selected', false);
          this.$set(p, '__type', p.type);
        });
        this.initializedProps = true;
      }
    }
  },

  methods: {
    ...mapActions('definitionListSuggestions', { fetchDefinitions: 'fetch' }),
    ...mapActions('sharedProperty', ['importSharedProperty']),
    onClose() {
      this.reset();
      this.$emit('close');
    },
    cancelExport() {
      this.onClose();
    },
    selectableTerms() {
      return [];
    },
    getStatus(row) {
      const status = row.imported || 'pending';
      return this.$t(`properties.import-wizard.status.${status}`);
    },
    handleSelectionChange(selection) {
      this.propertiesJSON.map((p) => Object.assign(p, { selected: false }));
      selection.forEach((s) => {
        const property = this.propertiesJSON.find((p) => p.name === s.name);
        Object.assign(property, { selected: true });
        if (
          property.imported === 'duplicate' ||
          property.imported === 'overriden'
        ) {
          Object.assign(property, { imported: 'tobeoverriden' });
        }
      });
    },
    updateDefinitionList({ dimensionName, definitionListId }) {
      const proprety = this.propertiesJSON.find(
        (p) => p.name === dimensionName
      );
      proprety.definitionListId = definitionListId;
    },
    requestImport() {
      this.freezeAll = true;
      const propertyData = this.propertiesJSON
        .filter((p) => !!p.selected)
        .map((p) => {
          const propertyFormatted = {
            id: p.id || null,
            name: p.name,
            team: p.team || null,
            valueType: p.valueType,
            metadata: p.metadata || {},
            color: p.color,
            type: p.type
          };

          if (p.definitionListId > 0 && p.type === 'DIMENSION') {
            Object.assign(propertyFormatted, {
              definitionListId: p.definitionListId
            });
          }
          return propertyFormatted;
        });
      propertyData
        .reduce((prevPromise, p) => {
          return prevPromise.then(() => {
            const property = this.propertiesJSON.find(
              (prop) => prop.name === p.name
            );
            return this.importSharedProperty({
              propertyData: p,
              propertyParams: { user: { group_id: this.user.group_id } },
              tenant: this.tenant
            })
              .then((status) => {
                property.imported =
                  status === 'created' ? 'imported' : 'overriden';
              })
              .catch((err) => {
                property.imported = err.name.toLowerCase();
                return noop();
              });
          });
        }, Promise.resolve())
        .then(() => {
          this.freezeAll = false;
          this.onClose();
        });
    },
    clearDefinitionLists() {
      this.propertiesJSON.forEach((p) => {
        Object.assign(p, { definitionListId: 0 });
      });
    },
    getRowClass({ row, rowIndex }) {
      const { dimensionName, definitionListId } = row;
      return `row-index-${rowIndex} property-import-wizard-dimension dimension-${dimensionName} listId-${definitionListId}`;
    },
    isPropertyImported({ imported }) {
      return imported === 'imported' || imported === 'overriden';
    },
    reset() {
      this.initializedProps = false;
      this.initializedList = false;
    }
  }
};
</script>
<style scoped lang="scss">
@import "@axatechlab/assets/scss/_variables";

.content_subinfo {
  margin: 20px auto;

  span {
    font-weight: bold;
    width: 66px;
    display: inline-block;
  }
}

.label_itemExist {
  background: #fff8c2;
  color: #a1684c;
  padding: 0 5px;
  border-radius: 4px;
}

.label_itemImported {
  background: #d8f4c1;
  // color: #d8f4c1;
  padding: 0 5px;
  border-radius: 4px;
}

.label_bold {
  font-weight: 700;
}
</style>
<style lang="scss">
.import-properties-dialog {
  .el-dialog__body {
    max-height: 60vh;
    overflow: scroll;
  }
}
</style>
