import {
  availableLanguages,
  defaultLanguage
} from '../../../helpers/translations';

const getInitialState = () => ({
  sourceLanguage: defaultLanguage,
  targetLanguage: null,
  availableLanguages
});

const assertValidKey = (k) => {
  if (!k || k.length !== 2)
    throw Error('Invalid key, missing or too short to be ISO');
};

export default {
  namespaced: true,
  state: getInitialState(),
  actions: {
    reset: ({ commit }) => commit('RESET'),
    setSourceLanguage({ commit }, key) {
      commit('SET_SOURCE_LANGUAGE', key);
    },
    setTargetLanguage({ commit }, key) {
      commit('SET_TARGET_LANGUAGE', key);
    }
  },
  getters: {},
  mutations: {
    RESET: (state) => Object.assign(state, getInitialState()),
    SET_SOURCE_LANGUAGE(state, key) {
      const k = key && key.trim().toLowerCase();
      assertValidKey(k);

      state.sourceLanguage = k;
      if (state.targetLanguage === k) {
        state.targetLanguage = null;
      }
    },
    SET_TARGET_LANGUAGE(state, key) {
      const k = key.trim().toLowerCase();
      assertValidKey(k);
      if (state.sourceLanguage === k) return;
      state.targetLanguage = k;
    }
  }
};
