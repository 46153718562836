const termLabel = (term) => {
  return term.name &&
    term.definitions &&
    term.definitions.length === 1 &&
    term.definitions[0].displayName &&
    term.name === term.definitions[0].primaryKey
    ? term.definitions[0].displayName
    : term.name;
};

module.exports = { termLabel };
