const getInitialState = () => ({
  searchSidebarVisible: false,
  searchTrigger: 0
});

export default {
  namespaced: true,
  state: getInitialState(),
  actions: {
    reset: ({ commit }) => commit('RESET'),
    toggleSidebar: async ({ commit }) => {
      commit('TOGGLE_SIDEBAR');
    },
    triggerSearch: ({ commit }) => {
      commit('TRIGGER_SEARCH');
    }
  },
  getters: {},
  mutations: {
    RESET: (state) => Object.assign(state, getInitialState()),
    TOGGLE_SIDEBAR(state) {
      state.searchSidebarVisible = !state.searchSidebarVisible;
    },
    TRIGGER_SEARCH(state) {
      state.searchTrigger += 1;
    }
  }
};
