/**
 * Copyright 2020 AXA Group Operations S.A.
 *
 * Licensed under the Apache License, Version 2.0 (the "License")
 * you may not use this file except in compliance with the License
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

// Disabl eslint bitwise check as bitwise operation are needed to compute hex
/* eslint-disable no-bitwise */

const stringToHash = (string) =>
  string.split('').reduce((accumulator, currentValue) => {
    let updatedAccumulator =
      (accumulator << 5) - accumulator + currentValue.charCodeAt(0);
    updatedAccumulator |= 0;
    return updatedAccumulator;
  }, 0);

function generateHex(string) {
  let colour = '#';
  const hash = stringToHash(string);
  for (let i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    colour += `00${value.toString(16)}`.substr(-2);
  }

  return colour;
}

module.exports = {
  stringToHash,
  generateHex
};
