/* eslint-disable no-param-reassign */
export default (object, path, defaultValue) => {
  const p = path.replace(/\[/g, '.').replace(/]/g, '').split('.');

  p.forEach((level) => {
    object = object[level];
  });

  if (object === undefined) {
    return defaultValue;
  }

  return object;
};
