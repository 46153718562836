import semver from 'semver';

const compareRelease =
  (k = 'value') =>
  (a, b) =>
    semver.compare(b[k], a[k]);

const lastMajors = (releases) => {
  return Object.values(
    releases.reduce((m, v) => {
      // eslint-disable-next-line no-param-reassign
      m[v.major] = v.label;
      return m;
    }, {})
  ).sort(semver.compare);
};

const lastMajor = (releases) => {
  return releases.reduce((m, v) => {
    return parseInt(v.major, 10) > parseInt(m, 10) ? v.major : m;
  }, 0);
};

const lastMinors = (releases) => {
  return Object.values(
    releases.reduce((m, v) => {
      // eslint-disable-next-line no-param-reassign
      m[`${v.major}.${v.minor}`] = v.label;
      return m;
    }, {})
  ).sort(semver.compare);
};

const possibleNextMajorReleases = (releases) => {
  return lastMajors(releases).map((v) => ({
    value: semver.inc(v, 'minor'),
    previousValue: v,
    help: 'Minor release'
  }));
};

const possibleNextMinorReleases = (releases) => {
  return lastMinors(releases).map((v) => ({
    value: semver.inc(v, 'patch'),
    previousValue: v,
    help: 'Patch release'
  }));
};

const possibleNextReleases = (releases) => {
  const l = lastMajor(releases);
  const releasesValues = releases.map((r) => r.key);
  const data = [
    {
      value: semver.inc(semver.coerce(`${l}`), 'major'),
      previousValue: l,
      help: 'Next major'
    }
  ];

  data.push(...possibleNextMajorReleases(releases));
  data.push(...possibleNextMinorReleases(releases));
  data.sort(compareRelease('value'));

  return Object.freeze(data.filter((d) => !releasesValues.includes(d.value)));
};

export default possibleNextReleases;
