<template>
  <div>
    <el-dialog title="Returned values" :visible.sync="returnedDialogVisible">
      <div v-if="returned" class="returned-dialog">
        <div v-if="returned.dimensions.length" class="returned-column">
          <div class="returned-title">Dimensions</div>
          <div
            v-for="dim in returned.dimensions"
            :key="dim.name"
            class="returned-element"
          >
            <div
              v-if="dim.color"
              :style="{
                backgroundColor: dim.color
              }"
              class="color-preview mx-auto ui_dimension_color"
            ></div>
            <div>{{ dim.name }}</div>
          </div>
        </div>
        <div v-if="returned.out.length" class="returned-column">
          <div class="returned-title">Outputs</div>
          <div
            v-for="out in returned.out"
            :key="out.name"
            class="returned-element"
          >
            <div>{{ out.name }}</div>
          </div>
        </div>
      </div>
    </el-dialog>
    <el-dialog
      :title="$t('product.create_new_prop')"
      :visible.sync="addDialogVisible"
      :close-on-click-modal="false"
      custom-class="add-variable-dialog"
    >
      <el-form :model="addVariableForm" @submit.native.prevent="addItem()">
        <el-form-item :label="$t('product.questionnaire')">
          <el-select
            v-model="addVariableForm.questionnaire"
            class="ui_select_questionnaire"
            :placeholder="$t('product.terms.edit_select_questionnaire')"
            filterable
            :loading="loading"
            remote
            :remote-method="search"
            default-first-option
            value-key="id"
            clearable
            @visible-change="onVisibilityChange"
            @clear="clearQuestionnaire"
            @focus="onFocus"
            @change="onSearch"
          >
            <el-option
              v-for="questionnaire in searchResults"
              :key="questionnaire.id"
              :label="questionnaire.name"
              :value="questionnaire"
            />
          </el-select>
        </el-form-item>

        <el-form-item :label="$t('product.questionnaires.fields.outputKey')">
          <el-input
            v-model="addVariableForm.outputKey"
            autocomplete="off"
            @input.native="
              addVariableForm.outputKey = toValidOutputKey(
                addVariableForm.outputKey
              );
              setFromQuestionnaire = false;
            "
          ></el-input>
          <span v-if="!validOutputKey" class="warn-user">{{
            $t('product.questionnaires.outputKey-used')
          }}</span>
        </el-form-item>
        <el-form-item
          v-if="addVariableForm.questionnaire"
          label="Trigger on dimension"
        >
          <el-cascader
            v-model="addVariableForm.triggers"
            :options="triggers"
            :popper-class="'qbinding'"
            filterable
            clearable
            :props="cascaderProps"
            @change="(triggers) => setTriggers(addVariableForm, triggers)"
          >
            <div
              slot-scope="{ node, data }"
              :title="`${getDefinitionName(data)}`"
              class="trigger-label"
              @click="selectTrigger(addVariableForm, node)"
            >
              <span :title="`${getDefinitionName(data)}`">{{
                getDefinitionName(data)
              }}</span>
              <span
                v-if="data.children && data.children.length"
                :title="`${getDefinitionName(data)}`"
              >
                ({{ data.children.length }})
              </span>
            </div>
          </el-cascader>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button
          id="add-questionnaire-button-cancel"
          @click="addDialogVisible = false"
        >
          {{ $t('action.cancel') }}
        </el-button>
        <el-button
          id="add-questionnaire-button-ok"
          :disabled="cantCreate()"
          type="primary"
          @click="addItem()"
        >
          {{ $t('action.ok') }}
        </el-button>
      </span>
    </el-dialog>
    <UiTable
      :data="filteredEntries(entries)"
      class="ui_table_questionnaire"
      variant="padded"
      @row-dblclick="(row) => editRow(entries.indexOf(row))"
    >
      <!-- Id -->
      <el-table-column label="Id" width="150">
        <template slot-scope="{ row }">
          <router-link
            class="ui_item_id"
            :to="{
              name: 'product-home',
              params: { productId: row.version.latest, refresh: false },
              query: { version: getQuestionnaireVersion(row) }
            }"
            >{{ getQuestionnaireIdLabel(row) }}</router-link
          >
        </template>
      </el-table-column>

      <!-- Name -->
      <el-table-column
        :label="$t('product.questionnaires.fields.name')"
        min-width="260"
      >
        <template slot-scope="{ row }">
          <span class="ui_item_name">{{ row.name }}</span>
        </template>
      </el-table-column>
      <!-- Priority -->
      <el-table-column
        prop="priority"
        :label="$t('product.questionnaires.fields.priority')"
        width="120"
      >
        <template slot-scope="{ row, $index }">
          <el-input-number
            v-if="isEditing($index)"
            v-model="editedRow.value.priority"
            controls-position="right"
            :min="0"
            :max="1000"
            class="priority ui_item_priority"
          />
          <template v-else>
            <span :class="{ italic: !row.priority }">
              {{ row.priority || $t('product.not_prioritised') }}
            </span>
          </template>
        </template>
      </el-table-column>
      <!-- Output key -->
      <el-table-column
        prop="outputKey"
        :label="$t('product.questionnaires.fields.outputKey')"
        width="300"
      >
        <template slot-scope="{ row, $index }">
          <el-input
            v-if="isEditing($index)"
            v-model="editedRow.value.outputKey"
            @input.native="
              editedRow.value.outputKey = toValidOutputKey(
                editedRow.value.outputKey
              )
            "
          />
          <template v-else>
            <span :class="{ italic: !row.outputKey }">
              {{ row.outputKey || 'Undefined' }}
            </span>
          </template>
        </template>
      </el-table-column>

      <!-- Trigger -->
      <el-table-column
        :label="$t('product.questionnaires.fields.trigger')"
        width="340"
      >
        <template slot-scope="{ row, $index }">
          <div
            v-if="!isEditing($index) || !editedRow"
            class="questionnaire-trigger"
            :title="triggerText(row.trigger)"
          >
            <div
              v-if="row.trigger.dimension"
              :style="{
                backgroundColor: row.trigger.dimension.color
              }"
              class="color-preview mx-auto ui_dimension_color"
            ></div>
            <strong> {{ triggerText(row.trigger) }} </strong>
          </div>
          <div v-if="isEditing($index) && editedRow">
            <el-cascader
              v-model="editedRow.value.triggers"
              :options="triggers"
              :popper-class="'qbinding'"
              filterable
              clearable
              :props="cascaderProps"
              @change="(triggers) => setTriggers(editedRow.value, triggers)"
            >
              <div
                slot-scope="{ node, data }"
                :title="`${getDefinitionName(data)}`"
                class="trigger-label"
                @click="selectTrigger(editedRow.value, node)"
              >
                <span :title="`${getDefinitionName(data)}`">{{
                  getDefinitionName(data)
                }}</span>
                <span
                  v-if="data.children && data.children.length"
                  :title="`${getDefinitionName(data)}`"
                >
                  ({{ data.children.length }})
                </span>
              </div>
            </el-cascader>
          </div>
        </template>
      </el-table-column>

      <!-- Operations -->
      <el-table-column width="160">
        <RowOperations
          slot-scope="{ $index }"
          :read-only="isReadOnlyMode"
          :is-invalid="isInvalid()"
          :is-read-only="isReadOnlyMode"
          :is-editing="isEditing($index)"
          :is-overridden="false"
          :is-inherited="false"
          class="ui_buttons_operations"
          @command="(command) => handleRowItemCommand(command, $index)"
        />
      </el-table-column>
    </UiTable>
  </div>
</template>

<script>
import { Message, MessageBox } from 'element-ui';
import { debounce, keys, noop } from 'lodash';
import { mapGetters, mapActions, mapState } from 'vuex';
import ProductSaveMixin from './ProductSaveMixin';
import RowOperations from './RowOperations.vue';
import * as api from '../api';
import { productTypes } from '../const/product';

export default {
  name: 'ProductQuestionnaires',
  components: { RowOperations },
  mixins: [ProductSaveMixin],
  props: {
    filterQuery: { type: String, default: '' }
  },
  data: () => ({
    editedRow: { index: null, value: null },
    editedProp: null,
    entries: [],
    loading: false,
    setFromQuestionnaire: false,
    questionnaires: [],
    dimensions: {},
    returned: null,
    searchQuery: '',
    addDialogVisible: false,
    returnedDialogVisible: false,
    cascaderProps: {
      expandTrigger: 'click',
      checkStrictly: true
    },
    addVariableForm: {
      questionnaire: null,
      trigger: { dimension: null, term: null },
      outputKey: null
    },
    cancelToken: null
  }),
  computed: {
    ...mapGetters('product', ['isReadOnly']),
    ...mapGetters('auth', ['isGuest', 'isSuperAdmin', 'getUser']),
    ...mapState('auth', ['tenant']),
    ...mapState('product', ['product']),
    ...mapState('products', { searchResults: 'list' }),
    isReadOnlyMode() {
      return this.isReadOnly || this.isGuest(this.product.team.slug);
    },
    triggers() {
      // eslint-disable-next-line no-underscore-dangle
      const _triggers = keys(this.product.specification.dimensions).map(
        (dim) => ({
          value: dim,
          label: dim,
          children: (this.dimensions.terms[dim] || [])
            .map((t) => ({
              value: t.name,
              label: t.name,
              children: (t.definitions || [])
                .map((def) => ({
                  value: def.primaryKey,
                  label: def.displayName || def.primaryKey
                }))
                .sort((l, r) => l.label && l.label.localeCompare(r.label))
            }))
            .concat(
              (this.dimensions.unmatchedTerms[dim] || []).map((t) => ({
                value: t,
                label: t
              }))
            )
            .concat([
              {
                value: '_UNANSWERED',
                label: this.$t('product.questionnaires.fields.unanswered')
              }
            ])
            .sort((l, r) => l.label.localeCompare(r.label))
        })
      );
      const deleteEmptyChildren = (t) => {
        // eslint-disable-next-line no-param-reassign
        if (!t.children || !t.children.length) delete t.children;
      };
      _triggers.forEach((t) => {
        deleteEmptyChildren(t);
        if (t.children) t.children.forEach(deleteEmptyChildren);
      });
      return _triggers.sort((l, r) => l.label.localeCompare(r.label));
    },
    validOutputKey() {
      return !this.product.questionnaires.some(
        (q) => q.outputKey === this.addVariableForm.outputKey
      );
    }
  },
  watch: {
    // Watch the product for changes. If it gets reloaded, we want to rebuild
    // our entries, because the product may have changed.
    product: {
      immediate: true,
      handler: 'updateEntries'
    },
    addDialogVisible() {
      this.addVariableForm.questionnaire = null;
      this.addVariableForm.trigger = { dimension: null, term: null };
      this.addVariableForm.id = null;
      this.addVariableForm.outputKey = null;
      this.addVariableForm.triggers = [];
    }
  },
  async created() {
    this.dimensions = (
      await api.getDimensionsAggregations(this.product.id, this.tenant)
    ).data;
    await this.fetchQuestionnaires();
  },
  methods: {
    ...mapActions('product', [
      'attachQuestionnaire',
      'detachQuestionnaire',
      'configureQuestionnaire'
    ]),
    ...mapActions('products', ['fetchAllProducts']),
    isLoading() {
      return this.loading;
    },
    matchSearch(query, value) {
      return value && value.toLowerCase().indexOf(query.toLowerCase()) === 0;
    },
    filteredEntries(entries) {
      if (!entries) return [];
      if (!this.filterQuery || this.filterQuery.trim().length === 0)
        return entries || [];

      return entries.filter((e) => this.matchSearch(this.filterQuery, e.name));
    },
    async search(search) {
      try {
        this.loading = true;
        if (this.searchQuery !== search) {
          this.addVariableForm.questionnaire = null;
          this.searchQuery = search;
          if (this.setFromQuestionnaire) {
            this.addVariableForm.outputKey = null;
            this.setFromQuestionnaire = false;
          }
        }
        if (this.cancelToken) {
          this.cancelToken.cancel('Request canceled');
        }
        this.cancelToken = api.getCancelToken();
        this.debounceFetchQuestionnaires();
      } catch (err) {
        // todo handle error correctly
        // eslint-disable-next-line no-console
        console.error(err);
      } finally {
        this.loading = false;
        this.cancelToken = null;
      }
    },
    // eslint-disable-next-line func-names
    debounceFetchQuestionnaires: debounce(function () {
      this.fetchQuestionnaires();
    }, 200),
    async fetchQuestionnaires() {
      await this.fetchAllProducts({
        silent: true,
        count: 25,
        type: productTypes.QUESTIONNAIRE,
        search: this.searchQuery,
        sort: {
          field: 'name',
          direction: 'ASC'
        },
        as: 'simple-search',
        tenant: this.tenant,
        team: this.isSuperAdmin ? null : this.getUser.teams.map((t) => t.id)
      });
      return this.searchResults;
    },
    toValidOutputKey(val) {
      return val.replace(/[^\w]/gi, '_').toUpperCase().slice(0, 255);
    },
    getDefinitionName(data) {
      return data.label || data.value;
    },
    triggerText(trigger) {
      const triggerTextWithDefinition = (term, dimension) => (definition) => {
        if (!this.dimensions.terms || !this.dimensions.terms[dimension]) {
          return definition ? `${term} / ${definition}` : `${term}`;
        }
        const df = (
          (this.dimensions.terms[dimension].find((t) => t.name === term) || {})
            .definitions || []
        ).find((d) => d.primaryKey === definition);

        return definition
          ? `${term} / ${(df && df.displayName) || definition}`
          : `${term}`;
      };

      if (!trigger.dimension) return 'Always';
      let triggertext = this.$t(
        'product.questionnaires.fields.asked-or-answered'
      );
      if (trigger.term) {
        if (trigger.term === '_UNANSWERED') {
          triggertext = this.$t('product.questionnaires.fields.unanswered');
        } else if (Array.isArray(trigger.term)) {
          // eslint-disable-next-line prefer-destructuring
          [triggertext] = trigger.term[0];
        } else {
          triggertext = triggerTextWithDefinition(
            trigger.term,
            trigger.dimension.name
          )(trigger.definition || null);
        }
      }
      return `${trigger.dimension.name} / ${triggertext}`;
    },
    editRow(index) {
      if (this.isReadOnlyMode) return;
      if (this.editedRow.index === index) return;
      const entry = this.entries[index];
      const dimension = entry.trigger.dimension && entry.trigger.dimension.name;
      let { term } = entry.trigger;
      let definition = (entry.trigger && entry.trigger.definition) || null;
      if (dimension && entry.trigger.term && this.product.terms[dimension]) {
        term = Array.isArray(entry.trigger.term)
          ? entry.trigger.term[0]
          : entry.trigger.term;
        this.product.terms[dimension].forEach((t) => {
          if (!definition) {
            definition =
              t.definitions && t.definitions.find((d) => d.primaryKey === term);
            if (definition) {
              definition = definition.primaryKey;
              term = t.name;
            }
          }
        });
      }
      if (dimension) {
        entry.triggers = [dimension, term];
        if (definition) entry.triggers.push(definition);
      } else {
        entry.triggers = [];
      }
      this.editedRow = { index, value: { ...entry } };
    },
    isInvalid() {
      return (
        this.editedRow &&
        this.editedRow.value &&
        !this.editedRow.value.outputKey
      );
    },
    isEditing(index) {
      return this.editedRow.index === index;
    },
    stopEditing() {
      this.editedRow = { index: null, value: null };
    },
    async saveItem(index) {
      const { id, name, priority, outputKey, trigger } = {
        ...this.entries[index],
        ...this.editedRow.value
      };
      await this.configureQuestionnaire({
        id,
        priority,
        outputKey,
        trigger: JSON.parse(JSON.stringify(trigger)),
        previousTrigger: JSON.parse(JSON.stringify(this.entries[index].trigger))
      });
      Message.success(
        this.$t('product.msg-success-update-property', { key: `(${name})` })
      );
      this.stopEditing();
    },
    cantCreate() {
      return (
        !this.addVariableForm.questionnaire ||
        !this.addVariableForm.outputKey ||
        !this.validOutputKey
      );
    },
    onVisibilityChange(visible) {
      if (!visible && !this.addVariableForm.questionnaire) {
        this.searchQuery = '';
      }
    },
    onSearch() {
      if (
        this.addVariableForm.questionnaire &&
        (!this.addVariableForm.outputKey || this.setFromQuestionnaire)
      ) {
        this.addVariableForm.outputKey = this.toValidOutputKey(
          this.addVariableForm.questionnaire.name
        );
        this.setFromQuestionnaire = true;
      } else if (this.setFromQuestionnaire) {
        this.addVariableForm.outputKey = null;
        this.setFromQuestionnaire = false;
      }
    },
    onFocus() {
      this.search(this.searchQuery);
    },
    clearQuestionnaire() {
      if (this.setFromQuestionnaire) {
        this.addVariableForm.outputKey = null;
        this.setFromQuestionnaire = false;
      }
      this.addVariableForm.questionnaire = null;
      this.searchQuery = '';
    },
    async updateEntries() {
      this.entries = this.product.questionnaires;
    },
    handleRowItemCommand(command, index) {
      if (this.isReadOnlyMode) return;
      switch (command) {
        case 'delete':
          this.deleteItem(index);
          return;
        case 'edit':
          this.editRow(index);
          return;
        case 'save':
          this.saveItem(index);
          return;
        case 'cancel':
          this.stopEditing();
          return;
        default:
          // eslint-disable-next-line no-console
          console.warn(`Unknown command ${command}`);
      }
    },
    async addItem() {
      try {
        // eslint-disable-next-line camelcase
        const { questionnaire, trigger, outputKey } = this.addVariableForm;

        const { id: questionnaireId, name } = questionnaire;
        await this.attachQuestionnaire({
          name,
          outputKey,
          questionnaireId,
          trigger: JSON.parse(JSON.stringify(trigger))
        });
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
        Message.error(
          this.$t('product.questionnaires.msg-error-create-questionnaire')
        );
      } finally {
        this.closeAddDialog();
        this.resetSearch();
      }
    },
    closeAddDialog() {
      this.addDialogVisible = false;
    },
    resetSearch() {
      this.searchQuery = '';
    },
    deleteItem(index) {
      const item = this.entries[index];
      const confirmMsg = this.$t('product.confirm.delete', {
        name: item.name
      });
      MessageBox.confirm(confirmMsg)
        .then(async () => {
          const { id, trigger } = item;
          await this.detachQuestionnaire({ id, trigger });
          Message.success(
            this.$t('product.msg-success-delete-property', {
              name: item.name
            })
          );
        })
        .catch(noop);
    },
    showReturned(returned) {
      this.returned = returned;
      this.returnedDialogVisible = true;
    },
    selectTrigger(row, trigger) {
      const triggers = [];
      let { parent } = trigger;
      while (parent) {
        triggers.unshift(parent.value);
        parent = parent.parent;
      }
      triggers.push(trigger.value);
      this.setTriggers(row, triggers);
    },
    setTriggers(row, triggers) {
      // eslint-disable-next-line no-param-reassign
      row.triggers = triggers;
      if (triggers.length) {
        const dimension = triggers[0];
        const term = triggers.length > 1 ? triggers[1] : null;
        const definition = triggers.length > 2 ? triggers[2] : null;

        // eslint-disable-next-line no-param-reassign
        row.trigger = {
          dimension: {
            name: dimension,
            color: this.product.specification.dimensions[dimension].color
          },
          term,
          definition
        };
      } else {
        // eslint-disable-next-line no-param-reassign
        row.trigger = { dimension: null, term: null };
      }
    },
    getQuestionnaireVersion(questionnaire) {
      return questionnaire.id !== questionnaire.version.latest
        ? questionnaire.version.current
        : 'initial';
    },
    getQuestionnaireIdLabel(questionnaire) {
      return questionnaire.id !== questionnaire.version.latest
        ? `${questionnaire.version.latest} v${questionnaire.version.current}`
        : questionnaire.id;
    },
    showDialog() {
      this.addDialogVisible = true;
    }
  }
};
</script>
<style>
.qbinding .el-cascader-node > .el-radio {
  margin-top: 0.7rem;
  width: 100%;
  position: absolute;
  left: 0;
  padding-left: 20px;
}
.qbinding .el-cascader-node > .el-cascader-node__label {
  padding: 0 24px;
}

.trigger-label {
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 400px;
}
.qbinding {
  max-width: 100%;
  height: 400px;
}
.qbinding .el-cascader-menu__wrap {
  height: 100%;
}
.qbinding .el-cascader-panel {
  height: 100%;
}

.qbinding .el-scrollbar__wrap {
  scrollbar-width: none;
  margin-right: 0 !important;
}
</style>

<style scoped>
.color-preview {
  display: inline-block;
  width: 20px;
  height: 20px;
  border-radius: 10px;
  border: 1px solid gray;
}

.el-input-number {
  width: 100%;
}

.el-button.el-tooltip.is-circle {
  padding: 0;
  border: none;
  background-color: transparent;
  font-size: 18px;
}

.icon-paper-write {
  text-align: center;
  font-size: 20px;
  cursor: pointer;
}

.icon-paper-write.disabled {
  cursor: default;
}

.el-table >>> .inherited {
  color: #aaa;
}

.el-table >>> .overridden {
  font-style: italic;
}

.el-dialog__wrapper >>> .add-variable-dialog {
  width: 418px;
  border-radius: 4px;
  border: 1px solid #ebeef5;
}
.el-dialog__wrapper >>> .el-dialog__header {
  padding: 15px 15px 10px;
  background-color: transparent;
}

.el-dialog__wrapper >>> .el-dialog__body {
  padding: 10px 15px;
}

.el-dialog__wrapper >>> .el-dialog__body .ui_select_questionnaire {
  width: 100%;
}

.italic {
  font-style: italic;
}
.questionnaire-trigger {
  display: flex;
  align-items: center;
}

.questionnaire-trigger >>> * {
  margin-right: 5px !important;
  margin-left: 0 !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.questionnaire-trigger .color-preview {
  flex-shrink: 0;
}

.returned-dialog {
  display: flex;
  padding-bottom: 20px;
}
.returned-dialog >>> .returned-column {
  flex: 1;
}
.returned-title {
  color: #b4b4b4;
  font-size: 22px;
}
.returned-element {
  display: flex;
  font-weight: bold;
  margin-top: 5px;
  font-size: 18px;
  align-items: center;
}
.returned-element >>> * {
  margin-right: 5px !important;
  margin-left: 0 !important;
}
.warn-user {
  color: red;
}
</style>
