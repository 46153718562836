/**
 * Copyright 2020 AXA Group Operations S.A.
 *
 * Licensed under the Apache License, Version 2.0 (the "License")
 * you may not use this file except in compliance with the License
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import semver from 'semver';
import { apiMutations } from '../helper';

const sortReleases = (releases) => {
  releases.sort((a, b) =>
    semver.compare(
      `${a.version.major}.${a.version.minor}.${a.version.patch}`,
      `${b.version.major}.${b.version.minor}.${b.version.patch}`
    )
  );
  releases.reverse();
  return releases;
};

export default {
  ...apiMutations(
    'FETCH_PRODUCT_RELEASES',
    (state) => {
      state.isLoading = true;
    },
    (state, { releases, product }) => {
      const sortedReleases = sortReleases(
        releases.map((r) => ({
          ...r,
          label: `${r.version.major}.${r.version.minor}.${r.version.patch}`,
          isFallbackRelease:
            r.type === 'PRODUCT'
              ? releases.filter(
                  (r2) => r2.type !== 'PRODUCT' && r2.isFallbackRelease
                ).length !== 1 &&
                !['QUESTIONNAIRE', 'SHADOW_QUESTIONNAIRE'].includes(
                  product.type
                )
              : r.isFallbackRelease
        }))
      );

      state.releases = sortedReleases;
      state.isLoading = false;
    },
    (state) => {
      state.releases = [];
      state.isLoading = false;
    }
  ),
  ...apiMutations(
    'CREATE_PRODUCT_RELEASE',
    (state, release) => {
      state.isCreating = true;
      state.releases.push(release);
      sortReleases(state.releases, 'versionAsString');
    },
    (state) => {
      state.isCreating = false;
    },
    (state) => {
      state.isCreating = false;
    }
  ),
  ...apiMutations(
    'MARK_AS_FALLBACK_RELEASE',
    () => {},
    () => {},
    () => {}
  ),
  ...apiMutations(
    'REMOVE_PRODUCT_RELEASE',
    () => {},
    () => {},
    () => {}
  ),
  ...apiMutations(
    'CHANGE_PRODUCT_RELEASE_STATUS',
    () => {},
    () => {},
    () => {}
  ),
  ...apiMutations(
    'SWITCH_USER_ACTIVE_PRODUCT_RELEASE',
    () => {},
    (state, { productId, latestId, version }) => {
      state.activeReleases = {
        ...state.activeReleases,
        [latestId]: [productId, version]
      };
    },
    () => {}
  ),
  ...apiMutations(
    'RETRY_LAMBDA_PUBLICATION',
    () => {},
    () => {},
    () => {}
  )
};
