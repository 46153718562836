/* eslint-disable no-param-reassign */
/**
 * Copyright 2020 AXA Group Operations S.A.
 *
 * Licensed under the Apache License, Version 2.0 (the "License")
 * you may not use this file except in compliance with the License
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import axios from '../axios';

const AUTH_BASE_URL =
  window.__env__.VUE_APP_AUTH_BASE_URL || 'https://auth.axatechlab.localhost';

const LOGOUT_URL = window.__env__.VUE_APP_AUTH_LOGOUT_URL;
const DEFAULT_LOGIN = window.__env__.VUE_APP_DEFAULT_LOGIN;

const authenticate = async (data) => {
  const response = await axios.post(
    `${AUTH_BASE_URL}/api/auth/authenticate`,
    data
  );
  return response.data;
};

const validate = async (accessToken) => {
  const response = await axios.get(
    `${AUTH_BASE_URL}/api/auth/validate?tokenType=jwt`,
    {
      headers: { authorization: accessToken }
    }
  );
  return response.data;
};

const logout = () => {
  // We just need to redirect to One Account ping_end_session_endpoint and the SSO will handle the logout
  if (DEFAULT_LOGIN === 'openid' && LOGOUT_URL)
    window.location.href = `${LOGOUT_URL}`;
  // Local logout redirects to local login screen
  else window.location.href = `${window.origin}/auth/login`;
};

export default { authenticate, logout, validate };
