import { assertValidRevisionType, revisionTypes } from '../../model/revision';

const user = (metadata) =>
  metadata.user ? metadata.user.name : 'Missing user';

const revisionLabel = (type, metadata) => {
  assertValidRevisionType(type);
  let reverted = '';
  switch (type) {
    case revisionTypes.REVISION:
      return `Revision created by ${user(metadata)}`;
    case revisionTypes.RELEASE:
      return `Release ${metadata.version} created by ${metadata.user.name} with status ${metadata.status}`;
    case revisionTypes.RELEASE_DELETED:
      return `Release ${metadata.version} deleted by ${user(metadata)}`;
    case revisionTypes.RELEASE_STATUS_CHANGED:
      return `Release ${metadata.version} publication status changed by ${metadata.user.name} from ${metadata.previousStatus} to ${metadata.status}`;
    case revisionTypes.IMPORT:
      return `Imported by ${user(metadata)}`;
    case revisionTypes.EXPORT:
      return `Exported by ${user(metadata)}`;
    case revisionTypes.LOCK:
      return `Locked by ${user(metadata)}`;
    case revisionTypes.UNLOCK:
      return `Unlocked by ${user(metadata)}`;
    case revisionTypes.QUESTIONAIRE_SUBSTITUTION:
      reverted =
        metadata.rollbackData && metadata.rollbackData.reverted
          ? ' (Reverted)'
          : '';
      return `Questionnaire substitution by ${user(metadata)}${reverted}`;
    default:
      throw new Error(`Unsupported revision type (${type})`);
  }
};

export default revisionLabel;
