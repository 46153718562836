import { getMaxComplexity } from '../../api';
import { apiMutations, queryApi } from '../helper';

const getInitialState = () => ({
  maxComplexity: null,
  isLoading: false,
  errors: null
});

export default {
  namespaced: true,
  state: getInitialState(),
  actions: {
    fetchMaxComplexity: async ({ commit }) => {
      await queryApi(commit, 'FETCH_COMPLEXITY', async () => {
        const { data } = await getMaxComplexity();
        return data.value;
      });
    }
  },
  getters: {
    maxComplexity: (state) => {
      return state.maxComplexity;
    }
  },
  mutations: {
    RESET: (state) => Object.assign(state, getInitialState()),
    ...apiMutations(
      'FETCH_COMPLEXITY',
      (state) => {
        state.isLoading = true;
      },
      (state, value) => {
        state.maxComplexity = value;
        state.isLoading = false;
      },
      (state, errors) => {
        state.errors = [Object.freeze(errors)];
        state.maxComplexity = 550;
        state.isLoading = false;
      }
    )
  }
};
