/**
 * Copyright 2020 AXA Group Operations S.A.
 *
 * Licensed under the Apache License, Version 2.0 (the "License")
 * you may not use this file except in compliance with the License
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { mapActions } from 'vuex';

export default {
  methods: {
    ...mapActions('productRelease', [
      'releaseProduct',
      'switchUserActiveProductRelease'
    ]),
    async createRelease(major, minor, patch) {
      await this.releaseProduct({
        productId: this.product.id,
        major,
        minor,
        patch
      });
      await this.updateReleaseVersionRequestParameter({
        major,
        minor,
        patch
      });
    },
    async switchCurrentActiveRelease(releaseId, version, productId) {
      const v =
        version.major === 0 && version.minor === 0 && version.patch === 0
          ? 'initial'
          : `${version.major}.${version.minor}.${version.patch}`;
      await this.switchUserActiveProductRelease({
        productId,
        releaseId,
        version: v
      });
      await this.updateReleaseVersionRequestParameter(version);
    },
    async updateReleaseVersionRequestParameter(version) {
      const v =
        version.major === 0 && version.minor === 0 && version.patch === 0
          ? 'initial'
          : `${version.major}.${version.minor}.${version.patch}`;
      await this.$router.push({
        query: {
          version: v
        }
      });
    }
  }
};
