import axios from '../axios';
import { configureClient } from './helpers';

export const settings = { baseURL: `${window.__env__.VUE_APP_API_URL}/` };

const client = axios.create(settings);
configureClient(client, settings);

const searchUrl = (tenant, productId, query, options) =>
  options
    ? `/tenants/${tenant}/api/editor/search/1.0/${productId}?q=${query.trim()}&o=${options}`
    : `/tenants/${tenant}/api/editor/search/1.0/${productId}?q=${query.trim()}`;

const indexUrl = (tenant, productId, force) =>
  `/tenants/${tenant}/api/editor/search/1.0/${productId}?force=${
    force ? 'yes' : 'no'
  }`;

const indexAllUrl = (tenant) =>
  `/tenants/${tenant}/api/editor/search/1.0/build`;

const searchClient = async (tenant, productId, query, options) => {
  const { status, data } = await client.get(
    searchUrl(tenant, productId, query, options)
  );
  if (status === 200) return data;
  // @todo better error handling
  throw new Error('Todo better error handling');
};

export const indexingClient = async (tenant, productId, force = false) => {
  const { status, data } = await client.post(
    indexUrl(tenant, productId, force)
  );
  if (status === 200) return data;
  // @todo better error handling
  throw new Error('Todo better error handling');
};

export const indexingAllClient = async (tenant) => {
  const { status, data } = await client.post(indexAllUrl(tenant));
  if (status === 200) return data;
  // @todo better error handling
  throw new Error('Todo better error handling');
};

export default searchClient;
