/**
 * Copyright 2020 AXA Group Operations S.A.
 *
 * Licensed under the Apache License, Version 2.0 (the "License")
 * you may not use this file except in compliance with the License
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import defaultQueryClient from '../../../api/queryClient';
import { apiMutations, getTenant, queryApi } from '../../helper';

const FETCH_SUGGESTIONS = 'FETCH_SUGGESTIONS';

const getInitialState = () => ({
  isLoading: true,
  suggestions: [],
  errors: []
});

export default {
  namespaced: true,
  state: getInitialState(),
  actions: {
    reset: ({ commit }) => commit('RESET'),
    fetch: async ({ commit, dispatch, rootState }) => {
      const tenant = getTenant(rootState);

      await dispatch('reset');
      await queryApi(commit, FETCH_SUGGESTIONS, async () =>
        defaultQueryClient(tenant, 'definitionList', 'suggestions')
      );
    }
  },
  getters: {
    hasError: (state) => state.errors.length > 0
  },
  mutations: {
    RESET: (state) => Object.assign(state, getInitialState()),
    ...apiMutations(
      FETCH_SUGGESTIONS,
      (state) => {
        state.isLoading = true;
      },
      (state, suggestions) => {
        state.suggestions = Object.freeze(suggestions);
        state.isLoading = false;
      },
      (state, errors) => {
        state.errors = [Object.freeze(errors)];
        state.isLoading = false;
      }
    )
  }
};
