<template>
  <UiDialog
    id="questionnaireSubstitution"
    data-cy="questionnaire-substitution-modal"
    title="Questionnaire substitution"
    :visible="isVisible"
    :freeze="isLoading"
    :close-on-click-modal="!isLoading"
    :close-on-press-escape="!isLoading"
    :show-close="!isLoading"
    :disable-confirm="formHasErrors"
    variant="large"
    confirm-label="Confirm substitution"
    @close="closeModal()"
    @cancel="closeModal()"
    @confirm="confirmQuestionnaireSubstitution"
  >
    <template #dialog-content>
      <div v-if="isLoading" class="loadingContainer">
        <Loading />
        <span>{{ progressStatus }}</span>
      </div>
      <div v-else>
        <div class="topMessage" data-cy="modal-message">
          <span v-if="relatedProducts.length">
            {{ $t('product.questionnaire_substitution_products') }}
          </span>
          <span v-else>
            {{ $t('product.questionnaire_substitution_no_products') }}
          </span>
          <span v-if="formHasErrors" class="formWarning">
            {{ $t('product.questionnaire_substitution_form_warning') }}
          </span>
        </div>

        <UiTable
          v-if="relatedProducts.length"
          :key="Date.now()"
          data-cy="substitution-products"
          :data="questionnaireSubstitutionInfo.products"
          class="ui_table_questionnaire"
          variant="padded"
        >
          <el-table-column label="Product">
            <template slot-scope="{ row }"
              >{{ row.name
              }}<UiLockIcon v-if="row.readonly" class="ProductName__Icon"
            /></template>
          </el-table-column>
          <el-table-column label="New Release" width="150">
            <template slot-scope="{ row }">
              <UiInlineDropDown
                variant="default"
                :allow-create="true"
                :filterable="true"
                :default-first-option="true"
                :value="row.newVersion"
                :items="row.newVersionSuggestions"
                :handle-change="
                  (val) => updateSwitchVal(val, row.id, 'newVersion')
                "
                :toggle-editing="true"
                :class="{
                  withError: !validVersionSelected(row.id, row.newVersion)
                }"
              >
                <template #label>{{ row.newVersion }}</template>
              </UiInlineDropDown>
            </template>
          </el-table-column>
          <el-table-column label="Production" width="150">
            <template slot-scope="{ row }">
              <UiToggle
                :value="row.production"
                @change="updateSwitchVal(...arguments, row.id, 'production')"
              ></UiToggle>
            </template>
          </el-table-column>
          <el-table-column label="Expire previous" width="180">
            <template slot-scope="{ row }">
              <UiToggle
                :value="row.expirePrevious"
                @change="
                  updateSwitchVal(...arguments, row.id, 'expirePrevious')
                "
              ></UiToggle>
            </template>
          </el-table-column>
          <el-table-column label="Fallback release" width="180">
            <template slot-scope="{ row }">
              <UiToggle
                :value="row.fallbackRelease"
                :disabled="fallbackReleaseDisabled(row)"
                @change="
                  updateSwitchVal(...arguments, row.id, 'fallbackRelease')
                "
              ></UiToggle>
            </template>
          </el-table-column>
        </UiTable>

        <UiTable
          v-if="relatedProducts.length"
          data-cy="substitution-products-toogle-all"
          :data="['fake']"
          class="ui_table_questionnaire_actions"
          variant="padded"
        >
          <el-table-column> </el-table-column>
          <el-table-column width="150">
            <b>Toggle All</b>
          </el-table-column>
          <el-table-column width="150">
            <UiToggle
              :value="globalToggles.production"
              @change="toggleSwitchsCol(...arguments, 'production')"
            ></UiToggle>
          </el-table-column>
          <el-table-column width="180">
            <UiToggle
              :value="globalToggles.expirePrevious"
              @change="toggleSwitchsCol(...arguments, 'expirePrevious')"
            ></UiToggle>
          </el-table-column>
          <el-table-column width="180">
            <UiToggle
              :value="globalToggles.fallbackRelease"
              :disabled="!globalToggles.production"
              @change="toggleSwitchsCol(...arguments, 'fallbackRelease')"
            ></UiToggle>
          </el-table-column>
        </UiTable>
      </div>
    </template>
  </UiDialog>
</template>

<script>
import Vue from 'vue';
import { mapActions } from 'vuex';
import semver from 'semver';
import { mapValues, chunk } from 'lodash';
import { productTypes } from '../const/product';
import possibleNextReleases from '../domain/service/version/possibleNextReleases';
import filterNextPossibleVersions from '../domain/service/version/filterNextPossibleVersions';
import Loading from './Loading';

const compareRelease =
  (k = 'value') =>
  (a, b) =>
    semver.compare(b[k], a[k]);
const availableReleases = (prod) => {
  return prod.version.list
    .map((v) => {
      const versionLabel = `${v.version.major}.${v.version.minor}.${v.version.patch}`;
      return {
        label: versionLabel,
        key: versionLabel,
        ...v.version
      };
    })
    .sort(compareRelease('label'));
};

const releaseSuggestions = (releases, fromRelease) => {
  let suggestions = possibleNextReleases(releases);
  if (fromRelease === 'initial') fromRelease = '0.0.0';
  suggestions = filterNextPossibleVersions(suggestions, fromRelease).reverse();

  //Adding patch releases for the user to have more options on the selector.
  //The release number can still be inputed manually
  const firstSuggestion =
    suggestions && suggestions.length ? suggestions[0].value : '1.0.0';
  let suggestionStart = /^(\d+\.\d+\.)/.exec(firstSuggestion);
  suggestionStart = suggestionStart !== null ? suggestionStart[0] : '';
  let suggestionEnd = /.(\d+)$/.exec(firstSuggestion);
  suggestionEnd = suggestionEnd !== null ? suggestionEnd[1] : '';
  const newSuggestions = [];
  if (suggestionStart && suggestionEnd) {
    suggestionEnd = parseInt(suggestionEnd, 10);
    for (let i = suggestionEnd + 1; i <= suggestionEnd + 4; i++) {
      newSuggestions.push({ value: suggestionStart + i });
    }
  }

  suggestions = suggestions
    .slice(0, 1)
    .concat(newSuggestions, suggestions.slice(1));

  return suggestions.map((s) => {
    return { key: s.value, label: s.value };
  });
};

export default {
  name: 'ReplaceQuestionnaireModal',
  components: { Loading },
  props: {
    product: { type: Object, required: true },
    relatedProducts: { type: Array, required: true, default: () => [] },
    questionnaireReleaseVersion: { type: String, required: true },
    visible: { type: Boolean, required: true, default: false }
  },
  data() {
    return {
      isVisible: false,
      isLoading: false,
      questionnaireSubstitutionInfo: {
        products: [],
        questionnaireId: null,
        selectedRelease: {}
      },
      globalToggles: {
        production: false,
        expirePrevious: false,
        fallbackRelease: false
      },
      selectedVersions: null,
      processedProducts: 0,
      totalProducts: 0,
      productsWithError: {},
      formHasErrors: false,
      usedReleaseVersion: []
    };
  },
  computed: {
    progressStatus() {
      const prods = this.processedProducts > 1 ? 'products' : 'product';
      return `${this.processedProducts} ${prods} processed of ${this.totalProducts}`;
    }
  },
  watch: {
    visible() {
      if (this.visible) this.formatModalData();
      this.isVisible = this.visible;
    },
    selectedVersions() {
      Vue.nextTick(() => {
        this.formHasErrors = Object.values(this.productsWithError).includes(
          true
        );
      });
    }
  },
  beforeDestroy() {
    this.closeModal();
  },
  methods: {
    ...mapActions('product', ['questionnaireSubstitution']),
    formatModalData() {
      const products = this.relatedProducts
        .filter((p) => p.type !== productTypes.TEMPLATE)
        .map((p) => {
          const releases = availableReleases(p);
          const newVersionSuggestions = releaseSuggestions(
            releases,
            p.version.current
          );

          return {
            id: p.id,
            readonly: p.readonly,
            name: `${p.name} (${
              p.version.current === 'initial'
                ? 'Initial Product'
                : p.version.current
            })`,
            newVersionSuggestions,
            newVersion: newVersionSuggestions[0].key,
            production: false,
            expirePrevious: false,
            fallbackRelease: false,
            latest: p.version.latest
          };
        });

      const [major, minor, patch] = this.questionnaireReleaseVersion.split('.');
      this.questionnaireSubstitutionInfo = {
        products,
        questionnaireId: this.product.version.latest,
        selectedRelease: {
          key: this.questionnaireReleaseVersion,
          label: this.questionnaireReleaseVersion,
          major,
          minor,
          patch
        }
      };
      this.setSelectedVersions();
    },
    updateSwitchVal(val, productId, key) {
      const index = this.questionnaireSubstitutionInfo.products.findIndex(
        (p) => p.id === productId
      );
      this.questionnaireSubstitutionInfo.products[index][key] = val;
      if (!this.questionnaireSubstitutionInfo.products[index].production) {
        this.questionnaireSubstitutionInfo.products[
          index
        ].fallbackRelease = false;
      }
      if (key === 'newVersion') this.setSelectedVersions();
    },
    toggleSwitchsCol(val, key) {
      this.questionnaireSubstitutionInfo.products.map((p) => {
        const product = p;
        if (
          key !== 'fallbackRelease' ||
          (key === 'fallbackRelease' && product.production)
        ) {
          product[key] = val;
        }
        if (key === 'production' && !val) {
          product.fallbackRelease = val;
          this.globalToggles.fallbackRelease = val;
        }
        return product;
      });
      this.globalToggles[key] = val;
    },
    async confirmQuestionnaireSubstitution() {
      this.totalProducts = this.questionnaireSubstitutionInfo.products.length;
      this.processedProducts = 0;
      this.isLoading = true;

      let createSnapshot = true;
      const processingChunk = 2;
      const productsChunks = chunk(
        this.questionnaireSubstitutionInfo.products,
        processingChunk
      );

      // eslint-disable-next-line no-restricted-syntax
      for await (const pc of productsChunks) {
        await this.questionnaireSubstitution({
          data: {
            ...this.questionnaireSubstitutionInfo,
            products: pc,
            createSnapshot
          }
        });
        this.processedProducts += processingChunk;
        createSnapshot = false;
      }

      this.isLoading = false;
      this.$emit('substitution-accepted');
    },
    closeModal() {
      this.globalToggles = mapValues(this.globalToggles, () => false);
      this.$emit('modal-close');
    },
    setSelectedVersions() {
      this.selectedVersions = new Map();
      this.questionnaireSubstitutionInfo.products.forEach((p) => {
        const selectedStr = `${p.id}-${p.newVersion}`;
        this.selectedVersions.set(
          p.latest,
          this.selectedVersions.get(p.latest)
            ? [...this.selectedVersions.get(p.latest), selectedStr]
            : [selectedStr]
        );
      });
    },
    validVersionSelected(id, version) {
      if (!semver.valid(version)) {
        this.productsWithError[`p${id}`] = true;
        return false;
      }

      const prod = this.relatedProducts.find((p) => p.id === id);
      const usedVersions = prod.version.list.map((v) => {
        return `${v.version.major}.${v.version.minor}.${v.version.patch}`;
      });
      if (usedVersions.includes(version)) {
        this.productsWithError[`p${id}`] = true;
        return false;
      }

      const parent = [...this.selectedVersions].find((val) =>
        val[1].includes(`${id}-${version}`)
      );
      if (parent && parent[1].length > 1) {
        const re = new RegExp(version.replaceAll('.', '\\.'));
        let count = 0;
        parent[1].forEach((e) => {
          if (re.test(e)) count += 1;
        });
        if (count > 1) {
          this.productsWithError[`p${id}`] = true;
          return false;
        }
      }
      this.productsWithError[`p${id}`] = false;
      return true;
    },
    fallbackReleaseDisabled(questionnaireSubstitutionEntry) {
      if (questionnaireSubstitutionEntry.production === false) return true;

      const { latest: latestId, id: productId } =
        questionnaireSubstitutionEntry;
      const isAlreadySet = this.questionnaireSubstitutionInfo.products.find(
        (p) => p.fallbackRelease && p.latest === latestId && p.id !== productId
      );
      if (isAlreadySet) return true;

      return false;
    }
  }
};
</script>

<style lang="scss" scoped>
.topMessage {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 1rem;

  > span {
    text-align: center;

    &.formWarning {
      color: red;
    }
  }
}

.loadingContainer {
  text-align: center;

  span {
    display: inline-block;
    font-weight: bold;
    font-size: 1rem;
    margin-top: 25px;
  }
}

.ui_table_questionnaire {
  ::v-deep .el-table {
    margin-bottom: 0;
  }

  ::v-deep .UiInlineDropDown {
    &.withError {
      .el-select {
        .el-input__inner {
          border-color: red;
        }
      }
    }
  }
}

.ui_table_questionnaire_actions {
  ::v-deep .el-table__header-wrapper {
    display: none;
  }
}
</style>
