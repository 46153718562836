/**
 * Copyright 2020 AXA Group Operations S.A.
 *
 * Licensed under the Apache License, Version 2.0 (the "License")
 * you may not use this file except in compliance with the License
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

/* eslint-disable no-useless-escape */
/* eslint-disable no-sequences, no-return-assign */
import { deburr, kebabCase, pick, startsWith, endsWith } from 'lodash';

import XLSX from 'xlsx';

import { format } from 'date-fns';

import moment from 'moment';

export { invert, noop, startsWith, endsWith } from 'lodash';

export const delay = (ms) =>
  new Promise((resolve) => {
    setTimeout(resolve, ms);
  });

const JSZip = require('jszip');

/**
 * Convert an object's entries into an array while retaining the original key
 *
 * @param {Object} o – Input object (e.g. `{ a: { foo: true }, b: { foo:false } }`
 * @param {String} k - The name of the key that will hold the original entry's key
 * @returns {Array} - Output array (e.g. `[{ key: a, foo: true }, { key:b, foo: false }]`)
 */
export const objectToArray = (o, k = 'key') => {
  if (!o || !k) return [];
  return Object.entries(o).reduce(
    (acc, [key, props]) =>
      acc.concat({
        [k]: key,
        ...props
      }),
    []
  );
};

/**
 * Generate a default graph with an optional set of nodes
 * @param name
 * @param insertedNodes
 * @returns {Object}
 */
export const getDefaultGraph = (name, insertedNodes = null) =>
  insertedNodes
    ? {
        id: 1,
        type: 'operator',
        operator: 'and',
        terms: [insertedNodes],
        version: '2021-05-04'
      }
    : {
        id: 1,
        type: 'operator',
        operator: 'and',
        terms: [],
        version: '2021-05-04'
      };

export const classNameForComponent = (name, prefix = 'c') =>
  `${prefix}--${kebabCase(name)}`;

export const slugify = (str) =>
  deburr(str) // Strip accents
    .replace(/\s+/g, '_') // Replace spaces with -
    .replace(/[^\w\-]+/g, '_') // Remove all non-word chars
    .replace(/\_\_+/g, '_') // Replace multiple - with single -
    .replace(/^-+/, '') // Trim - from start of text
    .replace(/-+$/, ''); // Trim - from end of text

export const jsonToBase64 = (obj) =>
  encodeURIComponent(Buffer.from(JSON.stringify(obj)).toString('base64'));
export const base64ToJson = (str) =>
  JSON.parse(Buffer.from(decodeURIComponent(str), 'base64'));

export const extractDefaultValue = (name, property) => {
  const { metadata, type } = property;
  const { defaultValue } = metadata;
  const isBoolean = type === 'Boolean';
  return {
    type: name,
    value: isBoolean
      ? defaultValue.toString().toLowerCase() === 'true'
      : defaultValue
  };
};

export const extractDefaultValues = (properties) =>
  Object.keys(properties)
    .filter(
      (key) =>
        properties[key].metadata !== undefined &&
        properties[key].metadata.defaultValue !== undefined
    )
    .map((name) => extractDefaultValue(name, properties[name]));

export const getHumanDateFromTimestamp = (value, dateOrDateTime) => {
  if (!['date', 'datetime'].includes(value)) {
    return null;
  }
  if (dateOrDateTime === 'date') {
    return format(value, 'YYYY-MM-DD');
  }
  return format(value, 'YYYY-MM-DD HH-mm-ss');
};
export const getHumanDateFromISO = (date, useFromNow) => {
  // eslint-disable-next-line no-underscore-dangle
  const then = moment(date);
  const now = moment();
  const old = now.diff(then) > 24 * 3600 * 1000;
  if (!useFromNow) {
    return then.format('lll');
  }
  return old ? then.format('lll') : then.fromNow();
};

export const getExportToListName = (
  name = undefined,
  truncate = true,
  extension = undefined
) => {
  // xls sheet names cannot exceed 31 chars
  // and can not contain * \ / ? [ ]
  const nameParsed = name.replace(/[\*\[\]\?\/\\]/g, '');
  const truncatedName = truncate ? nameParsed.slice(0, 31) : nameParsed;
  const timestamp = format(new Date(), 'YYYY-MM-DD_HH-mm-ss');
  return {
    fullname: `${truncatedName}-${timestamp}.${extension}`,
    truncatedName
  };
};

export const exportToList = (items, fields, name, extension) => {
  const { fullname, truncatedName } = getExportToListName(
    name,
    true,
    extension
  );

  const itemsFiltered =
    Array.isArray(fields) && fields.length > 0
      ? items.map((item) => {
          const temp = { ...item };
          // eslint-disable-next-line no-underscore-dangle
          temp.updated_at = temp.updated_at || temp._updatedAt || null;
          if (temp.updated_at) {
            // eslint-disable-next-line no-underscore-dangle
            temp._updatedAt = getHumanDateFromISO(temp.updated_at, false);
            // eslint-disable-next-line no-underscore-dangle
            temp.updated_at = temp._updatedAt;
          }
          return pick(temp, fields);
        })
      : items;

  const sheet = XLSX.utils.json_to_sheet(itemsFiltered);
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, sheet, truncatedName);
  XLSX.writeFile(workbook, fullname);
};

// TODO: handle any ext
export const readFromFile = async (file) => {
  const reader = new FileReader();
  return new Promise((resolve, reject) => {
    reader.onload = (e) => {
      const content = e.target.result;
      try {
        const jsonParsed = JSON.parse(content);
        resolve(jsonParsed);
      } catch (error) {
        reject(error);
      }
    };
    reader.readAsText(file);
  });
};

export const extractVarsFromExpression = (expr) => {
  const regex = /[+/-\s*()]/gi;
  return expr
    .replace(regex, '!')
    .replace(/!+/g, '!')
    .split('!')
    .filter((v) => Number.isNaN(Number.parseFloat(v)));
};
// import v1 (one file) : unzip file and assign json content as a product
// import v2 (multiple file): unzip product file and assign to json content.
//        then loop over others file (excluding document) and attached the linked definition lists
export const readZipImportedContent = async (f, prefix) => {
  const zip = await JSZip.loadAsync(f);
  const files = Object.keys(zip.files);
  let file;
  let content;
  let zipFile;

  const getContentFromZipFile = async (filename) => {
    const fileContent = await zip.files[filename].async('text');
    return JSON.parse(fileContent);
  };

  if (!files.includes('package.json')) {
    // Simple ZIP with only one file
    zipFile = Object.values(zip.files).pop();
    content = await zipFile.async('text');
    content = JSON.parse(content);
  } else {
    file = files.find((v) => startsWith(v, prefix)); // i.e. SharedProperties / Product- / etc.
    if (!file) {
      throw new Error('Unable to load the requested export from file.');
    }
    content = await getContentFromZipFile(file);
    // associated content (list) used by import wizard (product / csp)
    const associatedContent = [];
    // eslint-disable-next-line no-restricted-syntax
    for (const filename of files) {
      if (endsWith(filename, '.json') && !startsWith(filename, prefix)) {
        // eslint-disable-next-line no-await-in-loop
        const fileContent = await getContentFromZipFile(filename);
        associatedContent.push({
          ...fileContent,
          ...{
            filename,
            type: startsWith(filename, 'DefinitionList')
              ? 'DEFINITION-LIST'
              : 'OTHERS'
          }
        });
      }
    }
    Object.assign(content, { _associatedContent: associatedContent });
  }
  return content;
};

export const trimHandler = (value) => {
  return typeof value === 'string' ? value.trim() : value;
};

// Coverage Check Utils
const COVERAGE_STATUS = {
  UNRESOLVED: 'UNRESOLVED',
  COVERED: 'COVERED',
  EXCLUDED: 'EXCLUDED',
  OUT_OF_SCOPE: 'OUT OF SCOPE'
};
export const computationV3Payload = (oldPayload) => {
  const {
    query: { input, endorsements, ...rest },
    productId
  } = oldPayload;
  const flatPayload = {
    ...input,
    ...rest
  };
  const state = Object.entries(flatPayload).map(([key, value]) => {
    return { id: key, value: value.is ? value.is[0] : value };
  });
  const policy = {
    endorsements
  };
  const newPayload = {
    productId: `${productId}`,
    policy,
    claim: { lines: [{ state, line_ref: '0' }] },
    ignoreTriggers: true,
    onlyNext: false
  };
  if (oldPayload.customization) {
    newPayload.customization = oldPayload.customization;
  }
  return newPayload;
};

const getStatuses = (status) => {
  const isExclusion = status === COVERAGE_STATUS.EXCLUDED;
  const couldNotResolve = status === COVERAGE_STATUS.UNRESOLVED;
  const cover = status === COVERAGE_STATUS.COVERED;
  const outOfScope = status === COVERAGE_STATUS.OUT_OF_SCOPE;
  return { cover, isExclusion, couldNotResolve, outOfScope };
};

const getMissingQuestions = (
  nextQuestion,
  sentQuestionsIds,
  questions,
  product
) => {
  if (!nextQuestion) return [];
  const missingQuestions = questions.filter(
    (q) => !sentQuestionsIds.includes(q.id)
  );
  return missingQuestions
    .map((q) => {
      // TODO: check the types again
      const type = q.type === 'enum' ? 'dimension' : 'input';
      const newQuestion = {
        error: `missing-${type}`,
        [`${type}Name`]: q.id,
        [`${type}DisplayName`]: q.id,
        facultative: q.facultative,
        metadata: q.metadata
      };
      if (q.answers) {
        const possibleAnswers = q.answers.filter((a) =>
          q.possibleAnswers.includes(a.id || a.primaryKey)
        );
        newQuestion.dimensionValues = possibleAnswers
          .filter((a) => a.primaryKey || a.id)
          .map((a) => ({
            primaryKey: a.primaryKey || a.id,
            displayName: a.displayName
          }));
      }
      return newQuestion;
    })
    .filter((missingQuestion) => {
      if (missingQuestion.error === 'missing-input')
        return product.specification.in[missingQuestion.inputName];
      return product.specification.dimensions[
        missingQuestion.dimensionName || missingQuestion.dimensionDisplayName
      ];
    });
};

const getOutputs = (response) => {
  const {
    result: { global, lines }
  } = response;
  const lineResult = lines[0];
  return [...lineResult.outputs, ...global.outputs];
};

export const formatCoverageCheck = (payload, response, product) => {
  const { claim: { lines: [{ state: payloadState = [] }] = [{}] } = {} } =
    payload;
  const sentQuestionsIds = payloadState.map((s) => s.id);
  const {
    coverageResponses: [{ result = {} }] = [{}],
    nextQuestion,
    questions: responseQuestions = []
  } = response;
  const { global: { status: responseStatus } = {} } = result;

  const statuses = getStatuses(responseStatus);

  const questions = getMissingQuestions(
    nextQuestion,
    sentQuestionsIds,
    responseQuestions,
    product
  );
  const outputs = getOutputs(response.coverageResponses[0]);

  return {
    ...statuses,
    isExtra: false,
    outputStatus: questions.find((question) => question.facultative)
      ? 'NOT_ENOUGH_INPUT'
      : 'SOLVED',
    leadingToExclusion: outputs.filter((o) => o.type === 'LEAD TO EXCLUSION'),
    questions,
    outputs
  };
};
