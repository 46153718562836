/**
 * Copyright 2020 AXA Group Operations S.A.
 *
 * Licensed under the Apache License, Version 2.0 (the "License")
 * you may not use this file except in compliance with the License
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import Vue from 'vue';
import VueI18n from 'vue-i18n';
import { mapValues } from 'lodash';
import messages from './lang';

Vue.use(VueI18n);

const removeEmptyStringValues = (obj) => {
  if (typeof obj === 'string') {
    return obj.length > 0 ? obj : null;
  }
  return mapValues(obj, removeEmptyStringValues);
};

export const acceptLanguage = (product = {}) => {
  const acceptLanguages = [];
  const currentLanguage = window.localStorage.locale;
  const productLanguage =
    product.defaultLanguage || window.localStorage.productLocale;
  if (currentLanguage) {
    acceptLanguages.push([currentLanguage, 0.9]);
  }
  if (productLanguage) {
    acceptLanguages.push([productLanguage, 0.7]);
  }
  acceptLanguages.push([navigator.language || 'en', 0.5]);
  return acceptLanguages
    .map(([language, priority]) => `${language};q=${priority}`)
    .join(',');
};

export const i18n = new VueI18n({
  locale: window.localStorage.locale || 'en',
  fallbackLocale: 'en',
  messages: mapValues(messages, removeEmptyStringValues)
});
