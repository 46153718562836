<template>
  <a
    href="#"
    class="SearchResultItem"
    :title="tooltipContent(item)"
    :data-id="item.id"
    :data-ruleId="item.rule_id"
    :data-productId="item.product_id"
    @click.prevent="$emit('click', item)"
  >
    <ul class="Path Path--Banner">
      <li class="Path__Item Path__Item--Icon">
        <svg
          v-if="item.sub_type === 'ask'"
          xmlns="http://www.w3.org/2000/svg"
          height="24"
          width="24"
        >
          <path fill="none" d="M0 0h24v24H0z" />
          <path
            d="M19 3H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm-6.99 15c-.7 0-1.26-.56-1.26-1.26 0-.71.56-1.25 1.26-1.25.71 0 1.25.54 1.25 1.25-.01.69-.54 1.26-1.25 1.26zm3.01-7.4c-.76 1.11-1.48 1.46-1.87 2.17-.16.29-.22.48-.22 1.41h-1.82c0-.49-.08-1.29.31-1.98.49-.87 1.42-1.39 1.96-2.16.57-.81.25-2.33-1.37-2.33-1.06 0-1.58.8-1.8 1.48l-1.65-.7C9.01 7.15 10.22 6 11.99 6c1.48 0 2.49.67 3.01 1.52.44.72.7 2.07.02 3.08z"
          />
        </svg>
        <svg
          v-else-if="item.sub_type === 'exclusion'"
          xmlns="http://www.w3.org/2000/svg"
          height="24"
          width="24"
        >
          <path d="M0 0h24v24H0z" fill="none" />
          <path
            d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zM4 12c0-4.42 3.58-8 8-8 1.85 0 3.55.63 4.9 1.69L5.69 16.9A7.902 7.902 0 0 1 4 12zm8 8c-1.85 0-3.55-.63-4.9-1.69L18.31 7.1A7.902 7.902 0 0 1 20 12c0 4.42-3.58 8-8 8z"
          />
        </svg>
        <svg v-else xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
          <path fill="none" d="M0 0h24v24H0z" />
          <path
            d="M22 11V3h-7v3H9V3H2v8h7V8h2v10h4v3h7v-8h-7v3h-2V8h2v3h7zM7 9H4V5h3v4zm10 6h3v4h-3v-4zm0-10h3v4h-3V5z"
          />
        </svg>
      </li>
      <li class="Path__Item Path__Item--Capitalize">
        {{ item.path[0] }}
      </li>
      <li
        class="Path__Item"
        :data-id="item.id"
        :data-ruleId="item.rule_id"
        :data-productId="item.product_id"
      >
        {{ subTypeMapping(item.sub_type) }}
      </li>
    </ul>
    <div class="Content">
      <div class="Label">
        <span
          class="Label__Color"
          :style="`background-color: ${item.color}`"
          :data-color="item.color"
        ></span>
        <span v-if="item.display_name" class="Label__Value"
          ><span class="Highlight" v-html="highlight(item.display_name)" /><sup
            ><span class="Highlight" v-html="highlight(item.label)" /></sup
        ></span>
        <span v-else class="Label__Value"
          ><span class="Highlight" v-html="highlight(item.label)"
        /></span>
      </div>
      <div class="Value">
        <span class="Value__Value"
          ><span class="Highlight" v-html="highlight(item.value)"
        /></span>
      </div>
      <div class="From">
        <span class="From__Label">Rule:</span
        ><span class="From__Value"> {{ item.path[1] }}</span>
      </div>
      <div class="Action">
        <a class="Action__Link" href="#"
          ><span class="Action__LinkLabel">Go to</span>
          <svg xmlns="http://www.w3.org/2000/svg" height="24" width="24">
            <path d="M0 0h24v24H0V0z" fill="none" />
            <path
              d="m12 4-1.41 1.41L16.17 11H4v2h12.17l-5.58 5.59L12 20l8-8-8-8z"
            />
          </svg>
        </a>
      </div>
    </div>
  </a>
</template>

<script>
import ResultItemMixin from './ResultItemMixin';

export default {
  name: 'ResultItemNode',
  mixins: [ResultItemMixin],
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  methods: {
    tooltipContent(item) {
      const path = [...item.path];
      path.push(this.subTypeMapping(item.sub_type));
      path.push(item.label);
      path.push(item.display_name);
      path.push(item.value);
      return path.filter((v) => v && v.trim() !== '').join(' / ');
    }
  }
};
</script>

<style lang="scss" scoped>
@import 'ResultItem.scss';
</style>
