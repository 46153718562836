/**
 * Copyright 2020 AXA Group Operations S.A.
 *
 * Licensed under the Apache License, Version 2.0 (the "License")
 * you may not use this file except in compliance with the License
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';
import SecureLS from 'secure-ls';
import auth from './auth';
import definitions from './definitions';
import teams from './teams';
import product from './product';
import products from './products';
import productProperty from './productProperty';
import productCustomization from './productCustomization';
import productRelease from './productRelease';
import sharedProperty from './sharedProperty';
import complexity from './complexity';
import users from './users';
import tokens from './tokens';

import v2 from './v2';

const ls = new SecureLS({ isCompression: false });

const modules = {
  auth,
  product,
  productTests: v2.product.tests,
  productTemplateTypes: v2.product.templateTypes,
  products,
  productProperty,
  productCustomization,
  productSnapshot: v2.product.snapshots,
  productRelease,
  users,
  definitions,
  definitionListSuggestions: v2.definition.suggestions,
  teams,
  i18n: v2.ui.i18n,
  uiProductReleases: v2.ui.productReleases,
  uiProductSearch: v2.ui.search,
  uiLoading: v2.ui.loading,
  notifications: v2.notifications,
  sharedProperty,
  complexity,
  tokens,
  maintenance: v2.maintenance.settings,
  ruleEditor: v2.rule.editor,
  search: v2.product.search
};

Vue.use(Vuex);

export default new Vuex.Store({
  plugins: [
    createPersistedState({
      paths: ['i18n', 'uiProductReleases'],
      key: 'pmt',
      storage: {
        getItem: (key) => ls.get(key),
        setItem: (key, value) => ls.set(key, value),
        removeItem: (key) => ls.remove(key)
      }
    })
  ],
  // @TODO: Reactivate strict mode in dev once the rule editor has been upgraded
  // strict: process.env.NODE_ENV !== 'production',
  state: {},
  mutations: {},
  actions: {},
  getters: {
    fullState: (state) => JSON.parse(JSON.stringify(state))
  },
  modules
});
