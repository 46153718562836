<template>
  <el-dialog
    :title="
      $t('product.versions.dialog.newRelease', { version: activeRelease })
    "
    :visible.sync="show"
    :before-close="close"
  >
    <el-form @submit.native.prevent="createRelease()">
      <el-form-item>
        <el-autocomplete
          ref="versionInput"
          v-model="version"
          :class="versionInputClassnames()"
          placeholder="2.0.1"
          :fetch-suggestions="versionSuggestions"
          @input="validateVersion"
        >
          <template slot-scope="{ item }">
            <div class="d-flex align-stretch">
              <span :style="{ display: 'flex', flex: 1 }">{{
                item.value
              }}</span>
              <i :style="{ marginLeft: 'auto' }">
                {{ item.help }}
              </i>
            </div>
          </template>
        </el-autocomplete>
        <div v-if="showValidationMessage" class="Help">
          <div v-if="!isValid" class="Help__Danger">
            {{ $t('product.versions.dialog.invalidVersionNumber') }}
            <a
              href="https://semver.org/spec/v2.0.0.html"
              target="_blank"
              rel="noopener"
              >{{ $t('product.versions.dialog.semanticVersionning') }}</a
            >
          </div>
          <div v-if="isDuplicatedVersion" class="Help__Danger">
            {{ $t('product.versions.dialog.existingVersion') }}
          </div>
          <div
            v-if="lowerThanActiveRelease && !isDuplicatedVersion"
            class="Help__Warning"
          >
            {{ $t('product.versions.dialog.confirmReleaseLower') }}
          </div>
        </div>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="toggle()">{{ $t('action.cancel') }}</el-button>
      <el-button
        :type="publishButtonType"
        :disabled="!canPublish"
        @click="createRelease()"
        >{{
          isValid
            ? $t('product.versions.dialog.publish', {
                version: validatedVersion
              })
            : $t('product.versions.dialog.chooseValidVersion')
        }}</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
import semver from 'semver';
import { MessageBox } from 'element-ui';
import filterNextPossibleVersions from '../../../domain/service/version/filterNextPossibleVersions';
import { noop } from '../../../util';
import possibleNextReleases from '../../../domain/service/version/possibleNextReleases';

const compareRelease =
  (k = 'value') =>
  (a, b) =>
    semver.compare(b[k], a[k]);

export default {
  name: 'CreateReleaseDialog',
  props: {
    activeRelease: { type: String, required: true },
    existingReleases: { type: Array, required: true },
    handleReleaseCreation: { type: Function, required: true }
  },
  data() {
    return {
      show: false,
      version: null,
      coercedVersion: null,
      isValid: false,
      isDuplicatedVersion: false,
      lowerThanActiveRelease: false
    };
  },
  computed: {
    releases() {
      return [...this.sortedExistingReleases].reverse();
    },
    showValidationMessage() {
      return (
        this.version &&
        this.version.trim() !== '' &&
        (this.isValid === false ||
          this.lowerThanActiveRelease ||
          this.isDuplicatedVersion)
      );
    },
    validatedVersion() {
      return this.coercedVersion ? this.coercedVersion.version : null;
    },
    possibleNextReleases() {
      const releases = possibleNextReleases(this.releases);
      return this.releases && this.releases.length > 0
        ? filterNextPossibleVersions(releases, this.coercedActiveRelease)
        : releases;
    },
    showVersionWarning() {
      if (!this.coercedVersion) return false;
      return semver.gt(this.coercedActiveRelease, this.coercedVersion.raw);
    },
    canPublish() {
      return this.isValid && !this.isDuplicatedVersion;
    },
    publishButtonType() {
      return this.showVersionWarning ? 'warning' : 'primary';
    },
    coercedActiveRelease() {
      return this.activeRelease === 'initial' ? '0.0.0' : this.activeRelease;
    },
    sortedExistingReleases() {
      return [...this.existingReleases].sort(compareRelease('label'));
    }
  },
  watch: {
    show(value) {
      if (!value) return;
      this.reset();
      setTimeout(() => {
        this.$refs.versionInput.focus();
      }, 400);
    }
  },
  methods: {
    toggle() {
      this.show = !this.show;
    },
    close() {
      this.show = false;
      this.$refs.versionInput.close();
    },
    versionSuggestions(query, callback) {
      callback(
        query && query.trim() !== ''
          ? this.possibleNextReleases.filter((v) => v.value.startsWith(query))
          : this.possibleNextReleases
      );
    },
    validateVersion(value) {
      if (!value || value.trim() === '') {
        this.reset();
      } else {
        try {
          this.coercedVersion = semver.coerce(value);
          if (this.coercedVersion.version === this.activeRelease) {
            this.isDuplicatedVersion = true;
          } else {
            const existingReleases = this.coercedVersion
              ? this.existingReleases.find(
                  (r) => r.label === this.coercedVersion.version
                )
              : undefined;
            this.isDuplicatedVersion = !!existingReleases;
          }

          this.isValid = semver.valid(this.coercedVersion);

          this.lowerThanActiveRelease = semver.gt(
            this.coercedActiveRelease,
            this.coercedVersion.raw
          );
        } catch (err) {
          noop();
        }
      }
    },
    async createRelease() {
      if (this.isValid) {
        try {
          if (this.isDuplicatedVersion) {
            MessageBox.alert(
              this.$t('product.versions.dialog.existingVersion'),
              this.$t('product.versions.create_new_error')
            );
          } else {
            if (this.showVersionWarning) {
              await MessageBox.confirm(
                this.$t('product.versions.dialog.confirmReleaseLower')
              );
            }
            const { major, minor, patch } = this.coercedVersion;
            this.handleReleaseCreation(major, minor, patch);
          }
          this.reset();
          this.close();
        } catch (err) {
          noop();
        }
      }
    },
    versionInputClassnames() {
      return {
        VersionInput: true,
        'VersionInput--Invalid': this.showValidationMessage
      };
    },
    reset() {
      this.version = null;
      this.coercedVersion = null;
      this.isValid = false;
      this.isDuplicatedVersion = false;
      this.lowerThanActiveRelease = false;
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@axatechlab/assets/scss/_variables';
.VersionInput {
  width: 100%;

  &--Invalid::v-deep {
    .el-input__inner {
      border-color: $color-axa-red;
    }
  }
}
.Help {
  line-height: 1.35;
  margin-top: 0.75rem;
  hyphens: auto;
}
.Help__Danger {
  color: $color-axa-red;
}
.Help__Warning {
  color: $color-burnt-sienna;
}
</style>
