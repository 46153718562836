/**
 * Copyright 2020 AXA Group Operations S.A.
 *
 * Licensed under the Apache License, Version 2.0 (the "License")
 * you may not use this file except in compliance with the License
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

export default {
  // FETCH
  FETCH_TOKENS_REQUEST: 'FETCH_TOKENS_REQUEST',
  FETCH_TOKENS_SUCCESS: 'FETCH_TOKENS_SUCCESS',
  FETCH_TOKENS_ERROR: 'FETCH_TOKENS_ERROR',
  // CREATE
  CREATE_TOKENS_REQUEST: 'CREATE_TOKENS_REQUEST',
  CREATE_TOKENS_SUCCESS: 'CREATE_TOKENS_SUCCESS',
  CREATE_TOKENS_ERROR: 'CREATE_TOKENS_ERROR',
  // UPDATE
  UPDATE_TOKENS_REQUEST: 'UPDATE_TOKENS_REQUEST',
  UPDATE_TOKENS_SUCCESS: 'UPDATE_TOKENS_SUCCESS',
  UPDATE_TOKENS_ERROR: 'UPDATE_TOKENS_ERROR',
  // DELETE
  DELETE_TOKENS_REQUEST: 'DELETE_TOKENS_REQUEST',
  DELETE_TOKENS_SUCCESS: 'DELETE_TOKENS_SUCCESS',
  DELETE_TOKENS_ERROR: 'DELETE_TOKENS_ERROR'
};
