import feathersClient from '../../../feathers';
import { acceptLanguage } from '../../../i18n';
import { apiMutations, getTenant } from '../../helper';

const productService = () => feathersClient.service('2.0/products');

const getInitialState = () => ({
  isLoading: true,
  templateTypes: [],
  errors: []
});

const validTechnicalName = (name) => name.replace(/[\s-]/g, '');

export default {
  namespaced: true,
  state: getInitialState(),
  actions: {
    reset: ({ commit }) => commit('RESET'),
    fetch: async ({ commit, rootState }) => {
      const tenant = getTenant(rootState);
      commit('FETCH_TEMPLATE_TYPES_REQUEST');
      try {
        const templateTypes = await productService().find({
          query: {
            query: 'products.templateTypes',
            tenant
          },
          headers: {
            'accept-language': acceptLanguage()
          }
        });
        commit('FETCH_TEMPLATE_TYPES_SUCCESS', { templateTypes });
      } catch (error) {
        commit('FETCH_TEMPLATE_TYPES_ERROR', [{ message: error.message }]);
      }
    }
  },
  getters: {
    getTest: (state) => (id) => {
      const test = state.tests && state.tests.find((t) => t.id === id);
      return test || {};
    },
    hasError: (state) => state.errors.length > 0
  },
  mutations: {
    RESET: (state) => Object.assign(state, getInitialState()),
    ...apiMutations(
      'FETCH_TEMPLATE_TYPES',
      (state) => {
        state.isLoading = true;
      },
      (state, { templateTypes }) => {
        state.templateTypes = Object.freeze(
          templateTypes.map((t) => {
            // eslint-disable-next-line no-param-reassign
            t.technicalName = validTechnicalName(t.name);
            return t;
          })
        );
        state.isLoading = false;
      },
      (state, errors) => {
        state.errors = [Object.freeze(errors)];
        state.isLoading = false;
      }
    )
  }
};
