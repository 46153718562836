/**
 * Copyright 2020 AXA Group Operations S.A.
 *
 * Licensed under the Apache License, Version 2.0 (the "License")
 * you may not use this file except in compliance with the License
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { uniq } from 'lodash';

const DISPLAY_NAME_METADATA = 'displayName';
const DESCRIPTION_METADATA = 'description';
const QUESTION_METADATA = 'question';
const DEFAULT_VALUE_METADATA = 'defaultValue';
const HINT_METADATA = 'hint';
const EXTERNAL_DATA = 'externalData';
const PRIVATE_DATA = 'private';
const OTHER_BEHAVIOR = 'otherBehavior';
const OTHER_KEY = 'otherKey';
const KYC = 'kyc';
const LONG_TEXT_BOX = 'longTextBox';
const NUMBER_RANGE_METADATA = 'numberRange';
const EXPRESSION = 'expression';
const ROUNDING_METADATA = 'rounding';
const TRUNCATE_METADATA = 'truncate';
const UNIT = 'unit';
const ICC_DATA = 'icc';
const ICC_QUESTION_METADATA = 'iccQuestion';
const ICC_HINT_METADATA = 'iccHint';
const ICC_PRIORITY_DATA = 'iccPriority';
const ICC_ANSWERS = 'iccAnswers';
const BUSINESS_OFFER_DATA = 'bod';

const metadataByType = {
  dimensions: [
    DISPLAY_NAME_METADATA,
    QUESTION_METADATA,
    DEFAULT_VALUE_METADATA,
    DESCRIPTION_METADATA,
    HINT_METADATA,
    EXTERNAL_DATA,
    PRIVATE_DATA,
    OTHER_BEHAVIOR,
    OTHER_KEY,
    ICC_DATA,
    ICC_QUESTION_METADATA,
    ICC_HINT_METADATA,
    ICC_PRIORITY_DATA,
    ICC_ANSWERS,
    BUSINESS_OFFER_DATA
  ],
  inputs: [
    DISPLAY_NAME_METADATA,
    QUESTION_METADATA,
    DEFAULT_VALUE_METADATA,
    DESCRIPTION_METADATA,
    HINT_METADATA,
    EXTERNAL_DATA,
    PRIVATE_DATA,
    KYC,
    LONG_TEXT_BOX,
    NUMBER_RANGE_METADATA,
    UNIT,
    ICC_DATA,
    ICC_QUESTION_METADATA,
    ICC_HINT_METADATA,
    ICC_PRIORITY_DATA,
    BUSINESS_OFFER_DATA
  ],
  computes: [
    DISPLAY_NAME_METADATA,
    ROUNDING_METADATA,
    TRUNCATE_METADATA,
    EXPRESSION
  ],
  outputs: [
    DISPLAY_NAME_METADATA,
    DESCRIPTION_METADATA,
    EXTERNAL_DATA,
    PRIVATE_DATA
  ],
  units: [DISPLAY_NAME_METADATA, DESCRIPTION_METADATA],
  endorsements: [DISPLAY_NAME_METADATA, DESCRIPTION_METADATA]
};
export const propertyMetadataByType = (t) => metadataByType[t];

export default {
  props: {
    properties: {
      type: Array,
      required: true
    },
    propertyMetadata: {
      type: Array,
      required: true
    }
  },
  methods: {
    displayName(property, language) {
      const { metadata = {} } = property;
      return metadata.displayName ? metadata.displayName[language] : null;
    },
    metadata(property, index) {
      let { metadata = {} } = property;
      metadata = metadata != null ? metadata : {};
      const fieldName = uniq([
        ...this.propertyMetadata,
        ...Object.keys(metadata)
      ]);

      const processedMetadata = fieldName.map((key) => ({
        key,
        value: metadata && metadata[key] ? metadata[key] : null,
        displayName: this.propertyMetadata.includes(key)
          ? this.$t(`product._metadata.${key}.name`)
          : key,
        placeholder: this.propertyMetadata.includes(key)
          ? this.$t(`product._metadata.${key}.placeholder`)
          : 'Custom metadata'
      }));

      return {
        id: property.name,
        index,
        displayName: property.displayName || property.name,
        type: property.type,
        __type: property.__type,
        metadata: processedMetadata
      };
    }
  }
};
