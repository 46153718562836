/**
 * Copyright 2020 AXA Group Operations S.A.
 *
 * Licensed under the Apache License, Version 2.0 (the "License")
 * you may not use this file except in compliance with the License
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import feathersClient from '@/feathers';
import { queryClient } from '../../api/queryClient';

export const types = {
  SET_TEAMS: 'SET_TEAMS',
  SET_TENANTS: 'SET_TENANTS',
  SET_ERROR: 'SET_ERROR',
  SET_LOADING: 'SET_LOADING',
  SET_PREVIOUS_ACTIVE_TAB: 'SET_PREVIOUS_ACTIVE_TAB'
};

const loadTeams = async ({ commit }, { includeUsers = false } = {}) => {
  try {
    commit(types.SET_LOADING, true);
    const queryName = includeUsers ? 'getTeamsAndUsers' : 'getTeams';
    const teams = await queryClient(null, 'teams', queryName);
    commit(types.SET_TEAMS, teams);
  } catch (err) {
    commit(types.SET_ERROR, err.message);
    commit(types.SET_TEAMS, []);
  } finally {
    commit(types.SET_LOADING, false);
  }
};

const loadTenants = async ({ commit }) => {
  try {
    commit(types.SET_LOADING, true);
    const tenants = await feathersClient.service('tenants').find();
    commit(types.SET_TENANTS, tenants);
  } catch (err) {
    commit(types.SET_ERROR, err.message);
    commit(types.SET_TENANTS, []);
  } finally {
    commit(types.SET_LOADING, false);
  }
};

const createTeam = async ({ commit }, { data }) => {
  try {
    await feathersClient.service('teams').create(data);
  } finally {
    commit(types.SET_LOADING, false);
  }
};

const createTenant = async ({ commit }, { data }) => {
  try {
    await feathersClient.service('tenants').create(data);
  } catch (err) {
    commit(types.SET_ERROR, err.message);
  } finally {
    commit(types.SET_LOADING, false);
  }
};

const saveTeams = async ({ commit }, { data }) => {
  try {
    if (data) {
      // eslint-disable-next-line no-restricted-syntax
      for (const team of data) {
        // eslint-disable-next-line no-await-in-loop
        await feathersClient.service('teams').update(team.id, team);
      }
    }
  } catch (err) {
    commit(types.SET_ERROR, err.message);
  } finally {
    commit(types.SET_LOADING, false);
  }
};

const saveTenant = async ({ commit, dispatch }, { data }) => {
  try {
    await feathersClient.service('tenants').update(data.slug, data);
    dispatch('users/getUsers', undefined, { root: true });
  } catch (err) {
    commit(types.SET_ERROR, err.message);
  } finally {
    commit(types.SET_LOADING, false);
  }
};

const deleteTeam = async ({ commit, dispatch }, { id }) => {
  try {
    await feathersClient.service('teams').remove(id);
    dispatch('users/getUsers', undefined, { root: true });
  } catch (err) {
    commit(types.SET_ERROR, err.message);
  } finally {
    commit(types.SET_LOADING, false);
  }
};

const deleteTenant = async ({ commit, dispatch }, { id }) => {
  try {
    await feathersClient.service('tenants').remove(id);
    dispatch('users/getUsers', undefined, { root: true });
  } catch (err) {
    commit(types.SET_ERROR, err.message);
  } finally {
    commit(types.SET_LOADING, false);
  }
};

const setError = ({ commit }, error) => {
  commit(types.SET_ERROR, error);
};

const setPreviousActiveTab = ({ commit }, tab) => {
  commit(types.SET_PREVIOUS_ACTIVE_TAB, tab);
};

const actions = {
  loadTeams,
  loadTenants,
  createTeam,
  createTenant,
  saveTeams,
  saveTenant,
  deleteTeam,
  deleteTenant,
  setError,
  setPreviousActiveTab
};

export default actions;
