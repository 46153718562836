<template>
  <b-container class="PropertyMetadata">
    <b-row>
      <b-col class="col-12">
        <div v-if="booleanMetadata.length" class="collapsibleContainer">
          <div class="header">
            <span>Configuration</span>
          </div>
          <div class="content">
            <div class="booleanMetaContainer">
              <UiToggle
                v-for="row in booleanMetadata"
                :key="row.key"
                :disabled="isReadOnly || inheritFromCsp"
                :label="row.displayName"
                :value="sourceValue(row) === 'true'"
                @change="(v) => saveBooleanMeta(row, v)"
              ></UiToggle>
            </div>
          </div>
        </div>
      </b-col>
      <b-col class="col-12">
        <div v-if="isCalculationInput" class="collapsibleContainer">
          <div class="header">
            <span>Calculation</span>
          </div>
          <div class="content">
            <div class="calculationMetaContainer">
              <UiCalculation
                :default-related-properties="defaultRelatedProperties"
                :property="property"
                :expressions-prop="calculationMetadata"
                @save="(v) => saveCalculationInput(calculationMetadata, v)"
              />
            </div>
          </div>
        </div>
        <div class="collapsibleContainer">
          <div class="header">
            <span>Metadata</span>
          </div>
          <div class="content">
            <UiTable :data="initialMetadata" @dblclick="(row) => edit(row)">
              <!-- 1ST COLUMN -->
              <el-table-column label="Type" width="280">
                <template slot-scope="{ row }">
                  <UiInlineEditable
                    :variant="row.displayName ? 'bold' : 'light'"
                    :label="row.displayName || 'New...'"
                    :value="row.displayName"
                    :is-editable="canEditDisplayName(row)"
                    :toggle-editing="isEditing(row)"
                    :handle-change="handleDisplayNameChange"
                    :placeholder="row.placeholder"
                  />
                </template>
              </el-table-column>

              <!-- 2ND COLUMN -->
              <el-table-column
                :label="sourceLanguageColumnLabel"
                min-width="64"
              >
                <template #header>
                  <LanguageSelector
                    :current-language="sourceLanguage"
                    :available-languages="availableLanguages"
                    @language-change="switchSourceLanguage"
                  />
                </template>
                <template slot-scope="{ row }">
                  <div
                    v-if="
                      !row.isAttribute &&
                      !isInputRangeRequired(row) &&
                      !isComputedTruncateRequired(row) &&
                      !isComputedRoundingRequired(row)
                    "
                  >
                    <div style="flex: 1">
                      <UiInlineDropDown
                        v-if="selectable(row) && !isReadOnly"
                        :variant="sourceValue(row) ? 'default' : 'light'"
                        :label="sourceLabel(row) || 'No value selected'"
                        :value="sourceValue(row)"
                        :toggle-editing="isEditing(row)"
                        :is-editable="!isReadOnly"
                        :items="getSelectorData(row)"
                        :prepend-empty-value="true"
                        empty-value-label="No value selected"
                        :handle-change="
                          (v) => handleValueChange(row, v, sourceLanguage)
                        "
                      />
                      <IccAnswersSelector
                        v-else-if="row.key === 'iccAnswers'"
                        :toggle-editing="isEditing(row)"
                        :icc-answers-meta="row"
                        :icc-answers-items="getSelectorData(row)"
                        :is-read-only="isReadOnly || inheritFromCsp"
                        @updateVal="
                          (v) => handleValueChange(row, v, sourceLanguage)
                        "
                        @save="save()"
                        @cancel="close(row)"
                      />
                      <UiInlineEditable
                        v-else
                        :as="isNumerical(row) ? 'number' : 'string'"
                        :variant="sourceValue(row) ? 'default' : 'light'"
                        :label="sourceLabel(row) || row.placeholder"
                        :value="sourceValue(row)"
                        :toggle-editing="isEditing(row)"
                        :is-editable="!isReadOnly"
                        :handle-change="
                          (v) => handleValueChange(row, v, sourceLanguage)
                        "
                        @enter="save()"
                      />
                    </div>
                  </div>

                  <div
                    v-else-if="!row.isAttribute && property.__type === 'INPUT'"
                    style="flex: 1"
                  >
                    <div v-if="rangeNumberErrorMessage" class="error">
                      {{ rangeNumberErrorMessage }}
                    </div>
                    <div class="d-flex flex-row align-items-center mb-2">
                      <span class="labelInputRange">{{
                        $t('product._metadata.numberRange.min.fieldname')
                      }}</span>
                      <div style="flex: 3">
                        <UiInlineEditable
                          class="valueInputRange"
                          as="number"
                          :placeholder="
                            $t('product._metadata.numberRange.min.placeholder')
                          "
                          :variant="sourceValue(row) ? 'default' : 'light'"
                          :label="sourceLabelForRange(row, 'minimum')"
                          :value="sourceValueForRange(row, 'minimum')"
                          :toggle-editing="isEditing(row)"
                          :is-editable="!isReadOnly && !inheritFromCsp"
                          :handle-change="
                            (v) => handleRangeValueChange(row, v, 'minimum')
                          "
                          @keyup.native="filterRangeEntry($event, 'min')"
                          @enter="save()"
                        />
                      </div>
                    </div>
                    <div class="d-flex flex-row align-items-center">
                      <span class="labelInputRange">
                        {{ $t('product._metadata.numberRange.step.fieldname') }}
                      </span>
                      <div style="flex: 3">
                        <UiInlineEditable
                          class="valueInputRange"
                          as="number"
                          :placeholder="
                            $t('product._metadata.numberRange.step.placeholder')
                          "
                          :disabled="rangeDisabled"
                          :variant="sourceValue(row) ? 'default' : 'light'"
                          :label="sourceLabelForRange(row, 'step')"
                          :value="sourceValueForRange(row, 'step')"
                          :toggle-editing="isEditing(row)"
                          :is-editable="!isReadOnly && !inheritFromCsp"
                          :handle-change="
                            (v) => handleRangeValueChange(row, v, 'step')
                          "
                          @enter="save()"
                        />
                      </div>
                    </div>
                  </div>
                </template>
              </el-table-column>

              <!-- 3RD COLUMN -->
              <el-table-column
                :label="targetLanguageColumnLabel"
                prop="value"
                min-width="64"
              >
                <template #header>
                  <LanguageSelector
                    :current-language="targetLanguage"
                    :available-languages="targetLanguages"
                    @language-change="switchTargetLanguage"
                  />
                </template>
                <template slot-scope="{ row }">
                  <div
                    v-if="
                      !isInputRangeRequired(row) &&
                      !isComputedTruncateRequired(row) &&
                      !isComputedRoundingRequired(row)
                    "
                  >
                    <div
                      v-if="
                        isTechnical(row) ||
                        defaultPropertyMetadata.includes(row.key) === false
                      "
                      style="flex: 1"
                    >
                      <em>{{
                        $t('product._metadata.technicalNotTranslatable')
                      }}</em>
                    </div>
                    <div v-else style="flex: 1">
                      <div v-if="needsTranslation">
                        <UiInlineDropDown
                          v-if="selectable(row)"
                          :variant="targetValue(row) ? 'default' : 'light'"
                          :label="targetValue(row) || row.placeholder"
                          :value="targetValue(row)"
                          :toggle-editing="isEditing(row)"
                          :is-editable="!isReadOnly && !inheritFromCsp"
                          :items="terms"
                          :handle-change="
                            (v) => handleValueChange(row, v, targetLanguage)
                          "
                        />
                        <UiInlineEditable
                          v-else
                          :variant="targetValue(row) ? 'default' : 'light'"
                          :label="targetValue(row) || row.placeholder"
                          :value="targetValue(row)"
                          :toggle-editing="isEditing(row)"
                          :is-editable="!isReadOnly && !inheritFromCsp"
                          :handle-change="
                            (v) => handleValueChange(row, v, targetLanguage)
                          "
                          @enter="save()"
                        />
                      </div>
                    </div>
                  </div>
                  <div v-else-if="isInputRangeRequired(row)" style="flex: 1">
                    <div class="d-flex flex-row align-items-center mb-2">
                      <span class="labelInputRange">{{
                        $t('product._metadata.numberRange.max.fieldname')
                      }}</span>
                      <div style="flex: 3">
                        <UiInlineEditable
                          class="valueInputRange"
                          as="number"
                          :placeholder="
                            $t('product._metadata.numberRange.max.placeholder')
                          "
                          :variant="sourceValue(row) ? 'default' : 'light'"
                          :label="sourceLabelForRange(row, 'maximum')"
                          :value="sourceValueForRange(row, 'maximum')"
                          :toggle-editing="isEditing(row)"
                          :is-editable="!isReadOnly && !inheritFromCsp"
                          :handle-change="
                            (v) => handleRangeValueChange(row, v, 'maximum')
                          "
                          @enter="save()"
                        />
                      </div>
                    </div>
                    <div class="d-flex flex-row align-items-center">
                      <div
                        v-if="isEditing(row)"
                        style="flex: 1; padding: 8px 0; display: block"
                      >
                        <el-switch
                          v-model="rangeDisabled"
                          class="ui_switch_is_attribute"
                          active-color="#ff4949"
                          inactive-color="#67C23A"
                          @change="toggleRangeDisable(row, $event)"
                        ></el-switch>

                        <span
                          :class="{
                            labelRangeDisabled: rangeDisabled,
                            labelRangeEnabled: !rangeDisabled
                          }"
                          style="min-width: 80px; flex: 3"
                          >{{ sourceLabelForRange(row, 'step', true) }}</span
                        >
                      </div>
                      <div v-else style="padding: 8px 0px">&nbsp;</div>
                    </div>
                  </div>
                </template>
              </el-table-column>

              <!-- 4TH COLUMN -->
              <el-table-column
                v-if="showAsAttributeCheck(property)"
                label="Mark as attribute"
                width="64"
              >
                <template v-if="showAsAttributeCheck(row)" slot-scope="{ row }">
                  <el-switch
                    v-model="row.isAttribute"
                    :disabled="disabledToggleIsAttribute"
                    class="ui_switch_is_attribute"
                    @change="toggleIsAttribute(row, $event)"
                  ></el-switch>
                </template>
              </el-table-column>

              <!-- 5TH COLUMN -->
              <el-table-column
                v-if="property.__type === 'COMPUTED'"
                label="Computed attributes"
              >
                <template
                  v-if="
                    isComputedRoundingRequired(row) ||
                    isComputedTruncateRequired(row)
                  "
                  slot-scope="{ row }"
                >
                  <UiInlineDropDown
                    style="width: 180px"
                    :variant="true ? 'default' : 'light'"
                    :label="'No value selected'"
                    :value="
                      sourceValueForComputeAttributes(
                        row,
                        isComputedRoundingRequired(row)
                          ? 'rounding'
                          : 'truncate'
                      )
                    "
                    :toggle-editing="true"
                    :is-editable="!isReadOnly && !inheritFromCsp"
                    :items="
                      isComputedRoundingRequired(row) ? roundings : Truncations
                    "
                    :prepend-empty-value="false"
                    empty-value-label="No value selected"
                    :handle-change="
                      (v) => handleComputeValueChange(row, v, row.key)
                    "
                  />
                </template>
              </el-table-column>

              <!-- 6TH COLUMN -->
              <el-table-column width="160">
                <template slot-scope="{ row }">
                  <PropertyMetadataOperation
                    :key="refreshOperations"
                    :is-read-only="isReadOnly"
                    :entry="row"
                    :is-editing="isEditing"
                    :can-remove="canRemove"
                    :has-pending-change="hasPendingChange"
                    @editing="edit(row)"
                    @save="save()"
                    @close="close(row)"
                    @remove="remove(row)"
                  />
                </template>
              </el-table-column>
            </UiTable>

            <UiTable
              v-if="showAddRow && !inheritFromCsp"
              :data="['fake']"
              class="ui_table_add_meta"
              variant="padded"
            >
              <el-table-column width="280">
                <UiInlineDropDown
                  :variant="true ? 'default' : 'light'"
                  :label="'No value selected'"
                  :value="''"
                  :toggle-editing="true"
                  :is-editable="true"
                  :items="availableMetadata"
                  :prepend-empty-value="false"
                  empty-value-label="No value selected"
                  :handle-change="(v) => add(v)"
                />
              </el-table-column>
              <el-table-column>
                <input
                  type="text"
                  class="el-input__inner"
                  disabled
                  placeholder="Your Text"
                />
              </el-table-column>
              <el-table-column v-if="needsTranslation">
                <input
                  type="text"
                  class="el-input__inner"
                  disabled
                  placeholder="Your Text"
                />
              </el-table-column>
              <el-table-column
                v-if="showAsAttributeCheck(property)"
              ></el-table-column>
              <el-table-column width="160"></el-table-column>
            </UiTable>
          </div>
        </div>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import Vue from 'vue';
import { debounce } from 'lodash';
import { Message } from 'element-ui';
import { mapState, mapGetters } from 'vuex';
import { propertyMetadataName } from '../../domain/model/productMetadata';
import LanguageSelector from '../Translation/LanguageSelector';
import PropertyMetadataOperation from './PropertyMetadataOperation';
import IccAnswersSelector from './IccAnswersSelector.vue';
import { languageLabel } from '../../helpers/translations';
import uniqSorter from '../../helpers/uniqSorter';
import { testInterval } from '../../helpers/testInterval';
import I18nMixin from '../I18nMixin';

const defaultEntry = {
  displayName: '',
  key: 'yourCustomKey',
  previousKey: 'yourCustomKey',
  value: null,
  placeholder: 'yourCustomKey',
  isNew: true
};

export default {
  name: 'PropertyMetadata',
  components: {
    LanguageSelector,
    PropertyMetadataOperation,
    IccAnswersSelector
  },
  mixins: [I18nMixin],
  props: {
    inheritFromCsp: {
      type: Boolean,
      default: () => false
    },
    defaultRelatedProperties: {
      type: Array,
      default: () => []
    },
    editedRow: {
      type: Object,
      default: null
    },
    isCreatingProperty: {
      type: Boolean
    },
    property: {
      type: Object,
      required: true
    },
    selectableTerms: {
      type: Function,
      required: true
    },
    isDimension: {
      type: Boolean,
      default: false
    },
    defaultPropertyMetadata: {
      type: Array,
      default: () => {
        return [];
      }
    },
    isReadOnly: { type: Boolean, default: false },
    tooltip: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  data: () => ({
    roundings: [
      { label: 'Thousandths (0.001)', key: 3 },
      { label: 'Hundreths (0.01)', key: 2 },
      { label: 'Tenths (0.1)', key: 1 }
    ],
    Truncations: [
      { label: 'Thousandths (0.001)', key: 3 },
      { label: 'Hundreths (0.01)', key: 2 },
      { label: 'Tenths (0.1)', key: 1 }
    ],
    editedEntry: { key: null, entry: null },
    rangeDisabled: false,
    isRangeValid: false,
    metadata: [],
    suggestions: [],
    refreshOperations: 0,
    showAddRow: true,
    metaToShow: ['displayName', 'question', 'defaultValue'],
    booleanMetaToExclude: ['inherit'],
    booleanMetaToShow: [
      'externalData',
      'private',
      'kyc',
      'longTextBox',
      'icc',
      'bod'
    ],
    numericalMetaToShow: ['iccPriority'],
    calculationMetaToShow: ['expression'], // use same pattern in case of multiple expression variant in future..
    ATTRIBUTE_VALUE: '$ATTRIBUTE_VALUE',
    rangeNumberErrorMessage: null
  }),
  computed: {
    ...mapState('auth', ['tenant']),
    ...mapGetters('auth', ['isAdmin']),
    needsTranslation() {
      return this.targetLanguage && this.targetLanguage !== this.sourceLanguage;
    },
    sourceLanguageColumnLabel() {
      return languageLabel(this.sourceLanguage);
    },
    targetLanguageColumnLabel() {
      return this.targetLanguage ? languageLabel(this.targetLanguage) : null;
    },
    tooltipDisabled() {
      return !(this.tooltip && this.tooltip.message);
    },
    initialMetadata() {
      return this.metadata.filter(
        (m) =>
          (this.metaToShow.includes(m.key) || m.value) &&
          !this.booleanMetaToShow.includes(m.key) &&
          !this.booleanMetaToExclude.includes(m.key) &&
          !this.calculationMetaToShow.includes(m.key)
      );
    },
    calculationMetadata() {
      const expression = this.metadata.find((m) => m.key === 'expression');
      return expression;
    },
    booleanMetadata() {
      return this.metadata.filter((m) =>
        this.booleanMetaToShow.includes(m.key)
      );
    },
    availableMetadata() {
      const unavailable = this.initialMetadata.map((v) => v.key);

      const available = this.metadata
        .filter(
          (m) =>
            !unavailable.includes(m.key) &&
            !this.booleanMetaToShow.includes(m.key) &&
            !this.calculationMetaToShow.includes(m.key) &&
            this.rangeMetadataToShow(m.key) &&
            this.conditionalInputMetadataToShow(m.key)
        )
        .map(({ displayName, key }) => ({
          label: displayName,
          displayName,
          key
        }));

      available.push({
        label: this.$t('product.custom_metadata'),
        displayName: this.$t('product.custom_metadata'),
        key: 'custom'
      });

      return available;
    },
    disabledToggleIsAttribute() {
      return (
        this.editedEntry.entry &&
        ['', 'yourCustomKey'].includes(this.editedEntry.entry.key)
      );
    },
    isCalculationInput() {
      return this.property.__type === 'COMPUTED';
    }
  },
  watch: {
    editedEntry() {
      if (
        this.editedEntry.entry &&
        this.editedEntry.entry.key === 'numberRange'
      ) {
        this.rangeDisabled = this.editedEntry.entry.value.disabled;
      }
    }
  },

  mounted() {
    this.loadSuggestions();
    this.metadata = this.property.metadata.map((m) => {
      if (this.isAttribute(m)) {
        // eslint-disable-next-line no-param-reassign
        m.isAttribute = true;
      }
      return {
        ...m,
        previousKey: m.key
      };
    });
    if (this.isReadOnly) {
      this.showAddRow = false;
    }
  },
  methods: {
    validateRangInputBeforeSave(currentValue) {
      const metaInputRange = this.metadata.find((m) => m.key === 'numberRange');

      if (
        this.editedEntry.key === 'defaultValue' &&
        metaInputRange &&
        metaInputRange.value &&
        metaInputRange.value.minimum
      ) {
        const { status: statusInterval } = testInterval(
          currentValue,
          metaInputRange.value.maximum,
          metaInputRange.value.step,
          metaInputRange.value.disabled
        );

        if (
          !(
            currentValue >= metaInputRange.value.minimum &&
            metaInputRange.value.maximum &&
            metaInputRange.value.maximum !== '' &&
            currentValue <= metaInputRange.value.maximum &&
            statusInterval
          )
        ) {
          if (
            parseFloat(currentValue) !==
              parseFloat(metaInputRange.value.maximum) &&
            parseFloat(currentValue) !==
              parseFloat(metaInputRange.value.minimum)
          ) {
            return false;
          }
        }
      }
      return true;
    },
    filterRangeEntry($e) {
      if ($e.target.value === '') {
        // eslint-disable-next-line no-param-reassign
        // $e.target.value = 0;
      }
    },
    isComputedTruncateRequired(entry) {
      return entry && entry.key === 'truncate';
    },
    isComputedRoundingRequired(entry) {
      return entry && entry.key === 'rounding';
    },
    isInputRangeRequired(entry) {
      return entry && entry.key === 'numberRange';
    },
    isNumerical(entry) {
      return entry && this.numericalMetaToShow.includes(entry.key);
    },
    async loadSuggestions() {
      const suggestions = await this.selectableTerms();
      this.suggestions = uniqSorter(suggestions).map((suggestion) => ({
        key: suggestion.key || suggestion.label || suggestion,
        label: suggestion.label || suggestion
      }));
    },
    currentEditedEntry() {
      return this.editedEntry.entry;
    },
    currentEditedKey() {
      return this.editedEntry.key;
    },
    canEditDisplayName(entry) {
      return (
        !this.isReadOnly &&
        !this.defaultPropertyMetadata.includes(entry.key) &&
        !this.isAttribute(entry)
      );
    },
    canEditDisplayNameByKey(key) {
      return this.canRemoveByKey(key);
    },
    handleDisplayNameChange(value) {
      const currentEntry = this.currentEditedEntry();

      const normalizedValue = value === '' ? '' : propertyMetadataName(value);
      if (!this.canEditDisplayNameByKey(normalizedValue)) {
        Message.error(
          `The current key (${normalizedValue}) is a reserved keyword.`
        );
        return normalizedValue;
      }
      if (this.isTechnical(currentEntry)) {
        this.debouncedUpdateEntryField(['key', 'displayName'], normalizedValue);
      } else {
        this.debouncedUpdateEntryField('displayName', normalizedValue);
      }
      return normalizedValue;
    },
    handleComputeValueChange(entry, value, field) {
      this.edit(entry);
      this.debounceUpdatedEntryComputeField(entry, value, field);
    },
    handleRangeValueChange(entry, value, field) {
      this.debounceUpdatedEntryRangeField(entry, value, field);
    },
    handleValueChange(entry, value, language) {
      const localValue =
        entry.key === 'question' ? value.substring(0, 255) : value;
      if (this.isTechnical(entry)) {
        this.debouncedUpdateEntryField('value', value);
      } else {
        this.debouncedUpdateEntryLocalizedField(
          'value',
          language,
          value,
          localValue
        );
      }
      return localValue;
    },
    debounceUpdatedEntryComputeField: debounce(function updateEntryComputeField(
      entry,
      value,
      field
    ) {
      this.editedEntry.entry.value[field] = value;
      this.refreshOperations += 1;
    },
    600),
    debounceUpdatedEntryRangeField: debounce(function updateEntryRangeField(
      entry,
      value,
      field
    ) {
      if (!this.currentEditedEntry()) return;

      this.editedEntry.entry.value[field] = value;

      const {
        minimum = '',
        maximum = '',
        step = ''
      } = this.editedEntry.entry.value;

      this.rangeNumberErrorMessage = null;

      const { status, code } = testInterval(
        minimum,
        maximum,
        step,
        this.editedEntry.entry.value.disabled
      );

      if (!status) {
        if (
          !(
            code === 'MIN_MAX_RANGE_UNSATISFIED_WITH_GIVEN_STEP' &&
            (!this.editedEntry || this.editedEntry.entry.value.disabled)
          )
        ) {
          this.rangeNumberErrorMessage = this.$t(
            `product._metadata.numberRange.validation.${code}`
          );
        }
      }
      this.isRangeValid = status;
      this.refreshOperations += 1;
    },
    600),
    debouncedUpdateEntryField: debounce(function updateEntryField(
      fieldName,
      value
    ) {
      if (!this.currentEditedEntry()) {
        return;
      }

      if (Array.isArray(fieldName)) {
        fieldName.forEach((f) => {
          this.editedEntry.entry[f] = value;
        });
      } else {
        this.editedEntry.entry[fieldName] = value;
      }
      this.refreshOperations += 1;
    },
    300),
    debouncedUpdateEntryLocalizedField: debounce(
      function updateEntryLocalizedField(
        fieldName,
        language,
        value,
        compareValue = ''
      ) {
        if (compareValue !== '' && value.length !== compareValue.length) {
          Message.error(
            this.$t('properties.metadata-length-exceeded', {
              length: compareValue.length
            })
          );
          // eslint-disable-next-line no-param-reassign
          value = compareValue;
        }
        if (!this.currentEditedEntry()) return;
        if (typeof this.editedEntry.entry[fieldName] !== 'object') {
          this.editedEntry.entry[fieldName] = {};
        }
        this.editedEntry.entry[fieldName][language] = value;
        this.refreshOperations += 1;
      },
      300
    ),
    // Technical metadata can't be translated, so they exist only in the source language
    isTechnical(entry) {
      if (
        entry.isNew ||
        [
          'defaultValue',
          'externalData',
          'otherBehavior',
          'otherKey',
          'private',
          'kyc',
          'expression',
          'longTextBox',
          'unit',
          'icc',
          'iccPriority',
          'iccAnswers',
          'bod'
        ].includes(entry.key)
      )
        return true;
      return (
        !this.isReadOnly && !this.defaultPropertyMetadata.includes(entry.key)
      );
    },
    sourceValue(entry) {
      return this.value(entry, this.sourceLanguage);
    },
    sourceLabel(entry) {
      if (this.isTechnical(entry)) {
        const values = this.getSelectorData(entry);
        const value = values && values.find((v) => v.key === entry.value);
        if (value) {
          return value.label;
        }
      }
      return this.sourceValue(entry);
    },
    sourceLabelForRange(entry, field, asLabel = false) {
      if (field === 'step' && this.rangeDisabled) {
        return this.$t('product._metadata.numberRange.step.disabled');
      }
      if (field === 'step' && !this.rangeDisabled && asLabel) {
        return this.$t('product._metadata.numberRange.step.enabled');
      }
      if (entry.value === null) {
        return 'undefined';
      }
      return `${entry.value[field]}`;
    },
    sourceValueForComputeAttributes(entry, field) {
      if (entry.value) {
        return entry.value[field];
      }
      return '';
    },
    sourceValueForRange(entry, field) {
      if (entry.value) {
        return entry.value[field];
      }
      return '';
    },
    targetValue(entry) {
      return this.value(entry, this.targetLanguage);
    },
    value(entry, language) {
      if (
        this.isTechnical(entry) ||
        this.defaultPropertyMetadata.includes(entry.key) === false
      ) {
        return entry.value;
      }
      if (entry.value) {
        return entry.value[language];
      }
      return this.booleanMetadata.includes(entry.key) ? false : null;
    },
    isEditing(entry) {
      return entry.key === this.currentEditedKey();
    },
    edit(entry) {
      this.showAddRow = false;
      if (this.isReadOnly) {
        return;
      }
      const { key } = entry;
      if (this.currentEditedKey() === key) return;
      this.loadSuggestions();

      this.editedEntry = {
        key,
        entry: this.deepEntryCopy(entry)
      };
    },
    deepEntryCopy(entry) {
      return this.isTechnical(entry)
        ? { ...entry }
        : {
            ...entry,
            value:
              typeof entry.value === 'object' ? { ...entry.value } : entry.value
          };
    },
    parseRangeNumberBeforeSave(currentEntry) {
      if (currentEntry.key === 'numberRange' && !currentEntry.value.minimum) {
        // eslint-disable-next-line no-param-reassign
        currentEntry.value.minimum = 0;
      }
    },
    save() {
      if (!this.hasPendingChange()) {
        this.resetEditedEntry();
        return;
      }

      const currentEntry = this.currentEditedEntry();
      const currentValue = currentEntry.value;

      if (!this.validateRangInputBeforeSave(currentValue)) {
        Message.error(
          this.$t(
            'product._metadata.numberRange.validation.invalidCurrentValueWithinRange'
          )
        );
        return;
      }

      this.parseRangeNumberBeforeSave(currentEntry);

      if (
        this.canRemove() &&
        (currentValue === null ||
          (typeof currentValue === 'string' && !currentValue.trim()))
      ) {
        Message.error(this.$t('product.custom_metadata_empty_val'));
        return;
      }

      const { key, previousKey, isNew = false } = currentEntry;

      if (
        key !== previousKey &&
        this.metadata.filter((m) => m.key === key).length > 0
      ) {
        Message.error(
          this.$t('product.custom_metadata_duplicate_key', { key })
        );
        return;
      }
      // Block key renaming for default metadata
      if (!this.isTechnical(currentEntry)) {
        currentEntry.key = previousKey;
      }
      this.updateEntry(currentEntry);
      this.resetEditedEntry();
      this.emitSignal('save', currentEntry, isNew);
    },
    saveBooleanMeta(entry, val) {
      const isNew = entry.value === null;
      // eslint-disable-next-line no-param-reassign
      entry.value = val ? 'true' : 'false';
      this.emitSignal('save', entry, isNew);
    },
    saveCalculationInput(entry, val = null) {
      const isNew = entry.value === null;
      // eslint-disable-next-line no-param-reassign
      entry.value = val;
      this.emitSignal('save', entry, isNew);
    },
    updateEntry(entry) {
      const e = this.deepEntryCopy(entry);
      delete e.isNew;
      this.metadata = this.metadata.map((m) => {
        if (m.key === e.previousKey) {
          // eslint-disable-next-line no-param-reassign
          delete m.isNew;
          return { ...m, ...e, previousKey: e.key };
        }
        return m;
      });
    },
    hasPendingChange() {
      const nextEntry = this.deepEntryCopy(this.currentEditedEntry());
      let previousEntry = this.metadata
        .filter((m) => m.key === nextEntry.key)
        .pop();

      if (nextEntry.key === 'iccAnswers') {
        return true;
      }

      if (!previousEntry && !['', 'yourCustomKey'].includes(nextEntry.key)) {
        return true;
      }
      if (
        !previousEntry ||
        (previousEntry && previousEntry.key === 'yourCustomKey')
      ) {
        return false;
      }

      previousEntry = this.deepEntryCopy(previousEntry);

      if (!this.isValidChange(nextEntry)) {
        return false;
      }

      // eslint-disable-next-line no-nested-ternary
      return this.isTechnical(previousEntry)
        ? !(
            previousEntry.key === nextEntry.key &&
            previousEntry.displayName === nextEntry.displayName &&
            previousEntry.value === nextEntry.value
          )
        : previousEntry.key === 'numberRange'
        ? this.isRangeValid
        : !(
            previousEntry.key === nextEntry.key &&
            previousEntry.displayName === nextEntry.displayName &&
            previousEntry.value[this.sourceLanguage] ===
              nextEntry.value[this.sourceLanguage] &&
            previousEntry.value[this.targetLanguage] ===
              nextEntry.value[this.targetLanguage]
          ) || true;
    },
    isValidChange(nextEntry) {
      if (
        this.property.__type === 'INPUT' &&
        ['Number', 'MonetaryAmount', 'Monetary'].includes(this.property.type) &&
        nextEntry.key === 'defaultValue'
      ) {
        return !isNaN(nextEntry.value);
      }
      return true;
    },
    resetEditedEntry() {
      this.editedEntry = { key: null, entry: null };
      this.showAddRow = true;
    },
    close(entry) {
      const currentEntry = entry || this.currentEditedEntry();

      this.resetEditedEntry();
      if (currentEntry.key === 'yourCustomKey') {
        this.metadata = this.metadata.filter((m) => m.key !== 'yourCustomKey');
      } else if (currentEntry.value === null) {
        this.metaToShow = this.metaToShow.filter((m) => m !== currentEntry.key);
      }
      if (currentEntry.key === 'numberRange') {
        this.rangeNumberErrorMessage = null;
      }
    },
    selectable(entry) {
      return (
        (this.isDimension && entry.key === 'defaultValue') ||
        (!this.isDimension &&
          entry.key === 'defaultValue' &&
          this.property.type === 'Boolean') ||
        ['otherBehavior', 'unit'].includes(entry.key)
      );
    },
    canRemove() {
      return !this.isReadOnly;
    },
    canRemoveByKey() {
      return !this.isReadOnly;
    },
    remove(entry, uiOnly = false) {
      const currentEntry = entry || this.currentEditedEntry();
      if (!this.canRemove()) return;
      if (!this.defaultPropertyMetadata.includes(currentEntry.key)) {
        this.metadata = this.metadata.filter((m) => m.key !== currentEntry.key);
      }
      if (this.editedEntry.key === currentEntry.key) this.resetEditedEntry();
      if (!uiOnly) this.emitSignal('remove', currentEntry, currentEntry.isNew);
      currentEntry.value = null;
      this.metaToShow = this.metaToShow.filter((m) => m !== currentEntry.key);
    },
    add(toAdd) {
      let metaKey = toAdd;
      if (toAdd === 'custom') {
        this.remove(defaultEntry, true);
        this.metadata.push({ ...defaultEntry, isNew: true }); // todo:check why and how isNew gets deleted
        metaKey = 'yourCustomKey';
      }
      this.metaToShow.push(metaKey);

      const curentMetadata = this.metadata.find((m) => m.key === metaKey);
      this.edit(curentMetadata);

      if (metaKey === 'yourCustomKey' && this.property.__type === 'OUTPUT') {
        this.toggleIsAttribute(curentMetadata, true, true);

        this.initialMetadata.map((m) => {
          if (m.key === 'yourCustomKey') {
            // eslint-disable-next-line no-param-reassign
            m.isAttribute = true;
          }
          return m;
        });
      }
    },
    emitSignal(event, entry, isNew = false) {
      this.$emit(
        event,
        {
          key: entry.key,
          // This is used to detect key renaming
          previousKey: entry.previousKey,
          index: this.property.index,
          value: entry.value
        },
        isNew
      );
    },
    showAsAttributeCheck(property) {
      return this.property.__type === 'OUTPUT' && this.isTechnical(property);
    },
    async toggleRangeDisable(row, checked) {
      this.editedEntry.entry.value.disabled = checked;
      const { minimum, maximum, step } = this.editedEntry.entry.value;
      const { status, code } = testInterval(minimum, maximum, step);

      this.isRangeValid = status;

      if (code === 'MIN_MAX_RANGE_UNSATISFIED_WITH_GIVEN_STEP' && checked) {
        this.isRangeValid = true;
        this.rangeNumberErrorMessage = null;
      } else if (code !== null) {
        this.rangeNumberErrorMessage = this.$t(
          `product._metadata.numberRange.validation.${code}`
        );
      }
      this.refreshOperations += 1;
    },
    toggleIsAttribute(row, checked, isNew = false) {
      if (checked) {
        if (
          !isNew &&
          this.editedEntry.entry &&
          ['', 'yourCustomKey'].includes(this.editedEntry.entry.key)
        ) {
          return;
        }

        this.edit(row);
        this.editedEntry.entry.value = this.ATTRIBUTE_VALUE;

        if (this.editedEntry.entry && !this.editedEntry.entry.isNew) {
          this.save();
        }
      } else {
        Vue.nextTick(() => {
          if (this.editedEntry.entry && this.editedEntry.entry.isNew) {
            this.editedEntry.entry.value = '';
            this.initialMetadata.map((m) => {
              if (
                m.key === 'yourCustomKey' &&
                this.property.__type === 'OUTPUT'
              ) {
                // eslint-disable-next-line no-param-reassign
                delete m.isAttribute;
              }
              return m;
            });
          } else {
            this.remove(row);
          }
        });
      }
    },
    isAttribute(row) {
      return (
        this.showAsAttributeCheck(row) && row.value === this.ATTRIBUTE_VALUE
      );
    },
    getSelectorData(row) {
      if (row.key === 'otherBehavior') {
        return [
          {
            key: 'DEFAULT',
            label: this.$t('product._metadata.otherBehavior.labels.DEFAULT')
          },
          {
            key: 'ALWAYS',
            label: this.$t('product._metadata.otherBehavior.labels.ALWAYS')
          },
          {
            key: 'EXPAND',
            label: this.$t('product._metadata.otherBehavior.labels.EXPAND')
          },
          {
            key: 'ALWAYS+EXPAND',
            label: this.$t(
              'product._metadata.otherBehavior.labels.ALWAYS+EXPAND'
            )
          }
        ];
      }
      return this.suggestions;
    },
    rangeMetadataToShow(key) {
      const allowedTypes = ['monetary', 'monetaryamount', 'number'];
      return (
        (this.isCreatingProperty &&
          allowedTypes.includes(
            this.editedRow.value &&
              this.editedRow.value.type &&
              this.editedRow.value.type.toLowerCase()
          )) ||
        !(
          key === 'numberRange' &&
          !allowedTypes.includes(
            this.property.type && this.property.type.toLowerCase()
          )
        )
      );
    },
    conditionalInputMetadataToShow(key) {
      const allowedTypes = ['monetary', 'monetaryamount', 'number'];
      return (
        (this.isCreatingProperty &&
          allowedTypes.includes(
            this.editedRow.value &&
              this.editedRow.value.type &&
              this.editedRow.value.type.toLowerCase()
          )) ||
        !(
          ['numberRange', 'unit'].includes(key) &&
          !allowedTypes.includes(
            this.property.type && this.property.type.toLowerCase()
          )
        )
      );
    }
  }
};
</script>

<style lang="scss" scoped>
.calculationMetaContainer {
  ::v-deep div {
    background-color: #f9f8f8;
  }
}

.calculation {
  ::v-deep input {
    border-radius: 0;
    border: none;
  }
}
.PropertyMetadata {
  width: 100%;
  max-width: none;
  em {
    opacity: 0.6;
  }

  .collapsibleContainer {
    background-color: #ffffff;
    padding: 1rem 0.8rem;
    border-radius: 0.3rem;
    margin-bottom: 1rem;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.04);
    width: 100%;
    display: block;

    .header {
      position: relative;
      font-size: 1.1rem;
      font-weight: bold;
      border-bottom: 1px solid #ebeef5;
      margin-bottom: 0.3rem;
      cursor: pointer;

      i {
        position: absolute;
        right: 1.2rem;
        font-weight: bold;
        top: 5px;
      }
    }

    ::v-deep .el-table {
      margin-bottom: 0;

      &::before {
        height: 0;
      }

      .ui-table-cell,
      .ui-table-header-cell {
        border-bottom: none;

        .cell {
          div:first-of-type {
            width: 100%;
            word-wrap: normal;
            white-space: normal;
          }
        }
      }

      .el-table--border::after {
        width: 0;
      }
    }

    .ui_table_add_meta {
      ::v-deep .el-table__header-wrapper {
        display: none;
      }
    }

    .booleanMetaContainer {
      padding: 0 1rem;
      display: flex;
      flex-flow: row nowrap;
      justify-content: space-around;

      ::v-deep .el-form {
        width: 100%;

        .el-form-item__label {
          font-size: 1.2em;
          text-align: left;
          white-space: nowrap;
        }
      }
    }
  }

  .metaLabel {
    padding: 0 1rem;
    font-size: 14px;
    font-weight: bold;
  }

  .UiButton {
    margin: 0 1rem 0.5rem 0;
  }
  .error,
  .labelRangeDisabled {
    color: red;
  }
  .labelRangeEnabled {
    color: green;
  }
  .disabledlabel {
    color: green;
  }
  .valueInputRange {
    max-width: 160px;
    flex: 1;
  }
  .labelInputRange {
    min-width: 80px;
    flex: 1;
  }
}
</style>
