/**
 * Copyright 2020 AXA Group Operations S.A.
 *
 * Licensed under the Apache License, Version 2.0 (the "License")
 * you may not use this file except in compliance with the License
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import types from './productProperty.types';

const removePropertyByName = (state, name) => {
  state.properties = state.properties.filter((p) => p.name !== name);
  return state;
};

const addProperty = (state, property) => {
  state.properties.push(property);
  return state;
};

const replacePropertyByName = (state, propertyName, property) => {
  state = removePropertyByName(state, propertyName);
  return addProperty(state, property);
};

export default {
  // CREATE
  [types.CREATE_PRODUCT_PROPERTY_REQUEST](state) {
    state.isProcessing = true;
  },
  [types.CREATE_PRODUCT_PROPERTY_SUCCESS](state, { property }) {
    state.isProcessing = false;
    state = addProperty(state, Object.freeze(property));
  },
  [types.CREATE_PRODUCT_PROPERTY_ERROR](state) {
    state.isProcessing = false;
  },
  // DELETE
  [types.DELETE_PRODUCT_PROPERTY_REQUEST](state) {
    state.isProcessing = true;
  },
  [types.DELETE_PRODUCT_PROPERTY_SUCCESS](state, name) {
    state.isProcessing = false;
    state = removePropertyByName(state, name);
  },
  [types.DELETE_PRODUCT_PROPERTY_ERROR](state) {
    state.isProcessing = false;
  },
  // UPDATE
  [types.UPDATE_PRODUCT_PROPERTY_REQUEST](state) {
    state.isProcessing = true;
  },
  [types.UPDATE_PRODUCT_PROPERTY_SUCCESS](state, { propertyName, property }) {
    state.isProcessing = false;
    state = replacePropertyByName(state, propertyName, Object.freeze(property));
  },
  [types.UPDATE_PRODUCT_PROPERTY_ERROR](state) {
    state.isProcessing = false;
  },
  // FETCH
  [types.FETCH_PRODUCT_PROPERTIES_REQUEST](state) {
    state.isLoading = true;
    state.properties = [];
  },
  [types.FETCH_PRODUCT_PROPERTIES_SUCCESS](state, { properties }) {
    state.properties = properties.map(Object.freeze);
    state.isLoading = false;
  },
  [types.FETCH_PRODUCT_PROPERTIES_ERROR](state) {
    state.properties = [];
    state.isLoading = false;
  },
  // DIMENSIONS
  [types.FETCH_DIMENSIONS_REQUEST](state) {
    state.isDimensionsLoading = true;
  },
  [types.FETCH_DIMENSIONS_SUCCESS](state, { dimensions }) {
    state.dimensions = Object.freeze(dimensions);
    state.isDimensionsLoading = false;
  },
  [types.FETCH_DIMENSIONS_ERROR](state) {
    state.dimensions = Object.freeze([]);
    state.isDimensionsLoading = false;
  }
};
