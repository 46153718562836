/**
 * Copyright 2020 AXA Group Operations S.A.
 *
 * Licensed under the Apache License, Version 2.0 (the "License")
 * you may not use this file except in compliance with the License
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import DOMPurify from 'dompurify';
import Answer from './answer.model';

// Possible input configuration
// "input-maxlength": 123
// "input-mask": "########",
// "input-maskplaceholder": "012345678"

/**
 * Safely trim values that might or might not be strings
 * @param {*} value
 * @returns {string}
 */
const safelyTrim = (value) => {
  if (typeof value === 'string') {
    return value.trim();
  }
  return ''; // Fall back to empty string if value is not a string (e.g. object, undefined)
};

const extractText = (question) => {
  if (question.text) {
    // This is the value from a previous question already instantiated
    return question.text;
  }

  if (question.metadata) {
    // This should be the value correctly negotiated and parsed by the endpoint
    // first get "question" metadata field, then get "displayName"
    const metaQuestion = question.metadata.find(
      (meta) => meta.key === 'question'
    );
    if (metaQuestion) {
      const trimmedMetaQuestion = safelyTrim(metaQuestion.value);
      if (trimmedMetaQuestion !== '') {
        return trimmedMetaQuestion;
      }
    }
    const metaDisplayName = question.metadata.find(
      (meta) => meta.key === 'displayName'
    );
    if (metaDisplayName) {
      const trimmedMetaDisplayName = safelyTrim(metaDisplayName.value);
      if (trimmedMetaDisplayName !== '') {
        return trimmedMetaDisplayName;
      }
    }
  }

  // Last case scenario : fallback on ID
  return question.id;
};

const extractDisplayName = (question) => {
  if (question.metadata) {
    const metaDisplayName = question.metadata.find(
      (meta) => meta.key === 'displayName'
    );
    if (metaDisplayName) {
      const trimmedMetaDisplayName = safelyTrim(metaDisplayName.value);
      if (trimmedMetaDisplayName !== '') {
        return trimmedMetaDisplayName;
      }
    }
  }

  // Last case scenario : fallback on ID
  return question.id;
};

export default class Question {
  constructor(question, locale, lastPriority = 0) {
    this.id = question.id;
    this.type = question.type;
    this.text = extractText(question);
    this.displayName = extractDisplayName(question);
    this.response = question.response;
    this.external_references = question.external_references;
    this.defaultValue = question.defaultValue || question.default_value;
    this.answers = [];
    this.possibleAnswers = [];
    this.required = question.required ?? true;
    this.requiredByComputeMatch = false;
    if (question.priority !== undefined) {
      this.priority = question.priority + lastPriority + 1;
    }
    this.metadata = [];
    this.private = false;
    if (question.metadata) {
      this.metadata = question.metadata;
      const privateQuestion = question.metadata.find(
        (meta) => meta.key === 'private'
      );
      if (
        privateQuestion &&
        privateQuestion.value &&
        privateQuestion.value === 'true'
      ) {
        this.private = true;
      }
    }
    this.allowMultiplePerClaim = question.allowMultiplePerClaim ?? false;

    if (question.lineRef) {
      this.lineRef = question.lineRef;
    } else {
      this.lineRef = this.allowMultiplePerClaim ? 'root' : undefined;
    }
    this.root = '';
    this.loading = false;
    this.submitted = false;
  }

  getMeta(key) {
    if (this.metadata && this.metadata.length) {
      const metaEntry = this.metadata.find((meta) => meta.key === key);
      if (metaEntry) {
        return metaEntry.value;
      }
    }
    return undefined;
  }

  update(question, locale, lastPriority = 0) {
    this.external_references = question.external_references;
    if (question.metadata) {
      this.metadata = question.metadata;
    }
    this.text = extractText(question);
    this.displayName = extractDisplayName(question);

    if (question.lineRef) {
      this.lineRef = question.lineRef;
    }
    if (
      question.priority >= 0 &&
      !question.response &&
      (!Array.isArray(question.response) || !question.response.length)
    ) {
      this.priority = question.priority + lastPriority + 1;
    }
  }

  fullId() {
    return this.allowMultiplePerClaim ? `${this.lineRef}-${this.id}` : this.id;
  }

  setLoading(state) {
    this.loading = state;
  }

  respond(answer, locale) {
    if (this.allowMultiplePerClaim) {
      let answers = answer;
      if (!Array.isArray(answer)) {
        answers = [answer];
      }
      const mappedAnswers = answers
        .map((a) => this.makeAnswer(a, locale))
        .filter((a) => a);
      if (mappedAnswers.length > 0) {
        this.response = mappedAnswers;
        return true;
      }
    } else {
      let a = answer;
      if (Array.isArray(answer)) {
        // eslint-disable-next-line prefer-destructuring
        a = answer[0];
      }
      this.response = this.makeAnswer(a);
      return true;
    }
    this.response = undefined;
    return true;
  }

  makeAnswer(a, locale) {
    if (a) {
      if (typeof a !== 'object') {
        const response = this.answers.find((ans) => ans.id === a);
        if (response) {
          return response;
        }
        return new Answer(
          {
            id: !Number.isNaN(Number(a)) ? Number(a) : a
          },
          locale
        );
      }
      if (a.id) {
        return new Answer(a, locale);
      }
    }
    return undefined;
  }

  setAllAnswersNotPossible() {
    this.possibleAnswers = [];
  }

  iconColor() {
    if (this.fetching) {
      return 'blue';
    }
    if (this.response !== undefined && this.response !== null) {
      return 'green';
    }
    if (!this.required) {
      return 'lightgrey';
    }
    if (this.type === 'finished') {
      return 'green';
    }
    return 'lightgray';
  }

  // eslint-disable-next-line class-methods-use-this
  mark(terms, nameString = '') {
    const marked = Array(nameString.length).fill(false);
    terms.forEach((term) => {
      const inputIndex = nameString.toLowerCase().indexOf(term);
      if (inputIndex !== -1) {
        for (let i = 0; i < term.length; i += 1) {
          marked[i + inputIndex] = true;
        }
      }
    });
    let result = '';
    let marking = false;
    for (let i = 0; i < nameString.length; i += 1) {
      if (marked[i] && !marking) {
        result += '<mark>';
        marking = true;
      }
      if (!marked[i] && marking) {
        result += '</mark>';
        marking = false;
      }
      result += nameString[i];
    }
    return result;
  }

  html(input = '') {
    return DOMPurify.sanitize(
      this.mark(input.toLowerCase().split(' '), this.text)
    );
  }

  defaultAnswer() {
    if (this.answers) {
      return this.answers.filter((a) => a.id === this.defaultValue)[0];
    }
    return this.defaultValue;
  }

  updateAnswers(answers, locale) {
    const inputAnswers = answers.map((a) => ({
      ...a,
      id: a.id
    }));

    const newAnswers = [
      ...inputAnswers.filter(
        (answer) => !this.answers.map((a) => a.id).includes(answer.id)
      )
    ];

    const oldAnswers = [
      ...this.answers.filter(
        (answer) => !inputAnswers.map((a) => a.id).includes(answer.id)
      )
    ];

    const updatedAnswers = inputAnswers
      .filter((answer) => this.answers.map((a) => a.id).includes(answer.id))
      .map((answer) => {
        const matchingAnswer = this.answers.find((a) => a.id === answer.id);
        if (matchingAnswer) {
          matchingAnswer.update(answer);
          return {
            ...matchingAnswer
          };
        }
        return answer;
      });

    const orderedAnswers = [...newAnswers, ...oldAnswers, ...updatedAnswers]
      .map((a) => new Answer(a, locale))
      .sort((a, b) => {
        if (a.priority && b.priority) {
          if (a.priority !== b.priority) {
            return a.priority - b.priority;
          }
          return a.displayName.toString().localeCompare(b.displayName);
        }
        if (a.priority || b.priority) {
          if (!a.priority) return 1;
          if (!b.priority) return -1;
        }
        return a.displayName.toString().localeCompare(b.displayName);
      });

    this.answers = orderedAnswers;
  }

  apiComputationFormat(line) {
    let value = null;
    if (this.response) {
      if (this.getMeta('custom') === 'KYC') {
        value = '__private';
      } else if (Array.isArray(this.response)) {
        const matchedResponse = this.response.find((r) => {
          return line
            .split('|')
            .some((segment) => segment === `${this.id}/${r.id}`);
        });
        if (matchedResponse) {
          value = matchedResponse.id;
        } else {
          value = this.response.id;
        }
      } else {
        value = this.response.id;
      }
    }
    return {
      id: this.id,
      value
    };
  }

  jsonState() {
    return {
      id: this.id,
      type: this.type,
      text: this.text,
      response: this.response,
      external_references: this.external_references,
      defaultValue: this.defaultValue
    };
  }

  responseDisplay(locale) {
    if (this.response) {
      return new Answer(this.response, locale) || this.response.id;
    }
    return undefined;
  }

  getMask() {
    const metainputMask = this.getMeta('inputMask');
    if (metainputMask) {
      return metainputMask.split(',');
    }
    return undefined;
  }

  getMaskPlaceholder() {
    const metaInputMask =
      this.getMeta('inputMaskPlaceholder') || this.getMeta('inputMask');
    if (metaInputMask) {
      return metaInputMask;
    }
    return undefined;
  }

  getCustomComponentKey() {
    const customComponentKey = this.getMeta('customComponentKey');
    if (customComponentKey) {
      return customComponentKey;
    }
    return undefined;
  }

  formProp() {
    if (this.type === 'lineAmount') {
      return `amount.${this.id}`;
    }
    if (this.allowMultiplePerClaim) {
      return `lines.${this.lineRef}.${this.id}`;
    }
    return `global.${this.id}`;
  }

  setPrivate(value) {
    this.private = value;
  }
}
