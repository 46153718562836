/**
 * Copyright 2020 AXA Group Operations S.A.
 *
 * Licensed under the Apache License, Version 2.0 (the "License")
 * you may not use this file except in compliance with the License
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { mapGetters } from 'vuex';

export default {
  props: {
    ignoreActiveReleases: { type: Boolean, default: false }
  },
  computed: {
    ...mapGetters('productRelease', ['activeRelease', 'activeReleaseUpdatedAt'])
  },
  methods: {
    getGroupByMinorVersion(versions) {
      return Array.from(
        versions.reduce((a, v) => {
          const minor = `${v.version.major}.${v.version.minor}`;
          const patches = versions.filter(
            (i) => minor === `${i.version.major}.${i.version.minor}`
          );
          const mostRecent = patches.sort(
            (i, j) => i.version.patch < j.version.patch
          )[0];
          a.set(minor, {
            ...v,
            mostRecent,
            patches
          });
          return a;
        }, new Map())
      );
    },
    determineProductVersion(product) {
      return product.id !== product.version.latest
        ? product.version.current
        : 'initial';
    }
  }
};
