/**
 * Copyright 2020 AXA Group Operations S.A.
 *
 * Licensed under the Apache License, Version 2.0 (the "License")
 * you may not use this file except in compliance with the License
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import Vue from 'vue';

import {
  Button,
  ButtonGroup,
  Card,
  Col,
  Row,
  Drawer,
  Cascader,
  Select,
  Option,
  OptionGroup,
  Badge,
  Popover,
  Dialog,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  Input,
  InputNumber,
  ColorPicker,
  Switch,
  CheckboxGroup,
  Checkbox,
  Table,
  TableColumn,
  Tabs,
  TabPane,
  Alert,
  Tooltip,
  Form,
  FormItem,
  Radio,
  RadioButton,
  RadioGroup,
  Upload,
  Loading,
  Pagination,
  Autocomplete,
  DatePicker
} from 'element-ui';
import lang from 'element-ui/lib/locale/lang/en';
import locale from 'element-ui/lib/locale';

// configure language
locale.use(lang);

// import components
Vue.component(Button.name, Button);
Vue.component(ButtonGroup.name, ButtonGroup);
Vue.component(Card.name, Card);
Vue.component(Cascader.name, Cascader);
Vue.component(Drawer.name, Drawer);
Vue.component(Select.name, Select);
Vue.component(Col.name, Col);
Vue.component(Row.name, Row);
Vue.component(Option.name, Option);
Vue.component(OptionGroup.name, Option);
Vue.component(Badge.name, Badge);
Vue.component(Popover.name, Popover);
Vue.component(Tooltip.name, Tooltip);
Vue.component(Dialog.name, Dialog);
Vue.component(Dropdown.name, Dropdown);
Vue.component(DropdownMenu.name, DropdownMenu);
Vue.component(DropdownItem.name, DropdownItem);
Vue.component(Input.name, Input);
Vue.component(Autocomplete.name, Autocomplete);
Vue.component(InputNumber.name, InputNumber);
Vue.component(ColorPicker.name, ColorPicker);
Vue.component(Checkbox.name, Checkbox);
Vue.component(CheckboxGroup.name, CheckboxGroup);
Vue.component(Switch.name, Switch);
Vue.component(Table.name, Table);
Vue.component(TableColumn.name, TableColumn);
Vue.component(Tabs.name, Tabs);
Vue.component(TabPane.name, TabPane);
Vue.component(Alert.name, Alert);
Vue.component(Form.name, Form);
Vue.component(FormItem.name, FormItem);
Vue.component(Radio.name, Radio);
Vue.component(RadioButton.name, RadioButton);
Vue.component(RadioGroup.name, RadioGroup);
Vue.component(Upload.name, Upload);
Vue.component(Pagination.name, Pagination);
Vue.component(DatePicker.name, DatePicker);
Vue.use(Loading.directive);
