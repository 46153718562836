import { getTenant } from '../../helper';
import { indexingClient } from '../../../api/searchClient';

const getInitialState = () => ({
  isIndexing: {}
});

export default {
  namespaced: true,
  state: getInitialState(),
  actions: {
    reset: ({ commit }) => commit('RESET'),
    index: async ({ commit, state, rootState }, productId) => {
      const tenant = getTenant(rootState);
      if (state[productId]) return;
      commit('INDEX_STARTED', productId);
      try {
        await indexingClient(tenant, productId, false);
        commit('INDEX_SUCCEED', productId);
      } catch (error) {
        commit('INDEX_FAILED', productId);
      }
    }
  },
  mutations: {
    RESET: (state) => Object.assign(state, getInitialState()),
    INDEX_STARTED: (state, productId) => {
      state.isIndexing = {
        ...state.isIndexing,
        [productId]: true
      };
    },
    INDEX_SUCCEED: (state, productId) => {
      state.isIndexing = {
        ...state.isIndexing,
        [productId]: false
      };
    },
    INDEX_FAILED: (state, productId) => {
      state.isIndexing = {
        ...state.isIndexing,
        [productId]: false
      };
    }
  }
};
