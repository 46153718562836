/**
 * Copyright 2020 AXA Group Operations S.A.
 *
 * Licensed under the Apache License, Version 2.0 (the "License")
 * you may not use this file except in compliance with the License
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

export const name = 'Authoring Tool';

export const colors = {
  darkGreen: '#83AF9B',
  red: '#F07662',
  blue: '#00ADC6',
  green: '#9FD9B4',
  purple: '#9190AC',
  teal: '#027180',
  apache: '#DDBE65',
  tosca: '#914146'
};
