/**
 * Copyright 2020 AXA Group Operations S.A.
 *
 * Licensed under the Apache License, Version 2.0 (the "License")
 * you may not use this file except in compliance with the License
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import Vue from 'vue';
import actions from './definitions.actions';
import types from './definitions.types';
import { getters } from './definitions.getters';

const getInitialState = () => ({
  definitions: [],
  definitionItemsLoading: {},
  definitionItems: [],
  isBusy: false,
  error: null,
  definitionListValidations: {}
});

const mutations = {
  [types.RESET](state) {
    Object.assign(state, getInitialState());
  },
  [types.OPERATION_START](state) {
    state.isBusy = true;
    state.error = null;
  },
  [types.OPERATION_SUCCESS](state) {
    state.isBusy = false;
    state.error = null;
  },
  [types.OPERATION_FAILURE](state, error) {
    state.isBusy = false;
    state.error = error;
  },
  [types.SET_DEFINITION_ITEMS_REQUEST](state, { definitionId }) {
    state.definitionItemsLoading[definitionId] = true;
  },
  [types.SET_DEFINITION_ITEMS_SUCCESS](state, { definitionId, items }) {
    state.definitionItems[definitionId] = Object.freeze(items);
    state.definitionItemsLoading[definitionId] = false;
  },
  [types.SET_DEFINITION_ITEMS_ERROR](state, { definitionId }) {
    state.definitionItemsLoading[definitionId] = false;
  },
  [types.LOAD_STORE](state, { lists }) {
    state.definitions = lists;
  },
  [types.SET_DEFINITIONLIST_VALIDATION](
    state,
    { definitionListId, validation }
  ) {
    Vue.set(state.definitionListValidations, definitionListId, {
      ...validation
    });
  }
};

export default {
  namespaced: true,
  state: getInitialState(),
  getters,
  actions,
  mutations
};
