/**
 * Copyright 2020 AXA Group Operations S.A.
 *
 * Licensed under the Apache License, Version 2.0 (the "License")
 * you may not use this file except in compliance with the License
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { mapActions, mapState, mapGetters } from 'vuex';
import { Message } from 'element-ui';
import moment from 'moment';

export default {
  data: () => ({
    tokenName: '',
    MAX_TOKENS_PER_USER: 3
  }),
  computed: {
    ...mapState('tokens', ['tokens']),
    ...mapState('auth', ['tenant']),
    ...mapGetters('auth', {user: 'getUser'}),
  },
  methods: {
    ...mapActions('tokens', ['fetchTokens', 'createToken', 'deleteToken']),
    safeTokenName() {
      const unsafeUriRegEx = /[:/?@$&',;=\s]/g;
      if (unsafeUriRegEx.test(this.tokenName)) {
        return false;
      }
      return true;
    },
    async generateToken() {
      if (this.tokenName === '' || !this.safeTokenName()) {
        Message.error(this.$t('token.errors.invalid'));
        return;
      }
      for (let t = 0; t < this.tokens.length; t += 1) {
        if (this.tokenName === this.tokens[t].name) {
          Message.error(this.$t('token.errors.exists'));
          return;
        }
      }
      if (this.tokens.length === this.MAX_TOKENS_PER_USER) {
        Message.error(
          this.$t('token.errors.max', { limit: this.MAX_TOKENS_PER_USER })
        );
        return;
      }

      const currentTenant = this.user.teams.find(
        (t) => (t.tenant = this.tenant)
      );

      if (!currentTenant) {
        throw new Error(`No tenant found`);
      }

      const token = await this.createToken({
        name: this.tokenName,
        tenant: currentTenant.slug,
        user_id: this.user.id
      });
      if (token && token.hash) {
        Message.success('Token successfully added');
        this.fetchTokens(this.tenant && this.tenant.slug);
      } else {
        Message.error('There was an error with the token creation');
      }
      this.tokenName = '';
    },
    modified(token) {
      const then = moment(token.last_used_at);
      const now = moment();
      const old = now.diff(then) > 24 * 3600 * 1000;
      return old ? then.format('lll') : then.fromNow();
    },
    async handleRowItemCommand(command, index) {
      switch (command) {
        case 'delete': {
          const token = await this.deleteToken(this.tokens[index].id);
          if (token.status && [200, 201].includes(token.status)) {
            Message.success('Token successfully deleted');
          }
          this.fetchTokens(this.tenant && this.tenant.slug);
          break;
        }
        default:
          throw new Error(`Unknown command ${command}`);
      }
    }
  }
};
