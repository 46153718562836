<template>
  <a
    href="#"
    class="SearchResultItem"
    :title="tooltipContent(item)"
    @click.prevent="$emit('click', item)"
  >
    <ul class="Path Path--Banner">
      <li class="Path__Item Path__Item--Icon">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
          <path d="M0 0h24v24H0V0z" fill="none" />
          <path
            d="M19.43 12.98c.04-.32.07-.64.07-.98 0-.34-.03-.66-.07-.98l2.11-1.65c.19-.15.24-.42.12-.64l-2-3.46a.5.5 0 0 0-.61-.22l-2.49 1c-.52-.4-1.08-.73-1.69-.98l-.38-2.65A.488.488 0 0 0 14 2h-4c-.25 0-.46.18-.49.42l-.38 2.65c-.61.25-1.17.59-1.69.98l-2.49-1a.566.566 0 0 0-.18-.03c-.17 0-.34.09-.43.25l-2 3.46c-.13.22-.07.49.12.64l2.11 1.65c-.04.32-.07.65-.07.98 0 .33.03.66.07.98l-2.11 1.65c-.19.15-.24.42-.12.64l2 3.46a.5.5 0 0 0 .61.22l2.49-1c.52.4 1.08.73 1.69.98l.38 2.65c.03.24.24.42.49.42h4c.25 0 .46-.18.49-.42l.38-2.65c.61-.25 1.17-.59 1.69-.98l2.49 1c.06.02.12.03.18.03.17 0 .34-.09.43-.25l2-3.46c.12-.22.07-.49-.12-.64l-2.11-1.65zm-1.98-1.71c.04.31.05.52.05.73 0 .21-.02.43-.05.73l-.14 1.13.89.7 1.08.84-.7 1.21-1.27-.51-1.04-.42-.9.68c-.43.32-.84.56-1.25.73l-1.06.43-.16 1.13-.2 1.35h-1.4l-.19-1.35-.16-1.13-1.06-.43c-.43-.18-.83-.41-1.23-.71l-.91-.7-1.06.43-1.27.51-.7-1.21 1.08-.84.89-.7-.14-1.13c-.03-.31-.05-.54-.05-.74s.02-.43.05-.73l.14-1.13-.89-.7-1.08-.84.7-1.21 1.27.51 1.04.42.9-.68c.43-.32.84-.56 1.25-.73l1.06-.43.16-1.13.2-1.35h1.39l.19 1.35.16 1.13 1.06.43c.43.18.83.41 1.23.71l.91.7 1.06-.43 1.27-.51.7 1.21-1.07.85-.89.7.14 1.13zM12 8c-2.21 0-4 1.79-4 4s1.79 4 4 4 4-1.79 4-4-1.79-4-4-4zm0 6c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2z"
          />
        </svg>
      </li>
      <li class="Path__Item Path__Item--Capitalize">
        {{ item.path[0] }}
      </li>
      <li class="Path__Item">
        {{ typeMapping(item.type) }}
      </li>
    </ul>
    <div class="Content">
      <div class="Label">
        <span
          class="Label__Color"
          :style="`background-color: ${item.color}`"
          :data-color="item.color"
        ></span>
        <span v-if="item.display_name" class="Label__Value"
          ><span class="Highlight" v-html="highlight(item.display_name)" /><sup
            ><span class="Highlight" v-html="highlight(item.label)" /></sup
        ></span>
        <span v-else class="Label__Value"
          ><span class="Highlight" v-html="highlight(item.label)"
        /></span>
      </div>
      <div class="Action">
        <a class="Action__Link" href="#"
          ><span class="Action__LinkLabel">Go to</span>
          <svg xmlns="http://www.w3.org/2000/svg" height="24" width="24">
            <path d="M0 0h24v24H0V0z" fill="none" />
            <path
              d="m12 4-1.41 1.41L16.17 11H4v2h12.17l-5.58 5.59L12 20l8-8-8-8z"
            />
          </svg>
        </a>
      </div>
    </div>
  </a>
</template>

<script>
import ResultItemMixin from './ResultItemMixin';

export default {
  name: 'ResultItemNode',
  mixins: [ResultItemMixin],
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  methods: {
    tooltipContent() {
      return 'Foo / Bar';
    }
  }
};
</script>

<style lang="scss" scoped>
@import 'ResultItem.scss';
</style>
