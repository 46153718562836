/**
 * Copyright 2020 AXA Group Operations S.A.
 *
 * Licensed under the Apache License, Version 2.0 (the "License")
 * you may not use this file except in compliance with the License
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

export const defaultLanguage = 'en';

export const availableLanguages = [
  { key: 'da', label: 'Dansk', icon: 'flag' },
  { key: 'de', label: 'Deutsch', icon: 'flag' },
  { key: 'en', label: 'English', icon: 'flag' },
  { key: 'es', label: 'Español', icon: 'flag' },
  { key: 'fr', label: 'Français', icon: 'flag' },
  { key: 'it', label: 'Italiano', icon: 'flag' },
  { key: 'nl', label: 'Nederlands', icon: 'flag' },
  { key: 'no', label: 'Norsk', icon: 'flag' },
  { key: 'pl', label: 'Polski', icon: 'flag' },
  { key: 'pt', label: 'Português', icon: 'flag' },
  { key: 'fi', label: 'Suomi', icon: 'flag' },
  { key: 'sv', label: 'Svenska', icon: 'flag' },
  { key: 'el', label: 'Ελληνικά', icon: 'flag' }
];

export const availableLanguageKeys = availableLanguages.map((v) => v.key);

export const isValidLanguageKey = (key) => availableLanguageKeys.includes(key);

export const isValidLanguageKeyOrNull = (key) =>
  key == null || isValidLanguageKey(key);

export const languageLabel = (key) =>
  availableLanguages.filter((l) => l.key === key).pop().label;
