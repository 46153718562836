<template>
  <div
    v-if="maxAllowComplexityReached && type == 'mainCheck'"
    class="complexityWarning"
  >
    {{ $t('product.complexity.warning', { maxAllowed: maxAllowComplexity }) }}
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import { MessageBox } from 'element-ui';

export default {
  name: 'ComplexityChecker',
  props: {
    type: {
      type: String,
      required: true
    }
  },
  data: () => ({
    maxAllowComplexity: 0,
    previousComplexity: 0,
    allowedTypes: ['mainCheck', 'blocker'],
    prevProductId: 0
  }),
  computed: {
    ...mapGetters('complexity', ['maxComplexity']),
    ...mapGetters('product', ['complexity']),
    ...mapState('auth', ['tenant']),
    ...mapState('product', ['product']),
    maxAllowComplexityReached() {
      return (
        this.complexity && this.complexity.complexity > this.maxAllowComplexity
      );
    },
    complexityVal() {
      return this.complexity && this.complexity.complexity !== null
        ? this.complexity.complexity
        : null;
    },
    productId() {
      return this.product.id;
    }
  },
  watch: {
    async complexityVal() {
      this.setComplexityAllowed(!this.maxAllowComplexityReached);
      if (this.complexityVal !== null && this.complexityVal >= 0) {
        this.$emit('complexity-fetched', this.complexity);

        if (this.previousComplexity !== this.complexityVal) {
          this.previousComplexity = this.complexityVal;
          await this.setComplexity({
            productId: this.productId,
            complexity: this.previousComplexity
          });
        }
      }

      if (this.type === 'blocker' && this.maxAllowComplexityReached) {
        this.showBlockPopUp();
      }
    },
    productId() {
      if (this.productId !== this.prevProductId) {
        this.prevProductId = this.productId;
        this.fetchProductComplexity({ tenant: this.tenant });
      }
    }
  },
  async created() {
    if (this.maxComplexity === null) {
      await this.fetchMaxComplexity();
    }
    this.maxAllowComplexity = this.maxComplexity;
  },
  mounted() {
    switch (this.type) {
      case 'mainCheck':
        this.fetchProductComplexity({ tenant: this.tenant });
        break;
      case 'blocker':
        if (this.maxAllowComplexityReached) {
          this.showBlockPopUp();
        }
        break;
      default:
        break;
    }
  },
  methods: {
    ...mapActions('complexity', ['fetchMaxComplexity']),
    ...mapActions('product', [
      'fetchProductComplexity',
      'setComplexityAllowed',
      'setComplexity'
    ]),
    showBlockPopUp() {
      MessageBox.alert(this.$t('product.complexity.blocked'), {
        callback: () =>
          this.$router.push({
            name: 'product-home',
            params: { productId: this.productId },
            query: this.$route.query
          })
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.complexityWarning {
  width: 100%;
  text-align: center;
  background-color: red;
  color: white;
  padding: 8px;
}
</style>
