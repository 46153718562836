<template>
  <div v-selector.view class="app flex-grow-1">
    <ComplexityChecker type="blocker"></ComplexityChecker>

    <div class="row">
      <router-view :product="product"></router-view>
    </div>
  </div>
</template>

<script>
import ComplexityChecker from '../components/Product/ComplexityChecker.vue';

export default {
  name: 'ProductApps',
  components: {
    ComplexityChecker
  },
  props: { product: { type: Object, required: true } }
};
</script>

<style scoped lang="scss">
.row,
.app {
  min-height: calc(100% - 48px);
  margin-top: 12px;
  max-width: 100%;
  display: flex;
  flex-flow: column nowrap;

  .row {
    flex: 1;
  }
}

.close {
  padding: 1em;
}
</style>
