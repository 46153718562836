<template>
  <div v-selector class="loading-spinner is-active">
    <div class="loading-spinner-animation" />
    <div v-if="message" class="loading-spinner-caption">{{ message }}</div>
  </div>
</template>

<script>
export default {
  name: 'Loading',
  props: {
    message: { type: String, default: undefined }
  }
};
</script>
