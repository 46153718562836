/**
 * Copyright 2020 AXA Group Operations S.A.
 *
 * Licensed under the Apache License, Version 2.0 (the "License")
 * you may not use this file except in compliance with the License
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

export const revisionTypes = Object.freeze({
  REVISION: 'REVISION',
  RELEASE: 'RELEASE',
  RELEASE_DELETED: 'RELEASE_DELETED',
  RELEASE_STATUS_CHANGED: 'RELEASE_STATUS_CHANGED',
  IMPORT: 'IMPORT',
  EXPORT: 'EXPORT',
  RESTORE: 'RESTORE',
  LOCK: 'LOCK',
  UNLOCK: 'UNLOCK',
  QUESTIONAIRE_SUBSTITUTION: 'QUESTIONAIRE_SUBSTITUTION'
});

const validRevisions = Object.keys(revisionTypes);

export const isValidRevisionType = (type) => validRevisions.includes(type);

export const assertValidRevisionType = (type) => {
  if (isValidRevisionType(type)) return;
  throw new Error('Invalid revision type');
};
