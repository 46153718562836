<template>
  <div class="ProductReleasesSummary product-summary">
    <div class="title d-flex align-items-center ui_label_title">
      <component :is="icon" class="icon mr-2" />
      <div class="label">{{ $t('product.nav.releases') }}</div>
    </div>
    <div class="ProductReleasesSummary__Block">
      <div
        v-if="hasProductionVersions"
        class="ProductReleasesSummary__BlockSection"
      >
        <h5 class="ProductReleasesSummary__BlockSectionTitle">Production</h5>
        <ProductReleasesSummaryList
          :latest="product.version.latest"
          :product="product"
          :versions="recentProductionVersions"
        />
        <ProductReleasesSummaryLink
          :product="product"
          :count="countProductionVersions"
        />
      </div>

      <div v-if="hasDraftVersions" class="ProductReleasesSummary__BlockSection">
        <h5 class="ProductReleasesSummary__BlockSectionTitle">Draft</h5>
        <ProductReleasesSummaryList
          :latest="product.version.latest"
          :product="product"
          :versions="recentDraftVersions"
        />
        <ProductReleasesSummaryLink
          :product="product"
          :count="countDraftVersions"
        />
      </div>

      <div
        v-if="hasExpiredVersions"
        class="ProductReleasesSummary__BlockSection"
      >
        <h5 class="ProductReleasesSummary__BlockSectionTitle">Expired</h5>
        <ProductReleasesSummaryList
          :latest="product.version.latest"
          :product="product"
          :versions="recentExpiredVersions"
        />
        <ProductReleasesSummaryLink
          :product="product"
          :count="countExpiredVersions"
        />
      </div>

      <div v-if="!hasVersions">No release for the current product</div>

      <div class="ProductReleasesSummary__Footer">
        <ProductReleaseLink :product="product" type="all">
          {{ $t('product.nav.all_releases') }}
        </ProductReleaseLink>
      </div>
    </div>
  </div>
</template>

<script>
import ReleasesIcon from '../../../assets/images/releases.svg';
import sortByFields from '../../../helpers/sortByFields';
import ProductLink from '../../Product/ProductLink';
import ProductReleaseLink from '../../Product/ProductReleaseLink';
import ProductSnapshotMixin from '../ProductSnapshotMixin';
import ProductReleasesSummaryLink from './ProductReleasesSummaryLink';
import ProductReleasesSummaryList from './ProductReleasesSummaryList';

const filterRelease = (versions, status) => {
  return versions.filter((v) => v.status === status);
};
export default {
  name: 'ProductReleasesSummary',
  components: {
    ProductReleasesSummaryLink,
    ProductReleaseLink,
    ProductReleasesSummaryList,
    ProductLink,
    ReleasesIcon
  },
  mixins: [ProductSnapshotMixin],
  props: { product: { type: Object, required: true } },
  computed: {
    versions() {
      const releases = [...this.product.version.list];
      return releases.sort(
        sortByFields(['-version.major', '-version.minor', '-version.patch'])
      );
    },
    productionVersions() {
      return filterRelease(this.versions, 'PRODUCTION');
    },
    draftVersions() {
      return filterRelease(this.versions, 'DRAFT');
    },
    expiredVersions() {
      return filterRelease(this.versions, 'EXPIRED');
    },
    recentProductionVersions() {
      return this.countProductionVersions > 4
        ? this.productionVersions.slice(0, 4)
        : this.productionVersions;
    },
    recentDraftVersions() {
      return this.countDraftVersions > 4
        ? this.draftVersions.slice(0, 4)
        : this.draftVersions;
    },
    recentExpiredVersions() {
      return this.countExpiredVersions > 4
        ? this.expiredVersions.slice(0, 4)
        : this.expiredVersions;
    },
    countProductionVersions() {
      return this.productionVersions.length;
    },
    countDraftVersions() {
      return this.draftVersions.length;
    },
    countExpiredVersions() {
      return this.expiredVersions.length;
    },
    hasProductionVersions() {
      return this.countProductionVersions > 0;
    },
    hasDraftVersions() {
      return this.countDraftVersions > 0;
    },
    hasExpiredVersions() {
      return this.countExpiredVersions > 0;
    },
    hasVersions() {
      return this.versions.length > 0;
    },
    icon() {
      return ReleasesIcon;
    }
  }
};
</script>

<style scoped lang="scss">
@import '@axatechlab/assets/scss/_variables';

.title {
  position: relative;
  font-size: 150%;
  .icon {
    display: inline-block;
  }
}

.ProductReleasesSummary {
  &__Block {
    border: 1px solid rgba(43, 48, 52, 0.1);
    border-radius: 10px;
    padding: 1em 1.5em;
    margin-top: 1rem;
  }

  &__BlockSection {
    margin-bottom: 1rem;
  }

  &__BlockSectionTitle {
    font-size: 1.4rem;
  }

  &__Footer {
    display: flex;
    flex-direction: row-reverse;
  }
}
</style>
