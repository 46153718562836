/**
 * Copyright 2020 AXA Group Operations S.A.
 *
 * Licensed under the Apache License, Version 2.0 (the "License")
 * you may not use this file except in compliance with the License
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

export const types = {
  SET_SIDEBAR_VISIBILITY: 'SET_SIDEBAR_VISIBILITY',
  SET_INSPECTOR_VISIBILITY: 'SET_INSPECTOR_VISIBILITY',
  SET_RULE_DISPLAY_TYPE: 'SET_RULE_DISPLAY_TYPE',
  SET_TEST_COVERAGE_MODE: 'SET_TEST_COVERAGE_MODE',
  SET_PRODUCT_CONFIG_ACTIVE_TAB: 'SET_PRODUCT_CONFIG_ACTIVE_TAB',
  FETCH_PRODUCT_REQUEST: 'FETCH_PRODUCT_REQUEST',
  FETCH_PRODUCT_SUCCESS: 'FETCH_PRODUCT_SUCCESS',
  FETCH_PRODUCT_FAILURE: 'FETCH_PRODUCT_FAILURE',
  GET_RELATED_PRODUCT_REQUEST: 'GET_RELATED_PRODUCT_REQUEST',
  GET_RELATED_PRODUCT_SUCCESS: 'GET_RELATED_PRODUCT_SUCCESS',
  GET_RELATED_PRODUCT_ERROR: 'GET_RELATED_PRODUCT_ERROR',
  FETCH_PRODUCT_WARNINGS_REQUEST: 'FETCH_PRODUCT_WARNINGS_REQUEST',
  FETCH_PRODUCT_WARNINGS_SUCCESS: 'FETCH_PRODUCT_WARNINGS_SUCCESS',
  FETCH_PRODUCT_WARNINGS_ERROR: 'FETCH_PRODUCT_WARNINGS_ERROR',
  FETCH_PRODUCT_COMPLEXITY_REQUEST: 'FETCH_PRODUCT_COMPLEXITY_REQUEST',
  FETCH_PRODUCT_COMPLEXITY_SUCCESS: 'FETCH_PRODUCT_COMPLEXITY_SUCCESS',
  FETCH_PRODUCT_COMPLEXITY_ERROR: 'FETCH_PRODUCT_COMPLEXITY_ERROR',
  SET_RULE_ID: 'SET_RULE_ID',
  SET_SHARED_PROPERTIES: 'SET_SHARED_PROPERTIES',
  SET_TECHNICAL_NAME_VALIDATION_ERROR: 'SET_TECHNICAL_NAME_VALIDATION_ERROR',
  SET_TECHNICAL_NAME_VALIDATION_SUCCESS:
    'SET_TECHNICAL_NAME_VALIDATION_SUCCESS',
  SET_REQUEST_WARNINGS: 'SET_REQUEST_WARNINGS',
  TOGGLE_LOCK: 'TOGGLE_LOCK',
  SET_COMPLEXITY_ALLOWED: 'SET_COMPLEXITY_ALLOWED',
  UPLOAD_PDF_REQUEST: 'UPLOAD_PDF_REQUEST',
  UPLOAD_PDF_SUCCESS: 'UPLOAD_PDF_SUCCESS',
  UPLOAD_PDF_ERROR: 'UPLOAD_PDF_ERROR',
  SET_COPILOT_STATUS: 'SET_COPILOT_STATUS',
  SET_EXTRACTED_DIMENSIONS: 'SET_EXTRACTED_DIMENSIONS',
  SET_PRODUCT_EXTRACTING_DIMENSIONS: 'SET_PRODUCT_EXTRACTING_DIMENSIONS',
  RENAME_RULE: 'RENAME_RULE',
  UPDATE_RULE: 'UPDATE_RULE',
  DELETE_RULE: 'DELETE_RULE'
};

export default types;
