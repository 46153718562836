<template>
  <UiDialog
    v-if="currentExportProperties"
    id="exportManager"
    :title="$t('product.export-wizard.title')"
    :visible="$attrs.visible"
    variant="default"
    @close="onClose()"
    @cancel="cancelExport()"
    @confirm="requestExport()"
  >
    <template #dialog-sub-title>
      <div class="header_subtitle">
      {{ (currentExportProperties && currentExportProperties.name) || 'n/a' }}
      <i class="el-icon-arrow-right"></i>
      <strong>{{ $t('product.export-wizard.subtitle') }}</strong>
    </div>
    </template>
    <template #dialog-content>
      <div class="content_subinfo">
        <span>{{ $t('product.export-wizard.productType') }}: </span
        >{{ productType }}<br />
        <span>{{ $t('product.export-wizard.release') }}: </span
        >{{ release || '' }}
      </div>
      <div>
        <el-checkbox v-model="exportSelection.product" disabled>{{
          $t('product.export-wizard.product')
        }}</el-checkbox>
        <el-checkbox v-model="exportSelection.definitionsLists">{{
          $t('product.export-wizard.definitions_lists')
        }}</el-checkbox>
      </div>
    </template>
  </UiDialog>
</template>
<script>
import { Message } from 'element-ui';
import { mapState } from 'vuex';
import * as api from '../api';
import ProductReleaseMixin from './Product/ProductReleaseMixin';

export default {
  name: 'ProductExportManager',
  mixins: [ProductReleaseMixin],
  props: {
    productId: {
      type: Number,
      default: null
    },
    currentExportProperties: {
      type: Object,
      default: null
    }
  },
  data: () => {
    return {
      latest: null,
      currentRelease: null,
      product: null,
      exportSelection: {
        product: true,
        questionnaires: true,
        definitionsLists: true
      }
    };
  },

  computed: {
    ...mapState('productRelease', ['activeReleases']),
    release() {
      return this.currentRelease ? this.currentRelease[1] : 'Initial';
    },
    productType() {
      const type =
        (this.currentExportProperties && this.currentExportProperties.type) ||
        null;
      if (type) {
        return type.replace('SHADOW_', '');
      }
      return null;
    }
  },
  watch: {
    productId: {
      async handler(val) {
        this.currentRelease = this.activeRelease(val, true);
      },
      immediate: true
    },

    '$attrs.visible': {
      async handler(newVal) {
        if (newVal) {
          this.currentRelease = this.activeRelease(this.productId, true);
        }
      }
    }
  },
  methods: {
    onClose() {
      this.$emit('close');
    },
    cancelExport() {
      this.onClose();
    },
    requestExport() {
      const productIdExport =
        (this.currentRelease && this.currentRelease[0]) || this.productId;
      try {
        api.exportProduct(productIdExport, this.exportSelection);
        this.onClose();
      } catch (error) {
        Message.error(this.$t('product.export-wizard.failed-exportfile'));
      }
    }
  }
};
</script>
<style scoped lang="scss">
@import "@axatechlab/assets/scss/_variables";
.content_subinfo {
  margin: 20px auto;
  span {
    font-weight: bold;
    width: 66px;
    display: inline-block;
  }
}
</style>
