/**
 * Copyright 2020 AXA Group Operations S.A.
 *
 * Licensed under the Apache License, Version 2.0 (the "License")
 * you may not use this file except in compliance with the License
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import actions, { types } from './team.actions';
import getters from './team.getters';

const getInitialState = () => ({
  teams: undefined,
  tenants: undefined,
  isLoading: false,
  error: undefined,
  previousActiveTab: undefined
});

const mutations = {
  [types.SET_TEAMS](state, teams) {
    state.teams = teams;
  },
  [types.SET_TENANTS](state, tenants) {
    state.tenants = tenants;
  },
  [types.SET_LOADING](state, loading) {
    state.isLoading = loading;
  },
  [types.SET_ERROR](state, error) {
    state.error = error;
  },
  [types.SET_PREVIOUS_ACTIVE_TAB](state, tab) {
    state.previousActiveTab = tab;
  }
};

export default {
  namespaced: true,
  state: getInitialState(),
  getters,
  actions,
  mutations
};
