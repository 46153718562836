<template>
  <div :class="'rules-editor-toolbar' + (visible ? '' : ' hidden')">
    <i
      v-if="isTable"
      :class="ruleDisplayTypeIcon + ' on mr-4'"
      @click="toggleRuleDisplayType"
    ></i>
    <i
      :class="'icon-layout-left' + (sidebarIsVisible ? ' on' : '')"
      @click="toggleSidebarVisibility"
    ></i>
    <i
      v-if="!testCoverageMode"
      :class="'icon-layout-right' + (inspectorIsVisible ? ' on' : '')"
      @click="toggleInspectorVisibility"
    ></i>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';

export default {
  name: 'RulesEditorToolbar',
  props: {
    visible: Boolean,
    readOnly: Boolean,
    ruleId: { type: Number, default: undefined }
  },
  computed: {
    ...mapState('product', [
      'sidebarIsVisible',
      'inspectorIsVisible',
      'testCoverageMode',
      'ruleDisplayType',
      'product'
    ]),
    ...mapGetters('product', ['isReadOnly']),
    ...mapGetters('auth', ['isGuest']),
    isReadOnlyMode() {
      return this.isReadOnly;
    },
    isTable() {
      if (!this.product || !this.product.rules) return false;
      const rule = this.product.rules.find((r) => r.id === this.ruleId);
      return rule && rule.meta_data && rule.meta_data.source === 'XLSX';
    },
    ruleDisplayTypeIcon() {
      return this.ruleDisplayType === 'graph'
        ? 'icon-hierarchy-2'
        : 'icon-layout-module-2';
    }
  },
  methods: {
    ...mapActions('product', [
      'toggleSidebarVisibility',
      'toggleInspectorVisibility',
      'toggleRuleDisplayType'
    ])
  }
};
</script>

<style scoped lang="scss">
.rules-editor-toolbar {
  margin-left: 1rem;
}

.hidden {
  display: none;
}

i {
  font-size: 1.8em;
  margin-left: 0.2em;
  cursor: pointer;
  color: #9492cc;
}

i.on {
  color: #6344f7;
}
</style>
