/**
 * Copyright 2020 AXA Group Operations S.A.
 *
 * Licensed under the Apache License, Version 2.0 (the "License")
 * you may not use this file except in compliance with the License
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import en from './en.json';
import fr from './fr.json';
import de from './de.json';
import it from './it.json';
import es from './es.json';
import nl from './nl.json';
import pt from './pt.json';
import da from './da.json';
import el from './el.json';
import fi from './fi.json';
import no from './no.json';
import pl from './pl.json';
import sv from './sv.json';

export default {
  en,
  da,
  el,
  fi,
  no,
  pl,
  sv,
  fr,
  de,
  it,
  es,
  nl,
  pt
};
