<template>
  <ProductLink name="releases" tag="div" :product="product">
    <a :class="`all ui_link_${type}_releases`">
      <i class="icon-keyboard-arrow-right"></i>
      <slot />
    </a>
  </ProductLink>
</template>

<script>
import { mapGetters } from 'vuex';
import filterObject from '../../helpers/filterObject';
import ProductLink from './ProductLink';

export default {
  name: 'ProductReleaseLink',
  components: { ProductLink },
  props: {
    product: {
      type: Object,
      required: true
    },
    type: {
      type: String,
      default: 'all'
    },
    name: {
      type: String,
      default: 'home'
    },
    query: {
      type: Object,
      default: () => ({})
    },
    params: {
      type: Object,
      default: () => ({})
    },
    hash: {
      type: String,
      default: ''
    }
  },
  computed: {
    ...mapGetters('productRelease', ['activeRelease']),
    version() {
      return this.activeRelease(this.product.version.latest);
    },
    to() {
      return {
        name: `product-${this.name}`,
        query: filterObject(
          {
            ...this.$router.currentRoute.query,
            ...this.query,
            version: this.version
          },
          (v) => v !== undefined
        ),
        hash: this.hash,
        params: {
          ...this.params,
          productId: this.product.version.latest
        }
      };
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@axatechlab/assets/scss/_variables';
.InlineVersionList {
  &__List {
    display: flex;
    flex-wrap: nowrap;
    overflow: hidden;
    width: 100%;
    margin: 0;
    padding: 0;
  }
  &__ListItem {
    display: block;
    list-style: none;
    white-space: nowrap;
    padding: 0;
    margin: 0 0.5rem 0 0;
    &:last-child {
      margin-right: 0;
    }

    .InlineVersionList__VersionNumber {
      color: rgb(96, 98, 102);
    }

    &--Splitted {
      &:before {
        content: '|';
        margin-right: 0.4rem;
        &:last-child {
          &:before {
            content: '';
            margin-right: 0;
          }
        }
      }
    }
  }

  &__VersionNumber {
    white-space: nowrap;
  }

  .el-tooltip__popper::v-deep {
    .InlineVersionList__VersionNumber {
      color: white;
    }
  }
}
</style>
