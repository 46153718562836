import ui from './ui';
import definition from './definition';
import notifications from './notifications';
import product from './product';
import maintenance from './maintenance';
import rule from './rule';

export default {
  ui,
  product,
  definition,
  notifications,
  maintenance,
  rule
};
