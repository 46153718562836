var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('UiInlineEditable',{ref:'editable',attrs:{"label":_vm.stringToShow || _vm.$t('product._metadata.iccAnswers.placeholder'),"value":_vm.stringToShow,"is-editable":!_vm.isReadOnly,"toggle-editing":_vm.toggleEditing},nativeOn:{"click":function($event){return _vm.showPopup()},"keypress":function($event){$event.preventDefault();}}}),_vm._v(" "),_c('el-dialog',{attrs:{"title":_vm.$t('product._metadata.iccAnswers.placeholder').toUpperCase(),"visible":_vm.popupVisible,"custom-class":"iccAnswersDialog"},on:{"close":function($event){return _vm.close()}}},[_c('div',{attrs:{"id":"container"}},[_c('div',{staticClass:"answers"},[_c('h3',[_vm._v(_vm._s(_vm.$t('product._metadata.iccAnswers.toShow')))]),_vm._v(" "),_c('div',_vm._l((_vm.availableItems),function(item){return _c('UiChip',{key:item.key,attrs:{"item":{
              id: item.key,
              text: `${item.label}`,
              selected: false,
              selectable: false
            },"selectable":false,"selected":false,"hide":false},on:{"click":function($event){return _vm.disallowItem(item.key)}}})}),1)]),_vm._v(" "),_c('div',{staticClass:"answers"},[_c('h3',[_vm._v(_vm._s(_vm.$t('product._metadata.iccAnswers.toHide')))]),_vm._v(" "),_c('div',_vm._l((_vm.unavailableItems),function(item){return _c('UiChip',{key:item.key,attrs:{"item":{
              id: item.key,
              text: `${item.label}`,
              selected: false,
              selectable: false
            },"selectable":false,"selected":false,"hide":false},on:{"click":function($event){return _vm.allowItem(item.key)}}})}),1)])]),_vm._v(" "),_c('span',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{on:{"click":function($event){return _vm.close()}}},[_vm._v(_vm._s(_vm.$t('action.cancel')))]),_vm._v(" "),_c('el-button',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.saveValues()}}},[_vm._v(_vm._s(_vm.$t('action.ok'))+"\n      ")])],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }