const valideRoleIdentifierMapping = {
  superAdmin: 1,
  owner: 2,
  admin: 3,
  member: 4,
  guest: 5
};

const validRoleIdentifiers = Object.values(valideRoleIdentifierMapping);

const validateRole = (roleId, callback) => {
  if (!validRoleIdentifiers.includes(roleId))
    throw new Error(`Invalid role (${roleId}) identifier`);
  return callback(roleId);
};

const isSuperAdmin = (roleId) =>
  validateRole(roleId, (r) => r === valideRoleIdentifierMapping.superAdmin);
const isOwner = (roleId) =>
  validateRole(roleId, (r) => r <= valideRoleIdentifierMapping.owner);
const isAdmin = (roleId) =>
  validateRole(roleId, (r) => r <= valideRoleIdentifierMapping.admin);
const isMember = (roleId) =>
  validateRole(roleId, (r) => r <= valideRoleIdentifierMapping.member);
const isGuest = (roleId) =>
  validateRole(roleId, (r) => r === valideRoleIdentifierMapping.guest);

export { isSuperAdmin, isOwner, isAdmin, isMember, isGuest };
