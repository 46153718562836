<template>
  <div class="ProductAppsSummary product-summary">
    <div class="title d-flex align-items-center ui_label_title">
      <component :is="icon" class="icon mr-2" />
      <div class="label">{{ $t('product.applications') }}</div>
    </div>
    <div class="mt-3">
      <div class="d-flex flex-wrap justify-content-start">
        <div
          v-for="app in apps"
          :key="app.link"
          class="framed col-4 mr-2 ui_link_app"
        >
          <ProductLink :name="`app-${app.link}`" :product="product">
            <component :is="app.icon" class="icon mr-2" />
            <div class="app-name">{{ app.label }}</div>
          </ProductLink>
        </div>
      </div>
      <div class="d-flex justify-content-end align-items-center mt-2">
        <ProductLink
          name="apps"
          :product="product"
          class="all ui_link_all_apps"
        >
          <i class="icon-keyboard-arrow-right"></i>
          {{ $t('product.nav.all_applications') }}
        </ProductLink>
      </div>
    </div>
  </div>
</template>

<script>
import AppsIcon from '../assets/images/apps.svg';
import CoverageCheckIcon from '../assets/images/upsell.svg';
import ConsoleIcon from '../assets/images/risk_management.svg';
import { productTypes } from '../const/product';
import ProductLink from './Product/ProductLink';

export default {
  name: 'ProductAppsSummary',
  components: {
    ProductLink,
    AppsIcon,
    CoverageCheckIcon,
    ConsoleIcon
  },
  props: { product: { type: Object, required: true } },
  computed: {
    icon() {
      return AppsIcon;
    },
    apps() {
      return productTypes.QUESTIONNAIRE !== this.product.type
        ? [
            {
              label: 'Coverage Check',
              link: 'cover',
              icon: CoverageCheckIcon
            },
            {
              label: 'API Console',
              link: 'api-console',
              icon: ConsoleIcon
            }
          ]
        : [
            {
              label: 'API Console',
              link: 'api-console',
              icon: ConsoleIcon
            },
            {
              label: 'Diagnostic tree App',
              link: 'diagnostictree',
              icon: AppsIcon
            }
          ];
    }
  }
};
</script>

<style scoped lang="scss">
@import '@axatechlab/assets/scss/_variables';

.title {
  position: relative;
  font-size: 150%;
  .icon {
    display: inline-block;
  }
}

.framed {
  border: 1px solid rgba(43, 48, 52, 0.1);
  border-radius: 10px;
  padding: 1em 1.5em;
  text-align: center;

  .icon {
    font-size: 300%;
  }
}

.all {
  position: relative;
}
</style>
