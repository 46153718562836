<template>
  <div>
    <UiInlineEditable
      :ref="'editable'"
      :label="stringToShow || $t('product._metadata.iccAnswers.placeholder')"
      :value="stringToShow"
      :is-editable="!isReadOnly"
      :toggle-editing="toggleEditing"
      @click.native="showPopup()"
      @keypress.native.prevent
    />
    <el-dialog
      :title="$t('product._metadata.iccAnswers.placeholder').toUpperCase()"
      :visible="popupVisible"
      custom-class="iccAnswersDialog"
      @close="close()"
    >
      <div id="container">
        <div class="answers">
          <h3>{{ $t('product._metadata.iccAnswers.toShow') }}</h3>
          <div>
            <UiChip
              v-for="item in availableItems"
              :key="item.key"
              :item="{
                id: item.key,
                text: `${item.label}`,
                selected: false,
                selectable: false
              }"
              :selectable="false"
              :selected="false"
              :hide="false"
              @click="disallowItem(item.key)"
            />
          </div>
        </div>
        <div class="answers">
          <h3>{{ $t('product._metadata.iccAnswers.toHide') }}</h3>
          <div>
            <UiChip
              v-for="item in unavailableItems"
              :key="item.key"
              :item="{
                id: item.key,
                text: `${item.label}`,
                selected: false,
                selectable: false
              }"
              :selectable="false"
              :selected="false"
              :hide="false"
              @click="allowItem(item.key)"
            />
          </div>
        </div>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button @click="close()">{{ $t('action.cancel') }}</el-button>
        <el-button type="primary" @click="saveValues()"
          >{{ $t('action.ok') }}
        </el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: 'IccAnswersSelector',
  components: {},
  props: {
    iccAnswersMeta: {
      type: Array,
      required: true,
      default: () => []
    },
    iccAnswersItems: {
      type: Array,
      default: () => {
        [];
      }
    },
    toggleEditing: {
      type: Boolean,
      default: true
    },
    isReadOnly: {
      type: Boolean,
      default: true
    }
  },
  data: () => ({
    popupVisible: false,
    valuesToSave: [],
    isSaving: false
  }),
  computed: {
    availableItems() {
      if (!this.iccAnswersMeta) {
        return this.sortItems(this.iccAnswersItems);
      }
      return this.sortItems(
        this.iccAnswersItems.filter((i) => this.valuesToSave.includes(i.key))
      );
    },
    unavailableItems() {
      if (!this.iccAnswersMeta) {
        return [];
      }
      return this.sortItems(
        this.iccAnswersItems.filter((i) => !this.valuesToSave.includes(i.key))
      );
    },
    stringToShow() {
      if (this.iccAnswersItems && this.iccAnswersItems.length > 0) {
        return this.iccAnswersItems
          .filter((e) => this.valuesToSave.includes(e.key))
          .map((e) => e.label)
          .join(', ');
      }
      return '';
    }
  },
  mounted() {
    if (this.iccAnswersMeta && this.iccAnswersMeta.length > 0) {
      this.valuesToSave = [...this.iccAnswersMeta];
    } else {
      this.valuesToSave = this.iccAnswersItems.map((i) => i.key);
    }
  },
  methods: {
    saveValues() {
      this.$emit('updateVal', this.valuesToSave);
      this.$refs.editable.cachedValue = this.stringToShow;
      this.isSaving = true;
      this.close();
      setTimeout(() => {
        this.$emit('save');
      }, 500);
    },
    allowItem(key) {
      this.valuesToSave.push(key);
    },
    disallowItem(key) {
      this.valuesToSave = this.valuesToSave.filter((v) => v !== key);
    },
    close() {
      if (!this.isSaving) {
        this.$refs.editable.cachedValue = this.$refs.editable.originalValue;
        this.valuesToSave = [...this.iccAnswersMeta.value];
        setTimeout(() => {
          this.$emit('cancel');
        }, 500);
      }
      this.popupVisible = false;
    },
    showPopup() {
      this.isSaving = false;
      if (!this.isReadOnly && this.toggleEditing) {
        this.popupVisible = true;
      }
    },
    sortItems(items) {
      return items.sort((a, b) => {
        if (a.label < b.label) return -1;
        if (a.label > b.label) return 1;
        return 0;
      });
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep .iccAnswersDialog {
  max-width: 65%;
}

#container {
  display: flex;

  .answers {
    width: 50%;

    &:first-of-type {
      margin-right: 5px;
    }

    h3 {
      padding-left: 15px;
      font-size: 1rem;
      font-weight: bold;
    }

    > div {
      appearance: textfield;
      background-color: white;
      border: 1px solid darkgray;
      box-shadow: 1px 1px 1px 0 lightgray inset;
      margin-top: 5px;
      padding: 4px 3px;
      overflow-y: scroll;
      height: 300px;
    }

    .ui_chip {
      cursor: pointer;
      user-select: none;
    }
  }
}
</style>
