/**
 * Copyright 2020 AXA Group Operations S.A.
 *
 * Licensed under the Apache License, Version 2.0 (the "License")
 * you may not use this file except in compliance with the License
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import DOMPurify from 'dompurify';
import { ForkNode, ForkNodeFactory } from './index';
import './annotation.css';
import linkStyleForNode from './AnnotationStyle';

export default class AnnotatedForkNode extends ForkNode {
  constructor(graph, parent, model, children) {
    super(graph, parent, model, children);
    this.value.collapsed = false;
  }

  renderCollapseHandle() {}

  renderExpanded(x, y) {
    const frame = super.renderExpanded(x, y);

    // Delete old annotations
    this.div.childNodes.forEach((node) => {
      if (node.className === 'annotationGroup') {
        this.div.removeChild(node);
      }
    });

    this.nodes.forEach((child) => {
      const childExit = child.exitPoint();

      let annotated = false;
      const annotation = document.createElement('div');
      annotation.className = 'annotationGroup clickable';

      const { lineCurveWidth } = this.graph.drawConfiguration;
      annotation.style.left = `${lineCurveWidth * 2 + 4}px`;
      annotation.style.top = `${childExit.y - this.absoluteFrame().y - 10}px`;

      this.div.appendChild(annotation);

      // console.log(childEntry, childExit, entry, this.absoluteFrame())
      annotation.onclick = (e) => {
        e.preventDefault();
        e.stopPropagation();
        this.showAnnotation(annotation, child);
      };

      if (child.value.annotations && child.value.annotations.test) {
        const successes = child.value.annotations.test.success;
        if (successes !== 0) {
          const successSpan = document.createElement('span');
          successSpan.textContent = successes;
          successSpan.className = 'annotationPill success';
          annotation.appendChild(successSpan);
          annotated = true;
        }

        const fails = child.value.annotations.test.fail;
        if (fails !== 0) {
          const failSpan = document.createElement('span');
          failSpan.textContent = fails;
          failSpan.className = 'annotationPill fail';
          annotation.appendChild(failSpan);
          annotated = true;
        }
      }

      if (!annotated) {
        const warningSpan = document.createElement('span');
        warningSpan.className = 'annotationTag fail fa fa-exclamation-triangle';
        warningSpan.style = 'color: orange;';
        annotation.appendChild(warningSpan);
      }
    });

    return frame;
  }

  showAnnotation(annotation, child) {
    // Remove other annotations
    document.querySelectorAll('.pathAnnotation').forEach((node) => {
      node.remove();
    });
    const childExit = child.exitPoint();
    const txt = document.createElement('div');
    txt.className = 'pathAnnotation';
    txt.style.position = 'absolute';
    txt.style.left = annotation.style.left;
    txt.style.top = `${childExit.y - this.absoluteFrame().y + 10}px`;
    if (child.value.annotations && child.value.annotations.test) {
      const annotations = child.value.annotations.test;
      const testResults = [];
      Object.values(annotations.tests).forEach((test) => {
        testResults.push(
          `<span class="annotationPill ${
            test.status ? 'success' : 'fail'
          }">${test.name.replace(/ /g, '\u00a0')}</span>`
        );
      });
      txt.innerHTML = DOMPurify.sanitize(testResults.join(' '));
    } else {
      txt.innerHTML = '<span>Path is not covered by tests.</span>';
    }
    txt.onclick = (eTxt) => {
      txt.remove();
      eTxt.preventDefault();
      eTxt.stopPropagation();
    };
    this.div.appendChild(txt);
  }

  renderConnections(x, y, drawingContext) {
    const entry = this.entryPoint();
    const exit = this.exitPoint();
    let childrenRef = this.nodes.map((child) => {
      if (!child.value.annotations || !child.value.annotations.test)
        return { child, priority: 0 };
      if (child.value.annotations.test.fail > 0) return { child, priority: 2 };
      if (child.value.annotations.test.success > 0)
        return { child, priority: 1 };
      return { child, priority: 0 };
    });
    childrenRef = childrenRef.sort((left, right) => {
      return left.priority - right.priority;
    });

    childrenRef.forEach((ref) => {
      ref.child.renderConnections(x, y, drawingContext);
      const { color } = linkStyleForNode(ref.child);
      const childEntry = ref.child.entryPoint();
      this.graph.renderLine(
        drawingContext,
        { x: entry.x + x, y: entry.y + y },
        { x: childEntry.x + x, y: childEntry.y + y },
        color
      );

      const childExit = ref.child.exitPoint();
      this.graph.renderLine(
        drawingContext,
        { x: childExit.x + x, y: childExit.y + y },
        { x: exit.x + x, y: exit.y + y },
        color
      );
    });
  }
}

export class AnnotatedForkNodeFactory extends ForkNodeFactory {
  nodeFromModel(graph, parent, model) {
    return new AnnotatedForkNode(graph, parent, model);
  }

  nodeWithChildren(graph, parent, children) {
    return new AnnotatedForkNode(graph, parent, null, children);
  }
}
