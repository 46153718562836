<template>
  <Sidebar v-selector :hidden="!sidebarIsVisible">
    <div class="px-4 py-2">
      <div class="d-flex flex-row justify-content-between align-items-center">
        <div class="d-flex align-items-center">
          {{ $t('product.nav.rules') }}
          <b-badge pill variant="info" class="ml-1">{{
            product.rules.length
            }}</b-badge>
        </div>
        <b-button type="text" variant="outline-primary" class="border-0 text-uppercase" style="letter-spacing: 1px"
          :disabled="isReadOnlyMode || product.locked" @click="addRule(null)">
          <b-icon icon="plus" />
          {{ $t('product.add_rule') }}
        </b-button>
      </div>
      <b-form-checkbox v-model="warningsSwitch" switch class="warningsSwitch" @change="toggleWarnings()">
        <small>
          {{ $t('product.show_warnings') }}
        </small>
      </b-form-checkbox>
    </div>
    <ul class="rules_container flex-grow-1 flex-shrink-1 overflow-auto">
      <RulesSidebarItems :unused-rules="unusedRules" :cycle-rules="cycleRules" folder="default"
        @change-made="$emit('change-made')" @update="$emit('update')" @folder-dragover="setDraggedOverFolder"
        @add-rule="addRule($event.value, $event.graph)" />
      <RulesSidebarItems v-for="(folder, index) in ruleFolders" :key="`${folder}-items`" :unused-rules="unusedRules"
        :cycle-rules="cycleRules" class="folder" :folder="folder" @change-made="$emit('change-made')"
        @update="$emit('update')" @folder-dragover="setDraggedOverFolder"
        @add-rule="addRule($event.value, $event.graph)">
        <div slot="folder" :key="`${folder}-label`" class="folder px-4 py-2">
          <div v-b-toggle="`${folder.replaceAll(' ', '_')}-items`" class="sidebar-link-wrapper"
            @click="toggleOpen(folder)">
            <b-icon variant="dark" class="mr-2" :icon="openFolders.includes(folder) ? 'folder2-open' : 'folder'" />
            <a class="text-dark d-flex justify-content-between align-items-center">
              <span>
                {{ folder }}
              </span>
              <div v-if="!isReadOnlyMode && !product.locked" class="actions d-flex ml-auto mr-3">
                <button title="Edit Folder" class="ui_button_edit" @click.stop="editFolderName(folder)">
                  <i class="far fa-edit"></i>
                </button>
                <button title="Delete Folder" class="ui_button_delete" @click.stop="deleteFolder(folder)">
                  <i class="far fa-trash-alt"></i>
                </button>
              </div>
              <b-icon v-if="openFolders.includes(folder)" icon="chevron-up" />
              <b-icon v-else icon="chevron-down" />
            </a>
          </div>
        </div>
      </RulesSidebarItems>
      <RulesSidebarItems :unused-rules="unusedRules" :cycle-rules="cycleRules" folder="root"
        @change-made="$emit('change-made')" @update="$emit('update')" @folder-dragover="setDraggedOverFolder"
        @add-rule="addRule($event.value, $event.graph)" />
    </ul>
  </Sidebar>
</template>

<script>
import { MessageBox, Message } from 'element-ui';
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';
import RulesSidebarItems from './RulesSidebarItems.vue';
import XLSX from 'xlsx';
import * as api from '../api';
import Sidebar from './Sidebar.vue';
import { getDefaultGraph } from '../util';

const importerNames = ['import_standard', 'import_travellers', 'import_light'];

export default {
  name: 'RulesSidebar',
  components: {
    Sidebar,
    RulesSidebarItems
  },
  props: {
    unusedRules: { type: Array, required: true },
    cycleRules: { type: Array, required: true }
  },
  data: () => ({
    toolTipContent: 'This rule is not used.',
    emptyTipContent: 'This rule is empty.',
    cycleTipContent: 'There is a cycle involving this rule.',
    warningsSwitch: false,
    rulesCopy: [],
    openFolders: [],
    draggedOverFolder: undefined
  }),
  computed: {
    ...mapState('product', [
      'sidebarIsVisible',
      'product',
      'warnings',
      'requestWarnings'
    ]),
    ...mapState('auth', ['tenant']),
    ...mapGetters('product', ['isReadOnly']),
    ...mapGetters('auth', ['isGuest']),
    isReadOnlyMode() {
      return this.isReadOnly || this.isGuest(this.product.team.slug);
    },
    defaultRule() {
      let rule = this.product.rules.find(
        (r) => r.id === this.product.main_rule_id
      );
      if (!rule) {
        rule = this.product.rules.find(
          (r) => r.name === 'Cover' || r.name === 'Questions'
        );
      }
      return rule;
    },
    getLatestProductId() {
      return this.product.version.latest;
    },
    ruleFolders() {
      return Array.from(
        new Set(
          this.product.rules
            .filter((rule) => rule.name.includes('/'))
            .map((r) => r.name.split('/')[0])
        )
      ).sort((a, b) => a.localeCompare(b));
    }
  },
  created() {
    this.warningsSwitch = this.requestWarnings;
  },
  unmounted() {
    this.$root.$off('bv::toggle::collapse::true');
  },
  methods: {
    ...mapMutations('product', ['SET_RULE_ID']),
    ...mapActions('product', [
      'fetchProduct',
      'toggleRequestWarnings',
      'refreshProduct',
      'updateRule'
    ]),
    isValidName(name) {
      const specificationKeys = Object.values(this.product.specification)
        .map((spec) => Object.keys(spec))
        .flat();
      if (
        ['OUT', 'ASK', 'IN', 'Cover', ...specificationKeys]
          .map((k) => k.toUpperCase())
          .includes(name.trim().toUpperCase())
      ) {
        return false;
      }
      return true;
    },
    async addRule(ruleName = null, graph = null) {
      let name = null;
      try {
        name =
          ruleName ||
          (
            await MessageBox.prompt(
              this.$t('rule-editor.rules.create_rule_tip'),
              this.$t('rule-editor.rules.create_rule')
            )
          ).value;
      } catch {
        return;
      }

      if (!name || name.trim() === '' || name.split("/").some(segment => segment.trim() === "")) {
        Message.error(this.$t('rule-editor.rules.no_empty_folder_or_rule'));
        return;
      }

      if (!this.isValidName(name)) {
        Message.error(this.$t('rule-editor.rules.reserved_word', { name }));
        return;
      }
      for (let i = 0; i < this.product.rules.length; i += 1) {
        if (this.product.rules[i].name === name.trim()) {
          Message.error(this.$t('rule-editor.rules.rule_exists', { name }));
          return;
        }
      }

      this.$emit('change-made');
      if (graph) {
        this.$emit('addRule', name.trim(), graph);
        return;
      }
      this.$emit('addRule', name.trim());
    },
    importRule(event) {
      const [file] = event.target.files;
      if (!file) return;
      const reader = new FileReader();
      reader.onload = function importFile(e) {
        this.importRuleFromFile(e.target.result, file.name);
      }.bind(this);
      reader.readAsArrayBuffer(file);
    },
    async importRuleFromFile(file, file_name) {
      const workbook = XLSX.read(file, { type: 'array' });
      const { specification } = this.product;
      let importer = null;
      for (let i; i < importerNames.length; i += 1) {
        const name = importerNames[i];
        // eslint-disable-next-line no-await-in-loop
        const candidate = await import(`../importers/${name}`);
        if (
          candidate &&
          candidate.canReadWorkbook(workbook, specification) === true
        ) {
          importer = candidate;
          break;
        }
      }
      if (!importer) {
        this.$refs.fileInput.value = '';
        await MessageBox.alert(
          this.$t('product.import.no_matching_importer_found')
        );
        return;
      }

      const name = workbook.SheetNames[0];
      const sheet = workbook.Sheets[name];
      // eslint-disable-next-line prefer-const
      let { meta_data, graph } = importer.graphFromTable(
        this.product.specification,
        sheet,
        file_name
      );
      graph = getDefaultGraph(name, graph);
      this.$refs.fileInput.value = '';
      const productId = this.product.id;
      const { data } = await api.createRule(productId, name);
      const ruleId = data.id;
      await this.updateRule({
        ruleId,
        rule: { name, productId, graph, meta_data }
      });
      this.refreshProduct({ productId });
      const params = { ...this.$router.currentRoute.params, ruleId };
      this.$router.push({
        name: 'product-rules-edit',
        params,
        query: this.$router.currentRoute.query
      });
      Message.info(this.$t('product.import.success'));
    },
    async toggleLock(rule) {
      try {
        if (rule.is_locked)
          await MessageBox.confirm(
            `Are you sure you wish to unlock “${rule.name}”`
          );
        // eslint-disable-next-line
        await this.updateRule({
          ruleId: rule.id,
          rule: {
            ...rule,
            is_locked: !rule.is_locked
          }
        });
        Message.info(
          `The rule has been ${!rule.is_locked ? 'locked' : 'unlocked'}`
        );
      } catch (err) {
        // eslint-disable-next-line no-console
        console.log(err);
      }
    },
    async editFolderName(folder) {
      if (this.product.locked) {
        Message.error('The product is locked');
        return;
      }


      try {
        const { value } = await MessageBox.prompt(
          this.$t('rule-editor.rules.rename_folder_tip'),
          this.$t('rule-editor.rules.rename_folder'),
          {
            inputValue: folder
          }
        )

        if (value.trim() === "") {
          Message.error('The folder name cannot be empty');
          return;
        }

        this.saveFolderName(value, folder)
      } catch (e) {
        console.log(e)
      }
    },
    async deleteFolder(folderName) {
      const msg = `Are you sure you want to deleter this folder ("${folderName}")`
      try {
        await MessageBox.confirm(msg, 'Folder delete', {
          confirmButtonText: 'Confirm deletion',
        })
      } catch (e) {
        Message.info("Cancelled deleting folder")
        return false;
      }

      const rulesToRename = this.product.rules.filter(rule => rule.name.startsWith(`${folderName}/`));
      const rule = rulesToRename[0];
      let [_, ruleName] = rule.name.split("/")
      const newName = `${ruleName}`
      const rules = this.product.rules.map((rule) => {
        if (rule.graph) this.clearUndoStack(rule.graph);
        if (rule.name.startsWith(`${folderName}/`)) {
          rule.name = rule.name.replace(`${folderName}/`, '')
        }
        return rule;
      });
      await api.renameRule(rules, rule.name, newName);
      this.$emit('change-made');
      this.$emit('update');
      const productId = this.product.id;
      this.fetchProduct({ productId, tenant: this.tenant });
      await Message.info('Folder renamed');
    },
    async saveFolderName(folderName, oldName) {
      const msg = `Are you sure you want to rename this folder ("${oldName}" -> "${folderName}")`
      try {
        await MessageBox.confirm(msg, 'Folder rename', {
          distinguishCancelAndClose: false,
          confirmButtonText: 'Confirm rename',
        })
      } catch (e) {
        Message.info("Cancelled renaming folder")
        return false;
      }
      const rulesToRename = this.product.rules.filter(rule => rule.name.startsWith(`${oldName}/`));
      const rule = rulesToRename[0];
      let [_, ruleName] = rule.name.split("/")
      const newName = `${folderName}/${ruleName}`
      const rules = this.product.rules.map((rule) => {
        if (rule.graph) this.clearUndoStack(rule.graph);
        if (rule.name.startsWith(`${oldName}/`)) {
          rule.name = rule.name.replace(`${oldName}/`, `${folderName}/`)
        }
        return rule;
      });
      await api.renameRule(rules, rule.name, newName);
      this.$emit('change-made');
      this.$emit('update');
      Message.info('Folder renamed');
    },
    setDraggedOverFolder(id) {
      this.draggedOverFolder = id;
      setTimeout(() => {
        if (this.draggedOverFolder === id) {
          this.$root.$emit('bv::toggle::collapse::true', id)
        }
      }, 600);
    },
    clearUndoStack(graph) {
      if ('undoStack' in graph) {
        // eslint-disable-next-line no-param-reassign
        delete graph.undoStack;
      }
      if (graph.children) {
        graph.children.forEach((child) => this.clearUndoStack(child));
      }
    },
    toggleWarnings() {
      this.toggleRequestWarnings();
      const productId = this.product.id;
      this.fetchProduct({ productId, tenant: this.tenant });
    },
    toggleOpen(folder) {
      if (this.openFolders.includes(folder)) {
        this.openFolders = this.openFolders.filter((f) => f !== folder);
      } else {
        this.openFolders.push(folder);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@axatechlab/assets/scss/_variables';

$error-color-dark: #721c24;

h3 {
  position: sticky;
  top: 0;
  background: white;
  z-index: 1;
  margin-bottom: 0;
}

.move-up {
  margin-top: -10px;
}

label[for='file'] {
  font-weight: 600;
  padding: 12px 20px;
  margin: 0;
}

input[type='file'] {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.flipY {
  transform: scaleY(-1);
}

.inline {
  display: inline-block;
}

.actions {
  opacity: 0;
}

.folder:hover {
  a span {
    color: $color-axa-blue;
  }

  .actions {
    opacity: 1;

    button:hover {
      color: $color-axa-blue;
    }
  }
}

button {
  cursor: pointer;
  background: white;
  border: 0;
  color: gray;
  text-transform: uppercase;
  font-size: 12px;
  letter-spacing: 1px;
}

.rules_container {
  margin: 0;
  padding: 0;
  list-style: none;
  overflow: visible;
}

.ui_table_rules {
  max-height: 700px;
  display: flex;
  flex-direction: column;
}

.folder {
  border-top: 1px solid #ddd;
}

.sidebar-link-wrapper {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;

  >a {
    flex: 1;
  }
}

.warnings-count {
  text-align: center;
  font-weight: bold;
  color: #fff;
  background: #ff7979;
  border-radius: 4px;
  width: 20px;
  height: 20px;
  font-size: 12px;
  line-height: 20px;
  position: relative;
  margin-right: 5px;
}
</style>
