<template>
  <nav v-selector class="px-5">
    <ul class="d-flex">
      <li v-for="{
        name,
        label,
        iconClass,
        isBeta,
        subtitle,
        tests = false,
        exact = false
      } in links" :key="name" class="d-flex align-items-stretch">
        <router-link v-if="!hasError() || name !== 'product-tests'" :to="to(name, product, $route)" :exact="exact"
          class="d-flex flex-column align-items-center flex-xl-row d-xl-inline-flex py-0 px-4"
          :class="'ui_link_' + name" :data-cy="'tab-' + name">
          <div v-if="iconClass" :class="'fontIcon mr-lg-2 ' + iconClass" />
          <div class="label d-none d-lg-block" data-cy="product-tab-name">
            <div class="d-flex">
              {{ label }}
              <span v-if="isBeta" class="beta-label">BETA</span>
            </div>
            <div v-if="subtitle" class="subtitle d-none d-xl-block">
              {{ subtitle }}
            </div>
            <div v-if="tests" class="d-none d-xl-block" :class="tests.success === tests.total
              ? 'subtitle'
              : 'subtitle text-danger'
              ">
              <span v-if="
                !hasError() &&
                !stats.isLoading &&
                stats.progress === undefined
              ">{{ tests.success }}/{{ tests.total }}</span>
              <div v-else-if="hasError()" class="text-danger el-icon-refresh" @click.stop="fetchTests()">
                Error!
              </div>
              <div v-else v-selector class="loading-spinner is-active">
                <div class="loading-spinner-animation" />
                {{ stats.success }}/{{ stats.total }} ({{
                  stats.progress || 0
                }}%)
              </div>
            </div>
          </div>
        </router-link>

        <a v-else class="d-flex flex-column align-items-center flex-xl-row d-xl-inline-flex py-0 px-4"
          style="cursor: pointer" :class="'ui_link_' + name" @click="fetchTests()">
          <div v-if="iconClass" :class="'fontIcon mr-lg-2 ' + iconClass" />
          <div class="label d-none d-lg-block" data-cy="product-tab-name">
            <div class="d-flex">
              {{ label }}
              <span v-if="isBeta" class="beta-label">BETA</span>
            </div>
            <div v-if="subtitle" class="subtitle d-none d-xl-block">
              {{ subtitle }}
            </div>
            <div class="d-none d-xl-block subtitle text-danger">
              <div class="text-danger el-icon-refresh">Error!</div>
            </div>
          </div>
        </a>
      </li>
      <div class="search-toolbar">
        <i :class="'icon-search' + (searchSidebarVisible ? ' on' : '')" @click="toggleSidebar"></i>
      </div>
      <!--
        We only show this toolbar when showing the Rule Editor.
        We're setting a key on the component so that it gets updated when the router path changes.
        And we're using a prop to hide the component because using v-if completely removes it when we're not
        on the Rule Editor, which then prevents the :key trick to work.
      -->
      <rules-editor-toolbar :key="'tb-' + $route.path" :visible="showRulesEditorToolbar" :read-only="isReadOnly"
        :rule-id="parseInt($router.currentRoute.params.ruleId)" />
    </ul>
  </nav>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import RulesEditorToolbar from './RulesEditorToolbar.vue';
import { productTypes } from '../const/product';
import { productReleaseLabel } from '../domain/model/productRelease';

export default {
  name: 'ProductNav',
  components: {
    RulesEditorToolbar
  },
  props: {
    product: {
      required: true,
      type: Object
    },
    stats: {
      required: true,
      type: Object
    },
    productType: {
      required: true,
      type: String
    }
  },
  data() {
    return {
      searchSidebarIsVisible: false
    };
  },
  computed: {
    ...mapState('uiProductSearch', ['searchSidebarVisible']),
    isReadOnly() {
      if (!this.product) return false;
      const { ruleId } = this.$router.currentRoute.params;
      const filtered = this.product.rules.filter((r) => r.id === ruleId);
      return filtered.length ? !!filtered[0].is_locked : false;
    },
    productVersionLabel() {
      const { currentVersion } = this.product;
      return productReleaseLabel(currentVersion);
    },
    links() {
      return [
        {
          label: this.$t('product.nav.overview'),
          name: `product-home`,
          iconClass: 'icon-view-1',
          exact: true
        },
        {
          label: this.$t('product.nav.rules'),
          name: `product-rules-index`,
          iconClass: 'icon-hierarchy-2'
        },
        {
          label: this.$t('product.nav.terms'),
          name: `product-terms-index`,
          iconClass: 'icon-layout-module'
        },
        {
          label: this.$t('product.nav.configuration'),
          name: `product-configuration`,
          iconClass: 'icon-cog-circle'
        },
        {
          label: this.$t('product.nav.releases'),
          name: `product-releases`,
          iconClass: 'icon-tags-double-1',
          hide: () =>
            this.product.source === 'import_light' ||
            (!this.isProduct(this.product) && !this.isQuestionnaire()),
          subtitle: this.productVersionLabel,
          isBeta: false
        },
        {
          label: this.$t('product.nav.tests'),
          name: `product-tests`,
          iconClass: 'icon-checklist',
          isBeta: false,
          hide: () =>
            this.product.source === 'import_light' || this.isQuestionnaire(),
          tests: this.stats
        },
        {
          label: this.$t('product.nav.applications'),
          name: 'product-apps',
          hide: () => this.product.source === 'import_light',
          iconClass: 'icon-app-window-layout'
        }
      ].filter((link) => {
        return !link.hide || !link.hide();
      });
    },
    error() {
      return this.stats.error;
    },
    showRulesEditorToolbar() {
      return this.$router.currentRoute.name === 'product-rules-edit';
    }
  },
  methods: {
    ...mapActions('uiProductSearch', ['toggleSidebar']),
    ...mapActions({ fetchTests: 'productTests/fetch' }),
    toggleSearchSidebarVisibility() { },
    isQuestionnaire() {
      return [
        productTypes.QUESTIONNAIRE,
        productTypes.SHADOW_QUESTIONNAIRE
      ].includes(this.productType);
    },
    isProduct(product) {
      return [productTypes.PRODUCT, productTypes.SHADOW_PRODUCT].includes(
        product.type
      );
    },
    to(name, product, $route) {
      return {
        name,
        params: { productId: product.version.latest },
        query: $route.query
      };
    },
    hasError() {
      return !!this.stats.error;
    }
  }
};
</script>

<style scoped lang="scss">
@import '@axatechlab/assets/scss/_variables';

nav {
  background: #ededf4;
  flex-shrink: 0;
  border-bottom: 1px solid rgba(0, 0, 143, 0.1);
  border-top: 1px solid rgba(0, 0, 143, 0.1);
  position: sticky;
  top: 0;
  z-index: 100;
  overflow-y: hidden;
  overflow-x: hidden;

  ul,
  ul li {
    margin: 0;
    padding: 0;
  }

  ul li {
    display: inline-block;
    border-left: 1px solid rgba(0, 0, 143, 0.1);

    &:first-child {
      border-left: none;
    }

    svg {
      fill: currentColor;
      height: 24px;
      width: 24px;
    }
  }

  a {
    opacity: 0.5;
    min-height: 45px;
    border-bottom: 3px solid transparent;
    margin-top: 3px;
  }

  a:hover {
    opacity: 1;
  }

  .router-link-active {
    border-bottom: 3px solid $color-axa-blue;
    opacity: 1;
    display: block;
    color: $color-axa-blue;
  }
}

.subtitle {
  color: $color-blue;
  font-size: 0.85em;
  font-weight: 700;
  margin-top: -0.4rem;
}

.beta-label {
  font-size: 10px;
  letter-spacing: 1px;
  margin-left: 2px;
}

.fontIcon {
  font-size: 150%;
}

.search-toolbar {
  margin-left: auto;
}

.search-toolbar i {
  font-size: 1.8em;
  margin-left: 0.2em;
  cursor: pointer;
  color: #9492cc;
}

.search-toolbar i.on {
  color: #6344f7;
}

.loading-spinner {
  display: flex;
  align-items: center;

  .loading-spinner-animation {
    width: 12px;
    height: 12px;
    margin: 0 5px 0 0;
  }
}
</style>
