<template>
  <div class="InlineVersionList">
    <ul class="InlineVersionList__List">
      <li
        v-for="[label, details] in groupByMinorVersion"
        :key="details.id"
        class="InlineVersionList__ListItem"
      >
        <el-tooltip>
          <template #content>
            <strong>{{ details.mostRecent.label }}</strong>
            <ul class="InlineVersionList__List">
              <li
                v-for="patch in details.patches"
                :key="patch.id"
                class="InlineVersionList__ListItem InlineVersionList__ListItem--Splitted"
              >
                <span class="InlineVersionList__VersionNumber">
                  {{ patch.version.major }}.{{ patch.version.minor }}.{{
                    patch.version.patch
                  }}
                  ({{ patch.status }})
                </span>
              </li>
            </ul>
          </template>
          <router-link
            class="InlineVersionList__VersionNumber"
            :to="toProductRelease(details.mostRecent.version)"
          >
            {{ label }}
          </router-link>
        </el-tooltip>
      </li>
    </ul>
  </div>
</template>

<script>
import ProductReleaseMixin from './ProductReleaseMixin';

export default {
  name: 'InlineVersionList',
  mixins: [ProductReleaseMixin],
  props: {
    current: {
      type: String,
      required: true
    },
    latest: {
      type: Number,
      required: true
    },
    versions: {
      type: Array,
      required: true
    },
    tenant: {
      type: String,
      required: true
    }
  },
  computed: {
    groupByMinorVersion() {
      return this.getGroupByMinorVersion(this.versions);
    }
  },
  methods: {
    toProductRelease(version) {
      return {
        name: 'product-releases',
        params: {
          productId: this.latest
        },
        query: {
          version: `${version.major}.${version.minor}.${version.patch}`
        }
      };
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@axatechlab/assets/scss/_variables';
.InlineVersionList {
  &__List {
    display: flex;
    flex-wrap: nowrap;
    overflow: hidden;
    width: 100%;
    margin: 0;
    padding: 0;
  }
  &__ListItem {
    display: block;
    list-style: none;
    white-space: nowrap;
    padding: 0;
    margin: 0 0.5rem 0 0;
    &:last-child {
      margin-right: 0;
    }

    .InlineVersionList__VersionNumber {
      color: rgb(96, 98, 102);
    }

    &--Splitted {
      &:before {
        content: '|';
        margin-right: 0.4rem;
        &:last-child {
          &:before {
            content: '';
            margin-right: 0;
          }
        }
      }
    }
  }

  &__VersionNumber {
    white-space: nowrap;
  }

  .el-tooltip__popper::v-deep {
    .InlineVersionList__VersionNumber {
      color: white;
    }
  }
}
</style>
