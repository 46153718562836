import { render, staticRenderFns } from "./ProductRevisionRowOperations.vue?vue&type=template&id=5e320408&scoped=true"
import script from "./ProductRevisionRowOperations.vue?vue&type=script&lang=js"
export * from "./ProductRevisionRowOperations.vue?vue&type=script&lang=js"
import style0 from "./ProductRevisionRowOperations.vue?vue&type=style&index=0&id=5e320408&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5e320408",
  null
  
)

export default component.exports