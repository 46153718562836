/**
 * Copyright 2020 AXA Group Operations S.A.
 *
 * Licensed under the Apache License, Version 2.0 (the "License")
 * you may not use this file except in compliance with the License
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import types from './productCustomization.types';

export default {
  // CREATE
  // FETCH
  [types.FETCH_PRODUCT_CUSTOMIZATIONS_REQUEST](state) {
    state.isLoading = true;
    state.customizations = [];
  },
  [types.FETCH_PRODUCT_CUSTOMIZATIONS_SUCCESS](state, { customizations }) {
    state.customizations = customizations;
    state.isLoading = false;
  },
  [types.FETCH_PRODUCT_CUSTOMIZATIONS_ERROR](state) {
    state.customization = [];
    state.isLoading = false;
  }
};
