/**
 * Copyright 2020 AXA Group Operations S.A.
 *
 * Licensed under the Apache License, Version 2.0 (the "License")
 * you may not use this file except in compliance with the License
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import actions, { types } from './auth.actions';
import getters from './auth.getters';

const getInitialState = () => ({
  // !!! Use getter getUser to access user data
  _user: undefined,
  impersonatedUser: undefined,
  access_token: undefined,
  error: null,
  isLoading: false,
  tenant: null
});

const mutations = {
  [types.SET_USER](state, user) {
    state._user = user;
  },
  [types.SET_IMPERSONATED_USER](state, user) {
    state.impersonatedUser = user;
  },
  [types.SET_PASSWORD](state, password) {
    state.password = password;
  },
  [types.SET_ERROR](state, error) {
    state.error = error;
  },
  [types.SET_LOADING](state, isLoading) {
    state.isLoading = isLoading;
  },
  [types.SET_TENANT](state, value) {
    state.tenant = value;
  }
};

export default {
  namespaced: true,
  state: getInitialState(),
  getters,
  actions,
  mutations
};
